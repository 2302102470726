import * as React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  Button,
  TextField,
  Grid,
  DialogTitle,
  Alert,
  Collapse,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useFileSendToSign } from "../hooks/useFileSendToSign";

export const SendToSignDialog = ({ open, setOpen, file, project }) => {
  //take in status

  //status = created

  //render the send component, which opens
  const { mutate: sendToSign, isSuccess: isSendSuccess } = useFileSendToSign(
    project._id
  );
  const [alertOpen] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const showSendDialog = () => {
    setOpen((prev) => !prev);
  };
  const [customer, setCustomer] = React.useState(project.customer);
  const [subject, setSubject] = React.useState("Please sign this document.");
  const editCustomer = (key, value) => {
    let newCustomer = Object.assign({}, customer);
    newCustomer[key] = value;
    setCustomer(newCustomer);
  };

  const sendDoc = () => {
    setSending(true);
    let pack = {
      project: file.project,
      customer: customer,
      subject: subject,
      user: file.user,
    };
    sendToSign({ id: file._id, pack: pack });
  };
  React.useEffect(() => {
    if (isSendSuccess) {
      setSending(false);
      setOpen(false);
    }
  }, [isSendSuccess]);
  return (
    <>
      <Dialog open={open} onClose={showSendDialog}>
        <DialogTitle>Confirm Info Before Sending</DialogTitle>
        <Grid container spacing={1} sx={{ m: 1, width: "90%" }}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="First Name"
              value={customer.firstName}
              onChange={(e) => editCustomer("firstName", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Last Name"
              value={customer.lastName}
              onChange={(e) => editCustomer("lastName", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              value={customer.email}
              onChange={(e) => editCustomer("email", e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            ></TextField>
          </Grid>
        </Grid>
        {/* {pro.customer.firstName}
          {pro.customer.lastName}
          {pro.customer.email} */}
        <DialogActions>
          {sending ? (
            <CircularProgress />
          ) : (
            <Button onClick={() => sendDoc()}> Send</Button>
          )}
        </DialogActions>
        <Collapse in={alertOpen}>
          <Alert>Alert!</Alert>
        </Collapse>
      </Dialog>
    </>
  );
};
SendToSignDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  status: PropTypes.string,
  file: PropTypes.object,
  project: PropTypes.object,
  update: PropTypes.func,
  deleteDoc: PropTypes.func,
};
