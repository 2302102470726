export const steps = {
  firstTime: [
    {
      intro:
        "Welcome to Manufacture.app! This guide will show you around the app.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#sidebar",
      intro:
        "This is the sidebar. You can navigate to different sections of the app from here.",
      position: "right",
      tooltipClass: "customStep",
    },
    {
      element: "#settings",
      intro:
        "This is the settings section. You can change your settings from here.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#help",
      intro:
        "This is the help button. You can click it and a guide like this will show up for the page you are currently on. Try it now for this page!",
      position: "bottom",
      tooltipClass: "customStep",
    },
  ],
  Home: [
    {
      intro: "This is your dashboard!",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#dashboard-notifications",
      intro:
        "This is the notifications section. Here you will see what has happened since last you checked. Tasks that have been completed, projects that have been updated, invoices paid, etc.",
      position: "left",
      tooltipClass: "customStep",
    },
    {
      element: "#dashboard-tasks",
      intro:
        "This is the tasks section. Here you will see what tasks are due today, and what tasks are overdue.",
      position: "left",
      tooltipClass: "customStep",
    },
    {
      element: "#dashboard-projects",
      intro:
        "This is the projects section. You can see how projects have changed over the last week.",
      position: "right",
      tooltipClass: "customStep",
    },
    {
      element: "#quick-actions",
      intro:
        "This is the quick actions section. Here you can quickly create a new customer, project, or product.",
      position: "right",
      tooltipClass: "customStep",
    },
    {
      element: "#bigFinancial",
      intro: "This is a summary of your financials over the last week.",
      position: "right",
      tooltipClass: "customStep",
      sub: "financial",
    },
  ],
  Customers: [
    {
      intro: "This is the customers page.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#customers-table",
      intro:
        "This is the customers table. Here you can see all your customers, and their information.",
      position: "right",
      tooltipClass: "customStep",
    },
    {
      element: "#customers-new",
      intro:
        "This is the new customer button. You can click it to create a new customer.",
      position: "bottom",
      tooltipClass: "customStep",
    },
  ],
  ViewCustomer: [
    {
      intro: "This is the customer view.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#customer-view",
      intro:
        "This is the customer view. Here you can see all the information about this customer.",
      position: "right",
      tooltipClass: "customStep",
    },
    {
      element: "#customer-edit",
      intro:
        "This is the customer edit button. You can click it to edit this customer.",
      position: "bottom",
      tooltipClass: "customStep",
    },
  ],
  Projects: [
    {
      intro: "This is the projects page.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#projects-table",
      intro:
        "This is the projects table. Here you can see all your projects, and their information.",
      position: "right",
      tooltipClass: "customStep",
    },
    {
      element: "#projects-new",
      intro:
        "This is the new project button. You can click it to create a new project.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#project-view-switch",
      intro: "Here you can switch between the table and the card view.",
    },
  ],
  Orders: [],
  Market: [],
  ViewProject: [
    {
      intro: "This is the project view.",
      position: "bottom",
      tooltipClass: "customStep",
    },
  ],
  Inventory: [
    {
      intro: "This is the inventory page.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#inventory-table",
      intro:
        "This is the inventory table. Here you can see all your inventory, and their information.",
      position: "right",
      tooltipClass: "customStep",
    },
  ],
  Suppliers: [
    {
      intro: "This is the suppliers page.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#suppliers-table",
      intro:
        "This is the suppliers table. Here you can see all your suppliers, and their information.",
      position: "right",
      tooltipClass: "customStep",
    },
    {
      element: "#suppliers-new",
      intro:
        "This is the new supplier button. You can click it to create a new supplier.",
      position: "bottom",
      tooltipClass: "customStep",
    },
  ],
  ViewSupplier: [
    {
      intro: "This is the supplier view.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#supplier-view",
      intro:
        "This is the supplier view. Here you can see all the information about this supplier.",
      position: "right",
      tooltipClass: "customStep",
    },
    {
      element: "#supplier-edit",
      intro:
        "This is the supplier edit button. You can click it to edit this supplier.",
      position: "bottom",
      tooltipClass: "customStep",
    },
  ],
  Files: [
    {
      intro: "This is the files page.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#files-table",
      intro:
        "This is the files table. Here you can see all your files, and their information.",
      position: "right",
      tooltipClass: "customStep",
    },
    {
      element: "#files-new",
      intro:
        "This is the new file button. You can click it to create a new file.",
      position: "bottom",
      tooltipClass: "customStep",
    },
  ],
  Templates: [
    {
      intro: "This is the templates page.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#templates-new",
      intro:
        "This is the new template button. You can click it to create a new template.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#templates-cards",
      intro:
        "This is where your templates show up. You can hover over them to see more actions such as view, edit, and delete.",
      position: "right",
      tooltipClass: "customStep",
    },
  ],
  ViewTemplate: [
    {
      intro: "This is the template view.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: ".template-view",
      intro:
        "This is the document. Hovering over a line will show six dots on the left, and that's how you can edit a specific line. You can also click on the plus on the left of an empty line to add a block.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#save-template",
      intro:
        "Click here to save the template. Make sure to give it a name first!",
      position: "right",
      tooltipClass: "customStep",
    },
    {
      element: "#template-variables",
      intro:
        "This brings up the variables table. You can click and drag it, and clicking on a variable row will copy the variable to your clipboard so you can paste it into the document.",
      position: "bottom",
      tooltipClass: "customStep",
    },
  ],
  Financial: [
    {
      intro: "This is the financial page.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#financial-table",
      intro:
        "This is the financial table. Here you can see all your invoices, and their information.",
      position: "right",
      tooltipClass: "customStep",
    },
    {
      element: "#financial-new",
      intro:
        "This is the new invoice button. You can click it to create a new invoice.",
      position: "bottom",
      tooltipClass: "customStep",
    },
  ],
  Components: [
    {
      intro:
        "This is the create page. Here is where you can create new products or components.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#product-toggle",
      intro:
        "This is the product toggle. You can click it to switch between creating a product and creating a component. A component is a part of a product that you want to keep track of.",
      position: "right",
      tooltipClass: "customStep",
    },
    {
      element: "#product-photo",
      intro:
        "This is the product photo. You can click it to upload a photo for your product.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#product-categories",
      intro:
        "This is the product categories. You can select the categories that this product belongs to. This is only used to help you organize.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#product-price",
      intro:
        "This is the product price. You can enter the price of this product here.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#product-smart-price",
      intro:
        "This is the smart price button. You can click it to automatically calculate the price of this product based on the cost of components inside it.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#product-cost",
      intro:
        "This is the product cost. You can enter the cost of this product here. This is only used to help you keep track of your costs.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#product-parameters",
      intro:
        "This is the parameters section. Here you can add parameters to your product. Parameters are things like width, height, etc. that you want to keep track of. The wrench icon once you add a parameter will show you advanced settings. (Part of the Product Module)",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#product-subcomponents",
      intro:
        "This is the subcomponents section. Here is where the subcomponents of the product or component will show up. You can add them by selecting a component from the list on the right and then clicking the button at the top.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#component-list",
      intro:
        "This is the component list. Here you can see all your components. You can select one to add it to the subcomponents section. This is the same as your inventory list.",
      position: "left",
      tooltipClass: "customStep",
    },
    {
      element: "#component-list-mode",
      intro:
        "This is the component list mode. You can switch between viewing components and viewing options. After you've switched to options, the button to create a new option will show up. Options are used to let you customize a product when adding it to a project.",
      position: "left",
      tooltipClass: "customStep",
    },
  ],
  Calendar: [
    {
      intro: "This is the calendar page.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#calendar",
      intro:
        "This is the calendar. Here you can see all your tasks, and their information.",
      position: "right",
      tooltipClass: "customStep",
    },
  ],
  People: [
    {
      intro: "This is the people page.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#people-table",
      intro:
        "This is the people table. Here you can see all your people, and their information.",
      position: "right",
      tooltipClass: "customStep",
    },
    {
      element: "#people-new",
      intro:
        "This is the new person button. You can click it to create a new person.",
      position: "bottom",
      tooltipClass: "customStep",
    },
  ],
  CreateOption: [
    {
      intro:
        "This is the option creation page. An option contains choices (components) that you can choose from when adding a product to a project. For example, if you have a box, an option could be the type of lid that you want to put on the box. The choices for that option could be a hinged lid, a sliding lid, or a removable lid; each of which would be a component.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#option-required",
      intro:
        "This is the required toggle. You can click it to select if this option requires that you make a choice.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#option-multiple",
      intro:
        "This is the multiple toggle. You can click it to select if this option allows multiple choices.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#option-parameters",
      intro:
        "This is the option parameter override. Use this if you want to override the parameters of the component that this option is based on.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#option-choices",
      intro:
        "This is the option choices. Here you can add choices to your option after selecting the component on the right. Choices are the different components that you can choose from for this option.",
      position: "bottom",
      tooltipClass: "customStep",
    },
  ],
  Parameters: [
    {
      intro: "This is the advanced parameters page.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#parameters-value",
      intro:
        "This is the value field. Here you can enter the value of the parameter. You can also use formulas to calculate the value.",
      position: "bottom",
      tooltipClass: "customStep",
    },
    {
      element: "#parameters-conditions",
      position: "bottom",
      intro:
        "You can check this if you want the parameter to use conditional logic. For example, in the 'if' field you could put 'width < 10' and in the 'then' field you could put '5'. This would mean that if the width is less than 10, the parameter value would be 5.",
      tooltipClass: "customStep",
    },

    {
      element: "#component-tree",
      position: "bottom",
      intro:
        "Here is the component tree. This shows you the relationship between components and subcomponents. Hovering one shows you its parameters and lets you edit them. Clicking on the copy icon will copy that parameter's unique variable name (very important!). Only that way you can use it in formulas.",
      tooltipClass: "customStep",
    },
  ],
};
