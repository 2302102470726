import { Box, Button, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { modules } from "../../signup/utils/modules";
import { checkSubscription } from "../../../utils/checkSubscription";
import { ModuleSelection } from "../../signup/components/ModuleSelection";

export const Account = ({ user, logout, settings }) => {
  const [editingSubscriptions, setEditingSubscriptions] = React.useState(false);

  if (!settings) return null;
  const sub = checkSubscription(settings) || [];
  const modulesActive = sub.map((m) =>
    modules.find((mod) => mod.lookup_key === m)
  );
  const total = modulesActive.reduce((acc, curr) => acc + curr.price, 0);
  return (
    <>
      {editingSubscriptions ? (
        // <EditSubscription />
        <ModuleSelection settings={settings} existingUser={user.id} />
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
            <Typography variant="h4">Account</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              margin: "auto 120px",
            }}
          >
            <Typography variant="h4">Subscription:</Typography>
            {settings.subscription?.status === "active" ? (
              <Typography variant="h4">{`${
                modulesActive.map((m) => m.name).join(", ") || "None"
              } - ${formatter.format(total)}/month`}</Typography>
            ) : (
              "None"
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              sx={{ mr: 2 }}
              onClick={() => setEditingSubscriptions(true)}
            >
              Edit Subscription
            </Button>

            <Button variant="outlined" onClick={logout}>
              {" "}
              Log out{" "}
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

Account.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  settings: PropTypes.object,
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
