import React from "react";
import { EditorContainer } from "../../templateEditor/components/EditorContainer";
import PropTypes from "prop-types";

export const CreateTemplate = ({ selectedTemplate, handleCreating }) => {
  return (
    <>
      <EditorContainer
        selectedTemplate={selectedTemplate}
        handleCreating={handleCreating}
      />
    </>
  );
};

CreateTemplate.propTypes = {
  selectedTemplate: PropTypes.object,
  handleCreating: PropTypes.func,
};
