import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { OverviewTab } from "./OverviewTab";
import { ProjectTabs } from "./ProjectTabs";
// import { ProductsTab } from "./ProductsTab";
import { OptionsZonesTab } from "./OptionsZonesTab";
import { FinancialView } from "./FinancialView";
import { ProjectFileManager } from "../../fileManager/components/ProjectFileManager";
import {
  Delete,
  Edit,
  Email,
  KeyboardBackspace,
  LocationOn,
  Person,
  Phone,
} from "@mui/icons-material";
// import { ProjectLog } from "../../statusStepper/components/ProjectLog";
import { EditProjectDialog } from "./EditProjectDialog";
import { columns, titles } from "../../../components/common/utils";
import { useProjectDelete } from "../hooks/useProjectDelete";
import { ProjectHistory } from "./ProjectHistory";
import { MozaikRooms } from "./importedViews/MozaikRooms";
// import { useCachedProjects } from "../../../hooks/useCachedProjects";
import { useQueryClient } from "react-query";
import { useProjectData } from "../../../hooks/useProjectsData";
import { useParametersData } from "../../../hooks/useParametersData";
import { Products } from "./newProducts/components/Products";
import { PlanTab } from "./plan/components/PlanTab";
import ChatWindow from "./chat/components/ChatWindow";
// import { MozaikProducts } from "./importedViews/MozaikProducts";

// import StatusStepper from "../../statusStepper/components/StatusStepper";

export const SelectedProjectContext = React.createContext();

export const useSyncedProjectContext = (projectId) => {
  const queryClient = useQueryClient();
  const [project, setProject] = React.useState(null);

  React.useEffect(() => {
    // Subscribe to the project data
    const unsubscribe = queryClient.getQueryCache().subscribe(() => {
      // Fetch the latest project data
      const newProjectData = queryClient.getQueryData(["project", projectId]);
      setProject(newProjectData);
    });

    // Unsubscribe when the component unmounts
    return () => unsubscribe();
  }, [queryClient, projectId]);

  return project;
};

export const ProjectView = ({ selectedProject, handleBack }) => {
  // const project = useSyncedProjectContext(selectedProject);
  const { data: project } = useProjectData(selectedProject);
  const [tab, setTab] = React.useState("overview");

  console.log(project);
  useParametersData();
  if (!project) {
    return null;
  }

  const TAB_COMPONENTS = {
    overview: <OverviewTab setTab={setTab} />,
    products: <Products />,
    optionZones:
      project.imported == "Mozaik" ? <MozaikRooms /> : <OptionsZonesTab />,
    files: <ProjectFileManager />,
    financial: <FinancialView />,
    tasks: <PlanTab />,
    chat: <ChatWindow project={project} />,
  };

  return (
    <div style={{ height: "800px" }}>
      <SelectedProjectContext.Provider value={project}>
        <Button
          onClick={handleBack}
          startIcon={<KeyboardBackspace />}
          sx={{ mb: 1 }}
        >
          Back
        </Button>
        <Grid container spacing={2} sx={{ height: "100%" }}>
          <Grid
            item
            xs={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              flex: 1,
              maxHeight: "100%",
            }}
          >
            <ProjectInfoCard handleBack={handleBack} />
            <ProjectHistory />
          </Grid>
          <Grid item xs={8}>
            <ProjectTabs tab={tab} setTab={setTab} />
            <Box sx={{ height: 636, width: "100%" }}>{TAB_COMPONENTS[tab]}</Box>
          </Grid>
        </Grid>
      </SelectedProjectContext.Provider>
    </div>
  );
};
ProjectView.propTypes = {
  selectedProject: PropTypes.string,
  handleBack: PropTypes.func,
};

const ProjectInfoCard = ({ handleBack }) => {
  const [open, setOpen] = React.useState(false);
  const project = React.useContext(SelectedProjectContext);
  const [deleting, setDeleting] = React.useState(false);
  const { mutate: deleteProject } = useProjectDelete();
  const handleDelete = async () => {
    if (deleting) {
      deleteProject({ pack: project._id });
      handleBack();
    } else {
      setDeleting(true);
      setTimeout(() => {
        setDeleting(false);
      }, 3000);
    }
  };

  return (
    <Card id="project-card">
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ mb: 1 }} variant="h4">
            {" "}
            {project.projectName}
          </Typography>
          <span
            style={{
              width: "28px",
              height: "28px",
              borderRadius: "100px",
              backgroundColor: project.color || null,
            }}
          ></span>
        </Box>
        <Typography
          variant="body1"
          sx={{ mb: 1, display: "flex", alignItems: "center", gap: 1 }}
        >
          {" "}
          <LocationOn />
          {project.address}
        </Typography>
        {project.customer ? (
          <>
            <Typography
              variant="body1"
              sx={{ mb: 1, display: "flex", alignItems: "center", gap: 1 }}
            >
              {" "}
              <Person />
              {`${project.customer.firstName} ${project.customer.lastName}`}
            </Typography>
            <Typography
              variant="body1"
              sx={{ mb: 1, display: "flex", alignItems: "center", gap: 1 }}
            >
              <Phone /> {project.customer.phone}
            </Typography>
            <Typography
              sx={{ mb: 1, display: "flex", alignItems: "center", gap: 1 }}
              variant="body1"
            >
              {" "}
              <Email />
              {project.customer.email}
            </Typography>
          </>
        ) : null}
        <Typography variant="body1">
          {" "}
          Created: {new Date(project.creationDate).toLocaleDateString("en-US")}
        </Typography>
        <Typography sx={{ mb: 1 }} variant="body1">
          Due: {new Date(project.dueDate).toLocaleDateString("en-US")}
        </Typography>
      </CardContent>
      <CardActions>
        <IconButton onClick={() => setOpen(true)}>
          <Edit />
        </IconButton>
        <IconButton onClick={handleDelete}>
          <Delete color={deleting ? "error" : "default"} />
        </IconButton>

        <EditProjectDialog
          title={titles["pros"]}
          isOpen={open}
          setOpen={setOpen}
          fields={columns["pros"]}
          updateTable={"projects"}
          data={project}
          autocompleteEndpoint={"customer"}
        />
      </CardActions>
    </Card>
  );
};
ProjectInfoCard.propTypes = {
  project: PropTypes.obj,
  handleBack: PropTypes.func,
};
