import React from "react";
import { UserContext } from "../../../App";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../../../Content";
import { updateSettings } from "../../../api/settingsApi";
import { useSettingsData } from "../../../hooks/useSettingsData";

export function useStepUpdate() {
  const user = React.useContext(UserContext);
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { data: settings } = useSettingsData();

  return useMutation({
    mutationFn: ({ pack }) => {
      pack.user = user;
      let indexToUpdate = settings.statusSteps.findIndex(
        (step) => step.id === pack.id
      );
      let steps = [...settings.statusSteps];
      steps[indexToUpdate] = pack;

      updateSettings({
        user,
        pack: { statusSteps: steps },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("settings");
      setSnack({
        open: true,
        message: "Step updated.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Step could not be updated.",
        severity: "error",
      });
    },
  });
}
