import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import { useComponentsData } from "../../../hooks/useComponentsData";
import PropTypes from "prop-types";
import { Product } from "./Product";
import { useCatalogCreate } from "../hooks/useCatalogCreate";
import { Add } from "@mui/icons-material";

export const AddCatalog = () => {
  const { data: components } = useComponentsData();
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");

  const handleSelect = (id) => {
    if (selected.includes(id)) {
      setSelected(selected.filter((item) => item !== id));
    } else {
      setSelected([...selected, id]);
    }
  };
  const { mutate: createCatalog } = useCatalogCreate();

  const handleSaveCatalog = () => {
    createCatalog({ name, description, products: selected });
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        startIcon={<Add />}
        variant="outlined"
      >
        Add Catalog
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Add Catalog</DialogTitle>
        <DialogContent>
          <Box>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              label="Catalog Name"
              fullWidth
              sx={{ mb: 1, mt: 1 }}
            />
            <TextField
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              label="Description"
              fullWidth
              multiline
              minRows={3}
              sx={{ mb: 1 }}
            />
            <Box sx={{ display: "flex", gap: 1, wrap: "flex-wrap" }}>
              {components
                .filter((component) => component.isProduct)
                .map((product) => (
                  <Product
                    key={product.name}
                    product={product}
                    handleSelect={handleSelect}
                    selected={selected.includes(product._id)}
                  />
                ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={handleSaveCatalog}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AddCatalog.propTypes = {
  catalog: PropTypes.object.isRequired,
};
