import {
  Stack,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  Paper,
  DialogContent,
  DialogActions,
  Grid,
  CircularProgress,
} from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";
import { TemplateViewEditor } from "./TemplateViewEditor";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import jsPDF from "jspdf";
import { useFileCreate } from "../../fileManager/hooks/useFileCreate";
import { useFoldersData } from "../../fileManager/hooks/useFoldersData";

export const TemplateView = ({
  template,
  projects,
  customers,
  handleClose,
  select,
}) => {
  console.log(select);
  const [uploading, setUploading] = React.useState(false);
  const handleProductChange = (prods) => {
    setSelectedProducts(prods);
    setSelectionModel(prods.map((p) => p._id));
  };
  const handleSelectionModelChange = (newSelectionModel) => {
    setSelectionModel(newSelectionModel);
    setSelectedProducts(
      newSelectionModel.map((p) => allProducts.find((ap) => ap._id === p))
    );
  };
  const handleProductOpen = () => {
    setProductsOpen(true);
  };
  const handleProductClose = () => {
    setProductsOpen(false);
  };

  const [selection, setSelection] = React.useState(
    select
      ? {
          ...select,
          project: {
            ...select.project,
          },
        }
      : { project: { projectName: "" }, customer: null, products: [] }
  );

  const handleSelect = (key, value) => {
    if (key === "project") {
      setSelection({
        project: {
          ...value,
        },
        customer: value.customer,
        products: value.products,
      });
      handleProductChange(value.products);
    } else {
      setSelection((prev) => ({ ...prev, [key]: value }));
    }
  };

  const { data: folders } = useFoldersData();

  const { mutate: createFile, isSuccess: isCreateSuccess } = useFileCreate(
    selection.project
  );
  const handleSave = async () => {
    setUploading(true);
    const doc = new jsPDF("p", "pt", [1314, 1600], true, true);
    doc.setFont("Roboto");
    doc.setFontSize(9);
    doc.addPage();
    doc.html(document.getElementById("pdfWrapper"), {
      callback: function (docData) {
        let data = new FormData();
        let docBlob = new Blob([docData.output("blob")], {
          type: "application/pdf",
        });
        let file = new File(
          [docBlob],
          `${template.name}${template.number}.pdf`,
          {
            type: "application/pdf",
          }
        );
        data.append("file", file);
        data.append("project", selection.project._id);
        data.append("customer", selection.customer._id);
        data.append(
          "folder",
          select.folder
            ? select.folder._id
            : folders.find((f) => f.project === selection.project._id)?._id
        );
        data.append("template", template._id);
        createFile({ pack: data });
      },
      x: 10,
      y: 10,
      autoPaging: "text",
    });
  };
  const [selectionModel, setSelectionModel] = React.useState(
    selection.products.map((p) => p._id)
  );
  const [selectedProducts, setSelectedProducts] = React.useState([
    ...selection.products,
  ]);
  const allProducts = selection.products;
  const [productsOpen, setProductsOpen] = React.useState(false);

  React.useEffect(() => {
    if (isCreateSuccess) {
      setUploading(false);
      handleClose();
    }
  }, [isCreateSuccess]);

  const ejInstance = React.useRef();

  if (!projects) return null;

  return (
    <>
      <DialogActions sx={{ boxShadow: 8, zIndex: 3 }}>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Stack direction="row" spacing={2}>
              <FormControl>
                <InputLabel id="project-select-label">Project</InputLabel>
                <Select
                  value={selection.project}
                  size="small"
                  renderValue={(project) => {
                    return project.projectName;
                  }}
                  onChange={(e) => handleSelect("project", e.target.value)}
                  sx={{ width: 300 }}
                  label="Project"
                >
                  {projects.map((project) => (
                    <MenuItem key={project._id} value={project}>
                      {project.projectName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel
                  id="customer-select-label"
                  shrink={selection.customer ? true : false}
                >
                  Customer
                </InputLabel>
                <Select
                  value={selection.customer}
                  sx={{ width: 300 }}
                  size="small"
                  renderValue={(customer) => {
                    return customer.firstName + " " + customer.lastName;
                  }}
                  onChange={(e) => handleSelect("customer", e.target.value)}
                  label="Customer"
                >
                  {customers.map((customer) => (
                    <MenuItem key={customer._id} value={customer}>
                      {customer.firstName + " " + customer.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="outlined"
                disabled={allProducts.length === 0}
                onClick={() => handleProductOpen(true)}
              >
                Products
              </Button>
            </Stack>
          </Grid>
          <Grid item>
            <Stack direction="row" spacing={2}>
              <Button
                variant="contained"
                onClick={() => handleSave()}
                disabled={uploading}
              >
                {uploading ? <CircularProgress size={24} /> : "Save"}
              </Button>

              <Button variant="outlined" onClick={() => handleClose()}>
                Close
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogActions>
      <DialogContent>
        <TemplateViewEditor
          ejInstance={ejInstance}
          template={template}
          selectedProject={selection.project}
          selectedCustomer={selection.customer}
          selectedProducts={selectedProducts}
        />
      </DialogContent>
      <Dialog
        open={productsOpen}
        onClose={() => handleProductClose()}
        maxWidth="md"
        fullWidth
      >
        <Paper>
          <DataGridPremium
            columns={columns}
            rows={allProducts}
            keepNonExistentRowsSelected
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelection) => {
              handleSelectionModelChange(newSelection);
            }}
            checkboxSelection
            sx={{ height: 800 }}
            density="compact"
            getRowId={(row) => row._id}
          />
        </Paper>
      </Dialog>
    </>
  );
};
TemplateView.propTypes = {
  template: PropTypes.object,
  projects: PropTypes.array,
  customers: PropTypes.array,
  selectedProject: PropTypes.object,
  setSelectedProject: PropTypes.func,
  handleClose: PropTypes.func,
  select: PropTypes.object,
};

const columns = [
  { field: "name", headerName: "Name", flex: 1 },
  { field: "price", headerName: "Price", flex: 1 },
  { field: "quantity", headerName: "Quantity", flex: 1 },
  { field: "total", headerName: "Total", flex: 1 },
];
