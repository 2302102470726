import React from "react";
import PropTypes from "prop-types";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { CardActions } from "./CardActions";

const CLOUDFRONT_URL = "https://cdn.manufacture.app/";

export const TemplateCard = ({
  template,
  projects,
  customers,
  setSelectedTemplate,
}) => {
  const [hover, setHover] = React.useState(false);
  return (
    <Card
      sx={{ width: 220, height: 250 }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {/* somehow get a thumbnail of the first 
            page of the template and put it here */}
      <CardMedia
        image={`${CLOUDFRONT_URL}${template.image}`}
        sx={{
          height: hover ? 150 : 200,
          backgroundPosition: "top",
          transition: "height 0.5s ease",
        }}
      />
      <CardContent sx={{ height: 50, pt: 1 }}>
        <Typography variant="h6">{template.name}</Typography>
        <CardActions
          template={template}
          projects={projects}
          customers={customers}
          setSelectedTemplate={setSelectedTemplate}
        />
      </CardContent>
    </Card>
  );
};

TemplateCard.propTypes = {
  template: PropTypes.object,
  projects: PropTypes.array,
  customers: PropTypes.array,
  setSelectedTemplate: PropTypes.func,
};
