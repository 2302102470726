/* eslint-disable no-unreachable */
import React from "react";
import { limitedEvaluate } from "../../flowCreate/components/utils/limitedEvaluate";
import { useCachedParameters } from "../../../hooks/useCachedParameters";
import _ from "lodash";

export function useProduct({ data, dataType }) {
  const parameters = useCachedParameters();

  const [product, setProduct] = React.useState(
    dataType === "existing"
      ? initializeExistingProduct(
          data,
          parameters
            .filter((p) => p.global)
            .map((p) => ({ ...p, variable: p.name }))
        )
      : dataType === "new"
      ? initializeComponentData(data)
      : {
          _id: "product",
          id: 0,
          name: "Component",
          description: "",
          image: "",
          cost: null,
          price: null,
          priceModifier: 0,
          suppliers: [],
          subcomponents: [],
          flatSubcomponents: [],
          options: [],
          type: [],
          isProduct: false,
          categories: [],
          parameters: [],
        }
  );

  const clearProduct = () => {
    setProduct({
      _id: "product",
      name: "Component",
      description: "",
      image: "",
      cost: "",
      price: "",
      priceModifier: 0,
      suppliers: [],
      subcomponents: [],
      flatSubcomponents: [],
      options: [],
      type: [],
      isProduct: false,
      categories: [],
      parameters: [],
    });
  };

  const handleEdit = ({ field, value, type }) => {
    switch (field) {
      case "parameters":
        switch (type) {
          // handle update
          case "update":
            setProduct((prev) =>
              handleParameterUpdate({
                component: prev,
                parameter: value,
                variables: parameters
                  .filter((p) => p.global)
                  .map((p) => ({ ...p, variable: p.name })),
              })
            );
            break;
          // handle add or remove
          default:
            setProduct((prev) => ({
              ...prev,
              parameters: handleParameterAddRemove({
                component: prev,
                parameter: value,
              }),
            }));
            break;
        }
        break;
      case "options":
        switch (type) {
          case "update":
            setProduct((prev) => {
              let component = handleOptionUpdate({
                component: prev,
                option: value,
                variables: parameters
                  .filter((p) => p.global)
                  .map((p) => ({ ...p, variable: p.name })),
              });
              return component;
            });
            break;
        }
        break;
      case "product":
        setProduct({
          ...initializeComponentData(value),
        });
        break;
      case "existingProduct":
        setProduct(
          initializeExistingProduct(
            value,
            parameters
              .filter((p) => p.global)
              .map((p) => ({ ...p, variable: p.name }))
          )
        );
        break;
      default:
        setProduct((prev) => ({
          ...prev,
          [field]: value,
        }));
    }
  };
  return [product, handleEdit, clearProduct, setProduct];
}

// handle parameter add or remove
const handleParameterAddRemove = ({ component, parameter }) => {
  let index = component.parameters.findIndex(
    (p) => p.name === parameter.name && p.hierarchy.length === 1
  );
  if (index > -1) {
    return component.parameters.filter(
      (p) => p.name !== parameter.name || p.hierarchy.length !== 1
    );
  } else {
    return [...component.parameters, parameter];
  }
};
// handle parameter update
const handleParameterUpdate = ({ component, parameter, variables }) => {
  // if (parameter.hierarchy.length === 1) {
  //   if (parameter.name === "qty") {
  //     component.qty = parameter;
  //   } else {
  //     let index = component.parameters.findIndex(
  //       (p) => p.name === parameter.name && p.hierarchy.length === 1
  //     );
  //     component.parameters[index] = parameter;
  //   }
  // } else {
  //   let subcompIndex = component.flatSubcomponents.findIndex(
  //     (s) => s.hierarchy.join("-") === parameter.hierarchy.join("-")
  //   );
  //   if (parameter.name === "qty") {
  //     component.flatSubcomponents[subcompIndex].qty = parameter;
  //   } else {
  //     let paramIndex = component.flatSubcomponents[
  //       subcompIndex
  //     ].parameters.findIndex((p) => p.name === parameter.name);
  //     component.flatSubcomponents[subcompIndex].parameters[paramIndex] =
  //       parameter;
  //   }
  // }
  let paramIndex = component.allParameters.findIndex(
    (p) =>
      p.hierarchy.join("-") == parameter.hierarchy.join("-") &&
      p.name === parameter.name
  );
  component.allParameters[paramIndex].selection = parameter.selection;

  // component.allParameters = getAllParameters(component);

  let allParameters = calculateParams(component, variables);
  component.allParameters = [...allParameters];
  component.price = calculatePrice(component);
  return {
    ...component,
    allParameters,
  };
};

// handle subcomponent add
// const handleSubcomponentAdd = ({ component, subcomponents }) => {

// handle subcomponent remove

// handle option update
// eslint-disable-next-line no-unused-vars
const handleOptionUpdate = ({ component, option, variables }) => {
  let index = component.options.findIndex((o) => o._id === option._id);
  if (component.options[index].choice) {
    component = removeChoice({ component, option: component.options[index] });
  }
  component.options[index] = option;

  console.log({ component, option });

  option.sources.forEach((source) => {
    let subcomp = _.cloneDeep(option.choice);
    subcomp.hierarchy = [...source.split("-")];
    subcomp.hierarchy;
    subcomp.hierarchy.push(option._id);
    subcomp.hierarchy.push(subcomp._id);
    // assign type to subcomponent that owns the option
    let optionParentIndex = component.flatSubcomponents.findIndex(
      (s) =>
        s.hierarchy.join("-").replace("parent", component._id) ===
        source.replace("parent", component._id)
    );
    let optionParent = component.flatSubcomponents[optionParentIndex];
    if (source === "parent" || source.split("-").length === 1) {
      optionParent = { ...component };
    }
    if (optionParent) {
      if (!optionParent.type) {
        optionParent.type = [];
      }
      optionParent.type = [...optionParent.type, subcomp.name];
      // !!! Here we should calculate the qty of the choice !!!
      let optionQtyParam = option.parameters.find(
        (p) =>
          p.name === "qty" &&
          p.hierarchy.length === 2 &&
          p.hierarchy[1] === subcomp._id
      );
      if (component.parameterOverrides) {
        let hierarchyToFind = [component._id, option._id, subcomp._id].join(
          "-"
        );
        let override = component.parameterOverrides.find(
          (p) => p.name === "qty" && p.hierarchy.join("-") === hierarchyToFind
        );
        if (override) {
          optionQtyParam = { ...override };
        }
      }
      if (optionQtyParam) {
        subcomp.qty.value = optionQtyParam.value * optionParent.qty.value;
        subcomp.parameters.push({
          ...optionQtyParam,
          name: "qty",
          value: optionQtyParam.value * optionParent.qty.value,
          hierarchy: [...subcomp.hierarchy],
        });
      } else {
        subcomp.qty.value = optionParent.qty.value;
        subcomp.parameters.push({
          name: "qty",
          value: optionParent.qty.value,
          hierarchy: [...subcomp.hierarchy],
        });
      }
      // change hierarchy of qty params for its subcomponents to be equal to its new hierarchy
      subcomp.parameters = subcomp.parameters.map((param) => {
        if (param.name !== "qty") {
          return {
            ...param,
            hierarchy: [...subcomp.hierarchy],
          };
        } else {
          return { ...param };
        }
      });
      //
      component.flatSubcomponents[optionParentIndex] = optionParent;
    }

    component.flatSubcomponents.push(subcomp);
    let flattened = flattenSubcomponents(subcomp, subcomp.hierarchy, component);
    component.flatSubcomponents = component.flatSubcomponents.concat(flattened);
  });

  component.allParameters = getAllParameters(component);
  let allParameters = calculateParams(component, variables);
  component.allParameters = [...allParameters];
  // let { subcomponents, allParameters } = calculateParams(newComp, variables);
  component.price = calculatePrice(component);

  return {
    ...component,
    options: component.options,
    subcomponents: component.flatSubcomponents,
    allParameters: allParameters,
  };
};

const removeChoice = ({ component, option }) => {
  let index = component.options.findIndex((o) => o._id === option._id);
  console.log({ component, option });
  option.sources.forEach((source) => {
    let subcomp = _.cloneDeep(option.choice);
    if (source === "parent" || source.split("-").length === 1) {
      component.type = component.type?.filter((t) => t !== subcomp.name);
    } else {
      let sourceIndex = component.flatSubcomponents.findIndex(
        (s) =>
          s.hierarchy.join("-").replace("parent", component._id) ===
          source.replace("parent", component._id)
      );
      if (sourceIndex === -1) {
        return;
      }
      component.flatSubcomponents[sourceIndex].type =
        component.flatSubcomponents[sourceIndex].type?.filter(
          (t) => t !== subcomp.name
        ) || [];
    }
    subcomp.hierarchy = [...source.replace("parent", component._id).split("-")];
    subcomp.hierarchy.push(option._id);
    subcomp.hierarchy.push(subcomp._id);
    let subcompIndex = component.flatSubcomponents.findIndex(
      (s) => s.hierarchy.join("-") === subcomp.hierarchy.join("-")
    );
    component.flatSubcomponents.splice(subcompIndex, 1);
  });
  delete component.options[index].choice;
  component.allParameters = getAllParameters(component);
  return component;
};

export const flattenSubcomponents = (component, hierarchy, parent) => {
  let flatSubcomponents = [];
  // give component overrideden qty
  //   let override = parent.parameterOverrides.find(
  //     (p) =>
  //       p.hierarchy.join("-") === component.hierarchy?.join("-") &&
  //       p.name === "qty"
  //   );
  //   if (override) {
  //       `overriding C ${component.name} qty to equal ${override.value}`
  //     );
  //     component.qty = override;
  //   }
  // }

  // start flattening
  component.subcomponents.forEach((subcomponent) => {
    // define subcomponent to add
    let subcomp = {
      ...subcomponent,
      hierarchy: [...hierarchy, subcomponent._id],
    };

    // set qty of subcomponent to be parent qty * subcomponent parameter qty
    let foundQty = component.parameters.find(
      (p) =>
        p.name === "qty" &&
        p.hierarchy.length == 2 &&
        p.hierarchy[1] === subcomponent._id
    );

    if (parent.parameterOverrides?.length) {
      let override = parent.parameterOverrides.find(
        (p) =>
          p.name === "qty" &&
          p.hierarchy.join("-") === subcomp.hierarchy.join("-")
      );
      if (override) {
        foundQty = override;
      }
    }

    if (foundQty) {
      let qty = foundQty.value * component.qty.value;
      subcomp.qty.value = qty;
      // RIGHT HERE
      subcomp.parameters = subcomp.parameters.concat({
        ...foundQty,
        name: "qty",
        value: qty,
        hierarchy: [...subcomp.hierarchy],
        details: "from foundQty",
        originalHierarchy: [...foundQty.hierarchy],
      });

      let indexToSplice = component.parameters.findIndex(
        (p) =>
          p.name === "qty" &&
          p.hierarchy.length == 2 &&
          p.hierarchy[1] === subcomponent._id
      );
      if (indexToSplice > -1) {
        let params = [...component.parameters];
        params.splice(indexToSplice, 1);
        component.parameters = params;
      }
    } else {
      subcomp.qty.value = component.qty.value;
      subcomp.parameters = subcomp.parameters.concat({
        name: "qty",
        value: component.qty.value,
        hierarchy: [...subcomp.hierarchy],
        details: "from component",
      });
    }

    // change hierarchy of qty params for its subcomponents to include its own id

    if (subcomponent.subcomponents) {
      flatSubcomponents = flatSubcomponents.concat(
        flattenSubcomponents(
          subcomponent,
          [...hierarchy, subcomponent._id],
          parent
        )
      );
    }
    subcomp.parameters = subcomp.parameters.map((param) => {
      return {
        ...param,
        hierarchy: [...subcomp.hierarchy],
      };
    });
    flatSubcomponents.push(subcomp);
  });
  return flatSubcomponents;
};

const initializeComponentData = (component) => {
  let flatSubcomponents = flattenSubcomponents(
    component,
    [component._id],
    component
  );
  component.flatSubcomponents = [...flatSubcomponents];
  let allParameters = getAllParameters(component);

  let newComp = { ...component, allParameters };

  return newComp;
};

const initializeExistingProduct = (product, variables) => {
  let component = { ...product.component };
  if (!component) {
    return product;
  }
  if (!component._id) return product;
  let flatSubcomponents = flattenSubcomponents(
    component,
    [component._id],
    component
  );
  component.flatSubcomponents = flatSubcomponents;
  let allParameters = getAllParameters(component);
  component.allParameters = allParameters;
  component.options.forEach((o) => {
    let optionIndex = product.options.findIndex((p) => p.name === o.name);
    if (optionIndex === -1) {
      return;
    }
    component = handleOptionUpdate({
      component: component,
      option: {
        ...o,
        choice: o.subcomponents.find(
          (s) => s.name === product.options[optionIndex].value
        ),
      },
      variables: variables,
    });
  });
  component.allParameters.forEach((p) => {
    p.formula = `${p.formula}`;
    if (p.formula?.includes(",")) {
      let subcomp = product.subcomponents.find(
        (s) => s.hierarchy.join("-") === p.hierarchy.join("-")
      );
      if (!subcomp) {
        subcomp = product;
      }

      component = handleParameterUpdate({
        component: component,
        parameter: {
          ...p,
          selection: `${
            subcomp.parameters.find((sp) => sp.name === p.name).value
          }`,
        },
        variables: variables,
      });
    }
  });
  component.id = product.id;
  component.price = calculatePrice(component);
  return component;
};

const getAllParameters = (component) => {
  let allParameters = _.cloneDeep(
    component.parameters.concat(
      component.flatSubcomponents
        .map((s) =>
          s.parameters.map((p) => {
            if (p.name === "qty") {
              return {
                ...p,
                hierarchy: [
                  ...s.hierarchy,
                  p.hierarchy[p.hierarchy.length - 1],
                ],
              };
            } else {
              return {
                ...p,
                hierarchy: [...s.hierarchy],
              };
            }
          })
        )
        .flat()
    )
  );

  // handle option source parameters:
  // loop over the sources of each option
  component.options.forEach((o) => {
    o.parameters.forEach((p) => {
      if (p.source) {
        o.sources.forEach((source) => {
          let sourceParam = allParameters.find(
            (par) => par.hierarchy.join("-") === source && par.name === p.name
          );

          if (!sourceParam) {
            sourceParam = allParameters.find(
              (par) =>
                par.hierarchy.join("-") ===
                  source.replace("parent", component._id) && par.name === p.name
            );
          }
          if (!sourceParam) {
            p.error = "Source parameter not found.";
            return;
          }

          sourceParam.sourceVariables
            ? sourceParam.sourceVariables.push(p.variable)
            : (sourceParam.sourceVariables = [p.variable]);
        });
      } else if (p.source === false && o.choice) {
        o.sources.forEach((source) => {
          let choiceParam = allParameters.find(
            (par) =>
              par.hierarchy.join("-") ===
                `${source}-${o._id}-${o.choice._id}` && par.name === p.name
          );

          if (!choiceParam) {
            choiceParam = allParameters.find(
              (par) =>
                par.hierarchy.join("-") ===
                  `${source.replace("parent", component._id)}-${o._id}-${
                    o.choice._id
                  }` && par.name === p.name
            );
          }
          if (!choiceParam) {
            p.error = "Choice parameter not found.";
            return;
          }
          choiceParam.choiceVariables
            ? choiceParam.choiceVariables.push(p.variable)
            : (choiceParam.choiceVariables = [p.variable]);
        });
      }
    });
  });
  if (component.parameterOverrides) {
    component.parameterOverrides.forEach((p) => {
      let index = allParameters.findIndex(
        (par) =>
          par.hierarchy.join("-") === p.hierarchy.join("-") &&
          par.name === p.name &&
          p.name !== "qty"
      );
      if (index > -1) {
        allParameters[index] = p;
      }
    });
  }

  allParameters = allParameters.map((p) => {
    let existingParameterWithSelection = component.allParameters?.find(
      (par) =>
        par.hierarchy.join("-") === p.hierarchy.join("-") &&
        par.name === p.name &&
        par.selection
    );
    if (existingParameterWithSelection) {
      p.selection = existingParameterWithSelection.selection;
    }
    return p;
  });
  // find the subcomponent that matches the source

  // find the parameters of the subcomponent that match the option parameters
  // push to each parameter the variable
  return allParameters;
};

const calculateParams = (component, variables) => {
  let allParams = _.cloneDeep(component.allParameters);
  let scope = new Map();
  let formulas = [];
  let normalParams = [];
  allParams.forEach((p) => {
    if (typeof p.formula === "string" && p.formula.trim() !== "") {
      formulas.push(p);
    } else if (p.variable) {
      scope.set(p.variable, p.value);
      normalParams.push(p);
    } else {
      normalParams.push(p);
    }
  });
  variables.forEach((v) => scope.set(v.variable, v.value));
  let evaluatedFormulas = [];
  let errors = [];
  let repetitions = 0;
  while (formulas.length > 0) {
    formulas.forEach((formula, index) => {
      let variable = formula.variable;
      let expression = formula.formula.trim();
      let localScope = new Map(scope);
      allParams.forEach((param) => {
        if (
          param.sourceVariables?.length &&
          param.hierarchy.at(-1) == formula.hierarchy.at(-3)
        ) {
          param.sourceVariables.forEach((v) => {
            if (param.formula) {
              try {
                scope.set(v, limitedEvaluate(param.formula, localScope));
              } catch {
                console.log("error");
              }
            } else {
              scope.set(v, param.value);
            }
          });
          // here we check if the parameter is a choice parameter and if it's hierarchy is one level deeper than the formula's hierarchy
        } else if (
          param.choiceVariables?.length &&
          param.hierarchy.length == formula.hierarchy.length + 2 &&
          param.hierarchy
            .join("-")
            .replace("parent", component._id)
            .startsWith(
              formula.hierarchy.join("-").replace("parent", component._id)
            )
        ) {
          param.choiceVariables.forEach((v) => {
            if (param.formula) {
              try {
                scope.set(v, limitedEvaluate(param.formula, localScope));
              } catch {
                console.log("error");
              }
            } else {
              scope.set(v, param.value);
            }
          });
        }
      });
      try {
        // Use limitedEvaluate to evaluate expressions within a restricted scope
        let result;

        // first, check that if it's a parameter that requires a selection from user, that the selection is made
        if (expression.includes(",")) {
          if (!formula.selection) {
            result = expression
              .split(",")
              .map((v) => limitedEvaluate(v, localScope))[0];
          } else {
            result = limitedEvaluate(formula.selection, localScope);
          }
        } else {
          result = limitedEvaluate(expression, localScope);
        }

        formula.value = result;
        if (variable) {
          scope.set(variable, result);
        }
        evaluatedFormulas.push(formula);
        formulas.splice(index, 1);
      } catch (error) {
        repetitions++;
        if (repetitions > 50) {
          errors.push({ formula, error: "Too many repetitions" });
          formulas.splice(index, 1);
          repetitions = 0;
        }
        // formulas.splice(index, 1);
        // Variable in formula not yet defined
      }
    });
  }

  for (let i = 0; i < evaluatedFormulas.length; i++) {
    let formula = evaluatedFormulas[i];
    if (formula.name === "qty" && formula.formula) {
      let parent = [...evaluatedFormulas, ...normalParams].find(
        (p) =>
          p.hierarchy.join("-") ===
            formula.hierarchy
              .slice(0, formula.hierarchy.length - 1)
              .join("-") && p.name === "qty"
      );
      if (!parent) {
        parent = [...evaluatedFormulas, ...normalParams].find(
          (p) =>
            p.hierarchy.join("-") ===
              formula.hierarchy
                .slice(0, formula.hierarchy.length - 2)
                .join("-") && p.name === "qty"
        );
      }
      if (parent) {
        formula.value = parent.value * formula.value;
      }
    }
  }

  return [...evaluatedFormulas, ...normalParams, ...formulas];
};

const calculatePrice = (component) => {
  if (!component.priceModifier) {
    return component.price;
  } else {
    let price = component.flatSubcomponents.reduce((acc, subcomp) => {
      let qtyParam = component.allParameters.find(
        (p) =>
          p.name === "qty" &&
          p.hierarchy.join("-") == subcomp.hierarchy.join("-")
      );
      let qty = qtyParam ? qtyParam.value : 1;

      let cost = subcomp.cost || 0;

      let total = cost * qty;

      return acc + total;
    }, 0);
    return Number.parseFloat((price * component.priceModifier).toFixed(2));
  }
};
