import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../../../Content";
import { updateFolder } from "../../../api/folderApi";

export function useFolderUpdate() {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  return useMutation({
    mutationFn: ({ id, pack }) => {
      return updateFolder({ id, pack });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("folders");
      setSnack({
        open: true,
        message: "Folder updated.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Folder could not be updated.",
        severity: "error",
      });
    },
  });
}
