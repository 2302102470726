import React, { Fragment, useCallback } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import multimonthPlugin from "@fullcalendar/multimonth";
import interactionPlugin from "@fullcalendar/interaction";
import PropTypes from "prop-types";
import { Box, Button, Paper, Typography, useTheme } from "@mui/material";
import { ProjectEvent } from "./ProjectEvent";
import "../utils/Calendar.css";
import { Check } from "@mui/icons-material";
// import { stringToColour } from "../../../utils/stringToColor";
import { MuiAdapter } from "../../../utils/MuiAdapter";

function Event({ event }) {
  if (event.type == "project") {
    return <ProjectEvent event={event} />;
  }
  return (
    <Button color="success" sx={{ backgroundColor: "primary.main" }}>
      {event.title}
      {event.desc && ":  " + event.desc}
    </Button>
  );
}
Event.propTypes = {
  event: PropTypes.object,
};

export function CustomCalendar({ ...props }) {
  const [events, setEvents] = React.useState(props.initialEvents);
  React.useEffect(() => {
    setEvents(null);
    setEvents(props.initialEvents);
  }, [props.initialEvents]);

  const renderEventContent = useCallback(
    (eventInfo) => {
      if (eventInfo.event.extendedProps.type == "project") {
        return (
          <Paper id={eventInfo.event.title} sx={{ p: 1 }}>
            {eventInfo.event.title}
          </Paper>
        );
      }

      if (eventInfo.event.display == "background") {
        return <Typography variant="h6">{eventInfo.event.title}</Typography>;
      }
      const info = eventInfo.event.extendedProps;

      return (
        <Paper
          id={`${info.project}_${info.milestone}`}
          sx={{
            p: 0.5,
            display: "flex",
            flexDirection: "column",
            backgroundColor: info.color,
            filter: info.completed ? "brightness(60%)" : "none",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              textWrap: "wrap",
            }}
          >
            <Typography sx={{ textWrap: "wrap" }} color={info.fontColor}>
              {eventInfo.event.title}
            </Typography>
            {info.completed ? (
              <Check
                size="small"
                color="success"
                sx={{ backgroundColor: "white", borderRadius: "100px" }}
              />
            ) : null}
          </Box>

          <Typography variant="caption" color={info.fontColor}>
            {info.project} - {info.milestone}
          </Typography>
        </Paper>
      );
    },
    [props.initialEvents]
  );
  const theme = useTheme();

  return (
    <Fragment>
      <MuiAdapter theme={theme} />{" "}
      <div>
        <FullCalendar
          {...props}
          initialEvents={events}
          plugins={[dayGridPlugin, interactionPlugin, multimonthPlugin]}
          eventContent={renderEventContent}
          initialView="dayGridMonth"
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,multiMonthYear",
          }}
        />
      </div>
    </Fragment>
  );
}

CustomCalendar.propTypes = {
  events: PropTypes.array,
  initialEvents: PropTypes.array,
};
