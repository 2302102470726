import React, { useState } from "react";

import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { usePersonUpdate } from "../hooks/usePersonUpdate";
import { columns } from "../../../components/common/utils";
import { useSettingsData } from "../../../hooks/useSettingsData";
import { usePersonDelete } from "../hooks/usePersonDelete";
import _ from "lodash";

export const EditPersonDialog = ({ data, isOpen = false, setOpen }) => {
  const [deleteing, setDeleteing] = useState(false);
  const { mutate: editPerson } = usePersonUpdate();
  const { mutate: deletePerson } = usePersonDelete();
  const { data: settings } = useSettingsData();
  const handleCancel = () => setOpen(false);
  const handleClose = () => setOpen(false);
  const [internalFields, setInternalFields] = useState([]);

  React.useEffect(() => {
    if (!data) return;
    const dialogFields = [{ id: "_id" }, ..._.cloneDeep(columns["person"])];
    dialogFields.forEach((x) => (x.value = data[x.field]));
    setInternalFields(dialogFields);
  }, [data]);

  console.log(columns["person"], data, internalFields);

  const onFieldChange = (fieldId, fieldValue) => {
    let tempFields = [...internalFields];
    tempFields.find((x) => x.field === fieldId).value = fieldValue;
    setInternalFields(tempFields);
  };

  const handleSubmit = () => {
    let payload = {};
    internalFields.map((f) => (payload[f.field] = f.value));

    const { _id } = data;

    payload._id = _id;

    editPerson({ pack: payload, id: _id });
    setOpen(false);
  };

  const handleDeleteFirstClick = () => {
    setDeleteing(true);
    setTimeout(() => {
      setDeleteing(false);
    }, 3000);
  };

  const handleDelete = () => {
    const { _id } = data;
    deletePerson({ id: _id });
    setOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        id="form-dialog-title"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Edit Person{" "}
      </DialogTitle>
      <DialogContent>
        {internalFields &&
          internalFields.map((field, i) =>
            field.type == "select" ? (
              <FormControl
                fullWidth
                key={field.id}
                sx={{ marginTop: 2, width: "100%" }}
              >
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  autoFocus={i === 0}
                  margin="dense"
                  key={field.id}
                  id={field.id}
                  disabled={field.disabled}
                  label={field.headerName}
                  type={field.type}
                  value={field.value || ""}
                  onChange={(e) => onFieldChange(field.field, e.target.value)}
                  fullWidth
                >
                  {settings.roles.map((role) => (
                    <MenuItem key={role._id} value={role._id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              !["uuid", "_id"].includes(field.id) && (
                <TextField
                  autoFocus={i === 0}
                  margin="dense"
                  key={field.id}
                  id={field.id}
                  disabled={field.disabled}
                  label={field.headerName}
                  type={field.type}
                  value={field.value || ""}
                  onChange={(e) => onFieldChange(field.field, e.target.value)}
                  fullWidth
                />
              )
            )
          )}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Box>
          <Button
            variant={deleteing ? "contained" : "outlined"}
            onClick={deleteing ? handleDelete : handleDeleteFirstClick}
            color="error"
            sx={{ mr: 2 }}
          >
            Delete
          </Button>
          {deleteing && (
            <Typography variant="subtitle1">
              Deleting this person will also delete their account.
            </Typography>
          )}
        </Box>
        <Box>
          <Button onClick={handleCancel} color="primary" sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

EditPersonDialog.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.object,
  customerID: PropTypes.string,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  updateRow: PropTypes.func,
};
