import React from "react";
import { NewComponentContext } from "../../components/ComponentNode";
import { Paper } from "@mui/material";
import CurrencyTextField from "../../../../utils/CurrencyTextField";

export const Cost = () => {
  const { newComponent, handleEdit } = React.useContext(NewComponentContext);

  return (
    <Paper sx={{ p: 1, mt: 1 }}>
      <CurrencyTextField
        size="small"
        label="Cost"
        value={newComponent.cost}
        fullWidth
        onChange={(e) => handleEdit({ field: "cost", value: e.target.value })}
      />
    </Paper>
  );
};
