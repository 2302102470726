import React from "react";
import PropTypes from "prop-types";
import { useProjectUpdate } from "../../hooks/useProjectUpdate";
import { Box, Button, TextField } from "@mui/material";

export const TaxDetails = ({ project }) => {
  const [taxRate, setTaxRate] = React.useState(parseInt(project.taxRate * 100));

  const { mutate: updateProject } = useProjectUpdate(project._id);

  const handleSave = () => {
    const pack = {
      taxRate: taxRate.toFixed(2),
    };
    updateProject({ id: project._id, pack });
  };

  const handleEdit = (e) => {
    setTaxRate(e.target.value);
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <TextField
          label="Tax Rate"
          value={taxRate}
          type="number"
          onChange={handleEdit}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={handleSave}>Save</Button>
      </Box>
    </Box>
  );
};

TaxDetails.propTypes = {
  project: PropTypes.object,
};
