import * as React from "react";
import PropTypes from "prop-types";
import { IconButton, Menu, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material/styles";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { updateProject } from "../../../api/projectApi";
import { SelectedProjectContext } from "./ProjectView";

export const ZoneView = () => {
  const project = React.useContext(SelectedProjectContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorSubEl, setAnchorSubEl] = React.useState(null);
  const [subAnchorSubEl, setSubAnchorSubEl] = React.useState(null);
  const [subZone, setSubZone] = React.useState();
  const [selected, setSelected] = React.useState(project.zones);
  // const [zones, setZones] = React.useState();
  const theme = useTheme();

  const queryClient = useQueryClient();

  const { data: settings } = useQuery("settings");
  let zones = settings.zones;

  const updateProjectMutation = useMutation(updateProject, {
    onSuccess: () => {
      queryClient.invalidateQueries("projects");
      handleSubSubMenuClose();
      handleSubMenuClose();
    },
  });

  React.useEffect(() => {
    setSelected(project.zones);
  }, [project]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSubMenuClick = (event, zone) => {
    setAnchorSubEl(event.currentTarget);
    setSubZone(zone);
  };
  const handleSubSubMenuClick = (event) => {
    setSubAnchorSubEl(event.currentTarget);
  };
  const handleDelete = (zone) => {
    let newZones = selected.filter((filter) => filter != zone);
    let newProducts = project.products.filter((filter) => filter.zone != zone);
    let pack = { zones: newZones, products: newProducts };
    updateProjectMutation.mutate({ id: project._id, pack: pack });

    // axios({
    //   method: "patch",
    //   url: `${endpoints.pros}/${project._id}`,
    //   data: { zones: newZones, products: newProducts },
    // }).then((res) => {
    //   setProject(res.data);
    //   setSelected(res.data.zones);
    //   handleSubMenuClose();
    // });
  };
  const handleChange = (oldZone, newZone) => {
    let newZones = selected.filter((filter) => filter != oldZone);
    newZones.includes(newZone) ? null : newZones.push(newZone);
    let newProducts = [];
    project.products.map((product) =>
      product.zone == oldZone
        ? newProducts.push(Object.assign({}, product, { zone: newZone }))
        : newProducts.push(product)
    );
    let pack = { zones: newZones, products: newProducts };
    updateProjectMutation.mutate({ id: project._id, pack: pack });
    // axios({
    //   method: "patch",
    //   url: `${endpoints.pros}/${project._id}`,
    //   data: { zones: newZones, products: newProducts },
    // }).then((res) => {
    //   setProject(res.data);
    //   setSelected(res.data.zones);

    // });
  };

  // React.useEffect(() => {
  //   axios({
  //     method: "get",
  //     url: endpoints.settings,
  //     params: { user: project.user },
  //   }).then((res) => {
  //     setZones(res.data.zones);
  //   });
  // }, []);
  const handleSubMenuClose = () => {
    setAnchorSubEl(null);
  };
  const handleSubSubMenuClose = () => {
    setSubAnchorSubEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    let proZone = project.zones.slice();
    let selectedZones = selected.slice();
    if (proZone.length == selectedZones.length) {
      for (let i = 0; i < proZone.length; ++i) {
        if (proZone[i] !== selectedZones[i]) {
          let pack = { zones: selected };
          updateProjectMutation.mutate({ id: project._id, pack: pack });

          // axios({
          //   method: "patch",
          //   url: `${endpoints.pros}/${project._id}`,
          //   data: { zones: selected },
          // }).then((res) => setProject(res.data));
        }
      }
    } else if (proZone.length != selectedZones.length) {
      let pack = { zones: selected };
      updateProjectMutation.mutate({ id: project._id, pack: pack });

      // axios({
      //   method: "patch",
      //   url: `${endpoints.pros}/${project._id}`,
      //   data: { zones: selected },
      // }).then((res) => setProject(res.data));
    }
  };

  const handleZoneClick = (zone) => {
    selected.includes(zone)
      ? setSelected((prev) => prev.filter((filter) => filter != zone))
      : setSelected((prev) => [...prev, zone]);
  };

  return (
    <>
      <IconButton
        onClick={handleMenuClick}
        sx={{
          backgroundColor: theme.palette.primary.main,
          p: 0,
          ":hover": { backgroundColor: theme.palette.primary.dark },
        }}
      >
        <AddIcon />
      </IconButton>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ horizontal: "right" }}
      >
        {zones
          ? zones.map((zone, i) => {
              return (
                <MenuItem
                  data-id="test"
                  key={i}
                  value={zone}
                  sx={{
                    backgroundColor: selected.includes(zone)
                      ? "primary.main"
                      : null,
                  }}
                  onClick={(e) => {
                    project.products.some((product) => product.zone == zone)
                      ? handleSubMenuClick(e, zone)
                      : handleZoneClick(zone);
                  }}
                >
                  {zone}
                </MenuItem>
              );
            })
          : null}
      </Menu>
      <Menu
        id="simple-menu-1"
        anchorEl={anchorSubEl}
        keepMounted
        open={Boolean(anchorSubEl)}
        onClose={handleSubMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ horizontal: "center" }}
      >
        <MenuItem onClick={(e) => handleSubSubMenuClick(e)}>Change</MenuItem>
        <MenuItem onClick={() => handleDelete(subZone)}> Delete </MenuItem>
      </Menu>
      <Menu
        id="simple-menu-2"
        anchorEl={subAnchorSubEl}
        keepMounted
        open={Boolean(subAnchorSubEl)}
        onClose={handleSubSubMenuClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ horizontal: "center" }}
      >
        {zones
          ? zones.map((zone, i) => {
              return (
                <MenuItem
                  data-id="test"
                  key={`${i}sub`}
                  value={zone}
                  onClick={() => handleChange(subZone, zone)}
                >
                  {zone}
                </MenuItem>
              );
            })
          : null}
      </Menu>
    </>
  );
};
ZoneView.propTypes = {
  project: PropTypes.object,
  setProject: PropTypes.func,
};
