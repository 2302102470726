import { Paper } from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";
import "./ProjectFileManager.css";
import { FileView } from "./FileView";
import { FolderBar } from "./FolderBar";
import { UserContext } from "../../../App";
import { useFilesData } from "../hooks/useFilesData";
import { useFoldersData } from "../hooks/useFoldersData";
import { SelectedProjectContext } from "../../project/components/ProjectView";

export const ProjectFileManager = () => {
  const { user } = React.useContext(UserContext);
  const project = React.useContext(SelectedProjectContext);
  const { data: files } = useFilesData();
  const { data: folders } = useFoldersData();

  const [selectedFolder, setSelectedFolder] = React.useState();

  React.useEffect(() => {
    if (folders) {
      setSelectedFolder(
        folders.filter(
          (f) => f.project == project._id && f.name == project.projectName
        )[0]
      );
    }
  }, [folders]);

  return (
    <div className={"mainWrapper"}>
      {files && folders ? (
        <>
          <div className={"folderStructure"}>
            <Paper
              sx={{ height: "100%", overflow: "auto", maxHeight: "100%" }}
              elevation={2}
            >
              <FolderBar
                folders={folders}
                topLevel={folders.filter(
                  (f) =>
                    f.project == project._id && f.name == project.projectName
                )}
                setFolder={setSelectedFolder}
                selectedFolder={selectedFolder}
              />
            </Paper>
          </div>

          <div className={"fileView"}>
            <Paper sx={{ height: "100%", maxHeight: "100%" }} elevation={2}>
              {selectedFolder ? (
                <FileView
                  folder={selectedFolder}
                  folders={folders}
                  user={user}
                  files={files}
                  setFolder={setSelectedFolder}
                  project={project}
                />
              ) : null}
            </Paper>
          </div>
        </>
      ) : null}
    </div>
  );
};
ProjectFileManager.propTypes = {
  project: PropTypes.object,
};
