import { InputAdornment, Paper, TextField } from "@mui/material";
import React from "react";
import BalanceIcon from "@mui/icons-material/Balance";
import { CustomIconButton } from "../../../../utils/CustomIconButtom";
import { NewComponentContext } from "../../components/ComponentNode";
import CurrencyTextField from "../../../../utils/CurrencyTextField";

export const Price = () => {
  const { newComponent, handleEdit } = React.useContext(NewComponentContext);

  const handlePriceChange = (e) => {
    if (newComponent.price === e.target.value) return;
    handleEdit({
      field: "price",
      value: e.target.value,
    });
  };

  const handleClick = () => {
    let value = newComponent.priceModifier ? 0 : 1;
    handleEdit({
      field: "priceModifier",
      value: value,
    });
  };

  const handlePriceModifierChange = (e) => {
    handleEdit({
      field: "priceModifier",
      value: e.target.value,
    });
  };

  return (
    <Paper sx={{ p: 1, mt: 1, display: "flex", gap: 1 }}>
      {!newComponent.priceModifier ? (
        <CurrencyTextField
          label="Price"
          value={newComponent.price}
          size="small"
          onChange={handlePriceChange}
          sx={{ flex: 1 }}
        />
      ) : (
        <TextField
          label="Markup"
          value={newComponent.priceModifier}
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start">x</InputAdornment>,
          }}
          onChange={handlePriceModifierChange}
          sx={{ flex: 1 }}
        />
      )}
      {/* <Button
        size="small"
        sx={{
          pl: 1,
          pr: 1,
          minWidth: 0,
          border: 1,
          borderColor: "primary.main",
        }}
        variant={price ? "contained" : "outlined"}
        onClick={() => setPrice((prev) => !prev)}
      >
        <BalanceIcon />
      </Button> */}
      <CustomIconButton
        icon={<BalanceIcon />}
        variant={newComponent.priceModifier ? "contained" : "outlined"}
        onClick={() => handleClick()}
      />
    </Paper>
  );
};
