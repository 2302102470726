import { ListItemText, Menu, MenuItem } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useSettingsData } from "../../../../hooks/useSettingsData";
import { NewComponentContext } from "../../components/ComponentNode";

export const CategoryMenu = ({ anchor, setAnchor }) => {
  const { data: settings } = useSettingsData();
  const { newComponent, handleEdit } = React.useContext(NewComponentContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    setAnchorEl(anchor);
  }, [anchor]);

  const handleClose = () => {
    setAnchor(null);
  };

  const handleClick = (category) => {
    handleEdit({ field: "categories", value: category });
  };

  return (
    <div>
      <Menu
        id="add-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {settings.categories.map((category) => (
          <MenuItem
            key={category._id}
            onClick={() => handleClick(category)}
            sx={{
              backgroundColor: newComponent.categories.includes(category)
                ? "primary.main"
                : "default",
              color: newComponent.categories.includes(category)
                ? "primary.contrastText"
                : "default",
            }}
            // onClick={() => handleCategoryClick(category)}
          >
            <ListItemText>{category}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

CategoryMenu.propTypes = {
  anchor: PropTypes.any,
  setAnchor: PropTypes.func,
};
