import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

export function NoFinancialsOverlay() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        zIndex: 5,
      }}
    >
      <Typography variant="h5" align="center">
        Your financial entries will appear here. You can add an entry, create
        and send an invoice, or send a payment link above.
      </Typography>
    </div>
  );
}

NoFinancialsOverlay.propTypes = {
  message: PropTypes.string,
};
