import axios from "axios";

const orderApi = axios.create();

export const getOrders = async (params) => {
  const response = await orderApi.get("/api/order", {
    params: params,
  });

  return response.data;
};

export const createOrder = async (pack) => {
  const response = await orderApi.post("/api/order", pack);
  return response.data;
};

export const getOrder = async (id) => {
  const response = await orderApi.get(`/api/order/${id}`);
  return response.data;
};

export const updateOrder = async ({ id, pack }) => {
  const response = await orderApi.patch(`/api/order/${id}`, pack);
  return response.data;
};

export const deleteOrder = async (pack) => {
  const response = await orderApi.delete("/api/order", { data: pack });
  return response;
};
