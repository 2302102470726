import React, { useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { useCustomersData } from "../../../hooks/useCustomerData";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { NewItemDialog } from "../../../components/common/itemList";
import { columns, endpoints, titles } from "../../../components/common/utils";
import { DatePicker } from "@mui/x-date-pickers";
import { useQueryClient } from "react-query";
import { useSettingsData } from "../../../hooks/useSettingsData";
import { MozaikTable } from "./MozaikTable";
import { useNewProject } from "../hooks/useNewProject";
import { CirclePicker } from "react-color";
import { usePeopleData } from "../../people/hooks/usePeopleData";
import { SouthWest } from "@mui/icons-material";
import { ImportGuide } from "../utils/ImportGuide";
import { SnackbarContext } from "../../../Content";

export const NewProjectDialog = ({
  isOpen = false,
  setOpen,
  fields = [],
  updateTable,
  user,
}) => {
  const { project, setProject } = useNewProject();

  const [addOpen, setAddOpen] = React.useState(false);
  const [projectPeople, setProjectPeople] = React.useState([]);

  const queryClient = useQueryClient();

  const handleCancel = () => setOpen(false);
  const handleClose = () => setOpen(false);
  const [internalFields, setInternalFields] = useState([...fields]);

  const [selectedImportData, setSelectedImportData] = useState(null);

  const { setSnack } = React.useContext(SnackbarContext);

  const { data: customers } = useCustomersData();
  const { data: people } = usePeopleData();
  const { data: settings } = useSettingsData();

  const handleValidation = () => {
    const newFields = [...internalFields];
    newFields.map((f) => {
      if (f.value == "" && f.required) f.error = true;
    });
    setInternalFields(newFields);
    return internalFields.some((f) => f.error == true);
  };

  const handleSubmit = () => {
    let payload = { user: user.id };

    internalFields.map((f) => (payload[f.field] = f.value));

    payload.tasks = project.tasks;
    payload.milestones = project.milestones;
    payload.imported = project.imported;
    payload.zones = project.zones;
    payload.projectValue = project.projectValue;
    payload.tax = settings.taxRate * project.projectValue;
    payload.componentList = project.componentList;
    payload.total = payload.projectValue + payload.tax;
    payload.balance = payload.total;
    payload.color = project.color;
    if (handleValidation()) {
      console.log("validation failed", internalFields);
      return;
    }

    if (importData) {
      selectedImportData && (importData.products = selectedImportData);

      payload.products = selectedImportData;

      payload.importData = importData;
    }
    axios
      .post(endpoints["pros"], payload)
      .then((r) => {
        if (r.status === 201) {
          queryClient.invalidateQueries(updateTable);
          setOpen(false);
        }
      })
      .catch((e) => console.log(e));
  };
  const onFieldChange = (fieldId, fieldValue) => {
    let tempFields = [...internalFields];
    tempFields.find((x) => x.field === fieldId).value = fieldValue;
    setProject((prev) => ({ ...prev, [fieldId]: fieldValue }));
    setInternalFields(tempFields);
  };

  const onColorChange = (color) => {
    setProject((prev) => ({ ...prev, color: color.hex }));
  };

  const onPeopleChange = (fieldId, fieldValue) => {
    setProjectPeople(fieldValue);
    let newPeople = [];
    fieldValue.forEach((p) => {
      newPeople.push({ person: people.find((x) => x._id == p) });
    });
    setProject((prev) => ({ ...prev, [fieldId]: newPeople }));
  };

  const handleAddNew = () => {
    setAddOpen(true);
  };
  const onAddSuccess = ({ data }) => {
    onFieldChange("customer", data._id);
    setAddOpen(false);
  };

  React.useEffect(() => {
    if (!settings) return;
    let milestones = [
      ...settings.milestones.map((m, i) => ({
        name: m.name,
        completed: false,
        active: i == 0 ? true : false,
      })),
    ];
    let tasks = [];
    settings.milestones.forEach((m) => {
      m.tasks.forEach((t) => {
        tasks.push({
          name: t,
          completed: false,
          assignedTo: [],
          dueDate: null,
          milestone: m.name,
        });
      });
    });
    setProject((prev) => ({ ...prev, milestones, tasks }));
  }, []);

  const [importData, setImportData] = useState([]);

  const handleImport = (e) => {
    const pack = new FormData();
    pack.append("file", e.target.files[0]);
    try {
      axios.post("/api/project/mzkImport", pack).then((r) => {
        setImportData(r.data);
        if (r.data.JobTable) {
          onFieldChange("projectName", r.data.JobTable[0].JobName);
        }
      });
    } catch (e) {
      setSnack({ open: true, message: "Error importing file!", type: "error" });
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        id="form-dialog-title"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Add New Project
        <Tooltip
          title={
            <Paper sx={{ p: 1, m: -1 }}>
              <CirclePicker onChange={onColorChange} />
            </Paper>
          }
        >
          <span
            style={{
              width: "28px",
              height: "28px",
              borderRadius: "100px",
              backgroundColor: project.color || null,
              border: project.color ? null : "1px solid black",
            }}
          ></span>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <Button
            component="label"
            variant="contained"
            startIcon={<SouthWest />}
          >
            Import{" "}
            <input type="file" accept=".db" hidden onChange={handleImport} />
          </Button>

          {importData.CabinetTable && (
            <MozaikTable
              data={importData}
              handleSelect={setSelectedImportData}
              setProject={setProject}
            />
          )}
          <ImportGuide />
        </Box>
        {internalFields.map((field, i) =>
          field.field == "customer" ? (
            <FormControl
              fullWidth
              sx={{ marginTop: 2 }}
              key={`${field.type}-${field.headerName}`}
            >
              <InputLabel>{field.headerName}</InputLabel>
              <Select
                fullWidth
                value={field.value}
                onChange={(e) => onFieldChange(field.field, e.target.value)}
                label={field.headerName}
              >
                {customers?.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.firstName} {option.lastName}
                  </MenuItem>
                ))}
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button onClick={handleAddNew}>Add New</Button>
                </Box>
              </Select>
            </FormControl>
          ) : field.field == "people" ? (
            <FormControl
              fullWidth
              sx={{ marginTop: 2 }}
              key={`${field.type}-${field.headerName}`}
            >
              <InputLabel>{field.headerName}</InputLabel>
              <Select
                fullWidth
                value={projectPeople}
                multiple
                onChange={(e) => onPeopleChange(field.field, e.target.value)}
                label={field.headerName}
              >
                {people?.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.firstName} {option.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : field.type == "date" ? (
            <DatePicker
              error={field.error}
              autoFocus={i === 0}
              sx={{ marginTop: 2, width: "100%" }}
              key={field.field}
              id={field.field}
              label={field.headerName}
              type={field.type}
              value={field.value}
              required={field.required}
              onChange={(value) => onFieldChange(field.field, value)}
              InputLabelProps={{ shrink: true }}
            />
          ) : !field.hideOnCreate ? (
            <TextField
              error={field.error}
              autoFocus={i === 0}
              margin="dense"
              key={field.id}
              id={field.field}
              label={field.headerName}
              type={field.type}
              InputLabelProps={{ shrink: true }}
              sx={{ mt: 2 }}
              value={field.value}
              onChange={(e) => onFieldChange(field.field, e.target.value)}
              fullWidth
            />
          ) : null
        )}
        <NewItemDialog
          title={titles["customers"]}
          isOpen={addOpen}
          setOpen={setAddOpen}
          fields={columns["customers"]}
          updateTable={"customers"}
          ENDPOINT={endpoints["customers"]}
          onSuccess={onAddSuccess}
        />
        <Box
          sx={{ display: "flex", justifyContent: "end", gap: 1, mt: 2 }}
        ></Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewProjectDialog.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  fields: PropTypes.array,
  onSubmit: PropTypes.func,
  updateTable: PropTypes.string,
  user: PropTypes.object,
};
