import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../../../Content";
import { updatePerson } from "../../../api/peopleApi";

export function usePersonUpdate(id) {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  return useMutation({
    mutationFn: ({ id, pack }) => {
      return updatePerson({ id, pack });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("people");
      queryClient.invalidateQueries(["person", id]);
      setSnack({
        open: true,
        message: "Person updated.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Person could not be updated.",
        severity: "error",
      });
    },
  });
}
