import React from "react";

export function useNewProject() {
  const [newProject, setNewProject] = React.useState({
    _id: "newProject",
    projectName: "",
    creationDate: new Date(),
    dueDate: new Date(),
    log: [],
    customer: "",
    milestones: [],
    tasks: [],
    zones: [],
    financial: [],
    projectValue: 0,
    products: [],
    new: true,
  });

  const handleNewProjectChange = (field, value) => {
    setNewProject({
      ...newProject,
      [field]: value,
    });
  };

  return {
    project: newProject,
    setProject: setNewProject,
    handleChange: handleNewProjectChange,
  };
}
