import {
  Box,
  Button,
  Dialog,
  DialogActions,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useSettingsUpdate } from "../../../hooks/useSettingsUpdate";
import { useSettingsData } from "../../../hooks/useSettingsData";
import { useParametersData } from "../../../hooks/useParametersData";

export const MozaikKeys = ({ data }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { data: settings } = useSettingsData();
  const [keys, setKeys] = React.useState(keyData);
  const { mutate: updateSettings } = useSettingsUpdate();
  const { data: parameters } = useParametersData();

  const handleSubmit = () => {
    const importData = {
      source: "mozaik",
      keys: keys,
    };
    updateSettings({ pack: { importData: importData } });
    setDialogOpen(false);
  };

  React.useEffect(() => {
    if (settings.importData?.find((x) => x.source == "mozaik")) {
      let data = settings.importData.find((x) => x.source == "mozaik").keys;
      let newKeys = [];
      keyData.forEach((x, i) => {
        if (data[i].import) {
          data[i].app == "parameterList"
            ? newKeys.push({
                ...x,
                import: data[i].import,
                parameters: data[i].parameters,
              })
            : newKeys.push({ ...x, import: data[i].import });
        } else {
          newKeys.push(x);
        }
      });
      setKeys(newKeys);
    }
  }, [settings]);

  const handleParameterSelect = (i, e) => {
    let newKeys = [...keys];
    if (newKeys[2].parameters.length !== newKeys[2].import.length) {
      newKeys[2].parameters = Array(newKeys[2].import.length).fill("");
    }
    newKeys[2].parameters[i] = e.target.value;
    setKeys(newKeys);
  };

  if (!settings || !parameters) return null;
  return (
    <>
      <Button
        variant="contained"
        color={
          settings.importData.find((d) => d.source == "mozaik")
            ? "primary"
            : "info"
        }
        onClick={() => setDialogOpen(true)}
      >
        Set Keys
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">Set Corresponding Values</Typography>
          <Typography variant="body1">
            Select the corresponding values for the new product. The left column
            is the product schema in the app, the right column is the imported
            data. Whatever you do not assign to a value will be stored in the
            product importData and accessible later.
          </Typography>
          <Box>
            {keys.map((key, i) => (
              <Box key={i}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <Typography sx={{ width: "30%" }}>{key.label}</Typography>
                  <Select
                    value={key.import}
                    fullWidth
                    multiple={
                      key.app == "parameterList" || key.app == "options"
                    }
                    onChange={(e) => {
                      setKeys((prev) =>
                        prev.map((x, j) =>
                          i == j ? { ...x, import: e.target.value } : x
                        )
                      );
                    }}
                  >
                    {Object.keys(data[0]).map((y, i) => (
                      <MenuItem key={i} value={y}>
                        {y.import ? y.import : y}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                {key.app == "parameterList"
                  ? key.import.map((x, j) => (
                      <Box
                        key={j}
                        sx={{
                          display: "flex",
                          ml: 3,
                          mr: 3,
                          mt: 1,
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ width: "30%" }}>{x}</Typography>
                        <Select
                          value={key.parameters[j]}
                          renderValue={(value) => value.name}
                          fullWidth
                          onChange={(e) => {
                            handleParameterSelect(j, e, x);
                          }}
                        >
                          {parameters.map((y, k) => (
                            <MenuItem key={k} value={y}>
                              {y.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    ))
                  : null}
              </Box>
            ))}
          </Box>
          <DialogActions sx={{ mt: 1 }}>
            <Button onClick={() => setDialogOpen(false)} variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleSubmit} variant="contained">
              Submit
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </>
  );
};

MozaikKeys.propTypes = {
  data: PropTypes.array,
};

const keyData = [
  { app: "name", import: "", label: "Name" },
  { app: "zone", import: "", label: "Zone" },
  { app: "parameterList", import: [], parameters: [], label: "Parameters" },
  { app: "options", import: [], label: "Options" },
  { app: "price", import: "", label: "Price" },
  { app: "cost", import: "", label: "Cost" },
];
