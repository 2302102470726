import React from "react";
import { useMutation } from "react-query";
import { UserContext } from "../../../App";
import { createCatalog } from "../../../api/catalogApi";

export const useCatalogCreate = (onSuccess, onError) => {
  const { user } = React.useContext(UserContext);

  return useMutation((pack) => createCatalog({ ...pack, user: user.id }), {
    onSuccess: (data) => {
      // Call the provided onSuccess handler
      if (onSuccess) onSuccess(data);
    },
    onError,
  });
};
