import * as React from "react";
import {
  Dialog,
  Paper,
  Grid,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import PropTypes from "prop-types";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import { useOrderUpdate } from "../hooks/useOrderUpdate";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const ViewOrders = ({ supplier }) => {
  const [viewOpen, setViewOpen] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState();
  const handleSelect = (row) => {
    setSelectedOrder(row.row);
    setViewOpen(true);
  };

  const { mutate: updateOrder } = useOrderUpdate();

  const handleReceived = async (e, order) => {
    e.stopPropagation();
    updateOrder({ id: supplier._id, pack: { ...order, status: "Received" } });
  };
  const handleSent = async (e, order) => {
    e.stopPropagation();
    updateOrder({ id: supplier._id, pack: { ...order, status: "Sent" } });
  };
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "date",
      headerName: "Date",
      valueFormatter: (params) => {
        return dayjs(params.value).format("DD/MM/YYYY");
      },
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Grid>
              <Button
                disabled={
                  params.row.status == "Received" || params.row.status == "Sent"
                }
                onClick={(e) => handleSent(e, params.row)}
              >
                {params.row.status == "Sent" || params.row.status == "Received"
                  ? "Sent"
                  : "Mark Sent"}
              </Button>
              <Button
                disabled={
                  params.row.status == "Received" ||
                  params.row.status !== "Sent"
                }
                onClick={(e) => handleReceived(e, params.row)}
              >
                {params.row.status == "Received" ? "Received" : "Mark Received"}
              </Button>
            </Grid>
          </>
        );
      },
    },
  ];

  const rows = supplier.orders;

  const data = { columns: columns, rows: rows };

  const handleClose = () => {
    setViewOpen(false);
  };
  return (
    <div
      id="supplier-orders"
      style={{
        height: "450px",
        display: "flex",
        flexFlow: "column",
        position: "relative",
        width: "100%",
        padding: "8px",
      }}
    >
      <Typography variant="h5" align="left">
        Orders
      </Typography>
      <DataGridPremium
        {...data}
        onRowClick={handleSelect}
        density="compact"
        getRowId={(row) => row.date}
        hideFooterRowCount
        sx={{
          height: 600,
          boxShadow: 2,
          ".MuiDataGrid-virtualScroller": {
            "&::-webkit-scrollbar": {
              width: "0.4em",
              backgroundColor: "divider",
            },
            "&::-webkit-scrollbar-track": {
              boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
              webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "primary.main",
            },
          },
        }}
      />
      <Dialog
        onClose={() => {
          handleClose();
        }}
        open={viewOpen}
        maxWidth="md"
        fullWidth
      >
        <Paper sx={{ height: "80vh" }}>
          {selectedOrder && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Code</TableCell>
                    <TableCell align="right">Price</TableCell>
                    <TableCell align="right">Qty</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedOrder.items.map((item) => {
                    return (
                      <TableRow
                        key={item.item.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {item.item.name}
                        </TableCell>
                        <TableCell align="right">{item.code}</TableCell>
                        <TableCell align="right">
                          {formatter.format(
                            item.item.suppliers
                              .find((s) => s.supplier._id == supplier._id)
                              .cost.toFixed(2)
                          )}
                        </TableCell>

                        <TableCell align="right">{item.qty}</TableCell>
                        <TableCell align="right">
                          {formatter.format(
                            (
                              item.item.suppliers
                                .find((s) => s.supplier._id == supplier._id)
                                .cost.toFixed(2) * item.qty
                            ).toFixed(2)
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  <TableRow>
                    <TableCell colSpan={4} align="right">
                      Total
                    </TableCell>
                    <TableCell align="right">
                      {formatter.format(
                        selectedOrder.items
                          .reduce(
                            (total, item) =>
                              total +
                              item.item.suppliers.find(
                                (s) => s.supplier._id === supplier._id
                              ).cost *
                                item.qty,
                            0
                          )
                          .toFixed(2)
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Paper>
      </Dialog>
    </div>
  );
};

ViewOrders.propTypes = {
  supplier: PropTypes.object,
};
