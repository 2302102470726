import { Box } from "@mui/material";
import React from "react";
import { useSettingsData } from "../../../hooks/useSettingsData";
import { DocusignIntegration } from "./integrations/DocusignIntegration";
import { StripeIntegration } from "./integrations/StripeIntegration";

export const Integrations = () => {
  const { data: settings } = useSettingsData();

  if (!settings) return null;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <DocusignIntegration settings={settings} />
      <StripeIntegration settings={settings} />
    </Box>
  );
};
