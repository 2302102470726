import React from "react";
import { Paper, Typography, Button, Box } from "@mui/material";
import PropTypes from "prop-types";
import { useCatalogUpdate } from "../hooks/useCatalogUpdate";
import { EditCatalog } from "./EditCatalog";
import { useCatalogDelete } from "../hooks/useCatalogDelete";
import {
  Cancel,
  Check,
  Delete,
  Link,
  Lock,
  Visibility,
} from "@mui/icons-material";

export const Catalog = ({ catalog }) => {
  const { mutate: updateCatalog } = useCatalogUpdate();
  const { mutate: deleteCatalog } = useCatalogDelete();

  const handleActiveClick = () =>
    updateCatalog({ _id: catalog._id, active: !catalog.active });
  const handlePublicClick = () =>
    updateCatalog({ _id: catalog._id, public: !catalog.public });
  const handleDeleteClick = () => deleteCatalog({ _id: catalog._id });

  return (
    <Paper key={catalog._id} sx={{ p: 1 }}>
      <Box sx={{ display: "flex", gap: 2, alignItems: "end" }}>
        <Typography variant="h6">{catalog.name}</Typography>{" "}
        <Typography variant="caption">
          {catalog.products.length}{" "}
          {catalog.products.length === 1 ? "Product" : "Products"}
        </Typography>
      </Box>

      <Typography sx={{ mb: 1 }}>{catalog.description}</Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Box sx={{ gap: 1, display: "flex" }}>
          <Button
            variant={catalog.active ? "contained" : "outlined"}
            color={catalog.active ? "success" : "error"}
            onClick={handleActiveClick}
            startIcon={catalog.active ? <Check /> : <Cancel />}
          >
            {catalog.active ? "Active" : "Not Active"}
          </Button>
          <Button
            variant="contained"
            color={catalog.public ? "success" : "primary"}
            onClick={handlePublicClick}
            startIcon={catalog.public ? <Visibility /> : <Lock />}
          >
            {catalog.public ? "Public" : "Private"}
          </Button>
          <Button variant="outlined" color="info" startIcon={<Link />}>
            Link
          </Button>
        </Box>
        <Box sx={{ gap: 1, display: "flex" }}>
          <Button
            variant="outlined"
            color="error"
            onClick={handleDeleteClick}
            startIcon={<Delete />}
          >
            Delete
          </Button>
          <EditCatalog catalog={catalog} />
        </Box>
      </Box>
    </Paper>
  );
};

Catalog.propTypes = {
  catalog: PropTypes.object.isRequired,
};
