import {
  Box,
  Typography,
  useTheme,
  TextField,
  ClickAwayListener,
} from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";
import FolderIcon from "@mui/icons-material/Folder";
import { useFolderUpdate } from "../hooks/useFolderUpdate";

export const Folder = ({ folder, setFolder, isEdit }) => {
  const theme = useTheme();
  const { mutate: updateFolder } = useFolderUpdate();
  const [edit, setEdit] = React.useState(!!isEdit);
  const handleFocus = (e) => {
    e.preventDefault();
    const { target } = e;
    const extensionStarts = target.value.lastIndexOf(".");
    target.focus();
    target.setSelectionRange(0, extensionStarts);
  };
  const [editName, setEditName] = React.useState(folder.name);

  React.useEffect(() => {
    setEditName(folder.name);
  }, [folder]);

  const handleFolderEdit = () => {
    let pack = { name: editName };
    if (folder.name === editName) {
      setEdit(false);
      return;
    }
    updateFolder({ id: folder._id, pack: pack });
  };

  return (
    <>
      <Box
        sx={{
          boxSizing: "border-box",
          borderRadius: 1,
          width: 100,
          height: 100,
          boxShadow: edit ? 5 : 0,
          backgroundColor: edit ? theme.palette.background.paper : "default",
          ":hover": {
            boxShadow: 5,
            backgroundColor: theme.palette.background.paper,
          },
        }}
      >
        <div
          onDoubleClick={() => setFolder(folder)}
          style={{
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FolderIcon fontSize="large" />
        </div>
        <div
          style={{
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: edit ? "visible" : "hidden",
          }}
        >
          {edit ? (
            <ClickAwayListener onClickAway={() => handleFolderEdit()}>
              <TextField
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
                sx={{ border: 0 }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleFolderEdit();
                  }
                }}
                variant="standard"
                autoFocus
                multiline
                onFocus={(e) => handleFocus(e)}
                InputProps={{ sx: { p: 0 } }}
              ></TextField>
            </ClickAwayListener>
          ) : (
            <Typography
              align="center"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
              onDoubleClick={() => setEdit(true)}
            >
              {folder.name}
            </Typography>
          )}
        </div>
      </Box>
    </>
  );
};

Folder.propTypes = {
  folder: PropTypes.string,
  setFolder: PropTypes.func,
  isEdit: PropTypes.bool,
};
String.prototype.trunc = function (n) {
  return this.slice(0, n - 1) + (this.length > n ? "..." : "");
};
