import React from "react";
import { CreateNewParameter } from "./CreateNewParameter";

export const ParameterSettings = () => {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <CreateNewParameter />
    </div>
  );
};
