import React, { useState } from "react";

import PropTypes from "prop-types";
import { useCustomerUpdate } from "../hooks/useCustomerUpdate";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

const columns = [
  { id: "firstName", label: "First Name", type: "text", minWidth: 80 },
  { id: "lastName", label: "Last Name", type: "text", minWidth: 80 },
  { id: "company", label: "Company", type: "text", minWidth: 80 },
  { id: "phone", label: "Phone", type: "tel", minWidth: 100 },
  { id: "email", label: "Email", type: "email", minWidth: 80 },
  { id: "address", label: "Address", type: "address1", minWidth: 80 },
  { id: "date", label: "Date", type: "date", minWidth: false },
  { id: "status", label: "Status", type: "text", minWidth: false },
];

export const EditCustomerDialog = ({ data, isOpen = false, setOpen }) => {
  const { mutate: editCustomer } = useCustomerUpdate();
  const handleCancel = () => setOpen(false);
  const handleClose = () => setOpen(false);
  const [internalFields, setInternalFields] = useState([]);

  React.useEffect(() => {
    if (!data) return;
    const dialogFields = [{ id: "_id" }, ...columns];
    dialogFields.map((x) => (x.value = data[x.id]));
    setInternalFields(dialogFields);
  }, [data]);
  //

  const onFieldChange = (fieldId, fieldValue) => {
    let tempFields = [...internalFields];
    tempFields.find((x) => x.id === fieldId).value = fieldValue;
    setInternalFields(tempFields);
  };

  const handleSubmit = () => {
    let payload = {};
    internalFields.map((f) => (payload[f.id] = f.value));

    const { _id } = data;

    payload._id = _id;

    editCustomer({ pack: payload, id: _id });
    setOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Edit Customer</DialogTitle>
      <DialogContent>
        {internalFields &&
          internalFields.map(
            (field, i) =>
              !["uuid", "_id"].includes(field.id) && (
                <TextField
                  autoFocus={i === 0}
                  margin="dense"
                  key={field.id}
                  id={field.id}
                  label={field.label}
                  type={field.type}
                  value={field.value || ""}
                  onChange={(e) => onFieldChange(field.id, e.target.value)}
                  fullWidth
                />
              )
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditCustomerDialog.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.object,
  customerID: PropTypes.string,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  updateRow: PropTypes.func,
};
