import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { getOption, getOptions } from "../api/optionApi";
import { UserContext } from "../App";

export const useOptionsData = (onSuccess, onError) => {
  const { user } = React.useContext(UserContext);
  const queryClient = useQueryClient();

  return useQuery("options", () => getOptions(user.id), {
    onSuccess: (data) => {
      // Cache each option individually
      data.forEach((option) => {
        queryClient.setQueryData(["option", option._id], option);
      });

      // Call the provided onSuccess handler
      if (onSuccess) onSuccess(data);
    },
    onError,
    staleTime: 1000 * 60 * 10, // 10 minutes
  });
};

export const useOptionData = (optionId) => {
  const queryClient = useQueryClient();

  // Define a query function that tries to get the data from the cache first
  const fetchOption = async () => {
    // Try to get the option from the cache
    const cachedOption = queryClient.getQueryData(["option", optionId]);

    // If found in the cache, return it
    if (cachedOption) {
      return cachedOption;
    }

    // If not found in the cache, fetch it from your API
    return await getOption(optionId);
  };

  return useQuery(["option", optionId], fetchOption, {
    staleTime: Infinity,
    // ... other options you might need, like refetchOnWindowFocus, etc.
  });
};
