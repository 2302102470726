export const modules = [
  {
    name: "Base",
    description: "Base features of the app.",
    price: 15,
    lookup_key: "base",
    features: ["Customer", "Project", "Product", "Files (50GB)"],
    selected: true,
  },
  {
    name: "Financial",
    description: "Financial tracking and reporting.",
    price: 25,
    fee: 1,
    lookup_key: "financial",
    features: [
      "Financial Tracking",
      "Automatic Invoices",
      "Payment Links",
      "Automatic Tax (Coming Soon)",
      "Document Generation",
    ],
    selected: false,
  },
  {
    name: "Project Management",
    description: "Advanced project management features.",
    price: 20,
    lookup_key: "project",
    features: [
      "Employee Management",
      "Employee Accounts",
      "Task Creation and Assignment",
      "Calendar View",
      "Inventory Tracking",
      "Files (300GB)",
    ],
    selected: false,
  },
  {
    name: "Product & Inventory Management",
    description: "Advanced product management features.",
    price: 20,
    lookup_key: "product",
    features: [
      "Product Options",
      "Product Categories",
      "Product Parameters",
      "Inventory Tracking",
    ],
    selected: false,
  },
];
