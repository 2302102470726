import { useTheme } from "@mui/material";
import React from "react";
// import { SketchPicker } from "react-color";
// import { ThemeContext } from "../../../Content";
import { ColorModeContext } from "../../../Content";
import { ModeSwitch } from "../utils/ModeSwitch";

export const ThemeSettings = () => {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);

  // const { colors, setColors } = React.useContext(ThemeContext);
  // const [localColors, setLocalColors] = React.useState(
  //   colors
  //     ? colors
  //     : {
  //         dark: {
  //           primary: "#000000",
  //           secondary: "#ffffff",
  //         },
  //         light: {
  //           primary: "#ffffff",
  //           secondary: "#000000",
  //         },
  //       }
  // );

  // const handleSave = () => {
  //   setColors(localColors);
  // };

  return (
    <div>
      <h1>Theme Settings</h1>
      <ModeSwitch
        checked={theme.palette.mode === "dark"}
        onChange={colorMode.toggleColorMode}
      />
      {/* <SketchPicker
        color={localColors.dark.primary}
        onChangeComplete={(color) =>
          setLocalColors((prev) => ({ ...prev, dark: { primary: color.hex } }))
        }
      />
      <SketchPicker
        color={localColors.dark.secondary}
        onChangeComplete={(color) =>
          setLocalColors((prev) => ({
            ...prev,
            dark: { secondary: color.hex },
          }))
        }
      /> */}
      {/* <Button onClick={handleSave}>Save</Button> */}
    </div>
  );
};
