import React from "react";
import PropTypes from "prop-types";
import { Box, Collapse, IconButton, Paper, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Task } from "./Task";
import { SelectedProjectContext } from "../../ProjectView";

export const Milestone = ({ milestone }) => {
  const project = React.useContext(SelectedProjectContext);
  const [expanded, setExpanded] = React.useState(milestone.active);

  return (
    <Paper
      sx={{ display: "flex", flexDirection: "column", gap: 1, p: 1, mt: 1 }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Typography variant="h6">{milestone.name} </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          <Typography variant="h6">
            {`${
              project.tasks.filter(
                (t) => t.milestone == milestone.name && t.completed
              ).length
            }/${
              project.tasks.filter((t) => t.milestone == milestone.name).length
            }`}
          </Typography>
          <IconButton
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <ExpandMoreIcon
              sx={{
                transform: expanded ? "rotate(180deg)" : null,
                transition: "all 500ms",
              }}
            />
          </IconButton>
        </Box>
      </Box>
      <Collapse in={expanded} unmountOnExit>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {project.tasks
            .filter((task) => task.milestone === milestone.name)
            .map((task) => (
              <Task key={task.id} task={task} />
            ))}
        </Box>
      </Collapse>
    </Paper>
  );
};

Milestone.propTypes = {
  milestone: PropTypes.object.isRequired,
};
