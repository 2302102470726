import React from "react";
import PropTypes from "prop-types";
import { Grid, Paper, Typography } from "@mui/material";
import { columns } from "../components/common/utils";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useOrdersData } from "../features/order/hooks/useOrdersData";

export const Order = () => {
  const { data: orders } = useOrdersData();

  if (!orders) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Grid
        container
        justifyContent="flex-start"
        alignItems={"center"}
        direction="row"
        columnSpacing={2}
        rowSpacing={2}
        sx={{ mb: 2 }}
      >
        <Grid item>
          <Typography variant="h3">Orders </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        rowSpacing={2}
        columnSpacing={2}
        sx={{ width: "100%", height: "80%" }}
      >
        <Grid item xs={12}>
          <Paper
            sx={{
              height: "100%",
            }}
          >
            <DataGridPremium
              // onRowClick={(e) => {
              // setSelectedCustomer([e.row._id]);
              //   setHelp(steps["ViewCustomer"]);
              //   setCustomerData(e.row);
              // }}
              getRowId={(row) => row._id}
              disableSelection
              rows={orders}
              columns={columns.customers}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

Order.propTypes = {
  selectedCustomer: PropTypes.object,
  user: PropTypes.string,
  options: PropTypes.object,
};
