import { Box, Tab, Tabs } from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";
import { checkSubscription } from "../../../utils/checkSubscription";
import { useSettingsData } from "../../../hooks/useSettingsData";

export const ProjectTabs = ({ tab, setTab }) => {
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const { data: settings } = useSettingsData();

  const subscription = checkSubscription(settings);

  return (
    <Box
      id="project-tabs"
      sx={{ display: "flex", justifyContent: "center", mb: 1 }}
    >
      <Tabs
        value={tab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab label="Overview" value="overview" sx={{ borderRadius: 1 }} />

        <Tab label="Products" value="products" sx={{ borderRadius: 1 }} />

        <Tab label="Files" value="files" sx={{ borderRadius: 1 }} />
        <Tab label="Financial" value="financial" sx={{ borderRadius: 1 }} />
        <Tab
          disabled={!subscription.includes("project")}
          label="Plan"
          value="tasks"
          sx={{ borderRadius: 1 }}
        />
        <Tab
          disabled={!subscription.includes("project")}
          label="Chat"
          value="chat"
          sx={{ borderRadius: 1 }}
        />
      </Tabs>
    </Box>
  );
};

ProjectTabs.propTypes = {
  tab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
};
