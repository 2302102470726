import React from "react";
import { ProductContext } from "./ProductCard";
import { DataGridPremium } from "@mui/x-data-grid-premium";

// const columns = [
//   {
//     field: "qty",
//     headerName: "Qty",
//     flex: 1,
//   },
//   {
//     field: "type",
//     headerName: "Type",
//     flex: 1,
//   },
// ];

export const Subcomponents = () => {
  const { product } = React.useContext(ProductContext);

  const [{ rows, columns }, setData] = React.useState(makeRows(product));

  React.useEffect(() => {
    setData(makeRows(product));
  }, [product]);

  return (
    <div>
      <DataGridPremium
        sx={{ height: 500 }}
        defaultGroupingExpansionDepth={10}
        rows={rows}
        density="compact"
        getRowId={(row) => row.hierarchy}
        columns={columns}
        groupingColDef={{ minWidth: 300 }}
        treeData
        getTreeDataPath={(row) => {
          return row.hierarchy;
        }}
      />
    </div>
  );
};

const makeRows = (component) => {
  let idsAndNames = new Map();
  let subcomponents = [...component.flatSubcomponents];
  subcomponents.forEach((subcomponent) => {
    idsAndNames.set(subcomponent._id, subcomponent.name);
  });

  let rows = [];
  subcomponents.forEach((subcomponent) => {
    let row = {
      id: subcomponent._id,
      name: subcomponent.name,
      hierarchy: subcomponent.hierarchy,
      type: subcomponent.type,
    };

    let parameters = component.allParameters.filter(
      (p) => p.hierarchy.join("-") === subcomponent.hierarchy.join("-")
    );

    parameters?.forEach((p) => {
      row[p.name] = p.value;
    });

    row.hierarchy = row.hierarchy
      .map((h) => {
        let name = idsAndNames.get(h);
        return name;
      })
      .filter((h) => h);

    rows.push(row);
  });

  let columns = [];
  rows.map((r) => {
    let rowCols = Object.keys(r);
    rowCols.map((c) => {
      if (
        !columns.find((col) => col.field === c) &&
        c !== "id" &&
        c !== "hierarchy" &&
        c !== "name"
      ) {
        c == "name"
          ? columns.push({ field: c, headerName: c, flex: 2 })
          : columns.push({ field: c, headerName: c, flex: 1 });
      }
    });
    r.parameters?.map((p) => {
      if (!columns.find((col) => col.field === p.name)) {
        columns.push({ field: p.name, headerName: p.name, flex: 1 });
      }
    });
  });
  console.log({ rows, columns: columns.sort((a, b) => a.field - b.field) });
  return { rows, columns };
};
