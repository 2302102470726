import {
  Box,
  Card,
  CardContent,
  Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext } from "react";
import PropTypes from "prop-types";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { TabContext } from "../../../Content";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const ProjectCard = ({ project }) => {
  const { handleTabChange } = useContext(TabContext);
  const paid = project.financial.reduce((acc, financial) => {
    if (financial.amount > 0) {
      acc += financial.amount;
    }
    return acc;
  }, 0);

  const theme = useTheme();

  const handleClick = () => {
    handleTabChange({ tab: "Projects", options: { project } });
  };

  return (
    <Card
      onClick={handleClick}
      sx={{
        width: "100%",
        mb: 1,
        cursor: "pointer",
        ":hover": {
          boxShadow: 8,
          transition: "all 0.3s ease-in-out",
        },
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography variant="h5" sx={{ width: "30%" }}>
            {project.projectName}
          </Typography>
          <Paper
            sx={{
              p: 1,
              backgroundColor:
                new Date() > new Date(project.dueDate)
                  ? theme.palette.error.main
                  : "primary.main",
              color: "primary.contrastText",
            }}
          >
            <Typography variant="body1">
              Due {new Date(project.dueDate).toLocaleDateString("en-US")}
            </Typography>
          </Paper>
          <div style={{ width: "30%", display: "flex", justifyContent: "end" }}>
            <Paper
              sx={{
                p: 1,
                backgroundColor: "primary.main",
                color: "primary.contrastText",
              }}
            >
              <Typography variant="body1" style={{ borderRadius: "8px" }}>
                {project.log[project.log.length - 1]?.name}
              </Typography>
            </Paper>
          </div>
        </Box>
        <Grid container sx={{ alignItems: "center" }}>
          <Grid
            item
            xs={4}
            sx={{ p: 1, display: "flex", alignItems: "center" }}
          >
            <LocationOnIcon />
            <Typography variant="body1">{project.address}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Paper elevation={2}>
              <div
                style={{
                  padding: "0 8px",
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundImage: "linear-gradient(rgb(0 0 0/40%) 0 0)",
                  borderTopLeftRadius: "4px",
                  borderTopRightRadius: "4px",
                }}
              >
                <Typography variant="body1">Project Value:</Typography>
                {formatter.format(project.projectValue)}
              </div>
              <div
                style={{
                  padding: "0 8px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1">Tax:</Typography>
                {formatter.format(project.tax)}
              </div>
              <div
                style={{
                  padding: "0 8px",
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundImage: "linear-gradient(rgb(0 0 0/40%) 0 0)",
                }}
              >
                <Typography variant="body1">Total:</Typography>
                {formatter.format(project.total)}
              </div>
              <div
                style={{
                  padding: "0 8px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1">Paid:</Typography>
                {formatter.format(paid)}
              </div>
              <Box
                sx={{
                  padding: "0 8px",
                  display: "flex",
                  justifyContent: "space-between",
                  backgroundColor:
                    project.balance > 0 ? "error.main" : "success.main",
                  borderBottomLeftRadius: "4px",
                  borderBottomRightRadius: "4px",
                  color: "primary.contrastText",
                }}
              >
                <Typography variant="body1">Balance:</Typography>
                {formatter.format(project.balance)}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
ProjectCard.propTypes = {
  project: PropTypes.object,
};
