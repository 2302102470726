import React from "react";
import { UserContext } from "../App";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
// import { Guide } from "../features/dashboard/components/Guide";
import { OrganizationDialog } from "../features/organization/components/NewOrganizationDialog";
import PropTypes from "prop-types";
import { useSettingsData } from "../hooks/useSettingsData";
import { Tasks } from "../features/dashboard/components/Tasks";
import dayjs from "dayjs";
import { Notifications } from "../features/dashboard/components/Notifications";
// import { BigFinancial } from "../features/dashboard/components/BigFinancial";
import "intro.js/introjs.css";
import "../utils/IntroJS.css";
import { useQueryClient } from "react-query";
import { useOrganizationData } from "../features/organization/hooks/useOrganizationData";
// import { checkSubscription } from "../utils/checkSubscription";

export const Dashboard = () => {
  const { user } = React.useContext(UserContext);
  const [open, setOpen] = React.useState(false);

  const { data: settings } = useSettingsData();
  const { data: organization } = useOrganizationData();

  // const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    if (!user || !settings) return;
    console.log(organization);
    // if (!organization) setOpen(true);
  }, [settings, organization]);

  // React.useEffect(() => {
  //   if (settings && organization) {
  //     setLoaded(true);
  //   }
  // }, [settings, organization]);

  const queryClient = useQueryClient();
  const components = queryClient.getQueriesData({
    queryKey: "components",
  });
  console.log(user, components);

  const dayOfTheWeek = dayjs().format("dddd");
  const date = dayjs().format("MMMM D, YYYY");
  // const [hover, setHover] = React.useState(false);
  // const sub = checkSubscription(settings) || [];

  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <Grid
        container
        sx={{ width: "66%", height: 800 }}
        display="flex"
        justifyContent={"start"}
        spacing={2}
      >
        <Grid xs={12} sx={{ height: "fit-content" }} id="title">
          <Typography variant="h3">{dayOfTheWeek}</Typography>
          <Typography variant="h4">{date}</Typography>
        </Grid>
        {/* 
        <Grid xs={6}>
          <Project />
        </Grid>
        <Grid xs={6}>
          <QuickActions />
        </Grid>
        <Grid xs={12}>
        </Grid> */}
        {/* <Paper
          sx={{
            width: "100%",
            height: 400,
            transition: "all 0.5s ease-in-out",
            mt: loaded ? 0 : 1,
            // borderRadius: "50px",
            // boxShadow: loaded ? 4 : 0,
            boxShadow: loaded
              ? // ? "7px 7px 14px #c8c8c8, -7px -7px 14px #ffffff;"
                "7px 7px 14px #202a2f, -7px -7px 14px #2c3a41"
              : "none",
            // filter: loaded ? "brightness(1.05)" : "none",
          }}
          id="big-financial"
          elevation={0}
          // elevation={hover ? 3 : 0}
          // onMouseEnter={() => setHover(true)}
          // onMouseLeave={() => setHover(false)}
        ></Paper> */}
      </Grid>

      <Box sx={{ width: "33%" }}>
        <Notifications />
        <Tasks />
      </Box>

      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 1,
        }}
      > */}
      {/* <Box>
          <Flowchart appBarOpen={appBarOpen} />
        </Box> */}
      {/* </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          p: 1,
          gap: 1,
          height: 350,
          mt: -15,
        }}
      >
        {" "}
        <Box sx={{}}>
          <Tasks />
        </Box>
        <Box sx={{ width: "40%", height: "75%", display: "flex", gap: 1 }}>
          <Financial pastMode={true} />
          <Financial pastMode={false} />
        </Box>
        <Box sx={{ width: "40%", height: "75%" }}>
          <Project />
        </Box>
        <OrganizationDialog isOpen={open} setOpen={setOpen} user={user} />
      </Box> */}
      <OrganizationDialog isOpen={open} setOpen={setOpen} user={user.id} />
      {/* <Steps
        enabled={openGuide}
        steps={dashboardSteps}
        initialStep={0}
        onExit={onExit}
      /> */}
    </Box>
  );
};

Dashboard.propTypes = {
  appBarOpen: PropTypes.bool,
};
