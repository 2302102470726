import React from "react";
import {
  Chart as ChartJS,
  TimeScale,
  LineElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  Filler,
  PointElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useTheme } from "@emotion/react";
import { Box, Paper, ToggleButton, ToggleButtonGroup } from "@mui/material";
import "chartjs-adapter-moment";
import { AddFinancial } from "../features/financial/components/AddFinancial";
import { useFinancialData } from "../features/financial/hooks/useFinancialData";
import { FinancialTable } from "../features/financial/components/FinancialTable";
import { Invoice } from "../features/financial/components/Invoice";
import { PaymentLink } from "../features/financial/components/PaymentLink";
import PropTypes from "prop-types";
import dayjs from "dayjs";

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Tooltip,
  Legend
);

export const Financial = ({ options }) => {
  const [dataSet, setDataSet] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [yMax, setYMax] = React.useState(0);
  // const [borderColor, setBorderColor] = React.useState("green");
  const [filter, setFilter] = React.useState("1M");
  const theme = useTheme();
  const { data: financial } = useFinancialData();

  const chartRef = React.useRef(null);
  const arbitraryLine = {
    id: "arbitraryLine",
    beforeDraw: (chart) => {
      const {
        ctx,
        chartArea: { top, bottom },
        scales: { x },
      } = chart;
      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = 3;
      ctx.strokeStyle = theme.palette.primary.main;
      ctx.moveTo(
        x.getPixelForValue(dayjs().hour(0).minute(0).second(0)),
        top + 24
      );
      ctx.lineTo(
        x.getPixelForValue(dayjs().hour(0).minute(0).second(0)),
        bottom
      );
      ctx.textAlign = "center";
      ctx.fillStyle = theme.palette.text.primary;
      ctx.fillText(
        "Today",
        x.getPixelForValue(dayjs().hour(0).minute(0).second(0)),
        top + 18
      );
      ctx.stroke();
      ctx.restore();
    },
  };
  React.useEffect(() => {
    if (!financial || !dataSet) return;
    let cutoffDate = new Date();
    switch (filter) {
      case "YTD":
        cutoffDate.setMonth(0);
        break;
      case "1M":
        cutoffDate.setMonth(cutoffDate.getMonth() - 1);
        break;
      case "3M":
        cutoffDate.setMonth(cutoffDate.getMonth() - 3);
        break;
      case "1Y":
        cutoffDate.setFullYear(cutoffDate.getFullYear() - 1);
        break;
      default:
        cutoffDate = new Date(0);
    }
    let data = [];
    [...financial]
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .forEach((entry) => {
        data.push({
          x: dayjs(entry.date).hour(0).minute(0).second(0).toDate(),
          y: entry.amount,
        });
      });

    let filteredData = data.filter((entry) => new Date(entry.x) >= cutoffDate);
    let finalData = [];
    let uniqueDays = [];
    filteredData.forEach((entry) => {
      if (!uniqueDays.includes(entry.x)) {
        uniqueDays.push(entry.x);
      }
    });
    uniqueDays.forEach((day) => {
      let dayTotal = filteredData
        .filter((entry) => entry.x == day)
        .reduce((acc, cur) => acc + cur.y, 0);
      finalData.push({
        x: day,
        y:
          dayTotal +
          (finalData[finalData.length - 1]
            ? finalData[finalData.length - 1].y
            : 0),
      });
    });
    let positiveData = getPositiveData(data, cutoffDate);
    let negativeData = getNegativeData(data, cutoffDate);
    let { positive, negative } = stretchLinesToEdges(
      positiveData,
      negativeData
    );

    setDataSet(finalData);
    setYMax(Math.max(...finalData.map((d) => d.y)) * 2);
    setData(
      createData({ positiveDataSet: positive, negativeDataSet: negative })
    );
  }, [financial, filter]);

  const handleFilter = (e, newFilter) => {
    setFilter(newFilter);
  };

  if (!financial) return null;

  return (
    <Box sx={{ height: 940 }}>
      <Paper id="financial-chart" sx={{ height: 350, p: 3 }}>
        <Line
          ref={chartRef}
          id="chart"
          plugins={[arbitraryLine]}
          options={{
            plugins: {
              legend: {
                display: false,
              },
              arbitraryLine: {
                color: theme.palette.primary.main,
              },
            },

            scales: {
              x: {
                type: "time",
                time: {
                  unit: "day",
                  unitStepSize: 100,
                  displayFormats: {
                    millisecond: "MMM DD",
                    second: "MMM DD",
                    minute: "MMM DD",
                    hour: "MMM DD",
                    day: "MMM DD",
                    week: "MMM DD",
                    month: "MMM DD",
                    quarter: "MMM DD",
                    year: "MMM DD",
                  },
                },

                ticks: {
                  color: theme.palette.text.primary,
                },
              },
              y: {
                ticks: {
                  color: theme.palette.text.primary,
                },
                suggestedMax: yMax,
                beginAtZero: true,
              },
            },
            responsive: true,
            maintainAspectRatio: false,
          }}
          width={100}
          data={{
            datasets: [
              {
                lineTension: 0.1,
                fill: true,
                label: "Income",
                data: data.pastIncome,
                borderWidth: 2,
                borderColor: "rgb(0, 180, 0, 0.5)",
                backgroundColor: function (context) {
                  return getGradient(context, "positive", "past");
                },
                pointRadius: 2,
              },
              {
                lineTension: 0.4,
                fill: true,
                label: "Future Income",
                data: data.futureIncome,
                borderWidth: 2,
                borderColor: "rgb(0, 180, 0, 1)",
                backgroundColor: function (context) {
                  return getGradient(context, "positive", "future");
                },
                pointRadius: 2,
              },
              {
                lineTension: 0.1,
                fill: true,
                label: "Expense",
                data: data.pastExpense,
                borderWidth: 2,
                borderColor: "rgb(180, 0, 0, 0.5)",
                backgroundColor: function (context) {
                  return getGradient(context, "negative", "past");
                },
                pointRadius: 2,
              },
              {
                lineTension: 0.4,
                fill: true,
                label: "Future Expense",
                data: data.futureExpense,
                borderWidth: 2,
                borderColor: "rgb(180, 0, 0, 1)",
                backgroundColor: function (context) {
                  return getGradient(context, "negative", "future");
                },
                pointRadius: 2,
              },
            ],
          }}
        />
      </Paper>
      <Box sx={{ height: 540 }}>
        {/* Income */}
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            p: 1,
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <AddFinancial />
            <Invoice
              info={{ open: options.data == "invoice" ? true : false }}
            />
            <PaymentLink />
          </Box>
          <ToggleButtonGroup
            value={filter}
            exclusive
            onChange={handleFilter}
            aria-label="text alignment"
          >
            <ToggleButton value="1M" aria-label="1 month">
              1M
            </ToggleButton>
            <ToggleButton value="3M" aria-label="3 months">
              3M
            </ToggleButton>
            <ToggleButton value="YTD" aria-label="year to date">
              YTD
            </ToggleButton>
            <ToggleButton value="1Y" aria-label="1 year">
              1Y
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box sx={{ height: 440 }}>
          <FinancialTable />
        </Box>
      </Box>
    </Box>
  );
};

Financial.propTypes = {
  options: PropTypes.object,
};

const getGradient = (context, line, time) => {
  var ctx = context.chart.ctx;

  var gradient = ctx.createLinearGradient(0, 0, 0, 300);
  gradient.addColorStop(
    0,
    line == "positive"
      ? time == "past"
        ? "rgb(0, 180, 0, 0.3)"
        : "rgb(0, 180, 0, 0.5)"
      : time == "past"
      ? "rgb(180, 0, 0, 0.3)"
      : "rgb(180, 0, 0, 0.5)"
  );
  gradient.addColorStop(1, "rgba(100, 100, 0,0)");
  return gradient;
};

const getPositiveData = (data, cutoffDate) => {
  let filteredPositiveData = data.filter(
    (entry) => new Date(entry.x) >= cutoffDate && entry.y > 0
  );
  let finalPositiveData = [];
  let uniquePositiveDays = [];
  filteredPositiveData.forEach((entry) => {
    if (!uniquePositiveDays.includes(entry.x)) {
      uniquePositiveDays.push(entry.x);
    }
    // else {
    //   filteredData[filteredData.findIndex((e) => e.x == entry.x)].y +=
    //     entry.y;
    // }
  });
  uniquePositiveDays.forEach((day) => {
    let dayTotal = filteredPositiveData
      .filter((entry) => entry.x == day)
      .reduce((acc, cur) => acc + cur.y, 0);
    finalPositiveData.push({
      x: day,
      y:
        dayTotal +
        (finalPositiveData[finalPositiveData.length - 1]
          ? finalPositiveData[finalPositiveData.length - 1].y
          : 0),
    });
  });

  return finalPositiveData;
};
const getNegativeData = (data, cutoffDate) => {
  if (!data.length) return;
  let filteredNegativeData = data.filter(
    (entry) => new Date(entry.x) >= cutoffDate && entry.y < 0
  );
  let finalNegativeData = [];
  let uniqueNegativeDays = [];
  filteredNegativeData.forEach((entry) => {
    if (!uniqueNegativeDays.includes(entry.x)) {
      uniqueNegativeDays.push(entry.x);
    }
    // else {
    //   filteredData[filteredData.findIndex((e) => e.x == entry.x)].y +=
    //     entry.y;
    // }
  });
  uniqueNegativeDays.forEach((day) => {
    let dayTotal = filteredNegativeData
      .filter((entry) => entry.x == day)
      .reduce((acc, cur) => acc + cur.y * -1, 0);
    finalNegativeData.push({
      x: day,
      y:
        dayTotal +
        (finalNegativeData[finalNegativeData.length - 1]
          ? finalNegativeData[finalNegativeData.length - 1].y
          : 0),
    });
  });

  return finalNegativeData;
};

const stretchLinesToEdges = (positive, negative) => {
  if (!positive.length || !negative.length) return { positive, negative };
  const minPositiveDate = new Date(
    positive.reduce((a, b) => (a.x < b.x ? a : b)).x
  );
  const maxPositiveDate = new Date(
    positive.reduce((a, b) => (a.x > b.x ? a : b)).x
  );
  const minNegativeDate = new Date(
    negative.reduce((a, b) => (a.x < b.x ? a : b)).x
  );
  const maxNegativeDate = new Date(
    negative.reduce((a, b) => (a.x > b.x ? a : b)).x
  );
  minPositiveDate < minNegativeDate
    ? negative.unshift({ x: minPositiveDate, y: 0 })
    : positive.unshift({ x: minNegativeDate, y: 0 });
  maxPositiveDate > maxNegativeDate
    ? negative.push({ x: maxPositiveDate, y: negative[negative.length - 1].y })
    : positive.push({ x: maxNegativeDate, y: positive[positive.length - 1].y });
  return { positive, negative };
};

const createData = (data) => {
  const { positiveDataSet, negativeDataSet } = data;
  const pastIncome =
    positiveDataSet?.filter((entry) => new Date(entry.x) <= new Date()) || [];
  if (
    pastIncome.length &&
    !pastIncome.find(
      (e) => dayjs(e.x).format("DD/MM/YYYY") === dayjs().format("DD/MM/YYYY")
    )
  ) {
    pastIncome.push({
      x: dayjs().hour(0).minute(0).second(0),
      y: pastIncome[pastIncome.length - 1]?.y,
    });
  }

  const futureIncome =
    positiveDataSet?.filter((entry) => new Date(entry.x) >= new Date()) || [];
  if (futureIncome.length) {
    pastIncome.length
      ? futureIncome.unshift({
          x: dayjs().hour(0).minute(0).second(0),
          y: pastIncome[pastIncome.length - 1]?.y,
        })
      : futureIncome.unshift({
          x: dayjs().hour(0).minute(0).second(0),
          y: 0,
        });
  }

  const pastExpense =
    negativeDataSet?.filter((entry) => new Date(entry.x) <= new Date()) || [];
  if (
    pastExpense.length &&
    !pastExpense.find(
      (e) => dayjs(e.x).format("DD/MM/YYYY") === dayjs().format("DD/MM/YYYY")
    )
  ) {
    pastExpense.push({
      x: dayjs().hour(0).minute(0).second(0),
      y: pastExpense[pastExpense.length - 1]?.y,
    });
  }

  const futureExpense =
    negativeDataSet?.filter((entry) => new Date(entry.x) >= new Date()) || [];

  if (futureExpense.length) {
    pastExpense.length
      ? futureExpense.unshift({
          x: dayjs().hour(0).minute(0).second(0),
          y: pastExpense[pastExpense.length - 1]?.y,
        })
      : futureExpense.unshift({
          x: dayjs().hour(0).minute(0).second(0),
          y: 0,
        });
  }
  return { pastIncome, futureIncome, pastExpense, futureExpense };
};
