import React from "react";
import PropTypes from "prop-types";
import { Box, Paper, Typography } from "@mui/material";
import { ControlledEditableInput } from "../../../../utils/ControlledEditableInput";

export const DefaultStep = ({ step, index }) => {
  return (
    <Paper sx={{ m: 1, p: 1 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h4" sx={{ opacity: "0.5", mr: 1 }}>
            {index}.{" "}
          </Typography>
          <Typography variant="body1">
            <ControlledEditableInput
              size="small"
              label="Label"
              hideLabel
              value={step.name}
            />
          </Typography>
        </Box>
      </Box>
      <Typography variant="body2">{step.description}</Typography>
    </Paper>
  );
};

DefaultStep.propTypes = {
  step: PropTypes.object,
  index: PropTypes.number,
};
