import React from "react";
import { ProductContext } from "./ProductCard";
import { Option } from "./Option";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

export const Options = ({ view }) => {
  const { product } = React.useContext(ProductContext);
  console.log(product);
  console.log(getOptions(product));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: view == "compact" ? "row" : "column",
        gap: 1,
      }}
    >
      {product.options
        .sort((a, b) => a.sources[0]?.length - b.sources[0]?.length)
        .filter((o) =>
          o.sources.some(
            (s) =>
              product.flatSubcomponents.find(
                (c) => c.hierarchy.join("-") == s
              ) || s == product._id
          )
        )
        .map((option) => (
          <Option key={option.name} option={option} />
        ))}
    </Box>
  );
};

Options.propTypes = {
  view: PropTypes.string,
};

const getOptions = (product) => {
  return product.options.filter((o) =>
    o.sources.some(
      (s) =>
        product.flatSubcomponents.find((c) => c.hierarchy.join("-") == s) ||
        s == product._id
    )
  );
};
