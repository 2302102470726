import React from "react";
import PropTypes from "prop-types";
import { modules } from "../utils/modules.js";
import { Box, Button, Card, Typography } from "@mui/material";
import { cancelSubscription, checkout } from "../../../api/paymentApi.js";
import { CustomerInfoContext } from "./Signup.js";
import { checkSubscription } from "../../../utils/checkSubscription.js";
export const ModuleSelection = ({ setProgress, existingUser, settings }) => {
  const [selectedModules, setSelectedModules] = React.useState(modules);
  const { customerInfo } = React.useContext(CustomerInfoContext);

  const handleSelect = (name) => {
    setSelectedModules((prev) => {
      let mod = prev.find((module) => module.name == name);
      if (mod.selected) {
        mod.selected = false;
      } else {
        mod.selected = true;
      }
      return [...prev];
    });
  };
  let sub = checkSubscription(settings) || [];

  React.useEffect(() => {
    if (settings) {
      setSelectedModules((prev) => {
        return prev.map((mod) => {
          if (sub.includes(mod.lookup_key)) {
            mod.selected = true;
          } else if (mod.name != "Base") {
            mod.selected = false;
          }
          return mod;
        });
      });
    }
  }, [settings]);

  const handleSubmit = async () => {
    if (existingUser) {
      const data = await checkout({
        pack: {
          products: selectedModules.filter((m) => m.selected),
          user: existingUser,
          action: sub.length > 0 ? "update" : "new",
        },
      });
      window.location.href = data.data;
    } else {
      setProgress(1);
      //post to payment/checkout
      const data = await checkout({
        pack: {
          products: selectedModules.filter((m) => m.selected),
          user: customerInfo,
        },
      });
      // nav to url
      window.location.href = data.data;
    }
  };
  const [canceling, setCanceling] = React.useState(false);
  const handleCancel = () => {
    if (!canceling) {
      setCanceling(true);
      setTimeout(() => {
        setCanceling(false);
      }, 3000);
    } else {
      cancelSubscription({ pack: { user: settings.user } }).then(() => {
        window.location.reload();
      });
    }
  };

  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <Typography variant="h4" fontWeight={"bold"}>
          Select Modules
        </Typography>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {modules.map((module) => (
          <Card
            sx={{
              width: "300px",
              textAlign: "center",
              height: "350px",
              display: "flex",
              flexDirection: "column",
              p: 2,
              m: 2,
            }}
            key={module.name}
          >
            <Typography variant="h4">{module.name}</Typography>
            <Typography variant="body" sx={{ mb: 2 }}>
              {module.description}
            </Typography>

            {module.features.map((feature) => (
              <Typography key={feature}>• {feature}</Typography>
            ))}

            <Box sx={{ mt: "auto" }}>
              <Typography variant="h5">{`$${module.price}/month`}</Typography>
              {module.fee && (
                <Typography variant="body" sx={{ mb: 2, display: "block" }}>
                  {`* ${module.fee}% transaction fee on transactions`}
                </Typography>
              )}

              {module.name == "Base" ? null : (
                <Button
                  variant={module.selected ? "outlined" : "contained"}
                  onClick={() => handleSelect(module.name)}
                >
                  {module.selected ? "Remove" : "Add"}
                </Button>
              )}
            </Box>
          </Card>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Typography variant="h6" sx={{ mr: 2 }}>
          Total: ${" "}
          {selectedModules.reduce((acc, curr) => {
            if (curr.selected) {
              return acc + curr.price;
            } else {
              return acc;
            }
          }, 0)}{" "}
          /month
        </Typography>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={
            !checkIfModulesChanged(
              sub,
              selectedModules.filter((m) => m.selected).map((m) => m.lookup_key)
            )
          }
        >
          Submit
        </Button>
      </Box>
      <Box sx={{ mt: 4, justifyContent: "center", display: "flex" }}>
        <Button
          variant="contained"
          disabled={sub.length == 0}
          color={canceling ? "error" : "primary"}
          sx={{ mr: 2 }}
          onClick={handleCancel}
        >
          {canceling ? "Are you sure?" : "Cancel Subscription"}
        </Button>
      </Box>
    </div>
  );
};

ModuleSelection.propTypes = {
  setProgress: PropTypes.func.isRequired,
  existingUser: PropTypes.string,
  settings: PropTypes.object,
};

const checkIfModulesChanged = (existingModules, selectedModules) => {
  let changed = false;
  existingModules.forEach((mod) => {
    if (!selectedModules.includes(mod)) {
      changed = true;
    }
  });
  if (existingModules.length != selectedModules.length) {
    changed = true;
  }
  return changed;
};
