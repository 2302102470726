import { Box, Button, Paper, TextField } from "@mui/material";
import React from "react";
import { CustomerInfoContext } from "./Signup";
import PropTypes from "prop-types";

export const SignupForm = ({ setPage, setProgress }) => {
  const { customerInfo, setCustomerInfo } =
    React.useContext(CustomerInfoContext);

  const handleBack = () => {
    setPage("home");
  };
  const handleContinue = () => {
    setProgress(0.5);
    setTimeout(() => {
      setProgress(1);
    }, 500);
  };
  return (
    <Paper sx={{ p: 2, width: 400 }}>
      {Object.keys(customerFields).map((key) => (
        <div key={key}>
          <TextField
            id="outlined-basic"
            sx={{ mb: 2 }}
            label={customerFields[key].label}
            variant="outlined"
            type={customerFields[key].type}
            onChange={(e) =>
              setCustomerInfo({ ...customerInfo, [key]: e.target.value })
            }
            value={customerInfo[key]}
            fullWidth
          />
        </div>
      ))}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button variant="outlined" color="primary" onClick={handleBack}>
          Back
        </Button>

        <Button variant="contained" color="primary" onClick={handleContinue}>
          Continue
        </Button>
      </Box>
    </Paper>
  );
};

SignupForm.propTypes = {
  setPage: PropTypes.func.isRequired,
  setProgress: PropTypes.func.isRequired,
};

const customerFields = {
  firstName: {
    label: "First Name",
  },
  lastName: {
    label: "Last Name",
  },

  email: {
    label: "Email",
    type: "email",
  },
  phone: {
    label: "Phone Number",
    type: "tel",
  },
  company: {
    label: "Company",
  },
  website: {
    label: "Website",
    type: "url",
  },
  address: {
    label: "Address",
  },
  city: {
    label: "City",
  },
  state: {
    label: "State",
  },
  zip: {
    label: "Zip Code",
  },
};
