import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../../../Content";
import { updateCatalog } from "../../../api/catalogApi";
import { UserContext } from "../../../App";

export function useCatalogUpdate() {
  const { setSnack } = React.useContext(SnackbarContext);
  const { user } = React.useContext(UserContext);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (pack) => {
      return updateCatalog({ ...pack, user: user.id });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("catalogs");
      queryClient.setQueryData("catalogs", (old) => {
        let newCatalogs = [...old];
        let index = newCatalogs.findIndex((n) => n._id === data._id);
        if (index === -1) {
          newCatalogs.push(data);
        } else {
          newCatalogs[index] = data;
        }
        return newCatalogs;
      });

      setSnack({
        open: true,
        message: "Catalog updated.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Catalog could not be updated.",
        severity: "error",
      });
    },
  });
}
