import { ListItemText, Menu, MenuItem } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { NewComponentContext } from "../../components/ComponentNode";
import { useSuppliersData } from "../../../../hooks/useSuppliersData";

export const SupplierMenu = ({ anchor, setAnchor }) => {
  const { data: suppliers } = useSuppliersData();
  const { newComponent, handleEdit } = React.useContext(NewComponentContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  React.useEffect(() => {
    setAnchorEl(anchor);
  }, [anchor]);

  const handleClose = () => {
    setAnchor(null);
  };

  const handleClick = (supplier) => {
    handleEdit({
      field: "suppliers",
      value: { supplier: supplier, default: true },
    });
  };

  return (
    <div>
      <Menu
        id="add-menu"
        anchorEl={anchorEl}
        open={open}
        disabled={!suppliers}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {suppliers?.map((supplier) => (
          <MenuItem
            key={supplier._id}
            onClick={() => handleClick(supplier)}
            sx={{
              backgroundColor: newComponent.suppliers.find(
                (s) => s._id === supplier._id
              )
                ? "primary.main"
                : "default",
              color: newComponent.suppliers.find((s) => s._id === supplier._id)
                ? "primary.contrastText"
                : "default",
            }}
            // onClick={() => handleCategoryClick(category)}
          >
            <ListItemText>{supplier.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

SupplierMenu.propTypes = {
  anchor: PropTypes.any,
  setAnchor: PropTypes.func,
};
