/* eslint-disable react/prop-types */
import { Box, TextField, Paper, Collapse } from "@mui/material";
import React from "react";
import useStore from "./store";
import { Handle, Position } from "reactflow";
import { useTheme } from "@mui/material";
import { Parameters } from "../nodes/component/Parameters";
import { Quantity } from "../nodes/component/Quantity";
import { Image } from "../nodes/component/Image";
import { Categories } from "../nodes/component/Categories";
import { Price } from "../nodes/component/Price";
import { isEditable } from "./utils";
import { NodeMenu } from "../nodes/component/NodeMenu";
import { useParametersData } from "../../../hooks/useParametersData";
import { Check, Lock, PriorityHigh } from "@mui/icons-material";
import { CustomIconButton } from "../../../utils/CustomIconButtom";
import { Code } from "../nodes/component/Code";
import { Supplier } from "../nodes/component/Supplier";
import { Cost } from "../nodes/component/Cost";

export const NewComponentContext = React.createContext({
  newComponent: {
    _id: "newComponent",
    nodeKey: "newComponent",
    name: "Component",
    description: "",
    image: "",
    cost: null,
    code: null,
    price: null,
    suppliers: [],
    subcomponents: [],
    options: [],
    isProduct: true,
    categories: [],
    parameters: [],
  },
  handleEdit: () => {},
  clearComponent: () => {},
  setComponent: () => {},
});

// eslint-disable-next-line react/display-name
export default function ComponentNode({ id, data, selected }) {
  const theme = useTheme();
  const colorMode = theme.palette.mode;
  const newComponent = data.data;
  const handleEdit = useStore((state) => state.editComponentNode);
  const { data: parameters } = useParametersData();
  const getVariables = useStore((state) => state.getVariables);
  const nodeExists = !id.split("-").at(-1).includes("_") && id !== "parent";
  if (!newComponent) return null;
  return (
    <Paper
      key={id}
      elevation={0}
      sx={{
        cursor: "default",
        boxShadow: selected ? 8 : 4,
        p: 1,
        minWidth: 300,
        maxWidth: 300,
        boxSizing: "border-box",
        transition: "all 0.2s",
        border: selected
          ? `1px solid ${colorMode === "light" ? "black" : "white"}`
          : "1px solid transparent",
      }}
    >
      <NewComponentContext.Provider
        value={{
          newComponent,
          handleEdit: ({ field, value, type }) =>
            handleEdit({
              id: id,
              field: field,
              value: value,
              type: type,
            }),
        }}
      >
        {id === "parent" ||
        newComponent.duplicate ||
        id.split("-").length == 1 ? null : (
          <Handle
            type="target"
            id="top"
            position={Position.Top}
            style={{
              top: "-8px",
              borderRadius: "5px",
              border: 0,
              backgroundColor: theme.palette.grey[300],
            }}
            isConnectable={false}
          />
        )}
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <CustomIconButton
            sx={{
              position: "absolute",
              mt: -3,
              p: "2px",
              minWidth: 0,
              zIndex: -1,
            }}
            tooltipPosition={"top"}
            onClick={() => {
              !newComponent.edit && !newComponent.duplicate && nodeExists
                ? handleEdit({
                    id: id,
                    field: "edit",
                    value: !newComponent.edit,
                  })
                : newComponent.saved &&
                  !newComponent.duplicate &&
                  nodeExists &&
                  id.split("-").length > 1
                ? handleEdit({
                    id: id,
                    field: "edit",
                    value: !newComponent.edit,
                  })
                : null;
            }}
            title={
              !newComponent.edit && !newComponent.duplicate && nodeExists
                ? "Locked"
                : newComponent.saved
                ? "Saved"
                : "Unsaved"
            }
            variant="contained"
            color={
              !newComponent.edit && !newComponent.duplicate && nodeExists
                ? "primary"
                : newComponent.saved
                ? "success"
                : "error"
            }
            icon={
              !newComponent.edit && !newComponent.duplicate && nodeExists ? (
                <Lock sx={{ fontSize: "16px" }} />
              ) : newComponent.saved ? (
                <Check sx={{ fontSize: "16px" }} />
              ) : (
                <PriorityHigh sx={{ fontSize: "16px" }} />
              )
            }
          ></CustomIconButton>
          {!newComponent.edit &&
          Object.hasOwn(newComponent, "edit") &&
          newComponent.nodeKey.split("-").length > 1 &&
          !newComponent.saved ? (
            <CustomIconButton
              color="error"
              icon={<PriorityHigh sx={{ fontSize: "16px" }} />}
              variant="contained"
              title={
                "Unsaved parameter overrides. Saving will apply them only to this component."
              }
              sx={{
                position: "absolute",
                right: 35,
                mt: -3,
                p: "2px",
                minWidth: 0,
                zIndex: -1,
              }}
              tooltipPosition={"top"}
            />
          ) : null}
        </Box>
        <Handle
          type="source"
          id="bottom"
          position={Position.Bottom}
          isConnectable={
            newComponent._id === "newComponent" ||
            newComponent.duplicate ||
            newComponent.edit
              ? true
              : false
          }
          style={{
            bottom: "-16px",
            width: "18px",
            height: "12px",
            borderRadius: "5px",
            border: 0,
            backgroundColor: newComponent.collapsed
              ? theme.palette.info.main
              : theme.palette.grey[300],
          }}
        />

        <Handle
          type="source"
          id="right"
          position={Position.Right}
          style={{
            right: "-16px",
            width: "12px",
            height: "12px",
            borderRadius: "5px",
            border: 0,
            backgroundColor: theme.palette.primary.main,
            top: "28px",
          }}
        />
        <Handle
          type="source"
          id="left"
          position={Position.Left}
          style={{
            left: "-16px",
            width: "12px",
            height: "12px",
            borderRadius: "5px",
            border: 0,
            backgroundColor: theme.palette.primary.main,
            top: "28px",
          }}
        />
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <TextField
            size="small"
            fullWidth
            value={newComponent.name}
            onChange={(e) =>
              isEditable(id, newComponent.edit)
                ? handleEdit({ id: id, field: "name", value: e.target.value })
                : null
            }
          />
          <NodeMenu
            currentId={id}
            switchFn={() => {}}
            duplicateFn={() => {}}
            deleteFn={() => {}}
          />
        </Box>
        <Collapse in={newComponent.categories.length}>
          <Categories />
        </Collapse>
        <Collapse in={newComponent.code !== null}>
          <Code />
        </Collapse>
        <Collapse in={newComponent.image !== null}>
          <Image />
        </Collapse>
        {id.split("-").length == 1 ? null : (
          <Quantity
            variables={[
              ...getVariables(),
              ...parameters
                .filter((p) => p.global)
                .map((p) => ({ ...p, variable: p.name })),
            ]}
          />
        )}
        <Collapse in={newComponent.price !== null}>
          <Price />
        </Collapse>

        <Collapse in={newComponent.suppliers.length}>
          {newComponent.suppliers.map((supplier) => (
            <Supplier key={supplier._id} supplier={supplier} />
          ))}
        </Collapse>

        <Collapse
          in={newComponent.cost !== null && !newComponent.suppliers.length}
        >
          <Cost />
        </Collapse>
        <Parameters
          variables={[
            ...getVariables(),
            ...parameters
              .filter((p) => p.global)
              .map((p) => ({ ...p, variable: p.name })),
          ]}
        />
      </NewComponentContext.Provider>
    </Paper>
  );
}
