import React from "react";
import { UserContext } from "../../../App";
import {
  Avatar,
  //   Button,
  Dialog,
  //   IconButton,
  Paper,
  Typography,
} from "@mui/material";
import axios from "axios";
import { QueryClient } from "react-query";

const cloudfrontUrl = "https://cdn.manufacture.app/";

export const UserAvatar = () => {
  const { user } = React.useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const onFileSelect = (e) => {
    const data = new FormData();
    data.append("image", e.target.files[0]);
    axios.patch(`/api/person/${user.person._id}`, data).then(() => {
      QueryClient.invalidateQueries("employee");
    });
  };
  console.log(user);
  if (!user.person) return null;
  return (
    <>
      <Avatar
        id="profile"
        src={user.person ? `${cloudfrontUrl}${user.person.avatar}` : null}
        alt={user.person ? user.person.firstName : ""}
        onClick={handleClickOpen}
        sx={{
          cursor: "pointer",
          ":hover": {
            opacity: [0.9, 0.8, 0.7],
            boxShadow: 10,
          },
          ml: 1,
          transitionDuration: "0.5s",
        }}
      />
      <Typography variant="h6" sx={{ ml: 1 }}>
        {user.person.firstName}
      </Typography>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <Paper sx={{ height: "100%", p: 2 }}>
          <input
            accept="image/*"
            id="contained-button-file"
            type="file"
            onChange={onFileSelect}
          />
          <label htmlFor="contained-button-file">
            <Avatar
              src={`${cloudfrontUrl}${user.person?.avatar}`}
              style={{
                margin: "10px",
                width: "60px",
                height: "60px",
              }}
            />
          </label>
          <Typography variant="h5" sx={{ mt: 2 }}>
            {user.person?.firstName} {user.person?.lastName}
          </Typography>
          <Typography variant="h6">{user.person.email}</Typography>
          {/* <Button variant="contained" onClick={logout}>
            Log Out
          </Button> */}
        </Paper>
      </Dialog>
    </>
  );
};
