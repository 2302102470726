import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const FinancialCard = ({ projects, financials }) => {
  let outstanding = 0;
  let paid = projects.reduce((acc, project) => {
    let projectTotalPaid = 0;
    project.financial.reduce((acc, financial) => {
      if (financial.amount > 0) {
        acc += financial.amount;
        projectTotalPaid += financial.amount;
      }
      return acc;
    }, 0);

    acc += projectTotalPaid;

    outstanding += project.total - projectTotalPaid;

    return acc;
  }, 0);

  financials.map((financial) => {
    if (financial.stripeInvoiceId) {
      financial.stripeInvoiceStatus == "paid"
        ? (paid += financial.amount)
        : (outstanding += financial.amount);
    } else if (financial.plinkStatus) {
      financial.plinkStatus == "paid"
        ? (paid += financial.amount)
        : (outstanding += financial.amount);
    }
  });

  const data = {
    labels: ["Paid", "Outstanding"],
    datasets: [
      {
        label: "Balance",
        data: [paid.toFixed(2), outstanding.toFixed(2)],
        backgroundColor: ["rgba(75, 192, 192, 0.2)", "rgba(255, 99, 132, 0.2)"],
        borderColor: ["rgba(75, 192, 192, 1)", "rgba(255, 99, 132, 1)"],
        borderWidth: 1,
      },
    ],
  };
  return (
    <Paper sx={{ mt: 2, p: 2, position: "relative" }} id="customer-financial">
      <Box
        sx={{
          display: "flex",
          justifyConent: "center",
          alignItems: "center",
          flexDirection: "column",
          position: "absolute",
          top: "53%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Typography variant="h6" sx={{ color: "success.main" }}>
          {formatter.format(paid.toFixed(2))}
        </Typography>
        <Typography variant="h6" sx={{ color: "error.main" }}>
          {formatter.format(outstanding.toFixed(2))}
        </Typography>
      </Box>
      <Doughnut data={data} />
    </Paper>
  );
};

FinancialCard.propTypes = {
  customer: PropTypes.object.isRequired,
  projects: PropTypes.array.isRequired,
  financials: PropTypes.array.isRequired,
};
