import React from "react";
import { useQuery } from "react-query";
import { getCustomers } from "../api/customerApi";
import { UserContext } from "../App";

export const useCustomersData = (onSuccess, onError) => {
  const { user } = React.useContext(UserContext);
  return useQuery("customers", () => getCustomers(user.id), {
    onSuccess,
    onError,
  });
};
