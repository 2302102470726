import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  //   MenuItem,
  Paper,
  //   Select,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useSettingsData } from "../../../hooks/useSettingsData";
import { useParametersData } from "../../../hooks/useParametersData";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useComponentsCreateMany } from "../../../hooks/useComponentsCreateMany";
import { UserContext } from "../../../App";
import { useComponentsData } from "../../../hooks/useComponentsData";

export const MozaikMaterials = ({ data, setProject }) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { data: settings } = useSettingsData();
  const [selected, setSelected] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  const [existingMaterials, setExistingMaterials] = React.useState([]);
  const { data: parameters } = useParametersData();
  const { data: components } = useComponentsData();

  // eslint-disable-next-line no-unused-vars
  const { mutate: createManyComponents } = useComponentsCreateMany(
    "Items added to inventory."
  );
  const { user } = React.useContext(UserContext);

  const handleSubmit = () => {
    let pack = selected.map((x) => {
      let item = data.find((y) => y.ID == x);

      return {
        name: item.name,
        cost: parseFloat(item.price.replaceAll(",", "")),
        user: user.id,
        materialFor: "mozaik",
      };
    });
    createManyComponents({ pack: pack });
    setSelected([]);
    setRefresh(true);
    setConfirmDialogOpen(false);
  };

  React.useEffect(() => {
    if (components) {
      const mats = components.filter(
        (c) => c.materialFor == "mozaik" && data.find((d) => d.name == c.name)
      );
      let existingMats = [];
      data.map((row) => {
        let matIndex = mats.findIndex((m) => m.name == row.name);
        if (matIndex == -1) return;
        let foundIndex = existingMats.findIndex((m) => m.name == row.name);
        if (foundIndex > -1) {
          existingMats[foundIndex].qty += parseFloat(row.qty);
          existingMats[foundIndex].total =
            existingMats[foundIndex].qty * existingMats[foundIndex].cost;
        } else {
          existingMats.push({
            cost: mats[matIndex].cost,
            name: row.name,
            qty: parseFloat(row.qty),
            total: mats[matIndex].cost * parseFloat(row.qty),
            id: mats[matIndex]._id,
          });
        }
      });
      setExistingMaterials(existingMats);
      setProject((prev) => ({
        ...prev,
        componentList: existingMats,
      }));
    }
  }, [components, refresh]);

  //   React.useEffect(() => {
  //     if (settings.importData?.find((x) => x.source == "mozaik")) {
  //       let data = settings.importData.find((x) => x.source == "mozaik").keys;
  //       let newKeys = [];
  //       keyData.forEach((x, i) => {
  //         if (data[i].import) {
  //           data[i].app == "parameterList"
  //             ? newKeys.push({
  //                 ...x,
  //                 import: data[i].import,
  //                 parameters: data[i].parameters,
  //               })
  //             : newKeys.push({ ...x, import: data[i].import });
  //         } else {
  //           newKeys.push(x);
  //         }
  //       });
  //     }
  //   }, [settings]);

  // eslint-disable-next-line no-unused-vars
  const handleSelect = (e) => {
    setSelected(e);
  };
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  if (!settings || !parameters || !components) return null;

  return (
    <>
      <Button
        variant="contained"
        color={
          settings.importData.find((d) => d.source == "mozaik")
            ? "primary"
            : "info"
        }
        onClick={() => setDialogOpen(true)}
      >
        Set Materials
      </Button>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <Paper sx={{ p: 2 }}>
          <Typography variant="h6">Set Materials</Typography>
          <Typography variant="body1">
            Select what you want to be tracked as a material/component. After
            you have selected the items you want to track, clicking confirm will
            create the items in the Inventory tab.
          </Typography>
          <Box>
            {existingMaterials.length > 0 ? (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6">Existing Materials</Typography>
                <DataGridPremium
                  sx={{ maxHeight: 500 }}
                  rows={existingMaterials}
                  hideFooter
                  columns={existingMaterialColumns}
                  density="compact"
                />
              </Box>
            ) : null}
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6">Add Materials</Typography>
              <DataGridPremium
                sx={{
                  maxHeight: 500,
                  ".MuiDataGrid-virtualScroller": {
                    "&::-webkit-scrollbar": {
                      width: "0.4em",
                      backgroundColor: "divider",
                    },
                    "&::-webkit-scrollbar-track": {
                      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "primary.main",
                    },
                  },
                }}
                rowSelectionModel={selected}
                checkboxSelection
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setSelected(newRowSelectionModel);
                }}
                rows={data}
                density="compact"
                getRowId={(row) => row.ID}
                isRowSelectable={(params) => {
                  return !existingMaterials.find(
                    (x) => x.name == params.row.name
                  );
                }}
                columns={Object.keys(data[0]).map((k) => ({
                  field: k,
                  headerName: k,
                  flex: 1,
                }))}
              />
            </Box>
            {/* {keys.map((key, i) => (
              <Box key={i}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: 1,
                  }}
                >
                  <Typography sx={{ width: "30%" }}>{key.label}</Typography>
                  <Select
                    value={key.import}
                    fullWidth
                    multiple={
                      key.app == "parameterList" || key.app == "options"
                    }
                    onChange={(e) => {
                      setKeys((prev) =>
                        prev.map((x, j) =>
                          i == j ? { ...x, import: e.target.value } : x
                        )
                      );
                    }}
                  >
                    {Object.keys(data[0]).map((y, i) => (
                      <MenuItem key={i} value={y}>
                        {y.import ? y.import : y}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
                {key.app == "parameterList"
                  ? key.import.map((x, j) => (
                      <Box
                        key={j}
                        sx={{
                          display: "flex",
                          ml: 3,
                          mr: 3,
                          mt: 1,
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ width: "30%" }}>{x}</Typography>
                        <Select
                          value={key.parameters[j]}
                          renderValue={(value) => value.name}
                          fullWidth
                        >
                          {parameters.map((y, k) => (
                            <MenuItem key={k} value={y}>
                              {y.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    ))
                  : null}
              </Box>
            ))} */}
          </Box>
          <DialogActions sx={{ mt: 1 }}>
            <Button onClick={() => setDialogOpen(false)} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={() => setConfirmDialogOpen(true)}
              variant="contained"
              disabled={selected.length === 0}
            >
              Submit
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Confirm Materials</DialogTitle>
        <DialogContent>
          These are the items that will be turned into components and added to
          the inventory. Are you sure you want to continue?
          <DataGridPremium
            rows={data.filter((x) => selected.includes(x.ID))}
            sx={{
              mt: 2,
              ".MuiDataGrid-virtualScroller": {
                "&::-webkit-scrollbar": {
                  width: "0.4em",
                  backgroundColor: "divider",
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "primary.main",
                },
              },
            }}
            columns={Object.keys(data[0]).map((k) => ({
              field: k,
              headerName: k,
              flex: 1,
            }))}
            getRowId={(row) => row.ID}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setConfirmDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

MozaikMaterials.propTypes = {
  data: PropTypes.array,
  setProject: PropTypes.func,
};

const existingMaterialColumns = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "cost",
    headerName: "Cost",
    valueGetter: (params) => {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(params.value);
    },
    flex: 1,
  },
  {
    field: "qty",
    headerName: "Qty",
    flex: 1,
  },
];
