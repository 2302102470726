import axios from "axios";

const paymentApi = axios.create();

export const checkout = async ({ pack }) => {
  const data = await paymentApi.post("/api/payment/checkout", pack);
  return data;
};

export const signInToStripe = async ({ pack }) => {
  const data = await paymentApi.post("/api/payment/stripe", {
    user: pack.user,
  });
  return data;
};

export const createPaymentLink = async ({ pack }) => {
  const data = await paymentApi.post("/api/payment/stripe/link", pack);
  return data;
};

export const createInvoice = async ({ pack }) => {
  const data = await paymentApi.post("/api/payment/stripe/invoice", pack);
  return data;
};

export const cancelSubscription = async ({ pack }) => {
  const data = await paymentApi.post("/api/payment/stripe/cancel", pack);
  return data;
};
