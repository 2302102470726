import { Mail, Password } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { UserContext } from "../../../App";
import { login } from "../../../api/userApi";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const navigate = useNavigate();
  const { setUser } = React.useContext(UserContext);

  const [values, setValues] = React.useState({
    email: "",
    password: "",
    stayLoggedIn: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const loginUser = async () => {
    try {
      const returnedUser = await login({
        email: values.email,
        password: values.password,
        stayLoggedIn: values.stayLoggedIn,
      });
      if (returnedUser.token) {
        localStorage.setItem("user", returnedUser.token);
      }
      setUser(returnedUser);
      navigate("/");
    } catch (error) {
      setValues({ ...values, error: error.response.data.message });
    }
  };

  return (
    <Container maxWidth="xs">
      <Paper sx={{ marginTop: 12, width: 320, padding: 4 }}>
        <form>
          <Stack spacing={2} alignItems="center">
            <Typography variant="h6">Welcome to Manufacture.app!</Typography>
            <Typography variant="subtitle">
              Please sign-in to your account.
            </Typography>
            <TextField
              fullWidth
              label="Email"
              autoComplete="username"
              value={values.email}
              onChange={handleChange("email")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Mail />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              label="Password"
              type="password"
              autoComplete={"current-password"}
              value={values.password}
              onChange={handleChange("password")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Password />
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.stayLoggedIn}
                  onChange={(e) =>
                    setValues({ ...values, stayLoggedIn: e.target.checked })
                  }
                />
              }
              label="Stay logged in?"
            />
            {values.error && (
              <Typography variant="caption" color="error">
                {values.error}
              </Typography>
            )}
            <Button variant="contained" fullWidth onClick={loginUser}>
              Login
            </Button>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
};
