import React from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress } from "@mui/material";
import { Delete, DeleteForever } from "@mui/icons-material";

function DeleteButton(props) {
  const { onDelete, ...other } = props;
  const [deleting, setDeleting] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleFirstClick = () => {
    setDeleting(true);
    setTimeout(() => {
      setDeleting(false);
    }, 3000);
  };

  const handleSecondClick = () => {
    setLoading(true);
    onDelete(() => {
      setLoading(false);
      setDeleting(false);
    });
  };
  return (
    <Button
      {...other}
      variant={deleting ? "contained" : "outlined"}
      aria-label="edit"
      color="error"
      onClick={!deleting ? handleFirstClick : handleSecondClick}
      sx={{ mr: 0 }}
      disabled={loading || other.disabled}
      startIcon={
        loading ? (
          <CircularProgress color="error" size={20} />
        ) : (
          <>
            <Delete
              sx={{
                position: "absolute",
                opacity: !deleting ? 1 : 0,
                transition: "opacity 0.5s",
              }}
            />
            <DeleteForever
              sx={{
                opacity: deleting ? 1 : 0,
                transition: "opacity 0.5s",
              }}
            />
          </>
        )
      }
    >
      {loading ? "Loading" : deleting ? "Confirm" : "Delete"}
    </Button>
  );
}
DeleteButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default DeleteButton;
