export const STEPS = [
  {
    label: "Created",
    name: "Created",
    description:
      "This is the first step in the process. It is created when the project is created.",
    completed: true,
    category: "General",
  },
  {
    label: "Completed",
    name: "Completed",
    description:
      "This is the last step in the process and marks the project as completely done.",
    completed: false,
    category: "General",
  },
];

export const STEP_TYPES = [
  { name: "click", value: "Click. This is a blank step." },
  { name: "fileUpload", value: "Upload a file." },
  { name: "fileTemplate", value: "Create a file from a template." },
  { name: "payment", value: "Add a payment." },
];
export const CATEGORIES = ["General", "Financial", "Production", "Design"];
