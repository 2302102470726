/* eslint-disable no-unused-vars */
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { UserContext } from "../../../App";
import { SnackbarContext } from "../../../Content";
import { createFlow } from "../../../api/componentApi";

export function useFlowCreate() {
  const { user } = React.useContext(UserContext);
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  return useMutation({
    mutationFn: ({ pack }) => {
      console.log(pack);
      pack.user = user.id;
      let images = [];
      let imagesData = [];

      pack.nodeData = pack.nodeData.map((n, i) => {
        if (n.imgSrc) {
          images.push(n.image);
          imagesData.push({ ...n.imageData, id: i });
          delete n.imgSrc;
        }
        return n;
      });

      const formData = new FormData();
      formData.append("pack", JSON.stringify(pack));

      for (const image of images) {
        formData.append("images", image);
      }

      formData.append("imagesData", JSON.stringify(imagesData));

      return createFlow(formData);
    },
    onSuccess: (data) => {
      const { rootNode, dataToAdd, updatedNodes } = data;
      queryClient.refetchQueries(["components"]);
      // queryClient.invalidateQueries({ queryKey: ["components"] });
      queryClient.invalidateQueries({ queryKey: ["options"] });
      queryClient.setQueryData("components", (old) => {
        let newComponents = [...old];
        let rootIndex = newComponents.findIndex((c) => c._id === rootNode._id);
        newComponents[rootIndex] = rootNode;
        if (rootIndex === -1) {
          newComponents.push(rootNode);
        }
        dataToAdd.forEach((c) => {
          let index = newComponents.findIndex((n) => n._id === c._id);
          if (index === -1) {
            newComponents.push(c);
          } else {
            newComponents[index] = c;
          }
        });
        updatedNodes.forEach((c) => {
          let index = newComponents.findIndex((n) => n._id === c._id);
          if (index === -1) {
            newComponents.push(c);
          } else {
            newComponents[index] = c;
          }
        });
        return newComponents;
      });

      queryClient.setQueryData("options", (old) => {
        let newOptions = [...old];
        dataToAdd.forEach((c) => {
          if (!Object.hasOwn(c, "required")) return;
          let index = newOptions.findIndex((n) => n._id === c._id);
          if (index === -1) {
            newOptions.push(c);
          } else {
            newOptions[index] = c;
          }
        });
        return newOptions;
      });

      setSnack({
        open: true,
        message: "Components saved.",
        severity: "success",
      });
      return data;
    },
    onError: (error) => {
      console.log(error.response.data);
      queryClient.invalidateQueries("components");
      setSnack({
        open: true,
        message: error.response
          ? error.response.data.message
          : "Component could not be created.",
        severity: "error",
      });
    },
  });
}

// const removeOptionParametersIfOptionIsInherited = (component) => {
//   component.options.map((o) => {
//     if (o.inheritedFrom?.length > 0) {
//       component.parameterList = component.parameterList.filter(
//         (p) => p.hierarchy[1] !== o._id
//       );
//     }
//   });
//   return component;
// };
