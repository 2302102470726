import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import { useOrganizationUpdate } from "../hooks/useOrganizationUpdate";

const CLOUDFRONT_URL = "https://cdn.manufacture.app/";
export const Logo = ({ org }) => {
  const { mutate: updateOrganization } = useOrganizationUpdate();

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("id", org._id);
    updateOrganization(formData);
  };

  return (
    <div>
      {org.logo ? (
        <img src={`${CLOUDFRONT_URL}${org.logo}`} alt="logo" />
      ) : (
        <Button
          variant="contained"
          color="primary"
          component="label"
          htmlFor="logo"
        >
          Upload Logo
          <input
            type="file"
            id="logo"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleLogoChange}
          />
        </Button>
      )}
    </div>
  );
};

Logo.propTypes = {
  org: PropTypes.object.isRequired,
};
