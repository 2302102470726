import * as React from "react";
import PropTypes from "prop-types";
import { ListItemButton, ListItemText, Collapse } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { FolderBar } from "./FolderBar";
// import FolderOpenIcon from '@mui/icons-material/FolderOpen';

export const FolderBarFolder = ({ folder, setFolder, folders }) => {
  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setFolder(folder);
    setOpen((prev) => !prev);
  };

  let subFolders = folders.filter((f) => f.parent == folder._id);

  return (
    <>
      <ListItemButton
        sx={{ p: 0, pl: 3, pr: 2, borderRadius: 1 }}
        onClick={() => handleClick()}
      >
        <FolderIcon sx={{ pr: 1 }} />
        <ListItemText primary={folder.name} />
        {subFolders.length > 0 ? open ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItemButton>
      {subFolders.length > 0 ? (
        <div style={{ paddingLeft: "16px" }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <FolderBar
              folders={folders}
              topLevel={subFolders}
              setFolder={setFolder}
            />
          </Collapse>
        </div>
      ) : null}
    </>
  );
};
FolderBarFolder.propTypes = {
  folder: PropTypes.array,
  subFolders: PropTypes.array,
  setFolder: PropTypes.func,
  folders: PropTypes.array,
};
