import React from "react";

export function useNewStep() {
  const [newStep, setNewStep] = React.useState({
    id: "newStep",
    name: "New Step",
    description: "Description for new step.",
    type: "type",
    category: "General",
    template: "",
    templateId: "",
    active: false,
    amount: "0",
    percent: false,
    send: false,
  });

  const handleChange = ({ field, value }) => {
    if (field === "percent" && value === true) {
      setNewStep((prevNewStep) => {
        if (prevNewStep.amount > 100) {
          return {
            ...prevNewStep,
            amount: "100.00",
            [field]: value,
          };
        } else {
          return {
            ...prevNewStep,
            [field]: value,
          };
        }
      });
    } else {
      setNewStep((prevNewStep) => ({
        ...prevNewStep,
        [field]: value,
      }));
    }
  };

  const clearNewStep = () => {
    setNewStep({
      _id: "newStep",
      name: "New Step",
      description: "Description for new step.",
      type: "type",
      category: "category",
      template: "",
      templateId: "",
      active: false,
      amount: "0",
      percent: false,
      send: false,
    });
  };

  return [newStep, handleChange, clearNewStep];
}
