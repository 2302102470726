import React from "react";
import PropTypes from "prop-types";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { MozaikKeys } from "../utils/MozaikKeys";
import { useSettingsData } from "../../../hooks/useSettingsData";
import { MozaikMaterials } from "../utils/MozaikMaterials";

export const MozaikTable = ({ data, handleSelect, setProject }) => {
  const [dialogOpen, setDialogOpen] = React.useState(true);
  const { CabinetTable, RoomTable, PriceDataTable } = data;

  const [rows] = React.useState(
    matchPricesToProducts(
      CabinetTable.map((x) => {
        const room = RoomTable.find((y) => y.ID == x.Room);
        const material = splitProductString(room.material);
        return {
          ...x,
          zone: room.roomName,
          extTexture: room.extTexture,
          intTexture: room.intTexture,
          Doors: material.Doors,
          Drawers: material.Drawers,
          Cabinets: material.Cabinets,
          Tops: material.Tops,
        };
      }),
      transformToRows(PriceDataTable)
    )
  );
  const [columns, setColumns] = React.useState(
    Object.keys(CabinetTable[0]).map((x, i) => ({
      field: x,
      headerName: x,
      flex: 1,
      selected: i < 4 ? true : false,
    }))
  );

  React.useEffect(() => {
    setColumns(
      Object.keys(rows[0]).map((x, i) => ({
        field: x,
        headerName: x,
        flex: 1,
        selected: i < 5 ? true : false,
      }))
    );
  }, [rows]);

  //   const setViewToParts = () => {
  //     setRows(() => {
  //       const newRows = [];
  //       data.forEach((x, i) =>
  //         x.parts.forEach((y, j) => {
  //           newRows.push({
  //             ...y,
  //             hierarchy: [x.UniqueID, y.Name],
  //             UniqueID: `${i}_${j}`,
  //           });
  //         })
  //       );
  //       return newRows;
  //     });

  //   };

  // const setViewToProducts = () => {
  //   setRows(
  //     CabinetTable.map((x) => {
  //       const room = RoomTable.find((y) => y.ID == x.Room);
  //       const material = splitProductString(room.material);
  //       return {
  //         ...x,
  //         zone: room.roomName,
  //         extTexture: room.extTexture,
  //         intTexture: room.intTexture,
  //         Doors: material.Doors,
  //         Drawers: material.Drawers,
  //         Cabinets: material.Cabinets,
  //         Tops: material.Tops,
  //       };
  //     })
  //   );
  //   setView("products");
  // };

  // const setViewToPrices = () => {

  //   setRows(transformToRows(PriceDataTable));
  //   setView("prices");
  // };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setColumns(
      columns.map((column) => ({
        ...column,
        selected: value.indexOf(column) > -1,
      }))
    );
  };
  const { data: settings } = useSettingsData();

  const handleConfirm = () => {
    let importKeys = settings.importData.find(
      (x) => x.source == "mozaik"
    )?.keys;
    if (!importKeys) {
      return;
    }
    let projectValue = 0;
    const parsedRows = rows.map((x) => {
      const parsed = {};
      importKeys.forEach((key) => {
        if (key.import) {
          if (key.app == "parameterList") {
            parsed.parameterList = [];
            key.parameters.forEach((param, i) => {
              parsed.parameterList.push({
                ...param,
                hierarchy: ["newComponent"],
                originalValue: x[Object.values(key.import)[i]],
              });
            });
            parsed.parameterList.push({
              name: "qty",
              hierarchy: ["newComponent"],
              originalValue: "1",
            });
          } else if (Array.isArray(key.import)) {
            parsed[key.app] = key.import.map((y) => ({
              [y]: x[y],
            }));
            return;
          } else {
            parsed[key.app] = x[key.import];
          }
        }
      });
      projectValue += parseFloat(parsed.price.replace(",", ""));
      parsed.importData = x;
      return parsed;
    });

    setProject((prev) => ({
      ...prev,
      zones: RoomTable.map((x) => x.roomName),
      imported: "Mozaik",
      projectValue: projectValue,
    }));

    handleSelect(parsedRows);
    setDialogOpen(false);
  };

  return (
    <>
      <Button onClick={() => setDialogOpen(true)}>View Data</Button>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="xl"
        fullWidth
      >
        <Paper>
          <DialogTitle>Mozaik Import</DialogTitle>
          <DialogContent
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            <Typography variant="body1">
              If this is your first time importing from Mozaik, you will need to
              click on the &quot;Set Keys&quot; button below; it will be blue if
              you need to set the keys. This will allow you to map the Mozaik
              data to the app&apos;s data. You will only need to do this once.
              The &quot;Set Materials&quot; button is also helpful to keep track
              of what materials are being used in the project even when
              importing, but it is not necessary.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
              {/* <ToggleButtonGroup>
              <ToggleButton
                onClick={() => setViewToProducts()}
                sx={{
                  backgroundColor: view == "products" ? "primary.main" : null,
                }}
              >
                Products
              </ToggleButton>
              <ToggleButton
                onClick={() => setViewToPrices()}
                sx={{
                  backgroundColor: view == "prices" ? "primary.main" : null,
                }}
              >
                Prices
              </ToggleButton>
            </ToggleButtonGroup> */}
              <MozaikKeys data={rows} />
              <MozaikMaterials
                data={transformToRows(PriceDataTable)}
                setProject={setProject}
              />
            </Box>
            <Typography variant="body1">
              Here is all the data that was imported from Mozaik. You can view
              what each value represents and select which columns you want to
              view since there are probably many you do not need. This data is
              mainly useful so that you can see which keys you want to set.
            </Typography>
            <FormControl size="small">
              <InputLabel size="small">Columns</InputLabel>
              <Select
                value={columns.filter((x) => x.selected)}
                onChange={handleChange}
                multiple
                label="Columns"
                size="small"
                fullWidth
              >
                {columns.map((column) => (
                  <MenuItem key={column.field} value={column}>
                    {column.headerName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DataGridPremium
              rows={rows}
              density="compact"
              columns={columns.filter((x) => x.selected)}
              sx={{
                maxHeight: 700,
                overflow: "auto",
                ".MuiDataGrid-virtualScroller": {
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                    backgroundColor: "divider",
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "primary.main",
                  },
                },
              }}
              getRowId={(row) => row.ID}
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => setDialogOpen(false)}>
              Close
            </Button>
            <Button variant="contained" onClick={handleConfirm}>
              Confirm
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </>
  );
};

MozaikTable.propTypes = {
  data: PropTypes.array,
  handleSelect: PropTypes.func,
  setProject: PropTypes.func,
};

export function splitProductString(productString) {
  const regex = /(\w+:\s[\w\s]+)(?=\s\w+:|$)/g;
  const matches = productString.match(regex);
  const result = {};

  if (matches) {
    matches.forEach((match) => {
      const [key, value] = match.split(": ");
      result[key] = value;
    });
  }

  return result;
}

const transformToRows = (arrayOfStrings) => {
  return arrayOfStrings
    .map((price, i) => {
      let string = price.PriceData;
      let split = string.split("$");
      let first = split[0].split(",");
      let lastComma = split[1].lastIndexOf(",");
      let second = split[1].slice(0, lastComma);
      lastComma = second.lastIndexOf(",");
      second = second.slice(0, lastComma);
      let third = split[2];
      return {
        qty: first[0],
        increment: first[1],
        name: first[2],
        price: second,
        total: third,
        ID: i,
      };
    })
    .filter((x) => parseFloat(x.price) > 0);
};

const matchPricesToProducts = (products, prices) => {
  let rows = [];
  products.forEach((product) => {
    let price = prices.find((price) => price.name == product.cabName);
    if (!price) return;
    rows.push({ ...product, price: price.price, total: price.total });
  });
  return rows;
};
