import { Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
// import { ProductsTable } from "./ProductsTable";
// import { OptionsAndRoomsOverview } from "./OptionsAndRoomsOverview";
import { FinancialOverview } from "./overview/FinancialOverview";
// import { ProductsOverview } from "./overview/ProductsOverview";
import { OverviewButtons } from "./overview/OverviewButtons";
// import { ProjectFileManager } from "../../fileManager/components/ProjectFileManager";

export const OverviewTab = ({ setTab }) => {
  return (
    <>
      <Box sx={{ gap: 1, width: "100%" }}>
        <Box sx={{ display: "block", width: "100%", mb: 1 }}>
          <OverviewButtons setTab={setTab} />
        </Box>
        <Box sx={{ display: "flex", width: "100%", gap: 1, mb: 1 }}>
          <FinancialOverview />
          {/* <ProductsOverview /> */}
        </Box>
        <Box sx={{ display: "flex", width: "100%", gap: 1, mb: 5 }}>
          {/* <TaskOverview /> */}
          {/* <PeopleOverview /> */}
        </Box>
      </Box>
    </>
  );
};
OverviewTab.propTypes = {
  project: PropTypes.object,
  setTab: PropTypes.func.isRequired,
};
