import {
  Box,
  Button,
  Chip,
  Collapse,
  // Collapse,
  Dialog,
  // Grid,
  Paper,
  Typography,
} from "@mui/material";
import * as React from "react";
import { Component } from "../features/components/components/Component";
import { WrappedFlow } from "../features/flowCreate/components/Flow";
import { ProductCard } from "../features/newProductCard/components/ProductCard";
// import { useComponentsData } from "../hooks/useComponentsData";
import { useOptionsData } from "../hooks/useOptionsData";
import { useParametersData } from "../hooks/useParametersData";
import { AddCircle } from "@mui/icons-material";
import { TransitionGroup } from "react-transition-group";
// import { useCachedComponents } from "../hooks/useCachedComponents";
import { useComponentsData } from "../hooks/useComponentsData";
import { useSettingsData } from "../hooks/useSettingsData";
import useStore from "../features/flowCreate/components/store";
import { ViewCatalogs } from "../features/catalog/components/ViewCatalogs";

export const ComponentsContext = React.createContext();

export const Components = () => {
  const { data: components } = useComponentsData();
  // useComponentsData();
  // const components = useCachedComponents();
  // eslint-disable-next-line no-unused-vars
  const { data: options } = useOptionsData();
  // eslint-disable-next-line no-unused-vars
  const { data: parameters } = useParametersData();
  const { data: settings } = useSettingsData();
  const [create, setCreate] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [product, setProduct] = React.useState({});
  const [editProduct, setEditProduct] = React.useState({});
  const [filter, setFilter] = React.useState("");
  const [filteredItems, setFilteredItems] = React.useState([]);

  const resetStore = useStore((state) => state.reset);

  const handleChipClick = (category) => {
    if (filter === category) {
      setFilter("");
      setFilteredItems([...components, ...options]);
    } else {
      setFilter(category);
      if (category === "Product") {
        setFilteredItems(components.filter((c) => c.isProduct));
      } else if (category === "Option") {
        setFilteredItems(options);
      } else {
        setFilteredItems(
          components.filter((c) => c.categories.includes(category))
        );
      }
    }
  };

  React.useEffect(() => {
    if (filter === "" && components && options) {
      setFilteredItems([...components, ...options]);
    }
  }, [components, options, filter]);

  const handleClickOpen = (product) => {
    setProduct(product);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleEditClick = (component) => {
    setEditProduct(component);
    setCreate(true);
  };

  const handleBack = () => {
    setCreate(false);
    setEditProduct({});
    resetStore();
  };

  const handleDuplicateClick = (component) => {
    setEditProduct({
      ...component,
      duplicate: true,
    });
    setCreate(true);
  };

  if (!components || !options || !parameters) {
    return <div>Loading...</div>;
  }
  console.log({ components, options, filteredItems });

  return (
    <Box>
      <ComponentsContext.Provider value={{ components }}>
        {create ? (
          <WrappedFlow
            editProduct={editProduct}
            handleBack={handleBack}
            components={components}
            options={options}
          />
        ) : (
          <>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography variant="h3">Components </Typography>
                <Button
                  startIcon={<AddCircle />}
                  variant="contained"
                  onClick={() => setCreate(true)}
                >
                  Create Item
                </Button>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ViewCatalogs />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  position: "sticky",
                  top: 75,
                  flexWrap: "wrap",
                  gap: 1,
                  p: 1,
                  boxShadow: 6,
                  mb: 1,
                  width: "fit-content",
                }}
              >
                <Chip
                  key={"Product"}
                  label={"Product"}
                  sx={{
                    borderRadius: 1,
                    backgroundColor:
                      filter === "Product" ? "primary.main" : "default",
                    color:
                      filter === "Product" ? "primary.contrastText" : "default",
                    ":hover": {
                      backgroundColor:
                        filter === "Product" ? "primary.light" : "default",
                    },
                  }}
                  onClick={() => handleChipClick("Product")}
                />
                <Chip
                  key={"Option"}
                  label={"Option"}
                  sx={{
                    borderRadius: 1,
                    backgroundColor:
                      filter === "Option" ? "primary.main" : "default",
                    color:
                      filter === "Option" ? "primary.contrastText" : "default",
                    ":hover": {
                      backgroundColor:
                        filter === "Option" ? "primary.light" : "default",
                    },
                  }}
                  onClick={() => handleChipClick("Option")}
                />
                {settings.categories.map((category) => (
                  <Chip
                    key={category}
                    label={category}
                    sx={{
                      borderRadius: 1,
                      backgroundColor:
                        filter === category ? "primary.main" : "default",
                      color:
                        filter === category
                          ? "primary.contrastText"
                          : "default",
                      ":hover": {
                        backgroundColor:
                          filter === category ? "primary.light" : "default",
                      },
                    }}
                    onClick={() => handleChipClick(category)}
                  />
                ))}
              </Paper>
              <Box>
                <TransitionGroup
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    flexWrap: "wrap",
                    gap: 8,
                    mb: 16,
                  }}
                >
                  {filteredItems.map((component) => (
                    <Collapse
                      orientation="horizontal"
                      in={true}
                      key={component._id}
                    >
                      <div>
                        <Component
                          key={component._id}
                          component={component}
                          handleView={handleClickOpen}
                          handleEditClick={handleEditClick}
                          handleDuplicate={handleDuplicateClick}
                        />
                      </div>
                    </Collapse>
                  ))}
                </TransitionGroup>
              </Box>
            </Box>
            {/*             
            <Paper
              sx={{ height: "100%", width: "30%", p: 1, overflow: "auto" }}
            >
              {" "}
              <TransitionGroup>
                <Grid container spacing={1}>
                  {components
                    .filter((c) => c.isProduct)
                    .map((c) => (
                      <Grid item key={c._id}>
                        <Collapse
                          in={true}
                          key={c._id}
                          orientation="horizontal"
                        >
                          <Component
                            key={c._id}
                            component={c}
                            handleView={handleClickOpen}
                            handleEditClick={handleEditClick}
                            handleDuplicate={handleDuplicateClick}
                          />
                        </Collapse>
                      </Grid>
                    ))}
                </Grid>
              </TransitionGroup>
            </Paper>
            <Paper
              sx={{
                height: "100%",
                width: "70%",
                p: 1,
                overflow: "auto",
              }}
            >
              {" "}
              <TransitionGroup>
                <Grid container spacing={1}>
                  {components
                    .filter((c) => !c.isProduct)
                    .map((component, i) => (
                      <Grid item key={component._id}>
                        <Collapse in={true} key={i} orientation="horizontal">
                          <Component
                            key={component._id}
                            component={component}
                            handleView={handleClickOpen}
                            handleEditClick={handleEditClick}
                            handleDuplicate={handleDuplicateClick}
                          />
                        </Collapse>
                      </Grid>
                    ))}
                  {options.map((option, i) => (
                    <Grid item key={option._id}>
                      <Collapse
                        in={true}
                        key={`o${i}`}
                        orientation="horizontal"
                      >
                        <Component
                          key={option._id}
                          component={option}
                          handleView={handleClickOpen}
                          handleEditClick={handleEditClick}
                          handleDuplicate={handleDuplicateClick}
                        />
                      </Collapse>
                    </Grid>
                  ))}
                </Grid>
              </TransitionGroup>
            </Paper> */}
            <Dialog open={open} maxWidth="xl" fullWidth onClose={handleClose}>
              <ProductCard product={product} />
            </Dialog>
          </>
        )}
      </ComponentsContext.Provider>
    </Box>
  );
};
