import React from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import { Invoice } from "../../../financial/components/Invoice";
import { CreateFromTemplate } from "../../../fileManager/components/CreateFromTemplate";
import { Check } from "@mui/icons-material";
import { useProjectUpdate } from "../../hooks/useProjectUpdate";
import { SelectedProjectContext } from "../ProjectView";

export const OverviewButtons = ({ setTab }) => {
  const project = React.useContext(SelectedProjectContext);
  const { mutate: updateProject } = useProjectUpdate();
  const markAsComplete = () => {
    const newLog = {
      name: "Completed",
      description: "Project marked as complete.",
      date: `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString(
        {},
        { timeStyle: "short" }
      )}`,
      longDate: new Date(),
    };
    updateProject({
      id: project._id,
      pack: {
        log: [newLog, ...project.log],
      },
    });
  };
  return (
    <Box
      id="project-actions"
      sx={{ display: "flex", gap: 1, justifyContent: "space-evenly" }}
    >
      <Button onClick={() => setTab("products")} variant="contained">
        Add Product
      </Button>
      <CreateFromTemplate project={project} />
      <Invoice info={{ project: project, customer: project.customer }} />
      <Button
        startIcon={<Check />}
        variant="contained"
        color="success"
        onClick={markAsComplete}
        disabled={project.log[0].name == "Completed"}
      >
        Mark as Complete
      </Button>
    </Box>
  );
};

OverviewButtons.propTypes = {
  project: PropTypes.object.isRequired,
  tab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
};

// const buttons = [
//   {
//     label: "Add Product",
//     icon: "add",
//     action: "addProduct",
//   },
//   {
//     label: "Upload File",
//     icon: "upload",
//     action: "uploadFile",
//   },
//   {
//     label: "Send Invoice",
//     icon: "send",
//     action: "sendInvoice",
//   },
//   {
//     label: "Create Template",
//     icon: "template",
//     action: "createTemplate",
//   },
//   {
//     label: "Mark as Complete",
//     icon: "complete",
//     action: "markAsComplete",
//   },
// ];
