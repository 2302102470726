import * as React from "react";
import { PropTypes } from "prop-types";
import { Box, Card, Dialog, Fade, IconButton } from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "../utils/canvasPreview";
import { useDebounceEffect } from "../hooks/useDebounceEffect";
import "react-image-crop/dist/ReactCrop.css";
import CropIcon from "@mui/icons-material/Crop";
import { NewComponentContext } from "../../components/ComponentNode";

const CLOUDFRONT_URL = "https://cdn.manufacture.app/";

export const Image = () => {
  const { newComponent, handleEdit } = React.useContext(NewComponentContext);
  const [open, setOpen] = React.useState(false);
  const [imgSrc, setImgSrc] = React.useState(
    newComponent.image ? newComponent.image : ""
  );
  const [hover, setHover] = React.useState(false);
  const imgRef = React.useRef(null);
  const [scale] = React.useState(1);
  const [rotate] = React.useState(0);
  const previewCanvasRef = React.useRef(null);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    imgSrc ? setOpen(true) : null;
  };
  // React.useEffect(() => {
  //   if (!product.image && !product.imageData) {
  //     imgRef.current = null;
  //     previewCanvasRef.current = null;
  //     setImgSrc("");
  //     setCompletedCrop();
  //   }
  // }, [product]);

  const [aspect] = React.useState(1);
  const [crop, setCrop] = React.useState();
  const [completedCrop, setCompletedCrop] = React.useState();

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result?.toString() || "");
        handleEdit({
          field: "imgSrc",
          value: reader.result?.toString() || "",
        });
      });
      reader.readAsDataURL(e.target.files[0]);
      const target = { field: "image", value: e.target.files[0] };
      handleEdit(target);
      setOpen(true);
    }
  }
  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }
  const onComplete = (c) => {
    setCompletedCrop(c);
    let imageData = Object.assign({}, c, {
      originalHeight: imgRef.current.clientHeight,
      originalWidth: imgRef.current.clientWidth,
    });
    const target = { field: "imageData", value: imageData };
    handleEdit(target);
  };
  React.useEffect(() => {
    if (completedCrop) {
      handleEdit({ field: "crop", value: completedCrop });
    }
  }, [completedCrop]);

  const handleHover = () => {
    newComponent._id === "newComponent" ||
    newComponent.edit ||
    newComponent.duplicate
      ? setHover(true)
      : null;
  };

  if (newComponent.image == null) {
    return null;
  }

  return (
    <Box
      id="product-photo"
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      {newComponent.imgSrc || newComponent.image ? (
        <Card
          sx={{
            mt: 1,
            width: 220,
            aspectRatio: "1/1",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            // ":hover": {
            //   filter: "brightness(0.3)",
            // },
            // transition: "filter 0.5s",
          }}
          onMouseEnter={handleHover}
          onMouseLeave={() => setHover(false)}
        >
          <Fade in={hover} unmountOnExit>
            <div
              style={{
                position: "fixed",
                zIndex: 2,
              }}
            >
              <IconButton onClick={() => handleOpen()}>
                <CropIcon color="primary" />
              </IconButton>
              <IconButton variant="contained" component="label">
                <input
                  hidden
                  type="file"
                  accept="image/*"
                  onChange={onSelectFile}
                />
                <PhotoCamera color="primary" />
              </IconButton>
            </div>
          </Fade>
          {newComponent.image && typeof newComponent.image == "string" ? (
            <img
              src={`${CLOUDFRONT_URL}${newComponent.image}`}
              style={{
                // objectFit: "contain",
                minWidth: "100%",
                minHeight: "100%",
                filter: hover ? "brightness(0.3)" : "brightness(1)",
                transition: "filter 0.5s",
                maxWidth: "100%",
                maxHeight: "100%",
              }}
            />
          ) : (
            !!newComponent.crop && (
              <canvas
                ref={previewCanvasRef}
                style={{
                  objectFit: "contain",
                  width: completedCrop.width,
                  maxWidth: "100%",
                  height: completedCrop.height,
                  maxHeight: "100%",
                  aspectRatio: "1/1",
                  filter: hover ? "brightness(0.3)" : "brightness(1)",
                  transition: "filter 0.5s",
                }}
              />
            )
          )}
        </Card>
      ) : (
        <IconButton
          variant="contained"
          component="label"
          size="large"
          sx={{ mt: 1 }}
        >
          <input hidden type="file" accept="image/*" onChange={onSelectFile} />
          <PhotoCamera fontSize="large" />
        </IconButton>
      )}

      {/* {showLabel && (
        <Typography sx={{ textAlign: "center" }}>{f.label}</Typography>
      )} */}

      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <div>
          {!!imgSrc && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => {
                onComplete(c);
              }}
              aspect={aspect}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{
                  transform: `scale(${scale}) rotate(${rotate}deg)`,
                  minWidth: 400,
                  minHeight: 400,
                }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}
        </div>
      </Dialog>
    </Box>
  );
};
Image.propTypes = {
  f: PropTypes.object,
  showLabel: PropTypes.bool,
  product: PropTypes.object,
  onPhotoChange: PropTypes.func,
};
