import React from "react";
import { useQuery } from "react-query";
import { UserContext } from "../../../App";
import { getFinancials } from "../../../api/financialApi";

export const useFinancialData = (type, project, onSuccess, onError) => {
  const { user } = React.useContext(UserContext);
  let typeQuery = type ? type : "all";
  let projectQuery = project ? project : "all";
  return useQuery(
    type ? type : "financial",
    () => getFinancials(user.id, typeQuery, projectQuery),
    {
      onSuccess,
      onError,
    }
  );
};
