import {
  DataGridPremium,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-premium";
import React from "react";
import PropTypes from "prop-types";
import { ParameterDetails } from "./ParameterDetails";
import { Edit, ExpandLess } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useParametersData } from "../../../../hooks/useParametersData";

export const ParameterTable = ({ active }) => {
  const { data: parameters } = useParametersData();
  const getDetailPanelContent = React.useCallback(
    ({ row }) => <ParameterDetails row={row} />,
    []
  );

  const getDetailPanelHeight = React.useCallback(() => 265, []);
  // if (!parameters) return null;

  return (
    <DataGridPremium
      rows={parameters}
      columns={columns}
      getRowId={(row) => row._id}
      sx={{
        height: active ? 500 : 700,
        transition: "height 0.5s",
        ".MuiDataGrid-virtualScroller": {
          "&::-webkit-scrollbar": {
            width: "0.4em",
            backgroundColor: "divider",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "primary.main",
          },
        },
      }}
      getDetailPanelHeight={getDetailPanelHeight}
      getDetailPanelContent={getDetailPanelContent}
    />
  );
};

ParameterTable.propTypes = {
  active: PropTypes.bool,
};

function CustomDetailPanelToggle(props) {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector
  );

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = React.isValidElement(contentCache[id]);

  return (
    <IconButton
      size="small"
      tabIndex={-1}
      disabled={!hasDetail}
      aria-label={isExpanded ? "Close" : "Open"}
    >
      <Edit
        sx={{
          position: "absolute",
          opacity: !isExpanded ? 1 : 0,
          transition: "opacity 0.5s",
        }}
        fontSize="inherit"
      />
      <ExpandLess
        fontSize="inherit"
        sx={{
          opacity: isExpanded ? 1 : 0,
          transition: "opacity 0.5s",
        }}
      />
    </IconButton>
  );
}

CustomDetailPanelToggle.propTypes = {
  id: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const columns = [
  {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    renderCell: (params) => (
      <CustomDetailPanelToggle id={params.id} {...params} />
    ),
  },
  { field: "name", headerName: "Name", flex: 1 },
  { field: "description", headerName: "Description", flex: 1.5 },
  {
    field: "value",
    headerName: "Value",
    flex: 1,
    renderCell: (params) => (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        <div>{params.value}</div>
        <div>
          <em> {params.row.unit}</em>
        </div>
      </div>
    ),
  },
  { field: "global", headerName: "Global", flex: 0.5 },
];
