import axios from "axios";

const customerApi = axios.create();

export const getCustomers = async (user) => {
  const response = await customerApi.get(`/api/customer?user=${user}`);
  return response.data;
};

export const updateCustomer = async ({ id, pack }) => {
  return await customerApi.patch(`/api/customer/${id}`, pack);
};

export const deleteCustomer = async (id) => {
  return await customerApi.delete(`/api/customer/${id}`);
};
export const createCustomer = async (pack) => {
  return await customerApi.post("/api/customer", pack);
};
