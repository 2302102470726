import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useSettingsUpdate } from "../../../hooks/useSettingsUpdate";
import { useSettingsData } from "../../../hooks/useSettingsData";

export const AddRole = () => {
  const { data: settings } = useSettingsData();
  const { mutate: updateSettings } = useSettingsUpdate("Role added.");
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleClose = () => setDialogOpen(false);
  const [newRole, setNewRole] = React.useState({
    name: "",
    permissions: {
      project: {
        tasks: {
          viewAll: false,
        },
        files: {
          view: false,
          add: false,
        },
        chat: {
          view: false,
          add: false,
        },
      },
    },
  });

  const [error, setError] = React.useState();

  React.useEffect(() => {
    if (settings.roles.find((role) => role.name === newRole.name)) {
      setError("Role name already exists.");
    } else {
      setError(null);
    }
  }, [newRole.name]);

  const handleNewRole = ({ field, value }) => {
    setNewRole((prev) => ({ ...prev, [field]: value }));
  };

  const handlePermissions = ({ field, value }) => {
    setNewRole((prev) => ({
      ...prev,
      permissions: {
        ...prev.permissions,
        project: {
          ...prev.permissions.project,
          [field]: { ...prev.permissions.project[field], ...value },
        },
      },
    }));
  };

  const handleAddRole = () => {
    if (!newRole.name) return;
    if (settings.roles.find((role) => role.name === newRole.name)) return;
    updateSettings({ pack: { roles: [...settings.roles, newRole] } });
    setDialogOpen(false);
  };

  return (
    <>
      <Button variant="outlined" onClick={() => setDialogOpen(true)}>
        Add Role
      </Button>
      <Dialog open={dialogOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Add Role</DialogTitle>
        <DialogContent>
          <TextField
            label="Role Name"
            value={newRole.name}
            sx={{ mt: 1, mb: 2 }}
            onChange={(e) =>
              handleNewRole({ field: "name", value: e.target.value })
            }
            helperText={error}
          />
          <Typography variant="h5">Permissions</Typography>

          <Paper sx={{ p: 1 }}>
            <Typography variant="h6">Project</Typography>
            <FormGroup row sx={{ alignItems: "center" }}>
              <Typography variant="h6" sx={{ mr: 4, width: 200 }}>
                View Tasks
              </Typography>
              <RadioGroup
                defaultValue={false}
                row
                onChange={(e) =>
                  handlePermissions({
                    field: "tasks",
                    value: { viewAll: e.target.value },
                  })
                }
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Assigned Only"
                />
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="All"
                />
              </RadioGroup>
            </FormGroup>
            <FormGroup row sx={{ alignItems: "center" }}>
              <Typography variant="h6" sx={{ mr: 4, width: 200 }}>
                Files
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newRole.permissions.project.files.view}
                    onChange={(e) =>
                      handlePermissions({
                        field: "files",
                        value: {
                          view: e.target.checked,
                        },
                      })
                    }
                  />
                }
                label="View"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={newRole.permissions.project.files.add}
                    onChange={(e) =>
                      handlePermissions({
                        field: "files",
                        value: {
                          add: e.target.checked,
                        },
                      })
                    }
                  />
                }
                label="Add"
              />
            </FormGroup>
            <FormGroup row sx={{ alignItems: "center" }}>
              <Typography variant="h6" sx={{ mr: 4, width: 200 }}>
                Chat
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newRole.permissions.project.chat.view}
                    onChange={(e) =>
                      handlePermissions({
                        field: "chat",
                        value: {
                          view: e.target.checked,
                        },
                      })
                    }
                  />
                }
                label="View"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newRole.permissions.project.chat.add}
                    onChange={(e) =>
                      handlePermissions({
                        field: "chat",
                        value: {
                          add: e.target.checked,
                        },
                      })
                    }
                  />
                }
                label="Add"
              />
            </FormGroup>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleAddRole} disabled={error}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
