import React from "react";
import { useQuery } from "react-query";
import { getFolders } from "../../../api/folderApi";
import { UserContext } from "../../../App";

export const useFoldersData = (onSuccess, onError) => {
  const { user } = React.useContext(UserContext);
  return useQuery("folders", () => getFolders(user.id), {
    onSuccess,
    onError,
  });
};
