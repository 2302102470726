import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Collapse,
  IconButton,
  Typography,
} from "@mui/material";
import { Delete, DeleteForever, Edit } from "@mui/icons-material";
import { useComponentDelete } from "../../componentAndOptionList/hooks/useComponentDelete";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import { useOptionDelete } from "../../componentAndOptionList/hooks/useOptionDelete";

const CLOUDFRONT_URL = "https://cdn.manufacture.app/";

export const Component = ({ component, handleEditClick, handleDuplicate }) => {
  const [hover, setHover] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const { mutate: deleteComponent } = useComponentDelete();
  const { mutate: deleteOption } = useOptionDelete();
  const handleDelete = () => {
    if (deleting) {
      if (Object.hasOwn(component, "required")) {
        deleteOption(component._id);
      } else {
        deleteComponent(component._id);
      }
    } else {
      setDeleting(true);
      setTimeout(() => {
        setDeleting(false);
      }, 3000);
    }
  };

  return (
    <Card
      elevation={3}
      sx={{
        width: 240,
        height: 200,
        display: "flex",
        pb: 0,
        flexDirection: "column",
        justifyContent: "flex-end",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <CardMedia
        sx={{ height: 150 }}
        image={`${CLOUDFRONT_URL}${component.image}`}
        title="image"
      />
      <CardContent sx={{ pt: 1, pb: 0, ":last-child": { pb: 0 } }}>
        <Typography
          variant="h5"
          component="div"
          flexWrap={"wrap"}
          sx={{ pb: 1 }}
        >
          {component.name}
        </Typography>
        <Collapse in={hover}>
          <Box
            sx={{
              justifyContent: "space-around",
              display: "flex",
              pb: 1,
            }}
          >
            <IconButton onClick={() => handleEditClick(component)}>
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => handleDuplicate(component)}
              disabled={Object.hasOwn(component, "required")}
            >
              <ControlPointDuplicateIcon />
            </IconButton>
            <IconButton onClick={handleDelete}>
              {deleting ? <DeleteForever color="error" /> : <Delete />}
            </IconButton>
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  );
};

Component.propTypes = {
  component: PropTypes.object.isRequired,
  handleDuplicate: PropTypes.func.isRequired,
  handleEditClick: PropTypes.func.isRequired,
  handleView: PropTypes.func,
};
