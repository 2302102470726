import React from "react";
import { useQuery } from "react-query";
import { getSettings } from "../api/settingsApi";
import { UserContext } from "../App";

export const useSettingsData = (onSuccess, onError) => {
  const { user } = React.useContext(UserContext);
  return useQuery("settings", () => getSettings(user.id), {
    onSuccess,
    onError,
    staleTime: 1000 * 60 * 10, // 10 minutes
  });
};
