export const checkSubscription = (userSettings) => {
  if (!userSettings?.subscription) return false;
  const products = userSettings.subscription.products;
  const status = userSettings.subscription.status;
  if (status === "active") {
    return products.map((p) => p.metadata.lookup);
  } else {
    return false;
  }
};
