import axios from "axios";

const personApi = axios.create();

export const getPersons = async (user) => {
  const response = await personApi.get(`/api/person?user=${user}`);
  return response.data;
};

export const updatePerson = async ({ id, pack }) => {
  return await personApi.patch(`/api/person/${id}`, pack);
};

export const deletePerson = async (id) => {
  return await personApi.delete(`/api/person/${id}`);
};
export const createPerson = async (pack) => {
  return await personApi.post("/api/person", pack);
};
//router.route("/:id/data").get(personController.getPersonData);
export const getPersonData = async (id) => {
  const response = await personApi.get(`/api/person/${id}/data`);
  return response.data;
};
