import React from "react";
import { useOrdersData } from "../../order/hooks/useOrdersData";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { columns } from "../../../components/common/utils";

export const Orders = () => {
  const { data: orders } = useOrdersData();

  if (!orders) {
    return <div>Loading...</div>;
  }

  return (
    <DataGridPremium
      // onRowClick={(e) => {
      // setSelectedCustomer([e.row._id]);
      //   setHelp(steps["ViewCustomer"]);
      //   setCustomerData(e.row);
      // }}
      getRowId={(row) => row._id}
      disableSelection
      rows={orders}
      columns={columns.customers}
    />
  );
};
