import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../../../Content";
import { deleteCatalog } from "../../../api/catalogApi";
import { UserContext } from "../../../App";

export function useCatalogDelete() {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { user } = React.useContext(UserContext);

  return useMutation({
    mutationFn: ({ pack }) => {
      return deleteCatalog({ ...pack, user: user.id });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("catalogs");

      setSnack({
        open: true,
        message: "Catalog deleted.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Catalog could not be deleted.",
        severity: "error",
      });
    },
  });
}
