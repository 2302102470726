import React from "react";
import { useFinancialData } from "./useFinancialData";
import _ from "lodash";
import moment from "moment";

export const useNewFinancial = (data = {}) => {
  const { data: financial } = useFinancialData();

  const [newFinancial, setNewFinancial] = React.useState({
    _id: "",
    date: Date.now(),
    name: data.name || "",
    type: "income",
    number: 0,
    payee: "",
    file: "",
    category: "",
    memo: data.memo || "",
    amount: data.amount || 0,
    customer: null,
    project: null,
    supplier: null,
    dueDate: Date.now(),
    balance: 0,
    status: "paid",
    user: "",
    isEdited: false,
  });
  const handleFinancialChange = ({ field, value }) => {
    if (field == "view") {
      setNewFinancial(value);
    } else if (field == "date") {
      let status = "";
      moment(value).subtract(1, "days").isAfter(moment())
        ? (status = "paid")
        : (status = "pending");
      setNewFinancial((prev) => ({
        ...prev,
        status: status,
        dueDate: value,
        [field]: value,
      }));
    } else {
      setNewFinancial((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const clearFinancial = () => {
    setNewFinancial({
      _id: "",
      date: Date.now(),
      name: "",
      type: "income",
      number: 0,
      payee: "",
      file: "",
      category: "",
      memo: "",
      amount: 0,
      customer: null,
      project: null,
      supplier: null,
      dueDate: Date.now(),
      balance: 0,
      status: "",
      user: "",
    });
  };
  React.useEffect(() => {
    if (!financial) return;
    let originalFinancial = financial.find((f) => f._id == newFinancial._id);
    if (!originalFinancial) return;
    handleFinancialChange({
      field: "isEdited",
      value: checkIsEdited(newFinancial, originalFinancial),
    });
  }, [
    newFinancial.date,
    newFinancial.name,
    newFinancial.type,
    newFinancial.memo,
    newFinancial.amount,
  ]);

  return { newFinancial, handleFinancialChange, clearFinancial };
};

const checkIsEdited = (newFinancial, originalFinancial) => {
  let VALUESTOCHECK = ["date", "name", "type", "memo", "amount"];
  let isEdited = false;
  VALUESTOCHECK.forEach((key) => {
    if (key == "amount") {
      if (parseFloat(newFinancial[key]) != parseFloat(originalFinancial[key])) {
        isEdited = true;
      }
    } else if (!_.isEqual(newFinancial[key], originalFinancial[key])) {
      isEdited = true;
    }
  });
  return isEdited;
};
