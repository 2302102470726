import React, { createContext, useMemo, useState } from "react";
import { useEmployeeData } from "../features/employee/hooks/useEmployeeData";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CssBaseline,
  Dialog,
  FormControlLabel,
  IconButton,
  Paper,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import axios from "axios";
import { useProjectUpdate } from "../features/project/hooks/useProjectUpdate";
import { Project } from "../features/employee/components/Project";
import { useQueryClient } from "react-query";
import { UserContext } from "../App";
import { amber, blueGrey, grey, teal } from "@mui/material/colors";
import { HelpOutline } from "@mui/icons-material";
import "intro.js/introjs.css";
import "../utils/IntroJS.css";
import { steps } from "../utils/personIntroSteps";
import { Steps } from "intro.js-react";

const cloudfrontUrl = "https://cdn.manufacture.app/";

export const Employee = () => {
  const [tab, setTab] = React.useState(0);
  const { setUser } = React.useContext(UserContext);
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };
  const queryClient = useQueryClient();

  const onFileSelect = (e) => {
    const data = new FormData();
    data.append("image", e.target.files[0]);
    axios.patch(`/api/person/${person._id}`, data).then(() => {
      queryClient.invalidateQueries("employee");
    });
  };
  const [mode, setMode] = useState(localStorage.getItem("mode") || "dark");
  const [colors, setColors] = useState(null);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          localStorage.setItem("mode", prevMode === "light" ? "dark" : "light");
          return prevMode === "light" ? "dark" : "light";
        });
      },
    }),
    []
  );
  const theme = useMemo(
    () => createTheme(getDesignTokens(mode, colors)),
    [mode, colors]
  );
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { data: employee } = useEmployeeData();
  const { projects, person } = employee || { projects: [], person: {} };

  const tasks = projects?.map((project) => project.tasks).flat();

  const { mutate: updateProject } = useProjectUpdate();

  const handleCheck = ({ project, task, event }) => {
    let newProject = { ...project };
    let newTask = newProject.tasks.find((t) => t.name == task.name);
    newTask.completed = event.target.checked;
    if (newTask.completed) {
      newTask.completedDate = new Date();
    } else {
      newTask.completedDate = null;
    }
    updateProject({
      id: newProject._id,
      pack: { tasks: [newTask], type: "employeeCompleted", employee: person },
    });
  };

  const logout = async () => {
    localStorage.removeItem("user");
    setUser(null);
  };
  const [help, setHelp] = React.useState({
    open: false,
    steps: steps.dashboard,
    type: "",
  });
  const onExit = () => {
    setHelp({ ...help, open: false });
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeContext.Provider value={{ colors, setColors }}>
        <ThemeProvider theme={theme}>
          <CssBaseline>
            <Box>
              <Steps
                enabled={help.open}
                steps={help.steps}
                initialStep={0}
                onExit={onExit}
              />

              <Box
                sx={{
                  p: 2,
                  m: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h1>{getTimeOfDayGreeting()}</h1>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <IconButton
                    id="help"
                    onClick={() => setHelp((prev) => ({ ...prev, open: true }))}
                  >
                    <HelpOutline />
                  </IconButton>
                  <Avatar
                    id="profile"
                    src={person ? `${cloudfrontUrl}${person.avatar}` : null}
                    onClick={handleClickOpen}
                    sx={{
                      cursor: "pointer",
                      ":hover": {
                        opacity: [0.9, 0.8, 0.7],
                        boxShadow: 10,
                      },
                      transitionDuration: "0.5s",
                    }}
                  />
                </Box>
              </Box>
              <Tabs value={tab} sx={{ mb: 2 }} onChange={handleTabChange}>
                <Tab label="All Tasks" id="tasks" />

                {projects?.map((project) => (
                  <Tab
                    key={project._id}
                    label={project.projectName}
                    className="projects"
                  />
                ))}
              </Tabs>
              {tab == 0 && (
                <Paper sx={{ p: 2 }}>
                  {tasks
                    ?.filter((t) =>
                      t.assignedTo.find((p) => p._id === person._id)
                    )
                    .map((task) => (
                      <div key={task._id}>
                        <FormControlLabel
                          label={task.name}
                          disabled={task.completed}
                          control={<Checkbox checked={task.completed} />}
                          onChange={(e) =>
                            handleCheck({
                              project: projects[0],
                              task,
                              event: e,
                            })
                          }
                        />
                      </div>
                    ))}
                </Paper>
              )}
              {projects?.map((project, i) => (
                <div key={project._id} hidden={tab !== i + 1}>
                  <Project
                    person={person}
                    project={project}
                    handleCheck={handleCheck}
                  />
                </div>
              ))}
              <Dialog open={open} onClose={handleClose} maxWidth="md">
                <Paper sx={{ height: "100%", p: 2 }}>
                  <input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    onChange={onFileSelect}
                  />
                  <label htmlFor="contained-button-file">
                    <IconButton>
                      <Avatar
                        src={`${cloudfrontUrl}${person.avatar}`}
                        style={{
                          margin: "10px",
                          width: "60px",
                          height: "60px",
                        }}
                      />
                    </IconButton>
                  </label>
                  <Typography variant="h5" sx={{ mt: 2 }}>
                    {person?.firstName} {person?.lastName}
                  </Typography>
                  <Typography variant="h6">{person.email}</Typography>
                  <Button variant="contained" onClick={logout}>
                    Log Out
                  </Button>
                </Paper>
              </Dialog>
            </Box>
          </CssBaseline>
        </ThemeProvider>
      </ThemeContext.Provider>
    </ColorModeContext.Provider>
  );
};

const getTimeOfDayGreeting = () => {
  const now = new Date();
  const hour = now.getHours();
  if (hour < 12) {
    return "Good Morning";
  } else if (hour < 18) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
};

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});
export const ThemeContext = createContext({
  colors: {
    dark: {
      primary: teal,
      secondary: teal,
    },
    light: {
      primary: amber,
      secondary: amber,
    },
  },
  setColors: () => {},
});
const getDesignTokens = (mode, colors) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          primary: colors?.light.primary
            ? { main: colors.light.primary }
            : amber,
          divider: amber[200],
          background: {
            default: grey[50],
            paper: grey[50],
          },
          text: {
            primary: grey[900],
            secondary: grey[800],
          },
        }
      : {
          // palette values for dark mode
          primary: colors?.dark.primary ? { main: colors.dark.primary } : teal,
          divider: blueGrey[700],
          background: {
            default: blueGrey[900],
            paper: blueGrey[900],
          },
          text: {
            primary: "#fff",
            secondary: teal[50],
          },
        }),
  },
  // shape: {
  //   borderRadius: "8px",
  // },
});
