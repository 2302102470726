import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { ControlledEditableInput } from "../../../utils/ControlledEditableInput";
import { useNewFinancial } from "../hooks/useNewFinancial";
import { useFinancialUpdate } from "../hooks/useFinancialUpdate";
import { useFinancialDelete } from "../hooks/useFinancialDelete";
import DeleteButton from "../../../utils/DeleteButton";
import { ENTRY_TYPES } from "../utils/entryTypes";
import { FinancialMenuItem } from "../utils/FinancialEntryTypeMenuItem";
import { ControlledFinancialSelect } from "../utils/ControlledFinancialSelect";
import { ContentCopy } from "@mui/icons-material";
import CurrencyTextField from "../../../utils/CurrencyTextField";

// const formatter = new Intl.DateTimeFormat("en-US");

export const ViewFinancial = ({ entry }) => {
  const [open, setOpen] = React.useState(entry.open);
  const [editing, setEditing] = React.useState(false);
  const [alert, setAlert] = React.useState("");
  const { newFinancial, handleFinancialChange, clearFinancial } =
    useNewFinancial();
  const { mutate: updateFinancial, isSuccess: isUpdateSuccess } =
    useFinancialUpdate();
  const { mutate: deleteFinancial, isSuccess: isDeleteSuccess } =
    useFinancialDelete();
  const handleDelete = () => {
    deleteFinancial({ pack: newFinancial._id });
    clearFinancial();
    setEditing(false);
  };

  const handleClose = () => {
    setOpen(false);
    setEditing(false);
    clearFinancial();
  };

  const handleUpdate = () => {
    updateFinancial({ pack: newFinancial });
    clearFinancial();
    setEditing(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(newFinancial.plink);
    setAlert("Copied to clipboard.");
  };

  React.useEffect(() => {
    setOpen(entry.open);
    if (entry._id) {
      handleFinancialChange({ field: "view", value: entry });
    }
  }, [entry]);

  React.useEffect(() => {
    if (isUpdateSuccess || isDeleteSuccess) {
      setOpen(false);
    }
  }, [isUpdateSuccess, isDeleteSuccess]);
  let date = new Date(newFinancial.date || 0);

  const handlePaid = () => {
    updateFinancial({
      pack: { ...newFinancial, status: "paid", date: new Date() },
    });
  };

  if (!entry) return null;
  return (
    <div>
      <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
        <Paper>
          <DialogTitle>{newFinancial.name}</DialogTitle>
          <DialogContent>
            <Box sx={{ p: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: 1,
                  gap: 1,
                  mb: 1,
                }}
              >
                {/* <ControlledEditableInput
                  value={newFinancial.amount}
                  field={"amount"}
                  editing={editing}
                  helperText="Enter a negative number for expenses."
                  onChange={handleFinancialChange}
                  inputComponent={NumberFormatCustom}
                  label="Amount"
                /> */}
                <CurrencyTextField
                  value={newFinancial.amount}
                  label="Amount"
                  disabled={!editing}
                  onChange={(e) =>
                    handleFinancialChange({
                      field: "amount",
                      value: e.target.value,
                    })
                  }
                />
                <Box sx={{ width: "35%", mb: "auto" }}>
                  <ControlledFinancialSelect
                    value={newFinancial.type}
                    field={"type"}
                    onChange={handleFinancialChange}
                    editing={editing}
                    label="Type"
                    menuitems={ENTRY_TYPES}
                    menuItem={FinancialMenuItem}
                  />
                </Box>
              </Box>
              <ControlledEditableInput
                value={newFinancial.memo}
                field={"memo"}
                onChange={handleFinancialChange}
                editing={editing}
                label="Memo"
                sx={{ mb: 2 }}
              />

              <ControlledEditableInput
                value={date.toISOString().slice(0, 10)}
                editing={editing}
                field={"date"}
                type="date"
                onChange={handleFinancialChange}
                label="Date"
                sx={{ mb: 2 }}
              />
              {newFinancial.plink && (
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <OutlinedInput
                    fullWidth
                    disabled
                    endAdornment={
                      <InputAdornment>
                        <IconButton onClick={handleCopy}>
                          <ContentCopy />
                        </IconButton>
                      </InputAdornment>
                    }
                    helperText={alert}
                    sx={{
                      "&.Mui-disabled": {
                        borderColor: "#6772e5",
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "#6772e5",
                        },
                      },
                    }}
                    value={newFinancial.plink}
                  />
                  <FormHelperText>{alert}</FormHelperText>
                </FormControl>
              )}
              {newFinancial.status == "pending" && (
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handlePaid}
                  >
                    Mark as Paid
                  </Button>
                </Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            {/* <Button
              onClick={() => handleDelete()}
              variant="contained"
              color="error"
            >

              Delete
            </Button> */}
            <DeleteButton onDelete={handleDelete} />
            <Box>
              <Button
                variant="outlined"
                onClick={() => handleClose()}
                sx={{ mr: 1 }}
              >
                {editing ? "Cancel" : "Close"}
              </Button>
              <Button
                disabled={editing && !newFinancial.isEdited}
                color={editing ? "warning" : "primary"}
                onClick={
                  editing ? handleUpdate : () => setEditing((prev) => !prev)
                }
                variant="contained"
              >
                {editing ? "Save" : "Edit"}
              </Button>
            </Box>
          </DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
};

ViewFinancial.propTypes = {
  entry: PropTypes.object.isRequired,
};
