import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  ListItem,
  Menu,
  Divider,
  OutlinedInput,
  Typography,
  Paper,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/system";
import { Check, Visibility } from "@mui/icons-material";
import useStore from "./store";
import { useParametersData } from "../../../hooks/useParametersData";

export const Variables = ({ focusNode }) => {
  // eslint-disable-next-line no-unused-vars
  const [oldAnchorEl, setOldAnchorEl] = React.useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [edit, setEdit] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setOldAnchorEl(event.currentTarget);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setEdit(null);
  };

  // const [parameters, setParameters] = React.useState(
  //   getVariables(nodeData, params)
  // );
  let getVariables = useStore((state) => state.getVariables);
  let editVariable = useStore((state) => state.editVariable);
  const { data: allParameters } = useParametersData();
  // React.useEffect(() => {
  //   setParameters(getVariables(nodeData, params));
  // }, [nodeData]);
  let variables = getVariables();

  const handleEditClick = (parameter) => {
    setEdit(parameter);
  };

  const handleEditConfirm = () => {
    // handleEdit(editing);
    // setEditing(null);
    // setParameters(getVariables(nodeData, params));
    editVariable({ parameter: edit });
    setEdit(null);
  };

  const isBeingEdited = (parameter) => {
    if (!edit) return false;
    return (
      parameter.nodeKey === edit.nodeKey &&
      parameter.name === edit.name &&
      parameter.source === edit.source
    );
  };

  return (
    <Paper>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        variant="contained"
        disabled={
          !variables.length && !allParameters?.filter((p) => p.global).length
        }
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Variables
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            handleClose();
          }
          e.stopPropagation();
        }}
      >
        {variables.map((parameter) => (
          <ListItem
            key={makeLabel({
              ...parameter,
              label: `${parameter.label}`,
            })}
            sx={{ display: "flex" }}
          >
            <FormControl
              variant="outlined"
              size="small"
              sx={{ width: 300 }}
              disabled={!isBeingEdited(parameter)}
            >
              <InputLabel>
                {makeLabel({
                  ...parameter,
                  label: `${parameter.label}`,
                })}
              </InputLabel>
              <OutlinedInput
                size="small"
                label={makeLabel({
                  ...parameter,
                  label: `${parameter.label}`,
                })}
                sx={{ width: 300 }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  setEdit((prev) => ({
                    ...prev,
                    variable: e.target.value,
                  }));
                }}
                value={
                  isBeingEdited(parameter) ? edit.variable : parameter.variable
                }
                endAdornment={
                  <Box sx={{ display: "flex" }}>
                    {isBeingEdited(parameter) ? (
                      <IconButton onClick={() => handleEditConfirm()}>
                        <Check />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => handleEditClick(parameter)}
                        disabled={parameter.global}
                      >
                        <EditIcon />
                      </IconButton>
                    )}
                    <IconButton onClick={() => focusNode(parameter.nodeKey)}>
                      <Visibility />
                    </IconButton>
                  </Box>
                }
              />
            </FormControl>
          </ListItem>
        ))}
        <Divider sx={{ width: 300 }}>Global Variables</Divider>
        {allParameters
          .filter((p) => p.global)
          .map((parameter) => (
            <ListItem
              key={parameter.name}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography>{parameter.name}</Typography>
              <Typography>{parameter.value}</Typography>
            </ListItem>
          ))}
      </Menu>
    </Paper>
  );
};

Variables.propTypes = {
  nodeData: PropTypes.array,
  handleEdit: PropTypes.func,
  focusNode: PropTypes.func,
};

export const getVariables = (nodeData, params) => {
  return nodeData
    .map((n) => {
      let params = [];

      params = params.concat(
        n.parameters
          ?.filter((p) => p.variable)
          .map((p) => ({ ...p, nodeKey: n.nodeKey, label: n.name })) || []
      );

      // n.qty?.variable
      //   ? params.push({
      //       ...n.qty,
      //       nodeKey: n.nodeKey,
      //       label: n.name,
      //       name: "qty",
      //     })
      //   : null;

      return params;
    })
    .concat(
      params
        .filter((p) => p.global)
        .map((p) => ({ ...p, label: p.name, variable: p.name }))
    )
    .flat();
};

const makeLabel = (parameter) => {
  let label = parameter.label;
  if (parameter.global) return label;
  if (parameter.source) label = `${label} (source)`;
  if (parameter.source === false) label = `${label} (choice)`;
  return ` ${label} ${parameter.name}`;
};
