import React from "react";
import { ProductContext } from "./ProductCard";
import { Box } from "@mui/system";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export const ParameterSelect = () => {
  // eslint-disable-next-line no-unused-vars
  const { product, handleEdit } = React.useContext(ProductContext);
  const selectableParameters = getSelectableParameters(product);

  const handleSelect = ({ parameter, value }) => {
    handleEdit({
      field: "parameters",
      type: "update",
      value: { ...parameter, selection: value },
    });
  };

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      {selectableParameters.map((p) => {
        return (
          <FormControl key={p} sx={{ width: 200 }}>
            <InputLabel id={p.label}>{p.label}</InputLabel>
            <Select
              label={p.label}
              onChange={(e) =>
                handleSelect({ parameter: p, value: e.target.value })
              }
            >
              {p.formula.split(",").map((f) => (
                <MenuItem key={f} value={f}>
                  {f}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      })}
    </Box>
  );
};
const isSelectable = (parameter) => parameter.formula?.includes(",");

const getSelectableParameters = (product) => {
  let params = [];
  product.allParameters.forEach((p) => {
    if (isSelectable(p)) {
      params.push({ ...p, label: `${p.name}` });
    }
  });

  return params;
};
