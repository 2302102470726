import React from "react";
import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import { useQueryClient, useMutation } from "react-query";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useSettingsData } from "../../../../hooks/useSettingsData";
import { updateSettings } from "../../../../api/settingsApi";
import { Step } from "./Step";
import { STEPS } from "./utils";
import { DefaultStep } from "./DefaultStep";
import { useNewStep } from "../../hooks/useNewStep";
import { CreateNewStep } from "./CreateNewStep";

export const NewStepContext = React.createContext({
  newStep: [
    {
      _id: "newStep",
      name: "New Step",
      description: "Description for new step.",
      type: "type",
      category: "category",
      template: "",
      templateId: "",
      active: false,
      amount: "0",
      percent: false,
      send: false,
    },
  ],
  handleChange: () => {},
  clearNewStep: () => {},
});

export const StatusBarSettings = ({ user }) => {
  const { data: settings } = useSettingsData();
  const [newStep, handleChange, clearNewStep] = useNewStep();

  const [steps, setSteps] = React.useState(settings.statusSteps || []);
  React.useEffect(() => {
    setSteps(settings.statusSteps || []);
  }, [settings.statusSteps]);

  const queryClient = useQueryClient();
  const updateSettingsMutation = useMutation(updateSettings, {
    onSuccess: () => {
      queryClient.invalidateQueries("settings");
    },
  });

  const deleteStep = (step) => {
    const newSteps = steps.filter((s) => s.stepLabel !== step.stepLabel);
    setSteps(newSteps);
    updateSettingsMutation.mutate({
      user: user,
      pack: { statusSteps: newSteps },
    });
  };
  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const newSteps = Array.from(steps);
    newSteps.splice(source.index, 1);
    newSteps.splice(destination.index, 0, steps[source.index]);

    setSteps(newSteps);
    updateSettingsMutation.mutate({
      user: user,
      pack: { statusSteps: newSteps },
    });
  };
  if (!settings) return null;

  return (
    <NewStepContext.Provider value={{ newStep, handleChange, clearNewStep }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <CreateNewStep />
        <Droppable droppableId="stepList">
          {(provided) => (
            <Stack
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{ overflow: "auto" }}
            >
              <DefaultStep step={STEPS[0]} index={1} />
              {steps.map((step, i) => (
                <Step
                  key={step.name}
                  step={step}
                  index={i}
                  deleteStep={deleteStep}
                />
              ))}
              {provided.placeholder}
              <DefaultStep
                step={STEPS[STEPS.length - 1]}
                index={steps.length + 2}
              />
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
    </NewStepContext.Provider>
  );
};

StatusBarSettings.propTypes = {
  user: PropTypes.object,
};
