import React from "react";
import { useComponentsData } from "../../../hooks/useComponentsData";
import useStore from "./store";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  Paper,
  Typography,
} from "@mui/material";
import { useOptionsData } from "../../../hooks/useOptionsData";
import PropTypes from "prop-types";

const CLOUDFRONT_URL = "https://cdn.manufacture.app/";

export const SwitchNodeDialog = () => {
  const [open, setOpen] = React.useState(
    !!useStore((state) => state.nodeToSwitch)
  );
  const [selected, setSelected] = React.useState([]);

  const { data: components } = useComponentsData();
  const { data: options } = useOptionsData();
  const switchFn = useStore((state) => state.switchNode);
  const switchManyFn = useStore((state) => state.switchNodesMultiple);
  const nodes = useStore((state) => state.nodes);
  const nodeToSwitch = useStore((state) => state.nodeToSwitch);
  const setNodeToSwitch = useStore((state) => state.setNodeToSwitch);
  const handleClose = () => {
    setOpen(false);
    setNodeToSwitch(null);
  };
  React.useEffect(() => {
    setOpen(!!nodeToSwitch);
  }, [nodeToSwitch]);

  const handleComponentClick = (component) => {
    switchFn({
      newNodeData: component,
      components,
      options,
    });

    handleClose();
  };

  const handleSelect = (component) => {
    if (selected.find((c) => c._id == component._id)) {
      setSelected(selected.filter((s) => s._id !== component._id));
    } else {
      setSelected([...selected, component]);
    }
  };

  const handleSwitchClick = () => {
    switchManyFn({
      newNodesData: selected,
      components,
      options,
    });

    handleClose();
    setSelected([]);
  };

  if (!nodeToSwitch) return null;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      // sx={{ width: "fit-content" }}
    >
      <Paper
        sx={{
          display: "flex",
          p: 1,
          gap: 1,
          flexWrap: "wrap",
          justifyContent: "center",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "0.4em",
            backgroundColor: "divider",
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "primary.main",
            borderRadius: 1,
          },
        }}
      >
        {Object.hasOwn(nodeToSwitch, "options")
          ? components.map((component) => (
              // <Button
              //   key={component._id}
              //   disabled={
              //     component._id === nodeToSwitch.nodeKey.split("-").at(-1) ||
              //     nodeToSwitch.subcomponents.find(
              //       (s) => s._id === component._id
              //     ) !== undefined ||
              //     nodes.find(
              //       (n) =>
              //         n.id.split("-").length ===
              //           nodeToSwitch.nodeKey.split("-").length &&
              //         n.id.split("-").at(-1) === component._id
              //     ) || //if the component is the main parent node
              //     component._id === nodes[0]?.data.data._id
              //   }
              //   variant="outlined"
              //   onClick={() => handleComponentClick(component)}
              // >
              //   {component.name}
              // </Button>
              <Component
                key={component._id}
                component={component}
                onClick={handleSelect}
                disabled={
                  component._id === nodeToSwitch.nodeKey.split("-").at(-1) ||
                  nodeToSwitch.subcomponents.find(
                    (s) => s._id === component._id
                  ) !== undefined ||
                  nodes.find(
                    (n) =>
                      n.id.split("-").length ===
                        nodeToSwitch.nodeKey.split("-").length &&
                      n.id.split("-").at(-1) === component._id
                  ) ||
                  component._id === nodes[0]?.data.data._id
                }
                selected={selected.find((s) => s._id === component._id)}
              />
            ))
          : options.map((option) => (
              <Button
                key={option._id}
                disabled={
                  option._id === nodeToSwitch._id ||
                  nodeToSwitch.subcomponents.find(
                    (s) => s._id === option._id
                  ) !== undefined ||
                  nodes.find(
                    (n) =>
                      n.id.split("-").length ===
                        nodeToSwitch.nodeKey.split("-").length &&
                      n.id.split("-").at(-1) === option._id
                  )
                }
                variant="outlined"
                onClick={() => handleComponentClick(option)}
              >
                {option.name}
              </Button>
            ))}
      </Paper>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={handleSwitchClick}
          disabled={selected.length === 0}
        >
          Switch
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Component = ({ component, onClick, disabled, selected }) => {
  return (
    <Card
      elevation={disabled ? 0 : 3}
      sx={{
        boxSizing: "border-box",
        width: 240,
        height: 200,
        display: "flex",
        pb: 0,
        flexDirection: "column",
        justifyContent: "flex-end",
        cursor: disabled ? "default" : "pointer",
        border: 1,
        borderColor: selected ? "primary.main" : "transparent",
        transition: "border-color 0.3s",
        ":hover": { borderColor: disabled ? "transparent" : "primary.main" },
        filter: disabled ? "grayscale(1)" : "none",
      }}
      disabled={disabled}
      onClick={() => (disabled ? null : onClick(component))}
    >
      <CardMedia
        sx={{ height: 150 }}
        image={`${CLOUDFRONT_URL}${component.image}`}
        title="image"
      />
      <CardContent sx={{ pt: 1, pb: 0, ":last-child": { pb: 0 } }}>
        <Typography
          variant="h5"
          component="div"
          flexWrap={"wrap"}
          sx={{ pb: 1 }}
        >
          {component.name}
        </Typography>
      </CardContent>
    </Card>
  );
};

Component.propTypes = {
  component: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
};
