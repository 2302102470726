import React from "react";
import { useQuery } from "react-query";
import { getPersons } from "../../../api/peopleApi";
import { UserContext } from "../../../App";

export const usePeopleData = (onSuccess, onError) => {
  const { user } = React.useContext(UserContext);
  return useQuery("people", () => getPersons(user.id), {
    onSuccess,
    onError,
  });
};
