import {
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
// import { useSettingsData } from "../../../hooks/useSettingsData";
import { useSettingsUpdate } from "../../../hooks/useSettingsUpdate";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Cancel, Check, DragIndicator } from "@mui/icons-material";
import { TransitionGroup } from "react-transition-group";
import { useCachedSettings } from "../../../hooks/useCachedSettings";

export const MilestonesTasks = () => {
  const settings = useCachedSettings();
  console.log(settings);
  const { mutate: updateSettings } = useSettingsUpdate();
  const [milestones, setMilestones] = React.useState(settings.milestones || []);
  const [tasks, setTasks] = React.useState(settings.tasks || []);
  const [newTask, setNewTask] = React.useState({ adding: false, name: "" });
  const [newMilestone, setNewMilestone] = React.useState({
    adding: false,
    name: "",
    tasks: [],
  });

  React.useEffect(() => {
    setMilestones(settings.milestones || []);
    setTasks(settings.tasks || []);
  }, [settings]);

  const handleDeleteMilestone = (milestone) => {
    const newMilestones = milestones.filter((m) => m.name !== milestone);
    updateSettings({ pack: { milestones: newMilestones } });
  };

  const handleDeleteTask = (task) => {
    const newTasks = tasks.filter((t) => t !== task);
    updateSettings({ pack: { tasks: newTasks } });
  };

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    let pack = {};
    if (
      destination.droppableId === "milestones" &&
      source.droppableId == "milestones"
    ) {
      // Handling milestone drag
      const newMilestones = Array.from(milestones);
      const [reorderedMilestone] = newMilestones.splice(source.index, 1);
      newMilestones.splice(destination.index, 0, reorderedMilestone);

      setMilestones(newMilestones);
      pack = { milestones: newMilestones };
    } else if (destination.droppableId === "taskList") {
      setMilestones((prev) => {
        const sourceMilestoneIndex = prev.findIndex(
          (m) => m.name === source.droppableId
        );
        if (sourceMilestoneIndex === -1) return prev; // If not found, just return the existing state

        // Copy existing milestones to a new array
        const newMilestones = [...prev];

        // Remove the task from the original milestone's task array
        newMilestones[sourceMilestoneIndex].tasks.splice(source.index, 1);

        // Update the state
        pack = { milestones: newMilestones };
        return newMilestones;
      });
    } else {
      setMilestones((prev) => {
        const destinationMilestoneIndex = prev.findIndex(
          (m) => m.name === destination.droppableId
        );
        const sourceMilestoneIndex = prev.findIndex(
          (m) => m.name === source.droppableId
        );
        const newMilestones = [...prev];
        if (sourceMilestoneIndex !== -1) {
          newMilestones[sourceMilestoneIndex].tasks.splice(source.index, 1);
        }
        newMilestones[destinationMilestoneIndex].tasks.splice(
          destination.index,
          0,
          result.draggableId
        );

        pack = { milestones: newMilestones };
        return newMilestones;
      });
    }

    updateSettings({
      pack,
    });
  };

  const handleAddMilestone = (milestone) => {
    const newMilestones = [...milestones, milestone];
    updateSettings({ pack: { milestones: newMilestones } });
    setNewMilestone(() => ({ name: "", tasks: [], adding: false }));
  };

  const handleAddTask = (task) => {
    const newTasks = [...tasks, task];
    updateSettings({ pack: { tasks: newTasks } });
    setNewTask(() => ({ name: "", adding: false }));
  };

  if (!settings) return null;

  return (
    <>
      <Typography sx={{ mb: 1 }}>
        You can create milestones and tasks. Then, you can drag tasks into
        milestones and reorder them. Your projects will be organized by
        milestones and tasks in the Board View on the Projects tab.
      </Typography>
      <Box sx={{ display: "flex" }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Box sx={{ width: "75%" }}>
            {newMilestone.adding ? (
              <Box sx={{ width: "100%", display: "flex" }}>
                <TextField
                  size="small"
                  value={newMilestone.name}
                  onChange={(e) =>
                    setNewMilestone((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                />
                <IconButton
                  onClick={() =>
                    handleAddMilestone({ name: newMilestone.name, tasks: [] })
                  }
                >
                  <Check />
                </IconButton>
                <IconButton
                  onClick={() =>
                    setNewMilestone(() => ({ name: "", adding: false }))
                  }
                >
                  <Cancel />
                </IconButton>
              </Box>
            ) : (
              <Box sx={{ width: "100%" }}>
                <Button
                  onClick={() =>
                    setNewMilestone((prev) => ({ ...prev, adding: true }))
                  }
                >
                  Add Milestone
                </Button>
              </Box>
            )}
            <Droppable
              type="milestone"
              droppableId="milestones"
              direction="horizontal"
            >
              {(provided) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}
                >
                  {milestones.map((m, i) => (
                    <Draggable
                      key={m.name}
                      draggableId={`${m.name}-drag`}
                      type="milestone"
                      index={i}
                    >
                      {(provided) => (
                        <Paper
                          key={m}
                          sx={{ p: 1, m: 1, width: "25%" }}
                          elevation={0}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Typography variant="h6">{m.name}</Typography>

                          <Droppable
                            droppableId={m.name}
                            style={{ margin: "auto" }}
                          >
                            {(provided) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  minHeight: 50,
                                  gap: 1,
                                }}
                              >
                                {m.tasks.length ? (
                                  m.tasks.map((t, i) => (
                                    <Draggable
                                      key={t}
                                      draggableId={t}
                                      index={i}
                                    >
                                      {(provided) => (
                                        <Paper
                                          key={t}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          elevation={4}
                                          sx={{
                                            p: 1,
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <DragIndicator />
                                          <Typography>{t}</Typography>
                                        </Paper>
                                      )}
                                    </Draggable>
                                  ))
                                ) : (
                                  <Typography
                                    sx={{ textAlign: "center", opacity: 0.7 }}
                                  >
                                    Drag tasks here
                                  </Typography>
                                )}
                              </Box>
                            )}
                          </Droppable>
                          <IconButton
                            onClick={() => handleDeleteMilestone(m.name)}
                          >
                            <Cancel />
                          </IconButton>
                        </Paper>
                      )}
                    </Draggable>
                  ))}
                </Box>
              )}
            </Droppable>
          </Box>
          <Droppable droppableId="taskList">
            {(provided) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{
                  display: "flex",
                  width: "25%",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                {newTask.adding ? (
                  <Box sx={{ display: "flex" }}>
                    <TextField
                      value={newTask.name}
                      size="small"
                      onChange={(e) =>
                        setNewTask((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                    />
                    <IconButton onClick={() => handleAddTask(newTask.name)}>
                      <Check />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        setNewTask(() => ({ name: "", adding: false }))
                      }
                    >
                      <Cancel />
                    </IconButton>
                  </Box>
                ) : (
                  <Button
                    onClick={() =>
                      setNewTask((prev) => ({ ...prev, adding: true }))
                    }
                  >
                    Add Task
                  </Button>
                )}
                <TransitionGroup>
                  {tasks.map((t, i) => (
                    <Collapse key={t}>
                      <Draggable
                        key={t}
                        draggableId={
                          !milestones.find((m) =>
                            m.tasks.find((task) => task == t)
                          )
                            ? t
                            : `${t}::taskList`
                        }
                        index={i}
                        isDragDisabled={
                          !!milestones.find((m) =>
                            m.tasks.find((task) => task == t)
                          )
                        }
                      >
                        {(provided) => (
                          <Paper
                            key={t}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            elevation={
                              milestones.find((m) =>
                                m.tasks.find((task) => task == t)
                              )
                                ? 0
                                : 4
                            }
                            sx={{
                              p: 1,
                              mt: i !== 0 ? 1 : 0,
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <DragIndicator />
                            <Typography>{t}</Typography>
                            <Box sx={{ ml: "auto" }}>
                              <IconButton
                                onClick={() => handleDeleteTask(t)}
                                disabled={
                                  !!milestones.find((m) =>
                                    m.tasks.find((task) => task == t)
                                  )
                                }
                              >
                                <Cancel />
                              </IconButton>
                            </Box>
                          </Paper>
                        )}
                      </Draggable>
                    </Collapse>
                  ))}
                </TransitionGroup>
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </>
  );
};
