import * as React from "react";
import PropTypes from "prop-types";
import { Paper, TextField, IconButton } from "@mui/material";
import Check from "@mui/icons-material/Check";

export const FinancialSettings = ({ settings, setSettings }) => {
  const [taxRate, setTaxRate] = React.useState(settings.taxRate);
  const handleTaxChange = (e) => {
    setTaxRate(e.target.value);
  };
  const handleSubmit = () => {
    const newSettings = Object.assign({}, settings, { taxRate: taxRate });
    setSettings(newSettings);
  };

  return (
    <>
      <Paper sx={{ m: 1, p: 1 }}>
        <TextField
          value={taxRate}
          label="Tax Rate"
          onChange={(e) => handleTaxChange(e)}
          sx={{ pr: 1 }}
        />
        <IconButton sx={{ p: 1 }} size="small" onClick={() => handleSubmit()}>
          <Check />
        </IconButton>
      </Paper>
    </>
  );
};
FinancialSettings.propTypes = {
  settings: PropTypes.object,
  setSettings: PropTypes.func,
};
