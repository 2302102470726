//
// this is the search bar for the main market page
// lets you search, has a button to go to the browse page
// results show on the bottom, will show only previews and a limited number of stores\
// view all results will take you to the browse page
// clicking on a store will take you to the store page
//
// not 100% on the browse page yet

import React from "react";

export const Search = () => {
  return <div style={{ width: "100%", border: "5px solid red" }}>Search</div>;
};
