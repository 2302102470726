import axios from "axios";

const organizationApi = axios.create();

export const getOrganizations = async (params) => {
  const response = await organizationApi.get("/api/organization", {
    params: params,
  });

  return response.data;
};

// export const createOrganization = async (pack) => {
//   const response = await organizationApi.post("/api/catalog", pack);
//   return response.data;
// };

export const getOrganization = async (id) => {
  const response = await organizationApi.get(`/api/organization/${id}`);
  return response.data;
};

export const updateOrganization = async (pack) => {
  console.log(pack);
  const response = await organizationApi.patch(`/api/organization`, pack);
  return response.data;
};

// export const deleteOrganization = async (pack) => {
//   const response = await organizationApi.delete("/api/catalog", { data: pack });
//   return response;
// };
