import axios from "axios";

const folderApi = axios.create();

export const getFolders = async (user) => {
  const response = await folderApi.get("/api/folder", {
    params: { user: user },
  });
  return response.data;
};

// export const getProjectFolders = async (user, projectId) => {
//   const response = await folderApi.get("/folder", {
//     params: { user: user, project: projectId },
//   });
//   return response.data;
// };

export const updateFolder = async ({ id, pack }) => {
  return await folderApi.patch(`/api/folder/${id}`, pack);
};

export const deleteFolder = async (id) => {
  return await folderApi.delete("/api/folder", { params: { id: id } });
};
export const createFolder = async (pack) => {
  //   for (const value of pack.values()) {
  //   }
  try {
    return await folderApi.post("/api/folder", pack, { timeout: 5000 });
  } catch (err) {
    console.log("Post to folders failed!");
  }
};
