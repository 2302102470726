import React from "react";
import { NewOptionContext } from "../../components/OptionNode";
import { TransitionGroup } from "react-transition-group";
import { Box, Button, Collapse, Fade, Paper, Typography } from "@mui/material";

export const OptionVars = () => {
  const { newOption } = React.useContext(NewOptionContext);
  console.log(newOption);

  const vars = newOption.parameters.filter(
    (p) => p.hierarchy.length === 1 && p.variable
  );

  // React.useEffect(() => {
  //   setVars(
  //     newOption.parameters.filter((p) => p.hierarchy.length === 1 && p.variable)
  //   );
  // }, [newOption.parameters]);

  return (
    <div>
      <Fade in={vars.length} unmountOnExit>
        <div>
          <Fade in={vars.filter((v) => v.source).length} unmountOnExit>
            <Paper
              sx={{
                display: "flex",
                gap: 1,
                flexDirection: "column",
                alignItems: "center",
                mt: 1,
                p: 1,
              }}
              elevation={2}
            >
              <Typography>Source</Typography>
              <TransitionGroup>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  {vars
                    .filter((v) => v.source)
                    .map((parameter) => (
                      <Collapse
                        key={parameter.name}
                        in={true}
                        direction="horizontal"
                      >
                        <Button
                          key={parameter.name}
                          size="small"
                          variant="contained"
                          color="primary"
                        >
                          {parameter.name}
                        </Button>
                      </Collapse>
                    ))}
                </Box>
              </TransitionGroup>
            </Paper>
          </Fade>
          <Fade in={vars.filter((v) => !v.source).length} unmountOnExit>
            <Paper
              sx={{
                display: "flex",
                gap: 1,
                flexDirection: "column",
                alignItems: "center",
                mt: 1,
                p: 1,
              }}
              elevation={2}
            >
              <Typography>Choice</Typography>
              <TransitionGroup>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "100%",
                  }}
                >
                  {vars
                    .filter((v) => !v.source)
                    .map((parameter) => (
                      <Collapse
                        key={parameter.name}
                        in={true}
                        direction="horizontal"
                      >
                        <Button
                          key={parameter.name}
                          size="small"
                          variant="contained"
                          color="primary"
                        >
                          {parameter.name}
                        </Button>
                      </Collapse>
                    ))}
                </Box>
              </TransitionGroup>
            </Paper>
          </Fade>
        </div>
      </Fade>
    </div>
  );
};
