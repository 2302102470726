import React from "react";
import { NumericFormat } from "react-number-format";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) =>
        onChange({
          target: {
            value: values.value,
          },
        })
      }
      prefix="$"
      thousandSeparator={true}
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
};
NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

const CurrencyTextField = (props) => {
  return (
    <TextField
      {...props}
      InputProps={{
        inputComponent: NumberFormatCustom,
      }}
    />
  );
};

export default CurrencyTextField;
