import React from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  Collapse,
  Divider,
  Fade,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Paper,
} from "@mui/material";
import FunctionsIcon from "@mui/icons-material/Functions";
import { NewComponentContext } from "../../components/ComponentNode";
import { TransitionGroup } from "react-transition-group";
import PropTypes from "prop-types";
import { limitedEvaluate } from "../../components/utils/limitedEvaluate";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import { Conditions } from "./Conditions";

export const Parameters = ({ variables }) => {
  const { newComponent } = React.useContext(NewComponentContext);
  // const [variables, setVariables] = React.useState(
  //   nodeData
  //     .map(
  //       (n) =>
  //         n.parameters
  //           ?.filter((p) => p.variable)
  //           .map((p) => ({ ...p, nodeKey: n.nodeKey, label: n.name })) || []
  //     )
  //     .flat()
  // );
  // React.useEffect(() => {
  //   setVariables(
  //     nodeData
  //       .map(
  //         (n) =>
  //           n.parameters
  //             ?.filter((p) => p.variable)
  //             .map((p) => ({ ...p, nodeKey: n.nodeKey, label: n.name })) || []
  //       )
  //       .flat()
  //   );
  // }, [nodeData, setVariables]);

  return (
    <div>
      <Fade
        in={
          !!newComponent.parameters.filter((p) => p.hierarchy.length === 1)
            .length
        }
        unmountOnExit
      >
        <Paper sx={{ mt: 1, pr: 1, pb: 1, pl: 1 }}>
          <TransitionGroup>
            {newComponent.parameters
              .filter((p) => p.hierarchy.length === 1)
              .sort((a, b) => b.name.localeCompare(a.name))
              .map((parameter) => (
                <Collapse key={newComponent.nodeKey + parameter.name}>
                  <Parameter parameter={parameter} variables={variables} />
                </Collapse>
              ))}
          </TransitionGroup>
        </Paper>
      </Fade>
    </div>
  );
};

Parameters.propTypes = {
  variables: PropTypes.array,
};

export const Parameter = ({ parameter, variables }) => {
  const { newComponent, handleEdit } = React.useContext(NewComponentContext);

  const [focused, setFocused] = React.useState(false);
  const [conditional, setConditional] = React.useState(
    parameter.formula?.includes(":") || false
  );
  const checkFormula = (value) => {
    try {
      let scope = new Map();
      variables?.forEach((v) => {
        scope.set(v.variable, v.value);
      });
      let result = limitedEvaluate(value, scope);
      return result;
    } catch (error) {
      return { error: error.message };
    }
  };
  const handleFormula = (value) => {
    try {
      let scope = new Map();
      variables?.forEach((v) => {
        scope.set(v.variable, v.value);
      });
      if (value.split(",").length > 1) {
        value.split(",").map((v) => limitedEvaluate(v, scope));
      } else {
        limitedEvaluate(value, scope);
      }
      setError(null);
      return true;
    } catch (error) {
      setError(error.message);
      return false;
    }
  };
  const [conditionValue, setConditionValue] = React.useState(
    parameter.formula?.includes(":") ? parameter.formula : null
  );

  const [inputValue, setInputValue] = React.useState(
    parameter.formula
      ? parameter.formula.includes(":")
        ? parameter.formula.split("::")[1]
        : parameter.formula
      : parameter.value
  );
  const [error, setError] = React.useState(parameter.error);

  React.useEffect(() => {
    if (parameter.formula) {
      setInputValue(
        parameter.formula.includes(":")
          ? parameter.formula.split("::")[1]
          : parameter.formula
      );
    } else {
      setInputValue(parameter.value);
    }
  }, [parameter]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (conditional) {
      setConditionValue((prev) => `${prev.split("::")[0]}::${e.target.value}`);
    }
    if (parameter.formula) {
      if (handleFormula(e.target.value)) {
        setError(null);
      }
    }
  };

  React.useEffect(() => {
    if (parameter.formula) {
      if (handleFormula(inputValue)) {
        setError(null);
      }
    }
  }, [variables]);

  const handleInputBlur = (e) => {
    if (
      parameter.formula
        ? e.target.value == parameter.formula
        : e.target.value == parameter.value
    )
      return;
    if (e.target.value.trim() !== "") {
      if (isNumeric(e.target.value) && !conditionValue) {
        handleParameterValueEdit(e);
      } else {
        if (handleFormula(inputValue)) {
          handleEdit({
            field: "parameters",
            value: {
              ...parameter,
              formula: conditionValue ? conditionValue : inputValue,
            },
            type: "update",
          });
        } else {
          handleEdit({
            field: "parameters",
            value: {
              ...parameter,
              formula: conditionValue ? conditionValue : inputValue,
              error: error,
            },
            type: "update",
          });
        }
      }
    } else {
      handleEdit({
        field: "parameters",
        value: {
          ...parameter,
          value: 1,
          formula: "",
        },
        type: "update",
      });
      setInputValue("1");
    }
  };

  const handleConditionalClick = () => {
    setConditional((prev) => {
      setConditionValue(`::${inputValue}`);

      return !prev;
    });
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const onClickAway = () => {
    setFocused(false);
  };

  const handleVariable = () => {
    if (!parameter.variable) {
      handleEdit({
        field: "parameters",
        value: {
          ...parameter,
          variable: newComponent.name + parameter.name,
        },
        type: "update",
        updateType: "variable",
      });
    } else {
      handleEdit({
        field: "parameters",
        value: {
          ...parameter,
          variable: "",
        },
        type: "update",
        updateType: "removeVariable",
      });
    }
  };

  const handleParameterValueEdit = (e) => {
    handleEdit({
      field: "parameters",
      value: {
        ...parameter,
        value: Number(e.target.value),
        formula: "",
      },
      type: "update",
    });
  };

  const checked = !isNumeric(inputValue);

  return (
    <>
      <Paper
        sx={{
          gap: 1,
          // position: "fixed",
          mt: 1,
          ml: focused && checked ? -15 : 0,
          mr: focused && checked ? -15 : 0,
          p: 0,
          boxShadow: focused && checked ? "default" : 0,
          // width: focused && checked ? 400 : 200,
          transition: "all 300ms",
        }}
        elevation={focused && checked ? 4 : 1}
      >
        <ClickAwayListener onClickAway={onClickAway}>
          <div>
            <Collapse in={conditional && focused} unmountOnExit>
              <Conditions
                localValue={conditionValue}
                setLocalValue={(value) =>
                  setConditionValue(() => `${value}::${inputValue}`)
                }
                checkFormula={checkFormula}
                error={error}
              />
            </Collapse>
            <FormControl
              variant="outlined"
              fullWidth
              size="small"
              onFocus={handleFocus}
            >
              <OutlinedInput
                size="small"
                // disabled={
                //   newComponent._id !== "newComponent" &&
                //   newComponent.nodeKey.split("-").length > 1
                // }
                error={error}
                value={inputValue}
                fullWidth
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                startAdornment={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mr: 1,
                      pr: -1,
                    }}
                  >
                    <Button
                      sx={{
                        ml: -1,
                        pl: 1,
                        pr: 1,
                        pt: 0.2,
                        pb: 0.2,
                        mr: 0.5,
                        color: parameter.variable ? "default" : "text.primary",
                        fontSize: 15,
                        boxSizing: "border-box",
                        border: "1px solid transparent",
                      }}
                      size="small"
                      variant={parameter.variable ? "contained" : "outlined"}
                      onClick={handleVariable}
                      value={parameter}
                    >
                      {parameter.name}
                    </Button>

                    <Collapse
                      in={checked}
                      unmountOnExit
                      orientation="horizontal"
                    >
                      <Button
                        size="small"
                        sx={{
                          p: 0.2,
                          minWidth: 0,
                          ml: -0.5,
                          color: checked ? "primary" : "text.primary",
                        }}
                      >
                        <FunctionsIcon
                          color={checked ? "primary" : "default"}
                          sx={{ height: "26.232px" }}
                        />
                      </Button>
                    </Collapse>
                    <Collapse
                      in={(focused && checked) || conditional}
                      unmountOnExit
                      orientation="horizontal"
                    >
                      <Button
                        onClick={handleConditionalClick}
                        sx={{
                          p: 0.2,
                          minWidth: 0,
                          color: conditional ? "default" : "text.primary",
                        }}
                        size="small"
                      >
                        <AltRouteIcon
                          color={conditional ? "primary" : "default"}
                          sx={{ height: "26.232px" }}
                        />
                      </Button>
                    </Collapse>
                    <Divider orientation="vertical" flexItem />
                  </Box>
                }
                endAdornment={
                  <em style={{ color: "gray" }}> {parameter.unit}</em>
                }
              />
              {error && <FormHelperText error>{error}</FormHelperText>}
            </FormControl>
          </div>
        </ClickAwayListener>
      </Paper>
    </>
  );
};

Parameter.propTypes = {
  parameter: PropTypes.object,
  variables: PropTypes.array,
};

function isNumeric(str) {
  if (!str) return true;
  if (typeof str == "number") return true; // we only process strings!
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}
