import * as React from "react";
import axios from "axios";
import { tokenLogin } from "./api/userApi";
import { QueryClient, QueryClientProvider } from "react-query";
import styles from "./App.module.css";
import { Router } from "./Router";
import { amber, grey } from "@mui/material/colors";
import { ThemeProvider, createTheme } from "@mui/material";

// import { getSub } from "./api/authApi";

export const UserContext = React.createContext({
  user: null,
  setUser: () => {},
});

const App = () => {
  const [user, setUser] = React.useState(null);
  const [sub] = React.useState(true);
  const [loggedIn, setLoggedIn] = React.useState();
  // if (user) {
  //   getSub(user.id).then((res) => {
  //     res === "active" ? setSub(true) : setSub(false);
  //   });
  // }
  const handleUser = (user) => {
    setUser(user);
    setLoggedIn(true);
  };

  React.useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("user");
      if (token) {
        try {
          const data = await tokenLogin({ token });
          setUser(data);
          setLoggedIn(true);
        } catch (err) {
          setLoggedIn(false);
        }
      } else {
        setLoggedIn(false);
      }
    }
    fetchData();
  }, []);

  if (user && window.location.href.includes("code=")) {
    axios.patch(`${window.location.href}&user=${user.id}`);
    window.location.href = "/app";
  }

  const queryClient = new QueryClient();

  if (loggedIn === undefined) {
    return null;
  }
  const theme = createTheme({
    palette: {
      // palette values for light mode
      primary: amber,
      divider: amber[200],
      text: {
        primary: grey[900],
        secondary: grey[800],
      },
    },
  });

  return (
    // <div style={{ backgroundColor: "rgba(180, 180, 180, 1)" }}>
    <UserContext.Provider
      value={{ user: { ...user, sub }, setUser: handleUser }}
    >
      <QueryClientProvider client={queryClient}>
        <div className={styles.container}>
          <div className={styles.layout}>
            {/* <div className={styles.header}></div> */}
            <div className={styles.content}>
              <ThemeProvider theme={theme}>
                <Router user={{ ...user, sub }} />
              </ThemeProvider>
            </div>
          </div>
        </div>
      </QueryClientProvider>
    </UserContext.Provider>
    // </div>
  );
};
export default App;
