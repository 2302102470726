import { Box, Button, Grid, Paper, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import darkDocusign from "../../utils/darkDocusign.svg";
import lightDocusign from "../../utils/lightDocusign.svg";
import { checkSubscription } from "../../../../utils/checkSubscription";

export const DocusignIntegration = ({ settings }) => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark";
  const docusignLogin = () => {
    window.open(
      `https://account.docusign.com/oauth/auth?response_type=code&scope=signature impersonation&client_id=af7386ed-3582-4972-a306-8d229b8add35&redirect_uri=https://www.manufacture.app/api/auth/docusign`
    );
  };
  const sub = checkSubscription(settings);
  return (
    <Paper
      sx={{
        display: "flex",
        p: 1,
        overflow: "hidden",
        justifyContent: "space-between",
        alignItems: "center",
        height: 100,
        //   pl: 8,
        backgroundColor: darkMode ? "#191823" : "#F4F4F6",
        boxShadow: 1,
      }}
      elevation={0}
    >
      <img
        src={darkMode ? darkDocusign : lightDocusign}
        alt="docusign"
        style={{ marginLeft: -80 }}
      />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {settings.dsAccountName ? (
          <Grid sx={{ mr: 8 }}>
            <Grid item>{settings.dsAccountName}</Grid>
            <Grid item>{settings.dsAccountEmail}</Grid>
          </Grid>
        ) : null}
        <Button
          variant="contained"
          sx={{
            color: "black",
            mr: 3,
            backgroundColor: "#D5FF00",
            transition: "all 0.3s",
            ":hover": {
              filter: "brightness(0.8)",

              backgroundColor: "#D5FF00",
            },
          }}
          disabled={!sub.includes("project") && !sub.includes("financial")}
          onClick={() => docusignLogin()}
        >
          Connect
        </Button>
      </Box>
    </Paper>
  );
};

DocusignIntegration.propTypes = {
  settings: PropTypes.object,
};
