import * as React from "react";
import PropTypes from "prop-types";
import {
  Divider,
  Grid,
  List,
  ListItemButton,
  Paper,
  Typography,
} from "@mui/material";
import { DepositDetails } from "./financialDetails/DepositDetails";
import { ProjectValueDetails } from "./financialDetails/ProjectValueDetails";
import { PaymentDetails } from "./financialDetails/PaymentDetails";
import { CostDetails } from "./financialDetails/CostDetails";
import { Discount } from "./financialDetails/Discount";
import { TaxDetails } from "./financialDetails/TaxDetails";
import { SelectedProjectContext } from "./ProjectView";

export const FinancialView = ({ overview }) => {
  const project = React.useContext(SelectedProjectContext);
  const [selected, setSelected] = React.useState("project value");
  let paymentTotal = project.financial.reduce((acc, payment) => {
    if (payment.amount > 0) {
      return acc + payment.amount;
    } else {
      return acc + 0;
    }
  }, 0);
  const financialLines = [
    {
      field: "project value",
      label: "Project Value",
      value: project.projectValue.toFixed(2),
    },
    {
      field: "discount",
      label: `Discount (${project.discountRate.toFixed(2)}%)`,
      value: project.discount.toFixed(2),
    },
    {
      field: "tax",
      label: `Tax (${(project.taxRate.toFixed(2) * 100).toFixed(2)}%)`,
      value: project.tax.toFixed(2),
    },
    { field: "total", label: "Total", value: project.total.toFixed(2) },
    // { label: "deposit", value: depositTotal.toFixed(2) },
    { field: "payments", label: "Payments", value: paymentTotal.toFixed(2) },
    {
      field: "balance",
      label: "Balance",
      value: project.balance.toFixed(2),
    },
  ];
  const cost =
    project.imported == "Mozaik"
      ? project.componentList.reduce((acc, comp) => {
          return acc + comp.total;
        }, 0) +
        project.products.reduce((acc, prod) => {
          return prod.cost ? (acc += prod.cost) : (acc += 0);
        }, 0) +
        project.financial.reduce((acc, payment) => {
          if (payment.amount < 0) {
            return acc + payment.amount * -1;
          } else {
            return acc + 0;
          }
        }, 0)
      : project.products.reduce((acc, prod) => {
          return acc + prod.cost ? prod.cost : 0;
        }, 0) +
        project.financial.reduce((acc, payment) => {
          if (payment.amount < 0) {
            return acc + payment.amount * -1;
          } else {
            return acc + 0;
          }
        }, 0);
  const handleClick = (tab) => {
    if (tab == "total") return;

    setSelected((prev) => (prev == tab ? "" : tab));
  };
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <Paper sx={{ height: "100%", p: 1 }}>
      <Typography sx={{ p: 1 }}>Financial</Typography>
      <Grid container sx={{ height: "95%" }}>
        <Grid item xs={4}>
          <List>
            {financialLines.map((line) => (
              <ListItemButton
                key={line.field}
                disabled={line.field == "total"}
                sx={{
                  p: overview ? 0 : "default",
                  pl: 1,
                  pr: 2,
                  borderRadius: 1,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,

                  backgroundColor:
                    selected == line.field ? "primary.main" : "transparent",
                  color:
                    selected == line.field ? "primary.contrastText" : "default",
                  ":hover": {
                    backgroundColor:
                      selected == line.field ? "primary.light" : "transparent",
                  },
                }}
                onClick={() => handleClick(line.field)}
              >
                <Grid container justifyContent="space-between">
                  <Typography>{line.label} : </Typography>
                  <Typography>{formatter.format(line.value)}</Typography>
                </Grid>
              </ListItemButton>
            ))}
          </List>
          <Divider />
          <List>
            <ListItemButton
              key={"cost"}
              sx={{
                p: overview ? 0 : "default",
                pl: 1,
                pr: 2,
                borderRadius: 1,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,

                backgroundColor:
                  selected == "cost" ? "primary.main" : "transparent",
                color: selected == "cost" ? "primary.contrastText" : "default",
                ":hover": {
                  backgroundColor:
                    selected == "cost" ? "primary.light" : "transparent",
                },
              }}
              onClick={() => handleClick("cost")}
            >
              <Grid container justifyContent="space-between">
                <Typography>Cost : </Typography>
                <Typography>{formatter.format(cost)}</Typography>
              </Grid>
            </ListItemButton>
            <ListItemButton
              key={"profit"}
              disabled
              sx={{
                p: overview ? 0 : "default",
                pl: 1,
                pr: 2,
                borderTopLeftRadius: 1,
                borderBottomLeftRadius: 1,
                backgroundColor:
                  selected == "profit" ? "primary.main" : "transparent",
                color:
                  selected == "profit" ? "primary.contrastText" : "default",
                ":hover": {
                  backgroundColor:
                    selected == "profit" ? "primary.light" : "transparent",
                },
              }}
              onClick={() => handleClick("profit")}
            >
              <Grid container justifyContent="space-between">
                <Typography>Profit : </Typography>
                <Typography>
                  {formatter.format(project.total - cost)}
                </Typography>
              </Grid>
            </ListItemButton>
          </List>
        </Grid>
        <Grid item xs={8} sx={{ height: "100%" }}>
          <Paper
            elevation={0}
            sx={{
              border: 1,
              borderColor: "primary.main",
              p: 0.5,
              pb: 0,
              mt: "-12px",
              height: "calc(100% - 12px)",
            }}
          >
            {/* <Typography>{selected}</Typography> */}
            {selected == "deposit" && <DepositDetails project={project} />}
            {selected == "project value" && (
              <ProjectValueDetails project={project} />
            )}
            {selected == "tax" && <TaxDetails project={project} />}
            {selected == "payments" && <PaymentDetails project={project} />}
            {selected == "cost" && <CostDetails project={project} />}
            {selected == "discount" && <Discount project={project} />}
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};
FinancialView.propTypes = {
  project: PropTypes.object,
  overview: PropTypes.bool,
};
