import { Box, Tooltip } from "@mui/material";
import * as React from "react";

const SERV = "";
export const endpoints = {
  customers: SERV + "/api/customer",
  mats: SERV + "/api/material",
  pros: SERV + "/api/project",
  suppliers: SERV + "/api/supplier",
  products: SERV + "/api/product",
  allProducts: SERV + "/api/product",
  components: SERV + "/api/component",
  allComponents: SERV + "/api/component",
  options: SERV + "/api/option",
  allOptions: SERV + "/api/option",
  documents: SERV + "/api/document",
  settings: SERV + "/api/settings",
  person: SERV + "/api/person",
};
export const axiosHeaders = {
  customers: null,
  mats: {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  },
  prosducts: {
    headers: {
      "Content-Type": "application/json",
    },
  },
  allProducts: {
    headers: {
      "Content-Type": "application/json",
    },
  },
  suppliers: {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  },
  components: {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  },
  allComponents: {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  },
  options: {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  },
  allOptions: {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  },
};

export const collapseContents = {
  component: [
    {
      id: "suppliers",
      key: "suppliers",
      type: "suppliers",
      title: "suppliers",
      typeKey: "suppliers",
      keyword: "supplier",
      endpoint: "supplier",
    },
    {
      id: "selectedComponents",
      key: "components",
      type: "components",
      title: "components",
      typeKey: "components",
      keyword: "component",
    },
    {
      id: "options",
      key: "options",
      title: "add new option",
      label: "Option name",
      typeKey: "options",
      componentKey: "options",
      keyword: "option",
      type: "choiceMonger",
    },
  ],
  product: [
    {
      id: "selectedComponents",
      key: "selectedComponents",
      label: "Selected Components",
      typeKey: "prodComponents",
      productKey: "components",
      keyword: "component",
      chips: true,
    },
    {
      id: "availableComponents",
      key: "availableComponents",
      label: "Available Components",
      typeKey: "components",
      conn: true, //are these fetched or just listed (true/false)?
      hasFilter: true,
    },
    {
      id: "selectedOptions",
      key: "selectedOptions",
      label: "Selected Options",
      typeKey: "prodOptions",
      productKey: "options",
      keyword: "option",
      chips: true,
    },
    {
      id: "availableOptions",
      key: "availableOptions",
      label: "Available Options",
      typeKey: "options",
      conn: true, //are these fetched or just listed (true/false)?
      hasFilter: true,
    },
  ],
};

export const cards = {
  newProduct: {
    schema: {
      name: "",
      zone: "none",
      type: "none",
      width: [],
      height: [],
      depth: [],
      image: "",
      price: 0,
      components: [],
      options: [],
    },
    title: "product",
    headerText: "New Product",
    headerButtonText: "View All",
    dimensions: {
      width: {
        key: "width",
        label: "Width",
        options: [0, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45, 48],
        initial: [],
        multiple: true,
      },
      height: {
        key: "height",
        label: "Height",
        options: [0, 18, 24, 30, 34.5, 36, 42],
        initial: [],
        multiple: true,
      },
      depth: {
        key: "depth",
        label: "Depth",
        options: [0, 14, 24],
        initial: [],
        multiple: true,
      },
    },
    fields: [
      {
        type: "text",
        key: "name",
        sx: { paddingTop: "8px" },
        name: "name",
        label: "Product Name",
        xs: 12,
      },
      { type: "image", key: "image", xs: 7, label: "Upload product image" },
      {
        type: "fieldGroup",
        xs: 5,
        key: "fg-12",
        fields: [
          {
            type: "select",
            key: "width",
            label: "Width",
            options: [9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45, 48],
            xs: 12,
            multiple: true,
          },
          {
            type: "select",
            key: "height",
            label: "Height",
            options: [18, 24, 30, 34.5, 36, 42],
            xs: 12,
            multiple: true,
          },
          {
            type: "select",
            key: "depth",
            label: "Depth",
            options: [14, 24],
            xs: 12,
            multiple: true,
          },
          { xs: 12, type: "staticPriceDisplay", display: false },
        ],
      },
    ],
    hasCollapse: true,
    collapse: {
      title: "more",
      contents: collapseContents["product"],
    },
    submitButton: { label: "Create" },
  },
  newComponent: {
    schema: {
      name: "",
      zone: "none",
      type: "none",
      width: [],
      height: [],
      depth: [],
      price: 0,
      isProduct: false,
      components: [],
      suppliers: [],
      options: [],
      compAmounts: [],
      advanced: {},
      categories: [],
    },
    title: "component",
    headerText: "new component",
    headerButtonText: false,
    headerSwitch: "is product",
    titleTypographyProps: { variant: "button" },
    dimensions: {
      width: {
        key: "width",
        label: "Width",
        options: [9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45, 48, 96],
        initial: [15],
        multiple: true,
      },
      height: {
        key: "height",
        label: "Height",
        options: [18, 24, 30, 34.5, 36, 42, 48, 96],
        initial: [34.5],
        multiple: true,
      },
      depth: {
        key: "depth",
        label: "Depth",
        options: [14, 24],
        initial: [24],
        multiple: true,
      },
    },
    fields: [
      {
        type: "text",
        key: "name",
        sx: { paddingTop: "8px" },
        name: "name",
        label: "component name",
        xs: 12,
      },
      { type: "image", key: "image", xs: 7, label: "upload component image" },
      {
        type: "fieldGroup",
        xs: 12,
        key: "fg-12",
        fields: [
          {
            type: "select",
            key: "width",
            label: "Width",
            options: [9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45, 48],
            xs: 12,
            multiple: true,
          },
          {
            type: "select",
            key: "height",
            label: "Height",
            options: [18, 24, 30, 34.5, 36, 42],
            xs: 12,
            multiple: true,
          },
          {
            type: "select",
            key: "depth",
            label: "Depth",
            options: [14, 24],
            xs: 12,
            multiple: true,
          },
          {
            type: "text",
            key: "price",
            name: "price",
            label: "Price",
            format: "currency",
            xs: 12,
            display: false,
          },
        ],
      },
    ],
    hasCollapse: true,
    collapse: {
      title: ["more", "less"],
      contents: collapseContents["component"],
    },
    submitButton: { label: "Create" },
  },
  subComponents: {
    headerText: "subcomponents",
    titleTypographyProps: { variant: "button" },
  },
  newOption: {
    schema: {
      name: "",
      width: 12,
      height: 12,
      image: "",
      price: 100,
      quantity: 1,
      productType: "cabinet",
      productWidth: "X",
      productHeight: "X",
      productDepth: "X",
      code: "XO-AAA-XW-XH-XD",
    },
    title: "option",
    headerText: "New Option",
    headerButtonText: "View All",
    dimensions: {
      width: {
        key: "width",
        label: "Width",
        options: [9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45, 48],
        initial: 12,
      },
      height: {
        key: "height",
        label: "Height",
        options: [18, 24, 30, 34.5, 36, 42],
        initial: 12,
      },
      depth: { key: "depth", label: "Depth", options: [14, 24], initial: 24 },
    },
    fields: [
      {
        type: "text",
        key: "name",
        sx: { paddingTop: "8px" },
        name: "name",
        label: "Option Name",
        xs: 12,
      },
      {
        type: "image",
        key: "image",
        xs: 7,
        label: "Upload option image",
      },
      {
        type: "fieldGroup",
        xs: 5,
        key: "option-fg-12",
        fields: [
          {
            type: "text",
            key: "width",
            name: "width",
            label: "Width",
            xs: 12,
          },
          {
            type: "text",
            key: "height",
            name: "height",
            label: "Height",
            xs: 12,
          },
          {
            type: "text",
            key: "price",
            name: "price",
            label: "Price",
            format: "currency",
            xs: 12,
          },
        ],
      },
    ],
    hasCollapse: true,
    collapse: {
      title: ["more", "less"],
      contents: [
        {
          key: "productType",
          sx: { paddingTop: "8px" },
          type: "select",
          label: "Product Type",
          options: ["cabinet"],
          xs: 12,
        },
        {
          type: "select",
          key: "productWidth",
          label: "Product Width",
          options: ["X", 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45, 48],
          xs: 12,
        },
        {
          type: "select",
          key: "productHeight",
          label: "Product Height",
          options: ["X", 18, 24, 30, 34.5, 36, 42],
          xs: 12,
        },
        {
          type: "select",
          key: "productDepth",
          label: "Product Depth",
          options: ["X", 14, 24],
          xs: 12,
        },
        {
          type: "select",
          key: "quantity",
          label: "Default Quantity",
          options: [1, 2, 3, 4, 5, 6],
          xs: 12,
        },
        {
          type: "text",
          key: "code",
          name: "code",
          label: "Code",
          format: "currency",
          disabled: true,
          xs: 12,
        },
      ],
    },
    submitButton: { label: "Create" },
  },
  allProducts: {
    headerText: "All Products",
    headerButtonText: "go back",
  },
  allComponents: {
    headerText: "Components",
    titleTypographyProps: { variant: "button" },
  },
  allOptions: {
    headerText: "All Options",
    headerButtonText: "go back",
  },
};

export const cabSelects = [
  {
    key: "width",
    label: "Width",
    options: [9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45, 48],
    multiple: true,
  },
  {
    key: "height",
    label: "Height",
    options: [18, 24, 30, 34.5, 36, 42],
    multiple: true,
  },
  { key: "depth", label: "Depth", options: [14, 24], multiple: true },
];

export const columns = {
  allProducts: [
    {
      field: "image",
      headerName: "IMG",
      type: "image",
      minWidth: 60,
      editable: false,
      renderCell: (x) => (
        <Tooltip
          title={
            <img
              src={"https://cdn.vbx.app/" + x.value}
              height={360}
              width={360}
            />
          }
        >
          <img
            style={{ height: 52, width: 60, backgroundSize: "contain" }}
            src={"https://cdn.vbx.app/" + x.value}
          />
        </Tooltip>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      type: "string",
      minWidth: 160,
      editable: false,
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      minWidth: 80,
      editable: true,
    },
    {
      field: "code",
      headerName: "Code",
      type: "string",
      minWidth: 180,
      editable: false,
      align: "left",
    },
  ],
  allComponents: [
    {
      field: "name",
      headerName: "Name",
      type: "string",
      flex: 1,
      editable: false,
    },
  ],
  allOptions: [
    {
      field: "image",
      headerName: "IMG",
      type: "image",
      minWidth: 60,
      editable: false,
      renderCell: (x) => (
        <Tooltip title={<img src={x.value} height={360} width={360} />}>
          <img
            style={{ height: 52, width: 60, backgroundSize: "contain" }}
            src={x.value}
          />
        </Tooltip>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      type: "string",
      minWidth: 160,
      editable: false,
    },
    {
      field: "width",
      headerName: "Width",
      type: "string",
      minWidth: 80,
      editable: false,
    },
    {
      field: "height",
      headerName: "height",
      type: "string",
      minWidth: 80,
      editable: false,
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      minWidth: 80,
      editable: true,
    },
    {
      field: "code",
      headerName: "Code",
      type: "string",
      minWidth: 180,
      editable: false,
      align: "left",
    },
  ],
  person: [
    {
      field: "firstName",
      headerName: "First Name",
      type: "text",
      flex: 1,
      minWidth: 80,
      required: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      type: "text",
      minWidth: 80,
      required: true,
    },
    // {
    //   field: "position",
    //   headerName: "Position",
    //   type: "text",
    //   minWidth: 140,
    //   flex: 1,
    //   required: false,
    // },
    {
      field: "phone",
      headerName: "Phone",
      type: "tel",
      value: "+1",

      flex: 1,
      minWidth: 100,
    },
    {
      field: "email",
      headerName: "Email",
      type: "email",
      disabled: true,
      flex: 1,
      minWidth: 80,
      required: true,
    },
    {
      field: "address",
      headerName: "Address",
      type: "address1",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "role",
      headerName: "Role",
      type: "select",
      flex: 1,
      minWidth: 100,
      required: false,
    },
  ],
  customers: [
    {
      field: "firstName",
      headerName: "First Name",
      type: "text",
      flex: 1,
      minWidth: 80,
      required: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      type: "text",
      minWidth: 80,
      required: true,
    },
    {
      field: "company",
      headerName: "Company",
      type: "text",
      minWidth: 140,
      flex: 1,
      required: false,
    },
    {
      field: "phone",
      headerName: "Phone",
      type: "tel",
      value: "+1",

      flex: 1,
      minWidth: 100,
    },
    {
      field: "email",
      headerName: "Email",
      type: "email",
      flex: 1,
      minWidth: 80,
      required: true,
    },
    {
      field: "address",
      headerName: "Address",
      type: "address1",
      flex: 1,
      minWidth: 100,
    },
    // {
    //   field: "date",
    //   align: "right",
    //   flex: 1,
    //   headerName: "Date",
    //   type: "date",

    //   renderCell: (x) => {
    //     let d = new Date(x.row.date);
    //     return d.toLocaleDateString("en-US");
    //   },
    // },
    // {
    //   field: "status",
    //   flex: 1,
    //   headerName: "Status",
    //   type: "text",
    //   minWidth: false,
    // },
  ],
  mats: [
    { id: "name", label: "Material Name", minWidth: 170 },
    { id: "code", label: "Code", minWidth: 100 },
    {
      id: "w",
      label: "Width",
      align: "right",
    },
    {
      id: "h",
      label: "Height",
      align: "right",
    },
    {
      id: "d",
      label: "Depth",
      align: "right",
    },
    {
      id: "supplier",
      label: "Supplier",
      align: "right",
    },
  ],
  pros: [
    {
      field: "projectName",
      headerName: "Project Name",
      type: "text",
      width: 400,
      required: true,
      renderCell: (x) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <span
              style={{
                width: "22px",
                height: "22px",
                borderRadius: "100px",
                backgroundColor: x.row.color || null,
              }}
            ></span>
            <div>{x.formattedValue}</div>
          </Box>
        );
      },
    },
    {
      field: "customer",
      headerName: "Customer",
      type: "autocomplete",
      flex: 1,

      renderCell: (x) =>
        x.formattedValue
          ? `${x.formattedValue.firstName} ${x.formattedValue.lastName}`
          : "No Customer",
      create: "customer",
    },

    {
      field: "creationDate",
      headerName: "Creation Date",
      flex: 1,
      type: "date",
      renderCell: (x) => {
        let d = new Date(x.formattedValue);
        return d.toLocaleDateString("en-US");
      },
    },

    {
      field: "status",
      flex: 1,
      headerName: "Status",
      valueGetter: (x) => {
        let step = x.row.log.at(0);
        if (step) {
          if (step.name == "Project Completed") {
            return "Completed";
          }
          return step.name;
        }
      },
      hideOnCreate: true,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      flex: 1,
      type: "date",
      renderCell: (x) => {
        let d = new Date(x.formattedValue);
        return d.toLocaleDateString("en-US");
      },
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
    },
    {
      field: "people",
      headerName: "People",
      type: "autocomplete",
      hideOnTable: true,
      flex: 1,
      value: [],
      renderCell: (x) =>
        x.formattedValue
          ? `${x.formattedValue.firstName} ${x.formattedValue.lastName}`
          : "No People",
    },
  ],
  suppliers: [
    {
      field: "name",
      flex: 1,
      headerName: "Supplier",
      minWidth: 170,
      required: true,
    },
    { field: "address", flex: 1, headerName: "Address", minWidth: 100 },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
    },
    {
      field: "person",
      headerName: "Person",
      flex: 1,
    },
  ],
  components: [
    {
      field: "image",
      headerName: "IMG",
      type: "image",
      minWidth: 60,
      editable: false,
      renderCell: (x) =>
        x.value ? (
          <Tooltip
            title={
              <img
                src={"https://cdn.vbx.app/" + x.value}
                style={{ width: 300, height: 300, borderRadius: "5px" }}
              />
            }
          >
            <img
              style={{ height: 52, width: 60, backgroundSize: "contain" }}
              src={"https://cdn.vbx.app/" + x.value}
            />
          </Tooltip>
        ) : null,
    },
    {
      field: "stock",
      headerName: "Stock",
      width: 80,
      editable: true,
    },
    {
      field: "name",
      headerName: "Name",
      type: "string",
      width: 160,
      editable: false,
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      width: 80,
      editable: true,
    },
  ],
  options: [
    {
      field: "image",
      headerName: "IMG",
      type: "image",
      width: 60,
      editable: false,
      renderCell: (x) => (
        <Tooltip title={<img src={x.value} height={360} width={360} />}>
          <img
            style={{ height: 52, width: 60, backgroundSize: "contain" }}
            src={x.value}
          />
        </Tooltip>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      type: "string",
      width: 160,
      editable: false,
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      width: 80,
      editable: true,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      type: "number",
      width: 80,
      editable: true,
    },
    {
      field: "code",
      headerName: "Code",
      type: "string",
      width: 180,
      editable: false,
      align: "left",
    },
  ],
  orders: [
    {
      field: "name",
      headerName: "Order Name",
      minWidth: 170,
    },
    {
      field: "customer",
      headerName: "Customer",
      minWidth: 170,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 170,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      minWidth: 170,
    },
  ],
  zoneProducts: [
    { key: "name", id: "product", label: "Product" },
    {
      key: "width",
      id: "w",
      label: "Width",
      align: "right",
    },
    {
      key: "height",
      id: "h",
      label: "Height",
      align: "right",
    },
    {
      key: "depth",
      id: "d",
      label: "Depth",
      align: "right",
    },
    { key: "qty", id: "qty", label: "Qty" },
    {
      id: "price",
      label: "Price",
      align: "right",
    },
  ],
  organization: [
    { field: "name", headerName: "Organization Name", minWidth: 170 },
    { field: "address", headerName: "Address", minWidth: 100 },
    {
      field: "city",
      headerName: "City",
      align: "right",
    },
    {
      field: "state",
      headerName: "State",
      align: "right",
      width: "50%",
    },
    {
      field: "zip",
      headerName: "Zip",
      align: "right",
      width: "50%",
    },
    {
      field: "phone",
      headerName: "Phone",
      type: "tel",
      value: "+1",
    },
    {
      field: "email",
      headerName: "Email",
    },
    {
      field: "website",
      headerName: "Website",
    },
  ],
};

export const titles = {
  customers: "Customer",
  mats: "Material",
  pros: "Project",
  suppliers: "Supplier",
  organization: "Organization",
  person: "Person",
};

export const zonesAndProds = {
  deleteZoneDialogSchema: {
    title: "Please confirm",
    bodyText:
      "Are you sure you want to delete this zone? Products in this zone will also be deleted.",
    declineButtonText: "nevermind",
    confirmButtonText: "confirm",
  },
  zones: [
    {
      title: "Kitchen",
    },
    {
      title: "Bedroom",
    },
    {
      title: "Bathroom",
    },
    {
      title: "Closet",
    },
    {
      title: "Laundry",
    },
    {
      title: "Living Room",
    },
    {
      title: "Garage",
    },
  ],
};

// functions
const buildFormData = (formData, data, parentKey) => {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;
    formData.append(parentKey, value);
  }
};

export const jsonToFormData = (data) => {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
};
