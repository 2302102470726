import { Box, TextField, useTheme, ClickAwayListener } from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";
import FolderIcon from "@mui/icons-material/Folder";
import { useFolderCreate } from "../hooks/useFolderCreate";

export const NewFolder = ({ folder, setFolder, setEdit }) => {
  const theme = useTheme();
  const [editName, setEditName] = React.useState("New Folder");
  const { mutate: createFolder, isSuccess, isError } = useFolderCreate();

  const handleFolderSubmit = () => {
    let newFolder = Object.assign({}, folder, { name: editName });
    createFolder({ pack: newFolder });
  };

  React.useEffect(() => {
    if (isSuccess || isError) setEdit(false);
  }, [isSuccess, isError]);

  return (
    <ClickAwayListener onClickAway={() => setEdit(false)}>
      <Box
        sx={{
          boxSizing: "border-box",
          borderRadius: 1,
          width: 100,
          height: 100,
          ":hover": {
            boxShadow: 5,
            backgroundColor: theme.palette.background.paper,
          },
        }}
        onClick={() => setFolder(folder)}
      >
        <div
          style={{
            height: 75,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FolderIcon fontSize="large" />
        </div>
        <div
          style={{
            height: 25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <TextField
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
            sx={{ border: 0 }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleFolderSubmit();
              }
            }}
            variant="standard"
            autoFocus
            multiline
            onFocus={(e) => handleFocus(e)}
            InputProps={{ sx: { p: 0 } }}
          ></TextField>
        </div>
      </Box>
    </ClickAwayListener>
  );
};

NewFolder.propTypes = {
  folder: PropTypes.string,
  setFolder: PropTypes.func,
  setEdit: PropTypes.func,
};

const handleFocus = (e) => {
  e.preventDefault();
  const { target } = e;
  const extensionStarts = target.value.lastIndexOf(".");
  target.focus();
  target.setSelectionRange(0, extensionStarts);
};
