import React from "react";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import { StyleInlineTool } from "editorjs-style";
import AlignmentTuneTool from "editorjs-text-alignment-blocktune";
import DragDrop from "editorjs-drag-drop";
import Paragraph from "@editorjs/paragraph";
import Table from "@editorjs/table";
import { InvoiceHeader } from "./editorModules/AddBlocks/InvoiceHeader";
import ImageTool from "@editorjs/image";
import { DocumentEditor } from "./DocumentEditor";
import Delimiter from "@editorjs/delimiter";
import axios from "axios";
// import { SaveBlockTune } from "./editorModules/SaveBlock";
import PropTypes from "prop-types";
import { FinancialSummary } from "./editorModules/AddBlocks/FinancialSummary";
import { EditableField } from "./editorModules/AddBlocks/EditableField";
import { ProductsTable } from "./editorModules/ProductsTable";
import { useParametersData } from "../../../hooks/useParametersData";

// const CLOUDFRONT_URL = 'https://cdn.vbx.app/';
const vars = {
  product: [
    {
      headerName: "Name",
      field: "name",
      valueFormatter: (props) => props.value,
    },
    {
      headerName: "Code",
      field: "code",
      valueFormatter: (props) => props.value,
    },
    {
      headerName: "Price",
      field: "price",
      type: "number",
      valueFormatter: (props) => `$${props.value}`,
    },
    { headerName: "Qty", field: "qty", valueFormatter: (props) => props.value },
    {
      headerName: "Total",
      field: "total",
      valueFormatter: (props) => props.value,
    },
    {
      headerName: "Zone",
      field: "zone",
      valueFormatter: (props) => props.value,
    },
  ],
};

export const EditorContainer = ({ selectedTemplate, handleCreating }) => {
  const ejInstance = React.useRef();
  const { data: parameters } = useParametersData();

  const initEditor = () => {
    const editor = new EditorJS({
      holder: "editorjs",
      data: selectedTemplate ? selectedTemplate.data[0] : null,
      tools: {
        delimiter: Delimiter,
        invoiceHeader: InvoiceHeader,
        financialSummary: FinancialSummary,
        header: {
          class: Header,
          inlineToolbar: true,
          config: {
            placeholder: "Heading",
            levels: [1, 2, 3, 4],
            defaultLevel: 3,
          },
        },
        productTable: {
          class: ProductsTable,
          config: {
            variables: vars.product.concat(
              parameters.map((param) => {
                if (!param.global) {
                  return {
                    headerName: param.name,
                    field: param.name,
                    valueFormatter: (pros) => pros.value,
                  };
                }
              })
            ),
          },
        },
        editableField: {
          class: EditableField,
          config: { placeholder: "Editable Text" },
        },
        image: {
          class: ImageTool,
          config: {
            uploader: {
              uploadByFile(file) {
                let data = new FormData();
                data.append("file", file);
                return axios
                  .post("/file/template", data, { timeout: 5000 })
                  .then((res) => {
                    return {
                      success: 1,
                      file: {
                        url: res.data,
                        // any other image data you want to store, such as width, height, color, extension, etc
                      },
                    };
                  });
              },
            },
          },
        },
        list: List,
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
          config: { preserveBlank: true, placeholder: "" },
        },
        style: StyleInlineTool,
        alignmentTune: {
          class: AlignmentTuneTool,
          config: {
            default: "left",
            blocks: {
              header: "center",
              list: "left",
            },
          },
        },
        // saveBlockTune: { class: SaveBlockTune },
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
          },
        },
      },
      tunes: ["alignmentTune"],
      minHeight: 926,
      maxHeight: 926,
      defaultBlock: "paragraph",
      placeholder: "Start typing...",

      onReady: () => {
        new DragDrop(editor);
        ejInstance.current = editor;
      },
      onChange: () => {
        try {
          editor
            .save()
            .then((outputData) => {
              console.log("Article data: ", outputData);
            })
            .catch((error) => {
              console.log("Saving failed: ", error);
            });
        } catch (err) {
          console.log(err);
        }
      },
    });
  };
  React.useEffect(() => {
    if (!ejInstance.current) {
      initEditor();
    }
    return () => {
      ejInstance.current.destroy();
      ejInstance.current = null;
    };
  }, []);

  return (
    <div>
      <div>
        <DocumentEditor
          editor={ejInstance}
          selectedTemplate={selectedTemplate}
          handleCreating={handleCreating}
        />
      </div>
      <div
        id={"pdfWrapper"}
        style={{ display: "flex" }}
        className="template-view"
      >
        <div
          id="editorjs"
          style={{
            width: "1314px",
            borderRadius: "5px",

            margin: "auto",
            padding: "16px 96px 16px 96px",
            boxSizing: "border-box",
            backgroundColor: "white",
            color: "black",
          }}
        ></div>
      </div>
    </div>
  );
};

EditorContainer.propTypes = {
  selectedTemplate: PropTypes.object,
  handleCreating: PropTypes.func,
};
