import * as React from "react";
import PropTypes from "prop-types";
import {
  Breadcrumbs,
  CircularProgress,
  IconButton,
  Link,
  Input,
  Divider,
  Box,
} from "@mui/material";
import { FileIcon } from "./FileIcon";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { Folder } from "./FolderIcon";
import { NewFolder } from "./NewFolder";
import { CreateFromTemplate } from "./CreateFromTemplate";
import { useFileCreate } from "../hooks/useFileCreate";
// import FileViewContextMenu from "./FileViewContextMenu";
// import { getSubFolders } from "./FolderBar";

export const FileView = ({
  folder,
  folders,
  user,
  files,
  setFolder,
  project,
}) => {
  const [uploading, setUploading] = React.useState(false);
  const {
    mutate: createFile,
    isSuccess: isCreateSuccess,
    isError: isCreateError,
  } = useFileCreate();

  const handleUpload = (data) => {
    data.append("folder", folder._id);
    folder.project ? data.append("project", folder.project) : null;
    setUploading(true);
    createFile({ pack: data });
  };
  React.useEffect(() => {
    if (isCreateSuccess || isCreateError) setUploading(false);
  }, [isCreateSuccess, isCreateError]);

  const onFilesSelected = ({ target }) => {
    let data = new FormData();

    for (let i = 0; i < target.files.length; i++) {
      data.append("file", target.files[i]);
    }
    handleUpload(data);
  };

  const [selectedFiles, setSelectedFiles] = React.useState(
    files.filter((file) => file.folder == folder._id)
  );
  React.useEffect(() => {
    setSelectedFiles(files.filter((file) => file.folder == folder._id));
  }, [folder, files]);

  let breadcrumbs = makeBreadcrumbs(folder, [], folders);
  project ? breadcrumbs.shift() : null;

  const [creatingFolder, setCreatingFolder] = React.useState(false);

  const createNewFolder = () => {
    setCreatingFolder(true);
  };

  return (
    <div
      style={{
        height: "100%",
        padding: "16px",
        paddingTop: "8px",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "40px",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Breadcrumbs sx={{ pr: 3 }}>
          {breadcrumbs.map((f) => (
            <Link
              key={f.name}
              onClick={() => setFolder(f)}
              underline="hover"
              color="inherit"
            >
              {f.name}
            </Link>
          ))}
        </Breadcrumbs>
      </div>
      <div
        style={{
          width: "100%",
          height: "40px",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton sx={{ mr: 1 }}>
          <CreateNewFolderIcon onClick={() => createNewFolder()} />
        </IconButton>

        {uploading ? (
          <CircularProgress sx={{ mr: 1 }} />
        ) : (
          <label>
            <Input
              sx={{ display: "none" }}
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              inputProps={{ multiple: true }}
              onChange={(e) => onFilesSelected(e)}
            />
            <IconButton component="span" sx={{ mr: 1 }}>
              <UploadFileIcon />
            </IconButton>
          </label>
        )}
        {project ? (
          <CreateFromTemplate user={user} project={project} folder={folder} />
        ) : null}
      </div>
      <Divider sx={{ m: 1 }} />
      <Box
        sx={{
          maxHeight: "calc(100% - 85px)",
          overflow: "auto",
          gap: 1,
          display: "flex",
          flexWrap: "wrap",
          rowGap: 1,
          p: 1,
        }}
        id="FileGrid"
        // onContextMenu={(e) => handleContextMenu(e)}
      >
        {folders
          .filter((f) => f.parent == folder._id)
          .map((fold, i) => (
            <Box key={i}>
              {" "}
              <Folder folder={fold} setFolder={setFolder} />{" "}
            </Box>
          ))}
        {creatingFolder ? (
          <Box>
            <NewFolder
              setEdit={setCreatingFolder}
              folder={{
                name: "New Folder",
                parent: folder._id,
                user: user,
                project: folder.project ? folder.project : null,
              }}
              setFolder={setFolder}
            />{" "}
          </Box>
        ) : null}
        {selectedFiles.map((file, i) => (
          <FileIcon key={i} file={file} project={project} />
        ))}
      </Box>
      {/* <FileViewContextMenu
        contextMenu={contextMenu}
        handleClose={handleClose}
        createFolder={createNewFolder}
        handleUpload={onFilesSelected}
      /> */}
    </div>
  );
};
FileView.propTypes = {
  folder: PropTypes.string,
  folders: PropTypes.array,
  files: PropTypes.array,
  user: PropTypes.string,
  setFolder: PropTypes.func,
  project: PropTypes.object,
};

const makeBreadcrumbs = (folder, breadcrumbs, folders) => {
  breadcrumbs.unshift(folder);
  if (folder.parent) {
    makeBreadcrumbs(
      folders.find((f) => f._id == folder.parent),
      breadcrumbs,
      folders
    );
  }
  return breadcrumbs;
};
