import React from "react";
import { createRoot } from "react-dom";

export class EditableField {
  static get isReadOnlySupported() {
    return true;
  }

  static get toolbox() {
    return {
      title: "Editable Field",
      icon: "<svg>...</svg>", // Replace with your desired icon SVG
    };
  }

  constructor({ data, config, readOnly }) {
    this.readOnly = readOnly;
    this.config = config;
    this.data = {
      text: data.text || "Editable Text",
    };

    this.onContentEdit = this.onContentEdit.bind(this);
  }

  onContentEdit(event) {
    this.data.text = event.target.textContent;
  }

  render() {
    const jsx = (
      <div className="editable-field">
        <div contentEditable onInput={this.onContentEdit}>
          {this.data.text}
        </div>
      </div>
    );

    const fieldDiv = document.createElement("div");
    const root = createRoot(fieldDiv);
    root.render(jsx);
    return fieldDiv;
  }

  save(blockContent) {
    let text = blockContent.textContent;
    return {
      text: text,
    };
  }
}
