import React from "react";
import { useQuery } from "react-query";
import { getOrders } from "../../../api/orderApi";
import { UserContext } from "../../../App";

export const useOrdersData = (onSuccess, onError) => {
  const { user } = React.useContext(UserContext);

  return useQuery("orders", () => getOrders({ user: user.id }), {
    onSuccess: (data) => {
      // Call the provided onSuccess handler
      if (onSuccess) onSuccess(data);
    },
    onError,
    staleTime: 1000 * 60 * 10, // 10 minutes
  });
};
