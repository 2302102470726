import {
  Avatar,
  Box,
  Collapse,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { usePeopleData } from "../../../../people/hooks/usePeopleData";
import { PersonAdd } from "@mui/icons-material";
import { SelectedProjectContext } from "../../ProjectView";
import { useProjectUpdate } from "../../../hooks/useProjectUpdate";
import { TransitionGroup } from "react-transition-group";

const CLOUDFRONT_URL = "https://cdn.manufacture.app/";

export const People = () => {
  const { data: people } = usePeopleData();
  const project = React.useContext(SelectedProjectContext);
  const { mutate: updateProject, isSuccess } = useProjectUpdate(project._id);
  const [anchorEl, setAnchorEl] = React.useState(null);

  console.log(project, people);

  const handleSelect = useCallback(
    (person) => {
      project.people.find((p) => p._id == person._id)
        ? updateProject({
            id: project._id,
            pack: {
              person: person,
              updateType: ["people", "remove"],
            },
          })
        : updateProject({
            id: project._id,
            pack: {
              person: person,
              updateType: ["people", "add"],
            },
          });
    },
    [project.people, project._id, updateProject]
  );

  React.useEffect(() => {
    if (isSuccess) {
      console.log("success");
    }
  }, [isSuccess]);

  //   const handleClose = () => {
  //     setAnchorEl(null);
  //     updateProject();
  //   };
  if (!people) return null;

  return (
    <Paper sx={{ p: 1, mb: 1 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
        <Typography variant="h6">People</Typography>
        <IconButton
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
        >
          <PersonAdd />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {people.map((person) => (
            <MenuItem
              key={person._id}
              sx={{
                backgroundColor: project.people.find((p) => p._id == person._id)
                  ? "primary.main"
                  : "default",
                ":hover": {
                  backgroundColor: project.people.find(
                    (p) => p._id == person._id
                  )
                    ? "primary.light"
                    : "default",
                },
                color: project.people.find((p) => p._id == person._id)
                  ? "primary.contrastText"
                  : "default",
              }}
              onClick={() => {
                handleSelect(person);
              }}
            >
              <ListItemAvatar>
                <Avatar
                  alt={person.firstName}
                  src={`${CLOUDFRONT_URL}${person.avatar}`}
                  sx={{ width: 32, height: 32 }}
                />
              </ListItemAvatar>
              <ListItemText
                primary={`${person.firstName} ${person.lastName}`}
              />
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <TransitionGroup>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {project.people.map((person) => (
            <Collapse key={person._id} in={true}>
              <Paper
                key={person._id}
                sx={{ p: 1, display: "flex", alignItems: "center", gap: 1 }}
                elevation={2}
              >
                <Avatar
                  alt={person.firstName}
                  src={`${CLOUDFRONT_URL}${person.avatar}`}
                  sx={{ width: 32, height: 32 }}
                />
                {person.firstName} {person.lastName}
              </Paper>
            </Collapse>
          ))}
        </Box>
      </TransitionGroup>
    </Paper>
  );
};
