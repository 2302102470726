import {
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { NewStepContext } from "./StatusBarSettings";
import { useStepCreate } from "../../hooks/useStepCreate";
import { StepTypeOptions } from "./StepTypeOptions";
import { useSettingsData } from "../../../../hooks/useSettingsData";
import { CATEGORIES, STEP_TYPES } from "./utils";

export const CreateNewStep = () => {
  const { newStep, handleChange, clearNewStep } =
    React.useContext(NewStepContext);
  const { data: settings } = useSettingsData();
  const { mutate: createStep, isSuccess } = useStepCreate();
  const [error, setError] = React.useState({
    name: false,
    description: false,
    type: false,
    category: false,
    active: false,
  });
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    setError((prev) => ({
      name: !newStep.name
        ? "Name cannot be empty."
        : settings.statusSteps.some((param) => param.name === newStep.name)
        ? "Name already exists."
        : false,
      description: !newStep.description
        ? "Description cannot be empty."
        : false,
      active: prev.active,
    }));
  }, [newStep]);
  React.useEffect(() => {
    if (isSuccess) {
      clearNewStep();
      setLoading(false);
    }
  }, [isSuccess]);

  const handleAdd = () => {
    if (!newStep.active) {
      handleChange({ field: "active", value: true });
      return;
    }
    if (error.name || error.description || error.type) {
      setError((prev) => ({ ...prev, active: true }));
      return;
    }
    setLoading(true);
    createStep({ pack: newStep });
  };
  const handleCancel = () => {
    clearNewStep();
  };

  if (!settings) return null;

  return (
    <Box>
      <Grid
        container
        spacing={1}
        sx={{
          height: newStep.active ? "195px" : 0,
          width: "100%",
          transition: "height 0.5s",
          overflow: "hidden",
        }}
      >
        <Grid item xs={3}>
          <TextField
            error={error.active && error.name}
            helperText={error.active ? error.name : ""}
            value={newStep.label}
            label="Label"
            size="small"
            fullWidth
            onChange={(e) =>
              handleChange({ field: "name", value: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={9}>
          <TextField
            error={error.active && error.description}
            helperText={error.active ? error.description : ""}
            value={newStep.description}
            label="Description"
            size="small"
            fullWidth
            onChange={(e) =>
              handleChange({ field: "description", value: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl size="small" fullWidth>
            <InputLabel>Prompt user to:</InputLabel>
            <Select
              label="Prompt user to:"
              value={newStep.type}
              onChange={(e) =>
                handleChange({ field: "type", value: e.target.value })
              }
            >
              {STEP_TYPES.map((type) => (
                <MenuItem key={type.name} value={type.name} dense>
                  {type.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
          }}
        >
          <FormControl sx={{ width: "30%" }} size="small">
            <InputLabel>Category</InputLabel>
            <Select
              label="Category"
              value={newStep.category}
              onChange={(e) =>
                handleChange({ field: "category", value: e.target.value })
              }
            >
              {CATEGORIES.map((category) => (
                <MenuItem key={category} value={category} dense>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <StepTypeOptions step={newStep} handleChange={handleChange} />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", p: 1 }}>
        <Fade in={newStep.active}>
          <Button
            variant="outlined"
            color="error"
            sx={{ mt: 1, mr: 1 }}
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
        </Fade>

        <Button
          variant="outlined"
          onClick={() => handleAdd()}
          sx={{ mt: 1 }}
          style={{
            minWidth: "0px",
            maxWidth: newStep.active ? "7%" : "25%",
            transition: "width 0.5s",
            overflow: "hidden",
          }}
          disabled={
            (newStep.active &&
              error.name &&
              error.description &&
              error.originalValue) ||
            loading
          }
        >
          {loading ? (
            <>
              <CircularProgress size={20} />
              Loading
            </>
          ) : newStep.active ? (
            "Add"
          ) : (
            "Add New"
          )}
        </Button>
      </Box>
    </Box>
  );
};
