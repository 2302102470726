import {
  FormControl,
  InputLabel,
  ListSubheader,
  Select,
  useTheme,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { ENTRY_TYPES } from "./entryTypes";
import { FinancialMenuItem } from "./FinancialEntryTypeMenuItem";

// this is a textField that receives an edit prop and if true, it will be editable

export const ControlledFinancialSelect = (props) => {
  const theme = useTheme();
  return (
    <FormControl size={props.size} fullWidth>
      <InputLabel>{props.label}</InputLabel>
      <Select
        {...props}
        label={props.label}
        fullWidth
        disabled={!props.editing}
        SelectDisplayProps={{
          style: {
            "-webkit-text-fill-color": theme.palette.text.primary,
          },
        }}
        value={props.value}
        onChange={(e) =>
          props.onChange({ field: props.field, value: e.target.value })
        }
        onFocus={(e) => (props.selectallonfocus ? e.target.select() : null)}
      >
        <ListSubheader>Income</ListSubheader>
        {ENTRY_TYPES.filter((e) => e.className === "income").map((entry) => (
          <FinancialMenuItem
            className="income"
            key={entry.value}
            value={entry.value}
          >
            {entry.label}
          </FinancialMenuItem>
        ))}
        <ListSubheader>Expenses</ListSubheader>
        {ENTRY_TYPES.filter((e) => e.className === "expense").map((entry) => (
          <FinancialMenuItem
            key={entry.value}
            value={entry.value}
            className="expense"
          >
            {entry.label}
          </FinancialMenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

ControlledFinancialSelect.propTypes = {
  editing: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  menuitems: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  selectallonfocus: PropTypes.bool,
  size: PropTypes.string,
};
