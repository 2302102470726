import React from "react";
import { useQuery } from "react-query";
import { getSuppliers } from "../api/suppplierApi";
import { UserContext } from "../App";

export const useSuppliersData = (onSuccess, onError) => {
  const { user } = React.useContext(UserContext);
  return useQuery("suppliers", () => getSuppliers(user.id), {
    onSuccess,
    onError,
    staleTime: 1000 * 60 * 10, // 10 minutes
  });
};
