import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  ListSubheader,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useNewFinancial } from "../hooks/useNewFinancial";
import { Add } from "@mui/icons-material";
import { useFinancialCreate } from "../hooks/useFinancialCreate";
import { FinancialMenuItem } from "../utils/FinancialEntryTypeMenuItem";
import { ENTRY_TYPES } from "../utils/entryTypes";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CurrencyTextField from "../../../utils/CurrencyTextField";

export const AddFinancial = ({ type, project, data }) => {
  const [open, setOpen] = React.useState(false);
  const { newFinancial, handleFinancialChange, clearFinancial } =
    useNewFinancial(data);

  // const [file, setFile] = React.useState();
  // const onFileSelect = ({ target }) => {
  //   setFile(target.files[0]);
  // };
  const { mutate: createFinancial, isSuccess: isCreateSuccess } =
    useFinancialCreate(project);
  const upload = () => {
    handleFinancialChange({ field: "type", value: type });
    createFinancial({
      pack: {
        ...newFinancial,
        project: project ? project : "",
        type: type ? type : newFinancial.type,
      },
    });
    clearFinancial();
  };

  React.useEffect(() => {
    if (isCreateSuccess) {
      setOpen(false);
    }
  }, [isCreateSuccess]);

  return (
    <div>
      <Button
        id="financial-new"
        startIcon={<Add />}
        variant="contained"
        onClick={() => setOpen((prev) => !prev)}
      >
        Add
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <Paper>
          <DialogTitle>Add Entry</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Name"
              value={newFinancial.name}
              sx={{ mt: 2 }}
              onChange={(e) =>
                handleFinancialChange({
                  field: "name",
                  value: e.target.value,
                })
              }
            />
            <Box sx={{ display: "flex", alignItems: "center", mt: 2, gap: 1 }}>
              <CurrencyTextField
                fullWidth
                label="Amount"
                value={newFinancial.amount}
                onChange={(e) =>
                  handleFinancialChange({
                    field: "amount",
                    value: e.target.value,
                  })
                }
                helperText="Enter a negative number for expenses."
              />

              {type ? null : (
                <FormControl sx={{ width: "35%", mb: "auto" }}>
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={newFinancial.type}
                    label="Type"
                    onChange={(e) =>
                      handleFinancialChange({
                        field: "type",
                        value: e.target.value,
                      })
                    }
                  >
                    <ListSubheader>Income</ListSubheader>
                    {ENTRY_TYPES.filter((e) => e.className === "income").map(
                      (entry) => (
                        <FinancialMenuItem
                          className="income"
                          key={entry.value}
                          value={entry.value}
                        >
                          {entry.label}
                        </FinancialMenuItem>
                      )
                    )}
                    <ListSubheader>Expenses</ListSubheader>
                    {ENTRY_TYPES.filter((e) => e.className === "expense").map(
                      (entry) => (
                        <FinancialMenuItem
                          key={entry.value}
                          value={entry.value}
                          className="expense"
                        >
                          {entry.label}
                        </FinancialMenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              )}
            </Box>
            <TextField
              fullWidth
              sx={{ mt: 2 }}
              label="Memo"
              value={newFinancial.memo}
              onChange={(e) =>
                handleFinancialChange({
                  field: "memo",
                  value: e.target.value,
                })
              }
            />

            <DatePicker
              sx={{ mt: 2, width: "100%" }}
              label="Date"
              value={dayjs(newFinancial.date)}
              onChange={(value) =>
                handleFinancialChange({
                  field: "date",
                  value: value,
                })
              }
              slotProps={{
                textField: {
                  helperText:
                    "Selecting a future date will set it as the due date.",
                },
              }}
            />

            {/* <Box sx={{ mt: 2 }}>
              <label>
                <Input
                  sx={{ display: "none" }}
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={(e) => onFileSelect(e)}
                />
                {file ? (
                  <Check />
                ) : (
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<Upload />}
                  >
                    File
                  </Button>
                )}
              </label>
            </Box> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen((prev) => !prev)}>Cancel</Button>
            <Button onClick={() => upload()}>Add</Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
};

AddFinancial.propTypes = {
  type: PropTypes.string,
  project: PropTypes.string,
  data: PropTypes.object,
};
