import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import React from "react";
import PropTypes from "prop-types";
import { useParametersData } from "../../../../hooks/useParametersData";
import { NewOptionContext } from "../../components/OptionNode";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  DragHandle,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
// import { canOpenNodeMenu } from "../../components/utils";
import useStore from "../../components/store";
// import { canOpenNodeMenu } from "../../components/utils";

const selector = (state) => ({
  setNodeToSwitch: state.setNodeToSwitch,
  deleteNode: state.deleteNode,
  duplicateNode: state.duplicateNode,
  collapseNode: state.collapseNode,
  expandNode: state.expandNode,
  options: state.options,
  components: state.components,
});

export const NodeMenu = () => {
  // should let you:
  // - turn node into another
  // - delete node
  // - duplicate node (with a new key and name)
  const { newOption, handleEdit } = React.useContext(NewOptionContext);
  const { data: parameters } = useParametersData();
  // const nodes = useStore((state) => state.nodes);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const {
    setNodeToSwitch,
    deleteNode,
    duplicateNode,
    collapseNode,
    expandNode,
    options,
    components,
  } = useStore(selector);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.preventDefault();
    // if (!canOpenNodeMenu(newOption.nodeKey)) {
    //   return;
    // }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTurnIntoClick = () => {
    setNodeToSwitch(newOption);
    handleClose();
  };

  const handleDeleteClick = () => {
    deleteNode(newOption.nodeKey);
    handleClose();
  };
  const handleDuplicateClick = () => {
    let duplicate = duplicateNode({
      data: { ...newOption },
      options,
      components,
    });
    console.log(duplicate);
    handleClose();
  };

  const handleParameterSelect = (parameter) => {
    handleEdit({
      field: "parameters",
      value: {
        ...parameter,
        hierarchy: ["parent"],
        variable: `${newOption.name}_${parameter.name}`,
      },
    });
  };
  const handleCollapseClick = () => {
    if (!newOption.collapsed) {
      collapseNode(newOption.nodeKey);
    } else {
      expandNode(newOption.nodeKey);
    }
  };

  const disabled = React.useMemo(() => {
    if (newOption._id === "newOption" || newOption.edit) {
      return false;
    } else {
      return newOption.nodeKey.split("-").length > 1;
    }
  }, [newOption]);

  // const isParentEditable = React.useMemo(() => {
  //   const parent = nodes.find(
  //     (n) => n.id === newOption.nodeKey.split("-").slice(0, -1).join("-")
  //   );
  //   if (!parent) return false;
  //   return parent.data.data.edit;
  // }, [newOption]);

  return (
    <div className="drag-handle">
      <IconButton
        id="add-button"
        size="small"
        color="primary"
        variant="outlined"
        aria-controls={open ? "add-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onContextMenu={handleClick}
      >
        <DragHandle />
      </IconButton>
      <Menu
        id="add-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleTurnIntoClick} disabled={false}>
          <ListItemIcon>
            <SwapHorizIcon />
          </ListItemIcon>
          <ListItemText>Turn Into</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDuplicateClick} disabled={true}>
          <ListItemIcon>
            <ControlPointDuplicateIcon />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDeleteClick} disabled={false}>
          <ListItemIcon>
            <DeleteForeverIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleCollapseClick}>
          {newOption.collapsed ? (
            <>
              <ListItemIcon>
                <Visibility />
              </ListItemIcon>
              <ListItemText>Expand</ListItemText>
            </>
          ) : (
            <>
              <ListItemIcon>
                <VisibilityOff />
              </ListItemIcon>
              <ListItemText>Collapse</ListItemText>
            </>
          )}
        </MenuItem>
        <Divider />
        {parameters && (
          <div>
            <Typography sx={{ pl: 1 }}> Source</Typography>
            <MenuItem
              key={"source-qty"}
              disabled={disabled}
              onClick={() =>
                handleParameterSelect({
                  name: "qty",
                  hierarchy: ["newComponent"],
                  value: 1,
                  formula: "",
                  source: true,
                })
              }
            >
              <ListItemIcon>
                {newOption.parameters.find(
                  (p) =>
                    p.name === "qty" && p.hierarchy.length === 1 && p.source
                ) ? (
                  <CheckBox color="primary" />
                ) : (
                  <CheckBoxOutlineBlank />
                )}
              </ListItemIcon>
              <ListItemText>qty</ListItemText>
            </MenuItem>
            {parameters
              .filter((p) => !p.global)
              .map((parameter) => (
                <MenuItem
                  key={parameter.name}
                  onClick={() =>
                    handleParameterSelect({ ...parameter, source: true })
                  }
                  disabled={disabled}
                >
                  <ListItemIcon>
                    {newOption.parameters.find(
                      (p) =>
                        p.name === parameter.name &&
                        p.hierarchy.length === 1 &&
                        p.source
                    ) ? (
                      <CheckBox color="primary" />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )}
                  </ListItemIcon>
                  <ListItemText>{parameter.name}</ListItemText>
                </MenuItem>
              ))}
            <Divider />
            <Typography sx={{ pl: 1 }}> Choice</Typography>
            <MenuItem
              key={"choice-qty"}
              disabled={disabled}
              onClick={() =>
                handleParameterSelect({
                  name: "qty",
                  hierarchy: ["newComponent"],
                  value: 1,
                  formula: "",
                  source: false,
                })
              }
            >
              <ListItemIcon>
                {newOption.parameters.find(
                  (p) =>
                    p.name === "qty" && p.hierarchy.length === 1 && !p.source
                ) ? (
                  <CheckBox color="primary" />
                ) : (
                  <CheckBoxOutlineBlank />
                )}
              </ListItemIcon>
              <ListItemText>qty</ListItemText>
            </MenuItem>
            {parameters
              .filter((p) => !p.global)
              .map((parameter) => (
                <MenuItem
                  key={`choice-${parameter.name}`}
                  onClick={() =>
                    handleParameterSelect({ ...parameter, source: false })
                  }
                  disabled={disabled}
                >
                  <ListItemIcon>
                    {newOption.parameters.find(
                      (p) =>
                        p.name === parameter.name &&
                        p.hierarchy.length === 1 &&
                        !p.source
                    ) ? (
                      <CheckBox color="primary" />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )}
                  </ListItemIcon>
                  <ListItemText>{parameter.name}</ListItemText>
                </MenuItem>
              ))}
          </div>
        )}
      </Menu>
    </div>
  );
};

NodeMenu.propTypes = {
  currentId: PropTypes.string,
  switchFn: PropTypes.func,
  deleteFn: PropTypes.func,
  duplicateFn: PropTypes.func,
};
