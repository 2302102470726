import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

export function NoInventoryOverlay() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        zIndex: 5,
      }}
    >
      <Typography variant="h5" align="center">
        Your inventory items will appear here. You can create one in the Create
        tab.
      </Typography>
    </div>
  );
}

NoInventoryOverlay.propTypes = {
  message: PropTypes.string,
};
