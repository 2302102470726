import { useQuery } from "react-query";
import { getMessages } from "../../../../../api/messageApi";

export const useChatData = (projectId, onSuccess, onError) => {
  return useQuery("messages", () => getMessages(projectId), {
    refetchInterval: 10000,

    onSuccess: (data) => {
      // Cache each option individually

      // Call the provided onSuccess handler
      if (onSuccess) onSuccess(data);
    },
    onError,
    staleTime: Infinity,
    keepPreviousData: true,
  });
};
