/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { ListItemText, MenuItem, Select } from "@mui/material";
import { GridColumnMenu } from "@mui/x-data-grid";
import { DataGridPremium } from "@mui/x-data-grid-premium";

export const ProductsTableComponent = (props) => {
  const { variables } = props;

  console.log(variables);

  const [columns, setColumns] = useState(
    props.columns
      ? props.columns.map((col, i) => ({
          ...col,
          id: i,
          renderHeader: (pros) => {
            if (props.readOnly) {
              return <div>{col.headerName}</div>;
            } else {
              return (
                <HeaderSelect
                  onSelect={handleHeaderSelect}
                  variables={variables}
                  {...pros}
                />
              );
            }
          },
          valueFormatter: variables.find((item) => item.field === col.field)
            .valueFormatter,
        }))
      : [
          {
            field: "name",
            headerName: "Name",
            flex: 1,
            id: 0,
            renderHeader: (props) => {
              return (
                <HeaderSelect
                  onSelect={handleHeaderSelect}
                  variables={variables}
                  {...props}
                />
              );
            },
          },
        ]
  );

  const handleHeaderSelect = (variable, id) => {
    setColumns((prev) =>
      prev.map((col) => (col.id === id ? { ...col, ...variable } : col))
    );
  };

  const addColumn = (id, direction) => {
    const newColumn = {
      field: `column_${columns.length + 1}`,
      flex: 1,
      headerName: "placeholder",
      id: columns.length,
      renderHeader: (props) => {
        return (
          <HeaderSelect
            onSelect={handleHeaderSelect}
            variables={variables}
            {...props}
          />
        );
      },
    };
    setColumns((prev) => {
      let index = prev.findIndex((col) => col.id === id);
      let newColumns = [...prev];
      newColumns.splice(direction == "right" ? index + 1 : index, 0, newColumn);
      return newColumns.map((col, i) => ({ ...col, id: i }));
    });
  };

  const deleteColumn = (id) => {
    if (columns.length === 1) return;
    setColumns((prev) =>
      prev.filter((col) => col.id !== id).map((col, i) => ({ ...col, id: i }))
    );
  };

  React.useEffect(() => {
    console.log(columns);
    props.setColumns(columns);
  }, [columns]);

  return (
    <div style={{ width: "100%" }} contentEditable={false}>
      <DataGridPremium
        autoHeight
        hideFooter
        density="compact"
        columns={columns}
        disableRowSelectionOnClick
        rows={
          props.rows || [
            {
              id: 1,
              name: "Example Product",
              code: "P1",
              price: 100,
              qty: 1,
              total: 100,
            },
          ]
        }
        slots={{
          columnMenu: CustomColumnMenu,
        }}
        slotProps={{
          columnMenu: { addColumn: addColumn, deleteColumn: deleteColumn },
        }}
      />
    </div>
  );
};

ProductsTableComponent.propTypes = {
  readOnly: PropTypes.bool,
  columns: PropTypes.array,
  setColumns: PropTypes.func,
};

function CustomUserItem(props) {
  const { onClick, label } = props;
  console.log(props);
  return (
    <MenuItem onClick={() => onClick(props.colDef.id, props.direction)}>
      <ListItemText>{label}</ListItemText>
    </MenuItem>
  );
}
function CustomColumnMenu(props) {
  console.log(props);
  return (
    <GridColumnMenu
      {...props}
      slots={{
        addColumnLeft: CustomUserItem,
        addColumnRight: CustomUserItem,
        deleteColumn: CustomUserItem,
      }}
      slotProps={{
        addColumnLeft: {
          label: "Add Column Left",
          onClick: props.addColumn,
          direction: "left",
        },
        addColumnRight: {
          label: "Add Column Right",
          onClick: props.addColumn,
          direction: "right",
        },
        deleteColumn: { label: "Delete Column", onClick: props.deleteColumn },
      }}
    />
  );
}
const HeaderSelect = (props) => {
  const { onSelect } = props;
  const handleSelectChange = (e) => {
    let variable = props.variables.find(
      (item) => item.headerName === e.target.value
    );
    onSelect(variable, props.colDef.id);
  };

  console.log(props);

  return (
    <div>
      <Select
        size="small"
        value={props.colDef.headerName}
        inputProps={{
          border: "none",
          "::after": {
            border: "none",
          },
        }}
        sx={{ ".MuiOutlinedInput-notchedOutline": { border: 0 } }}
        IconComponent={() => null}
        placeholder="Select a variable..."
        onChange={handleSelectChange}
        renderValue={(value) => {
          if (value === "placeholder") {
            return "Select a value...";
          } else {
            return value;
          }
        }}
      >
        {props.variables.map((item) => (
          <MenuItem key={item.headerName} value={item.headerName}>
            {item.headerName}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

HeaderSelect.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selectedValue: PropTypes.string,
};
