import React from "react";
import { useQuery } from "react-query";
import { getFiles } from "../../../api/fileApi";
import { UserContext } from "../../../App";

export const useFilesData = (onSuccess, onError) => {
  const { user } = React.useContext(UserContext);
  return useQuery("files", () => getFiles(user.id), {
    onSuccess,
    onError,
  });
};
