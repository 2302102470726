// useEmployeeData
//export const getPersonData = async (id) => {
//    const response = await customerApi.get(`/person/${id}/data`);
//    return response.data;
//  };

import React from "react";
import { useQuery } from "react-query";
import { getPersonData } from "../../../api/peopleApi";
import { UserContext } from "../../../App";

export const useEmployeeData = (onSuccess, onError) => {
  const { user } = React.useContext(UserContext);
  console.log(user);
  return useQuery(["personData", user.id], () => getPersonData(user.id), {
    onSuccess,
    onError,
  });
};
