import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  useTheme,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

// this is a textField that receives an edit prop and if true, it will be editable

export const ControlledEditableInput = (props) => {
  const theme = useTheme();
  return (
    <FormControl size={props.size} fullWidth>
      <InputLabel>
        {props.hideLabel ? (props.editing ? props.label : null) : props.label}
      </InputLabel>

      <OutlinedInput
        {...props}
        label={
          props.hideLabel ? (props.editing ? props.label : null) : props.label
        }
        fullWidth
        disabled={!props.editing}
        inputProps={{
          style: {
            "-webkit-text-fill-color": theme.palette.text.primary,
          },
        }}
        value={props.value}
        onChange={(e) =>
          props.onChange({ field: props.field, value: e.target.value })
        }
        onFocus={(e) => (props.selectallonfocus ? e.target.select() : null)}
      />
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
};

ControlledEditableInput.propTypes = {
  editing: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  selectallonfocus: PropTypes.bool,
  size: PropTypes.string,
  helperText: PropTypes.string,
  hideLabel: PropTypes.bool,
};
