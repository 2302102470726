import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

export function NoSuppliersOverlay() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        zIndex: 5,
      }}
    >
      <Typography variant="h5" align="center" sx={{ width: "45%" }}>
        Your suppliers will appear here. <br />
        You can add one at the top of the page. Afterwards, you can add a
        supplier to a product when you create or edit a product if you want to
        create product orders more easily when managing inventory.
      </Typography>
    </div>
  );
}

NoSuppliersOverlay.propTypes = {
  message: PropTypes.string,
};
