/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Paper,
  Tooltip,
  useTheme,
} from "@mui/material";
import React, { useCallback } from "react";
import ReactFlow, {
  // addEdge,
  Background,
  // useNodesState,
  // useEdgesState,
  useReactFlow,
  ReactFlowProvider,
} from "reactflow";

import // nodes as initialNodes,
// edges as initialEdges,
"./initial-elements";

import "reactflow/dist/style.css";
import "./overview.css";
import ComponentNode from "./ComponentNode";
import { useFlowCreate } from "../hooks/useFlowCreate";
import PropTypes from "prop-types";
import { Variables } from "./Variables";
// import { useCachedComponents } from "../../../hooks/useCachedComponents";
import OptionNode from "./OptionNode";
// import { useOptionsData } from "../../../hooks/useOptionsData";
// import { useComponentsData } from "../../../hooks/useComponentsData";

import useStore from "./store";
import { useShallow } from "zustand/react/shallow";
import { SwitchNodeDialog } from "./SwitchNodeDialog";
import {
  CenterFocusStrong,
  KeyboardBackspace,
  Redo,
  Save,
  Undo,
} from "@mui/icons-material";
import _ from "lodash";
import { CustomIconButton } from "../../../utils/CustomIconButtom";
import OptionEdge from "./OptionEdge";
// import ReactFlowDevTools from "../devTools/Devtools";
// import { useCachedComponents } from "../../../hooks/useCachedComponents";
// import { useCachedOptions } from "../../../hooks/useCachedOptions";

const nodeTypes = {
  component: ComponentNode,
  option: OptionNode,
};

const edgeTypes = {
  optionedge: OptionEdge,
};

export const NodeContext = React.createContext({
  nodes: [],
  setNodes: () => {},
  nodeData: [],
  setNodeData: () => {},
  edges: [],
});

const selector = (state) => ({
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  onConnect: state.onConnect,
  onConnectStart: state.onConnectStart,
  onConnectEnd: state.onConnectEnd,
  setNodes: state.setNodes,
  setEdges: state.setEdges,
  nodeData: state.nodeData,
  setNodeData: state.setNodeData,
  switchNode: state.switchNode,
  deleteNode: state.deleteNode,
  duplicateNode: state.duplicateNode,
  handleQty: state.handleQty,
  reset: state.reset,
  getVariables: state.getVariables,
});

export const WrappedFlow = ({
  handleBack,
  editProduct,
  components,
  options,
}) => {
  return (
    <>
      <ReactFlowProvider>
        <Flow
          editProduct={editProduct}
          handleBack={handleBack}
          components={components}
          options={options}
        />
      </ReactFlowProvider>
    </>
  );
};

WrappedFlow.propTypes = {
  handleBack: PropTypes.func.isRequired,
  editProduct: PropTypes.object,
};

export const Flow = ({ editProduct, handleBack, components, options }) => {
  // eslint-disable-next-line no-unused-vars
  const theme = useTheme();

  const {
    nodes,
    setNodes,
    edges,
    onConnect,
    onConnectStart,
    onConnectEnd,
    onNodesChange,
    onEdgesChange,
    setNodeData,
    nodeData,
    switchNode,
    reset,
    getVariables,
  } = useStore(useShallow(selector));

  const { screenToFlowPosition } = useReactFlow();

  const reactFlowWrapper = React.useRef(null);

  const { undo, redo, clear, futureStates, pastStates } =
    useStore.temporal.getState();

  const [loading, setLoading] = React.useState(false);

  const { mutate: createFlow, isSuccess, isError } = useFlowCreate();

  const [rfInstance, setRfInstance] = React.useState(null);
  const onSave = useCallback(() => {
    if (rfInstance) {
      setLoading(true);

      let flow = rfInstance.toObject();
      flow.nodes = flow.nodes.map((n) => {
        return {
          ...n,
          data: {
            ...n.data,
            data: {
              ...n.data.data,
              imgSrc: null,
              image: null,
              imageData: null,
            },
          },
        };
      });
      let overrides = [];
      nodes.forEach((node) => {
        let n = node.data.data;
        // if (i === 0) return;
        if (n.parameters.find((p) => p.override)) {
          let os = n.parameters.filter((p) => p.override);
          os.forEach((o) => {
            if (o.name === "qty") {
              if (o.hierarchy[1].split("-").length > 1) {
                o.hierarchy = [n.nodeKey, o.hierarchy[1].split("-").at(-1)];
              } else {
                o.hierarchy = [n.nodeKey, o.hierarchy[1]];
              }
            } else {
              o.hierarchy = n.nodeKey.split("-");
            }
          });
          overrides = overrides.concat(os);
        }
      });
      let data = [...nodes];

      overrides.forEach((o) => {
        let index = data[0].data.data.parameterOverrides.findIndex(
          (p) =>
            parseHierarchy(p.hierarchy) ===
              parseHierarchy(o.hierarchy.join("-")) && p.name === o.name
        );
        if (index > -1) {
          data[0].data.data.parameterOverrides[index] = {
            ...o,
            hierarchy: o.hierarchy.join("-"),
          };
        } else {
          data[0].data.data.parameterOverrides.push({
            ...o,
            hierarchy: o.hierarchy.join("-"),
          });
        }
      });

      if (!data[0].data.data.parameterOverrides) {
        data[0].data.data.parameterOverrides = [];
      } else {
        data[0].data.data.parameterOverrides =
          data[0].data.data.parameterOverrides.filter((p) =>
            nodes.find(
              (n) =>
                n.id === parseHierarchy(p.hierarchy) ||
                parseHierarchy([n.id.split("-")[0], n.id.split("-")[2]]) ===
                  parseHierarchy(p.hierarchy)
            )
          );
      }

      // prune out data about components that already exist
      data = data.map((n) => {
        if (n.data.data._id === "newComponent") {
          return n.data.data;
        } else if (n.data.data._id === "newOption") {
          return n.data.data;
        } else if (n.id.split("-").at(-2)?.includes("_")) {
          return n.data.data;
        } else if (n.data.data.edit || n.data.data.duplicate) {
          return n.data.data;
        } else {
          if (Object.hasOwn(n.data.data, "required")) {
            return {
              _id: n.data.data._id,
              nodeKey: n.id,
              required: n.data.data.required,
            };
          } else return { _id: n.data.data._id, nodeKey: n.id };
        }
      });
      // let componentsWithoutImages = data.filter((d) => !d.image);
      // if (componentsWithoutImages.length > 0) {
      //   console.error(

      createFlow({ pack: { flow: JSON.stringify(flow), nodeData: data } });
    }
  }, [nodes, rfInstance]);

  React.useEffect(() => {
    if (isSuccess) {
      let node = nodes[0];
      setLoading(false);
      switchNode({
        oldNodeId: nodes[0].id,
        newNodeData: {
          ...components.find((c) => c.name === node.data.data.name),
          edit: true,
          freshCreate: true,
        },
        components,
        options,
      });
    }
  }, [isSuccess]);

  const onInit = (reactFlowInstance) => {
    reset();

    setRfInstance(reactFlowInstance);
    if (editProduct.duplicate) {
      editProduct.nodeKey = "parent";
      editProduct.flowData = editProduct.flowData.replaceAll(
        editProduct._id,
        "parent"
      );
      switchNode({
        oldNodeId: "parent",
        newNodeData: {
          ...editProduct,
          name: `${editProduct.name} Copy`,
          duplicate: true,
        },
        components,
        options,
      });
    } else if (editProduct._id) {
      switchNode({
        oldNodeId: "parent",
        newNodeData: { ...editProduct, edit: true },
        components,
        options,
      });
    }
    clear();
  };

  const focusNode = (nodeId) => {
    rfInstance.fitView({
      nodes: [{ id: nodeId }],
      duration: 500,
    });
  };

  const [error, setError] = React.useState({ disabled: false, tooltip: "" });

  React.useEffect(() => {
    setError(
      isSaveButtonDisabled({
        nodes,
        components,
        options,
        edges,
        variables: getVariables(),
      })
    );
  }, [nodes]);

  React.useEffect(() => {
    if (isError) {
      handleBack();
    }
  }, [isError]);

  const returnToComponents = () => {
    handleBack();
    clear();
  };

  // console.error({ nodeData, nodes, options, edges, edgeTypes });
  const canUndo = !!pastStates.length;
  const canRedo = !!futureStates.length;

  if (!options) return null;

  return (
    <div>
      <Paper
        sx={{
          top: 63,
          left: 64,
          position: "fixed",
          height: "calc(100vh - 64px)",
          width: "calc(100vw - 64px)",
          display: "flex",
          gap: 1,
        }}
        justifyContent={"center"}
        ref={reactFlowWrapper}
      >
        <NodeContext.Provider
          value={{ nodes, setNodes, nodeData, setNodeData, edges }}
        >
          <ReactFlow
            style={{ padding: "12px" }}
            nodes={setNodesSaved(nodes, components, options)}
            edges={edges}
            onNodesChange={onNodesChange}
            onNodesDelete={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            snapToGrid={true}
            onConnectStart={onConnectStart}
            onConnectEnd={(event) => onConnectEnd(event, screenToFlowPosition)}
            onInit={onInit}
            fitView
            proOptions={{ hideAttribution: true }}
            edgeTypes={edgeTypes}
            nodeTypes={nodeTypes}
            nodeOrigin={[0.5, 0]}
          >
            <Button
              onClick={returnToComponents}
              variant="contained"
              sx={{ zIndex: 4, position: "fixed", boxShadow: 6 }}
              startIcon={<KeyboardBackspace />}
            >
              Components
            </Button>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: 1,
              }}
            >
              {/* <ReactFlowDevTools /> */}

              <Paper
                sx={{ display: "flex", gap: 2, p: 1, zIndex: 6, boxShadow: 6 }}
                elevation={6}
              >
                <Variables focusNode={focusNode} />

                <CustomIconButton
                  icon={<CenterFocusStrong />}
                  variant="contained"
                  onClick={() => focusNode(nodes[0].id)}
                />

                <ButtonGroup>
                  <Button
                    onClick={() => undo()}
                    variant="contained"
                    disabled={!canUndo}
                  >
                    <Undo />
                  </Button>
                  <Button
                    onClick={() => redo()}
                    variant="contained"
                    disabled={!canRedo}
                  >
                    <Redo />
                  </Button>
                </ButtonGroup>

                <Tooltip title={error.tooltip} sx={{}}>
                  <Box>
                    <Button
                      variant="contained"
                      onClick={onSave}
                      disabled={error.disabled || loading}
                      startIcon={
                        loading ? <CircularProgress size={24} /> : <Save />
                      }
                    >
                      Save
                    </Button>
                  </Box>
                </Tooltip>
                {/* <ReactFlowDevTools /> */}
              </Paper>
            </Box>

            <Background color="#aaa" gap={16} />
          </ReactFlow>
        </NodeContext.Provider>
        <SwitchNodeDialog />
        {/* <Dialog open={switchNodeOpen} onClose={handleSwitchNodeClose}>
          <Paper>
            {}
          </Paper>
        </Dialog> */}
      </Paper>
    </div>
  );
};

Flow.propTypes = {
  editProduct: PropTypes.object,
  handleBack: PropTypes.func,
};

const isSaveButtonDisabled = ({
  nodes,
  components,
  options,
  edges,
  variables,
}) => {
  let disabled = false;
  let tooltip = "";

  nodes.forEach((node) => {
    let n = node.data.data;
    if (n._id === "newComponent" || n.duplicate) {
      if ([...components, ...options].find((c) => c.name === n.name)) {
        disabled = true;
        tooltip = `Component named "${n.name}" already exists.`;
      }
    }
    if (Object.hasOwn(n, "required") && n.nodeKey.split("-").length > 1) {
      let connectingEdges = edges.filter((e) => e.target === n.nodeKey);
      if (connectingEdges.length === 0) {
        disabled = true;
        tooltip = `Option "${n.name}" is not connected to any components.`;
      }
    }
  });

  if (nodes.every((n) => n.data.data.saved)) {
    disabled = true;
    tooltip = "No changes to save.";
  }

  if (hasDuplicateNames(variables)) {
    disabled = true;
    tooltip = "Variable names must be unique.";
  }

  return { disabled, tooltip };
};

const setNodesSaved = (nodes, components, options) => {
  return nodes.map((n) => {
    if (
      n.data.data._id === "newComponent" ||
      n.data.data._id === "newOption" ||
      n.data.data.duplicate
    ) {
      n.data.data.saved = false;
    } else {
      let found = _.cloneDeep(
        components.find((c) => c._id === n.data.data._id)
      );
      if (!found) {
        found = _.cloneDeep(options.find((o) => o._id === n.data.data._id));
      }
      if (!found) {
        n.data.data.saved = false;
      } else {
        // if there are parameter overrides that are already saved, remove them
        if (nodes[0].data.data.parameterOverrides) {
          let overrides = nodes[0].data.data.parameterOverrides.filter((p) =>
            p.name == "qty"
              ? n.data.data.subcomponents.includes(parseHierarchy(p.hierarchy))
              : parseHierarchy(p.hierarchy) === n.id
          );
          if (overrides.length > 0) {
            overrides.forEach((o) => {
              let index = found.parameters.findIndex((p) =>
                o.name === "qty"
                  ? o.hierarchy.at(-1) === p.hierarchy.at(-1)
                  : p.name === o.name
              );
              if (index > -1) {
                found.parameters[index] = {
                  ...o,
                  hierarchy: found.parameters[index].hierarchy,
                };
              } else {
                found.parameters.push({
                  ...o,
                  hierarchy:
                    o.name === "qty" ? ["parent", o.hierarchy.at(-1)] : n.id,
                });
              }
            });
          }
        }
        let strippedFound = stripData(found);
        let strippedN = stripData(n.data.data);

        const saved = _.isEqual(strippedN, strippedFound);
        n.data.data.saved = saved;
      }
    }
    return n;
  });
};

const stripData = (data) => {
  if (Object.hasOwn(data, "required")) {
    return {
      name: data.name,
      subcomponents: data.subcomponents.map((s) =>
        s._id ? s._id : s.split("-").at(-1)
      ),
      required: data.required,
      multiple: data.multiple,
      parameters: data.parameters,
    };
  } else {
    return {
      name: data.name,
      categories: data.categories,
      subcomponents: data.subcomponents.map((s) =>
        s._id ? s._id : s.split("-").at(-1)
      ),
      options: data.options.map((o) => (o._id ? o._id : o.split("-").at(-1))),
      parameters: data.parameters.map((p) => {
        return {
          formula: p.formula ? p.formula : "",
          name: p.name,
          hierarchy: p.hierarchy,
          value: Number(p.value),
          variable: p.variable,
        };
      }),
      price: data.price,
      priceModifier: data.priceModifier,
      isProduct: data.isProduct,
      parameterOverrides: data.parameterOverrides,
      cost: data.cost,
      image: data.image,
      code: data.code,
    };
  }
};

const hasDuplicateNames = (array) => {
  const nameCounts = array.reduce((counts, obj) => {
    if (obj.variable in counts) {
      counts[obj.name]++;
    } else {
      counts[obj.name] = 1;
    }
    return counts;
  }, {});

  return Object.values(nameCounts).some((count) => count > 1);
};

const parseHierarchy = (hierarchy) => {
  if (typeof hierarchy === "string") {
    return hierarchy;
  } else {
    return hierarchy.join("-");
  }
};
