import { useQueryClient } from "react-query";

export const useCachedParameters = () => {
  const queryClient = useQueryClient();
  const query = queryClient.getQueryData({
    queryKey: "parameters",
  });

  let parameters = query;
  // Ensure parameters is an array before filtering
  if (!Array.isArray(parameters)) {
    return [];
  }

  // Filter out any undefined entries in case some components are not cached
  return parameters.filter((p) => p !== undefined);
};
