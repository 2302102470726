import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Avatar,
} from "@mui/material";
import {
  StarBorder,
  AttachMoney,
  Group,
  Description,
  Store,
  Work,
} from "@mui/icons-material";

const features = [
  {
    title: "Product Components",
    description:
      "Craft intricate products with our detailed component management. From main assemblies down to the tiniest screws, ensure every part is accounted for. Streamline your manufacturing process with precision.",
    icon: <Store />,
  },
  {
    title: "Financial Tracking",
    description:
      "Gain unparalleled insights into your finances. Our comprehensive cash flow chart offers a clear view of your revenue streams, expenses, and overall financial health. Make informed decisions with real-time data.",
    icon: <AttachMoney />,
  },
  {
    title: "In Development: Customer Management",
    description:
      "COMING SOON: Build and nurture customer relationships with our robust CRM tools. Segment your audience, track interactions, and provide personalized experiences. Turn leads into loyal customers.",
    icon: <Group />,
  },
  {
    title: "Document Templating",
    description:
      "Simplify document creation with customizable templates. Seamlessly integrate with DocuSign for electronic signatures, ensuring your documents are both professional and legally binding.",
    icon: <Description />,
  },
  {
    title: "Supplier Management",
    description:
      "Forge strong supplier relationships with our comprehensive management tools. Track orders, negotiate deals, and ensure timely deliveries. Optimize your supply chain for efficiency and reliability.",
    icon: <StarBorder />,
  },
  {
    title: "Project Management",
    description:
      "Drive projects to success with our advanced management features. Set milestones, assign tasks, and monitor progress in real-time. With integrated event logs, never miss a beat and ensure timely project completion.",
    icon: <Work />,
  },
];

export const Features = () => {
  const [selectedFeature, setSelectedFeature] = useState(features[0]);

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography variant="h3" fontWeight={"bold"}>
          Features
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
        <Paper elevation={3} sx={{ flex: 1, mr: 3, p: 3 }}>
          <List component="nav">
            {features.map((feature, index) => (
              <ListItem
                onClick={() => setSelectedFeature(feature)}
                key={index}
                button
                sx={{
                  mb: 1,
                  borderRadius: 1,
                  backgroundColor:
                    selectedFeature.title == feature.title
                      ? "primary.light"
                      : "default",
                  "&:hover": {
                    backgroundColor:
                      selectedFeature.title == feature.title
                        ? "primary.dark"
                        : "default",
                  },
                }}
              >
                <Avatar sx={{ mr: 2, bgcolor: "primary.main" }}>
                  {feature.icon}
                </Avatar>
                <ListItemText primary={feature.title} />
              </ListItem>
            ))}
          </List>
        </Paper>
        <Paper elevation={3} sx={{ flex: 2, p: 3 }}>
          <Typography variant="h5" fontWeight={"bold"}>
            {selectedFeature.title}
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            {selectedFeature.description}
          </Typography>
        </Paper>
      </Box>
    </Box>
  );
};
