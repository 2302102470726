import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../../../Content";
import { updateProject } from "../../../api/projectApi";
import { UserContext } from "../../../App";

const socket = new WebSocket("wss://www.manufacture.app/");

export function useProjectUpdate(id, type) {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { user } = React.useContext(UserContext);

  return useMutation({
    mutationFn: ({ id, pack }) => {
      return updateProject({ id, pack });
    },
    onSuccess: (data) => {
      console.log(data);
      // if (data.type[0] == "products" && data.type[1] == "add") {
      //   queryClient.setQueryData(["project", data.result._id], {
      //     ...oldData,
      //     ...data.result,
      //     products: data.result.products,
      //   });
      // } else {
      socket.send(
        JSON.stringify({
          pack: {
            title: data.result.message.title,
            content: data.result.message.content,
            project: data.result._id,
            type: "system_log",
            user: user.id,
          },
        })
      );

      if (data.type[0] == "people") {
        if (data.type[1] == "add") {
          queryClient.setQueryData(["project", data.result._id], (oldData) => ({
            ...oldData,
            people: [
              ...oldData.people,
              data.result.people[data.result.people.length - 1],
            ],
          }));
        } else {
          queryClient.setQueryData(["project", data.result._id], (oldData) => ({
            ...oldData,
            people: data.result.people,
            tasks: data.result.tasks,
          }));
        }
      } else {
        queryClient.setQueryData(["project", data.result._id], (oldData) => ({
          ...oldData,
          ...data.result,
        }));
        queryClient.setQueryData(["projects"], (oldData) => {
          return oldData.map((project) => {
            if (project._id == data.result._id) {
              return { ...project, ...data.result };
            }
            return project;
          });
        });
      }
      // }
      if (type == "noSnack") return;

      setSnack({
        open: true,
        message: "Project updated.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Project could not be updated.",
        severity: "error",
      });
    },
  });
}
