import {
  Box,
  Button,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { AccountCircle, OpenInNew } from "@mui/icons-material";
import PromoImage from "../public/Promo01.png";
import { amber, grey } from "@mui/material/colors";
import { PlansAndPricing } from "../features/frontPage/components/PlansAndPricing";
import { Features } from "../features/frontPage/components/Features";
import { Footer } from "../features/frontPage/components/Footer";
import "../features/frontPage/utils/DotBackground.css";
// import { getSub } from "../api/authApi";
import { Account } from "../features/frontPage/components/Account";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import { Signup } from "../features/signup/components/Signup";
import { getSettings } from "../api/settingsApi";
import { checkSubscription } from "../utils/checkSubscription";
const AMBER = "#ffc400";

export const Main = ({ user }) => {
  const { setUser } = React.useContext(UserContext);
  const [settings, setSettings] = React.useState(null);
  const theme = createTheme({
    palette: {
      // palette values for light mode
      primary: amber,
      divider: amber[200],
      text: {
        primary: grey[900],
        secondary: grey[800],
      },
    },
  });
  const [page, setPage] = React.useState("home");
  React.useEffect(() => {
    if (!user.id) return;
    getSettings(user.id).then((res) => {
      setSettings(res);
    });
  }, [user]);

  const handleClick = (id) => {
    if (page !== "home") {
      setPage("home");
      waitForElm("#" + id).then((elm) => {
        elm.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      });
    } else {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  const logout = async () => {
    localStorage.removeItem("user");
    setUser(null);
  };

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!user.id) {
      setPage("home");
    }
  }, [user]);

  const sub = settings ? checkSubscription(settings) : [];
  console.log(user);
  return (
    <ThemeProvider theme={theme}>
      <div className="dotBackground">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            mb: 8,
          }}
        >
          <Box sx={{ display: "flex", gap: 2, alignItems: "end" }}>
            <Typography
              variant="h2"
              sx={{ display: "flex", alignItems: "end" }}
              onClick={() => setPage("home")}
            >
              [MANUFACTURE]
              <Typography variant="h4" sx={{ ml: -1.4, color: AMBER }}>
                .app
              </Typography>
            </Typography>
            {page == "home" && (
              <>
                <Button
                  sx={{ color: "black" }}
                  onClick={() => handleClick("features")}
                >
                  Features
                </Button>
                <Button
                  sx={{ color: "black" }}
                  onClick={() => handleClick("plans")}
                >
                  Plans
                </Button>
                <Button sx={{ color: "black" }}>Support</Button>
              </>
            )}
          </Box>

          <Box>
            {(user?.id && sub.length > 0) ||
            (user?.id && user.person?.role !== "Admin") ? (
              <Button
                sx={{ borderColor: AMBER, color: "black", mr: 2 }}
                variant="outlined"
                onClick={() => navigate("/app")}
                startIcon={<OpenInNew />}
              >
                app
              </Button>
            ) : null}
            {!user.id && page == "home" ? (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to="/login"
                  sx={{ mr: 2 }}
                >
                  Log In
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setPage("signup")}
                >
                  Sign Up
                </Button>
              </>
            ) : (
              user.id && (
                <Button
                  startIcon={<AccountCircle />}
                  sx={{ color: "black" }}
                  onClick={() => setPage("account")}
                >
                  My Account
                </Button>
              )
            )}
          </Box>
        </Box>
        <br />

        {page == "account" ? (
          <Account user={user} logout={logout} settings={settings} />
        ) : page == "signup" ? (
          <Signup setPage={setPage} />
        ) : (
          <>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
              <Grid container sx={{ width: "60%" }}>
                <Grid item xs={6}>
                  <Typography variant="h2" fontWeight={"bold"}>
                    The best way to manage your modern manufacturing business.
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <img src={PromoImage} alt="Promo Image" height={700} />
                </Grid>
              </Grid>
            </Box>
            <div id="features">
              <Features />
            </div>
            <div id="plans">
              <PlansAndPricing user={user} setPage={setPage} />
            </div>
            <Footer />
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

Main.propTypes = {
  user: PropTypes.object,
};

function waitForElm(selector) {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        resolve(document.querySelector(selector));
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}
