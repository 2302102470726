import { Paper, TextField } from "@mui/material";
import React from "react";
import { NewComponentContext } from "../../components/ComponentNode";

export const Code = () => {
  const { newComponent, handleEdit } = React.useContext(NewComponentContext);

  const handleChange = (e) => {
    handleEdit({
      field: "code",
      value: e.target.value,
    });
  };

  return (
    <Paper sx={{ mt: 1 }}>
      <TextField
        fullWidth
        size="small"
        label="Code"
        value={newComponent.code}
        onChange={handleChange}
      />
    </Paper>
  );
};
