import React from "react";
import { Box, Typography, Link, Grid } from "@mui/material";

export const Footer = () => {
  return (
    <Box sx={{ backgroundColor: "#f7f7f7", mt: 4, py: 3, px: 3 }}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>
            Quick Links
          </Typography>
          <ul style={{ listStyle: "none", padding: 0 }}>
            <li>
              <Link href="#features" color="inherit">
                Features
              </Link>
            </li>
            <li>
              <Link href="#pricing" color="inherit">
                Plans & Pricing
              </Link>
            </li>
            {/* Add more links as needed */}
          </ul>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>
            Contact
          </Typography>
          <Typography>
            Email:{" "}
            <Link href="mailto:support@example.com" color="inherit">
              support@example.com
            </Link>
          </Typography>
          <Typography>Phone: +1 (123) 456-7890</Typography>
          {/* Add more contact details as needed */}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" gutterBottom>
            Follow Us
          </Typography>
          {/* You can add social media icons and links here */}
          <Link href="#" color="inherit" style={{ marginRight: "10px" }}>
            Facebook
          </Link>
          <Link href="#" color="inherit" style={{ marginRight: "10px" }}>
            Twitter
          </Link>
          {/* Add more social links as needed */}
        </Grid>
      </Grid>
      <Box sx={{ mt: 3, textAlign: "center" }}>
        <Typography variant="body2" color="textSecondary">
          © {new Date().getFullYear()} Manufacture.app. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};
