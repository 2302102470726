import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Typography,
} from "@mui/material";
const CLOUDFRONT_URL = "https://cdn.manufacture.app/";

export const Product = ({ product, handleSelect, selected, viewOnly }) => {
  return (
    <Card
      elevation={3}
      sx={{
        width: 240,
        height: 200,
        display: "flex",
        pb: 0,
        flexDirection: "column",
        justifyContent: "flex-end",
        cursor: "pointer",
      }}
      onClick={() => handleSelect(product._id)}
    >
      {!viewOnly && (
        <Box sx={{ position: "static" }}>
          <Checkbox checked={selected} />
        </Box>
      )}

      <CardMedia
        sx={{ height: 150 }}
        image={`${CLOUDFRONT_URL}${product.image}`}
        title="image"
      />
      <CardContent sx={{ pt: 1, pb: 0, ":last-child": { pb: 0 } }}>
        <Typography
          variant="h5"
          component="div"
          flexWrap={"wrap"}
          sx={{ pb: 1 }}
        >
          {product.name}
        </Typography>
      </CardContent>
    </Card>
  );
};

Product.propTypes = {
  product: PropTypes.object.isRequired,
  handleSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  viewOnly: PropTypes.bool,
};
