import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { UNITS, useParameterDelete, useParametersUpdate } from "./utils";
import _ from "lodash";
import { useParametersData } from "../../../../hooks/useParametersData";
import { ControlledEditableInput } from "../../../../utils/ControlledEditableInput";
import DeleteButton from "../../../../utils/DeleteButton";
import AddButton from "../../../../utils/AddButton";

export const ParameterDetails = ({ row }) => {
  const { data: parameters } = useParametersData();
  const [editData, setEditData] = React.useState({
    name: row.name,
    description: row.description,
    value: row.value,
    unit: row.unit,
    global: row.global,
    arrayValues: row.arrayValues,
  });
  const [error, setError] = React.useState({
    name: false,
    description: false,
    value: false,
  });
  const { mutate: updateParameter, isLoading: updateLoading } =
    useParametersUpdate();
  const { mutate: deleteParameter, onSuccess: onDeleteSuccess } =
    useParameterDelete();

  React.useEffect(() => {
    setError(() => ({
      name: !editData.name
        ? "Name cannot be empty."
        : parameters.some((param) => param.name === editData.name) &&
          row.name !== editData.name
        ? "Name already exists."
        : false,
      description: !editData.description
        ? "Description cannot be empty."
        : false,
      value: !editData.value ? "Value cannot be empty." : false,
    }));
  }, [editData]);

  const onEdit = ({ field, value }) => {
    setEditData((prev) => ({ ...prev, [field]: value }));
  };

  const handleDelete = () => {
    deleteParameter(row._id);
  };
  const handleSave = () => {
    updateParameter({
      id: row._id,
      pack: editData,
    });
  };
  if (!parameters) return null;
  return (
    <Paper sx={{ p: 2, height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 1,
          flexWrap: "wrap",
        }}
      >
        <Box sx={{ width: "35%" }}>
          <ControlledEditableInput
            label="Name"
            field="name"
            editing={true}
            error={error.name}
            size="small"
            selectallonfocus
            value={editData.name}
            onChange={onEdit}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <ControlledEditableInput
            label="Description"
            editing={true}
            size="small"
            error={error.description}
            value={editData.description}
            field="description"
            selectallonfocus
            onChange={onEdit}
          />
        </Box>
        <Box sx={{ width: "80%" }}>
          <ControlledEditableInput
            label="Value"
            editing={true}
            size="small"
            error={error.value}
            value={editData.value}
            field="value"
            selectallonfocus
            onChange={onEdit}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <FormControl fullWidth size="small">
            <InputLabel>Unit</InputLabel>
            <Select
              label="Unit"
              value={editData.unit}
              onChange={(e) => onEdit({ field: "unit", value: e.target.value })}
            >
              <MenuItem value="" dense>
                <em>None</em>
              </MenuItem>
              {UNITS.map((unit) => (
                <MenuItem key={unit} value={unit} dense>
                  {unit}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <Box sx={{ display: "block" }}>
          <RadioGroup
            value={editData.global}
            onChange={(e) => onEdit({ field: "global", value: e.target.value })}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="Global"
              sx={{ mr: 2 }}
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Component Specific"
            />
          </RadioGroup>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <DeleteButton onDelete={handleDelete} loading={onDeleteSuccess} />
          <Box>
            <Typography variant="caption" color="error" sx={{ mr: 1 }}>
              {error.name || error.description || error.value}
            </Typography>
            {/* <Button
              variant="outlined"
              onClick={() => handleSave()}
              disabled={error.name || error.description || error.value}
            >
              Save
            </Button> */}
            <AddButton
              variant="outlined"
              onClick={handleSave}
              loading={updateLoading}
              disabled={
                error.name ||
                error.description ||
                error.value ||
                _.isEqual(
                  {
                    name: row.name,
                    description: row.description,
                    value: row.value,
                    unit: row.unit,
                    global: row.global,
                    arrayValues: row.arrayValues,
                  },
                  editData
                )
              }
              buttonText="Save"
            />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

ParameterDetails.propTypes = {
  row: PropTypes.object.isRequired,
};
