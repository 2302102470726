import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../../../Content";
import { createTemplate } from "../../../api/templateApi";

export function useTemplateCreate() {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  return useMutation({
    mutationFn: ({ pack }) => {
      // let formData = new FormData();
      // for (let [key, value] of Object.entries(pack)) {
      //   formData.append(key, key == "file" ? value : JSON.stringify(value));
      // }
      return createTemplate(pack);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("templates");
      setSnack({
        open: true,
        message: "Template created.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Template could not be created.",
        severity: "error",
      });
    },
  });
}
