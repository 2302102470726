import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useSettingsData } from "../../../../../hooks/useSettingsData";
import PropTypes from "prop-types";
import { Add, Cancel } from "@mui/icons-material";
import { useProjectUpdate } from "../../../hooks/useProjectUpdate";
import { SettingsDialogContext } from "../../../../../Content";
import { SelectedProjectContext } from "../../ProjectView";

export const AddTasks = () => {
  const project = React.useContext(SelectedProjectContext);
  const [open, setOpen] = React.useState(false);
  const [addedTasks, setAddedTasks] = React.useState([
    ...project.tasks.filter((t) => t.custom),
  ]);
  const [adding, setAdding] = React.useState({ milestone: null, task: null });
  const { data: settings } = useSettingsData();

  const { mutate: updateProject } = useProjectUpdate();

  const [allMilestones, setAllMilestones] = React.useState(
    settings.milestones?.map((m) => ({
      ...m,
      selected: !!project.milestones.find((ms) => ms.name == m.name),
      completed: project.milestones.find((ms) => ms.name == m.name)?.completed,
      started: project.tasks.some((t) => t.milestone == m.name && t.completed),
      tasks: m.tasks?.map((t) => ({
        name: t,
        milestone: m.name,
        selected: !!project.tasks.find((task) => task.name == t),
        completed: project.tasks.find((task) => task.name == t)?.completed,
      })),
    })) || []
  );

  //   const { milestones, tasks } = project;

  const handleCheck = ({ milestone, task, event }) => {
    if (milestone && !task) {
      setAllMilestones((prev) => {
        let newMilestones = [...prev];
        let newMilestone = newMilestones.find((m) => m.name == milestone.name);
        newMilestone.selected = event.target.checked;
        newMilestone.tasks.forEach((t) => {
          t.selected = event.target.checked;
        });
        return newMilestones;
      });
    } else if (task && milestone) {
      setAllMilestones((prev) => {
        let newMilestones = [...prev];
        let newMilestone = newMilestones.find((m) => m.name == milestone.name);
        let newTask = newMilestone.tasks.find((t) => t.name == task.name);
        newTask.selected = event.target.checked;
        return newMilestones;
      });
    }
  };

  const handleConfirm = () => {
    let newMilestones = allMilestones.filter((m) => m.selected);
    let newTasks = allMilestones
      .filter((m) => m.selected)
      .map((m) => m.tasks.filter((t) => t.selected))
      .flat();
    newTasks = [
      ...newTasks,
      ...addedTasks.map((t) => ({
        name: t.name,
        milestone: t.milestone,
        custom: true,
      })),
    ];
    let pack = {
      milestones: [],
      tasks: [],
    };
    newTasks.forEach((t) => {
      let found = project.tasks.find((task) => task.name == t.name);
      if (!found) {
        pack.tasks.push({
          name: t.name,
          assignedTo: [],
          milestone: t.milestone,
          completed: false,
          completedDate: null,
          dueDate: null,
          started: false,
          custom: t.custom,
        });
      } else {
        pack.tasks.push(found);
      }
    });
    newMilestones.forEach((m) => {
      let found = project.milestones.find((ms) => ms.name == m.name);
      if (!found) {
        pack.milestones.push({
          name: m.name,
          active: false,
          completed: false,
        });
      } else {
        pack.milestones.push(found);
      }
    });
    // set the first milestone to active if none are active
    if (!pack.milestones.some((m) => m.active) && pack.milestones.length) {
      pack.milestones[0].active = true;
    }

    updateProject({
      id: project._id,
      pack: { ...pack, updateType: ["milestones", "raw"] },
    });
    // }
    setOpen(false);
  };

  const handleAddingTask = (milestone) => {
    setAdding({ milestone: milestone.name, task: null });
  };

  const handleAdd = () => {
    setAddedTasks((prev) => [
      ...prev,
      { milestone: adding.milestone, name: adding.task },
    ]);
    setAdding({ milestone: null, task: null });
  };

  const { setSettingsDialog } = React.useContext(SettingsDialogContext);

  const createTasks = () => {
    setSettingsDialog({ open: true, tab: "tasks" });
  };
  console.log(addedTasks, "addedTasks");

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        size="small"
        variant="contained"
        sx={{ width: "100%" }}
      >
        Tasks
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          Add Tasks To Project
        </DialogTitle>
        <DialogContent>
          {settings.tasks.length ? (
            <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
              {allMilestones.map((m) => (
                <Paper
                  key={m}
                  sx={{ p: 1, m: 1, width: "fit-content", maxWidth: "35%" }}
                  elevation={0}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={m.selected}
                        disabled={m.completed || m.started}
                        onChange={(e) =>
                          handleCheck({ milestone: m, event: e })
                        }
                      />
                    }
                    label={<Typography variant="h6">{m.name}</Typography>}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",

                      gap: 1,
                    }}
                  >
                    {m.tasks.map((t) => (
                      <Paper
                        key={t}
                        elevation={4}
                        sx={{
                          pl: 1,
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={t.selected}
                              disabled={t.completed}
                              onChange={(e) =>
                                handleCheck({ task: t, event: e, milestone: m })
                              }
                            />
                          }
                          label={<Typography>{t.name}</Typography>}
                        />
                      </Paper>
                    ))}
                    {addedTasks.map(
                      (t) =>
                        t.milestone == m.name && (
                          <Paper
                            key={t.task}
                            elevation={4}
                            sx={{
                              pl: 1,
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={true}
                                  disabled={true}
                                  onChange={(e) =>
                                    handleCheck({
                                      task: t,
                                      event: e,
                                      milestone: m,
                                    })
                                  }
                                />
                              }
                              label={<Typography>{t.name}</Typography>}
                            />
                            <IconButton
                              size="small"
                              onClick={() =>
                                setAddedTasks((prev) =>
                                  prev.filter((task) => task.name != t.name)
                                )
                              }
                            >
                              <Cancel />
                            </IconButton>
                          </Paper>
                        )
                    )}
                    {adding.milestone == m.name ? (
                      <Box
                        sx={{ display: "flex", gap: 1, alignItems: "center" }}
                      >
                        <TextField
                          value={adding.task}
                          onChange={(e) =>
                            setAdding((prev) => ({
                              ...prev,
                              task: e.target.value,
                            }))
                          }
                          size="small"
                        />
                        <IconButton
                          size="small"
                          onClick={() =>
                            setAdding({ milestone: null, task: null })
                          }
                        >
                          <Cancel />
                        </IconButton>
                        <IconButton size="small" onClick={handleAdd}>
                          <Add />
                        </IconButton>
                      </Box>
                    ) : (
                      <Button
                        onClick={() => handleAddingTask(m)}
                        size="small"
                        variant="contained"
                      >
                        Add Task
                      </Button>
                    )}
                  </Box>
                </Paper>
              ))}
            </Box>
          ) : (
            <>
              <Typography variant="body1">No tasks available.</Typography>
              <Link sx={{ cursor: "pointer" }} onClick={createTasks}>
                Create tasks in settings.
              </Link>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirm}
            disabled={settings.tasks.length == 0}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AddTasks.propTypes = {
  project: PropTypes.object.isRequired,
  setProject: PropTypes.func,
};
