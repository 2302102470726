import React from "react";
import { createRoot } from "react-dom";
import { Table, TableCell, TableRow } from "@mui/material";

export class FinancialSummary {
  static get isReadOnlySupported() {
    return true;
  }
  static get toolbox() {
    return {
      title: "Financial Summary",
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }
  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {object} params - tool constructor options
   * @param {ListData} params.data - previously saved data
   * @param {object} params.config - user config for Tool
   * @param {object} params.api - Editor.js API
   * @param {boolean} params.readOnly - read-only mode flag
   * @param {object} params.project
   */
  constructor({ config, readOnly, data }) {
    console.log(data);
    this.readOnly = readOnly;
    this.config = config;
    this.data = data;
    // Bind methods
  }

  render() {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    const fields = this.data.project
      ? {
          projectValue: formatter.format(this.data.project.projectValue),
          discount: formatter.format(this.data.project.discount),
          discountRate: this.data.project.discountRate,
          tax: formatter.format(this.data.project.tax),
          total: formatter.format(this.data.project.total),
        }
      : {
          projectValue: "{/projectValue}",
          discount: "{/discount}",
          discountRate: "{/discountRate}",
          tax: "{/tax}",
          total: "{/total}",
        };

    const jsx = (
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          pageBreakInside: "avoid",
        }}
      >
        <Table sx={{ width: "30%" }}>
          <TableRow>
            <TableCell>Subtotal:</TableCell>
            <TableCell align="right">{fields.projectValue}</TableCell>
          </TableRow>
          {this.data.project?.discount ? (
            <TableRow>
              <TableCell>
                Discount {`(${fields.discountRate.toFixed(2)}%)`}:
              </TableCell>
              <TableCell align="right">- {fields.discount}</TableCell>
            </TableRow>
          ) : null}
          {this.data.project?.tax ? (
            <TableRow>
              <TableCell>Tax:</TableCell>
              <TableCell align="right">{fields.tax}</TableCell>
            </TableRow>
          ) : null}
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>Total:</TableCell>
            <TableCell align="right" sx={{ fontWeight: "bold" }}>
              {fields.total}
            </TableCell>
          </TableRow>
        </Table>
      </div>
    );
    // let renderData = (
    //   <>
    //     {this.data}
    //   </>
    // )
    const headerDiv = document.createElement("div");
    // headerDiv.innerHTML = this.data ? this.data : jsx

    // console.log({jsx, data: this.data})

    const root = createRoot(headerDiv);
    root.render(jsx);
    return headerDiv;
  }

  save(blockContent) {
    let html = blockContent.getInnerHTML();
    return html;
  }
}
