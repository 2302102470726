import * as React from "react";

import PropTypes from "prop-types";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Paragraph from "@editorjs/paragraph";
import { InvoiceHeader } from "./editorModules/AddBlocks/InvoiceHeader";
const ImageTool = require("@editorjs/image");
import Table from "@editorjs/table";
import { useSettingsData } from "../../../hooks/useSettingsData";
import { handleTemplate } from "./editorModules/variables";
import AlignmentTuneTool from "editorjs-text-alignment-blocktune";
import { StyleInlineTool } from "editorjs-style";
import Delimiter from "@editorjs/delimiter";
import { FinancialSummary } from "./editorModules/AddBlocks/FinancialSummary";
import { EditableField } from "./editorModules/AddBlocks/EditableField";
import { ProductsTable } from "./editorModules/ProductsTable";
import { useParametersData } from "../../../hooks/useParametersData";

const vars = {
  product: [
    {
      headerName: "Name",
      field: "name",
      valueFormatter: (props) => props.value,
    },
    {
      headerName: "Code",
      field: "code",
      valueFormatter: (props) => props.value,
    },
    {
      headerName: "Price",
      field: "price",
      type: "number",
      valueFormatter: (props) => `$${props.value}`,
    },
    { headerName: "Qty", field: "qty", valueFormatter: (props) => props.value },
    {
      headerName: "Total",
      field: "total",
      valueFormatter: (props) => props.value,
    },
    {
      headerName: "Zone",
      field: "zone",
      valueFormatter: (props) => props.value,
    },
  ],
};

export const TemplateViewEditor = ({
  ejInstance,
  template,
  selectedProject,
  selectedCustomer,
  selectedProducts,
}) => {
  const templateClone = JSON.parse(JSON.stringify(template));
  const { data: settings } = useSettingsData();
  const { data: parameters } = useParametersData();
  const initEditor = () => {
    const editor = new EditorJS({
      holder: "editorjs",
      data: template.data[0],
      tools: {
        delimiter: Delimiter,
        invoiceHeader: {
          class: InvoiceHeader,
          config: { organization: settings.organization },
        },
        financialSummary: FinancialSummary,
        editableField: {
          class: EditableField,
          config: { placeholder: "Editable Text" },
        },
        productTable: {
          class: ProductsTable,
          config: {
            variables: parameters
              .map((param) => {
                if (!param.global) {
                  return {
                    headerName: param.name,
                    field: param.name,
                    valueFormatter: (pros) => pros.value,
                  };
                }
              })
              .concat(vars.product),
          },
        },
        header: {
          class: Header,
          inlineToolbar: true,
          config: {
            placeholder: "Heading",
            levels: [1, 2, 3, 4],
            defaultLevel: 3,
          },
        },
        image: {
          class: ImageTool,
          config: {
            endpoints: {
              byFile: "http://localhost:8000/uploadFile", // Your backend file uploader endpoint
              byUrl: "http://localhost:8000/fetchUrl", // Your endpoint that provides uploading by Url
            },
          },
        },
        list: List,
        style: StyleInlineTool,

        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
          config: { preserveBlank: true, placeholder: "" },
        },
        alignmentTune: {
          class: AlignmentTuneTool,
          config: {
            default: "left",
            blocks: {
              header: "center",
              list: "left",
            },
          },
        },
        table: {
          class: Table,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
          },
        },
      },
      tunes: ["alignmentTune"],

      minHeight: 926,
      maxHeight: 926,
      defaultBlock: "paragraph",
      placeholder: "Start typing...",
      readOnly: true,
      onReady: () => {
        ejInstance.current = editor;
        if (selectedProject && selectedCustomer && selectedProducts) {
          let dataToRender = handleTemplate(
            templateClone,
            selectedProject,
            selectedCustomer,
            selectedProducts
          ).data[0];
          console.log({ dataToRender });
          ejInstance.current.render(dataToRender);
        }
      },
    });
  };
  React.useEffect(() => {
    if (!ejInstance.current) {
      initEditor();
    }
    return () => {
      ejInstance.current.destroy();
      ejInstance.current = null;
    };
  }, []);
  React.useEffect(() => {
    if (
      selectedProject &&
      selectedCustomer &&
      selectedProducts &&
      ejInstance.current
    ) {
      ejInstance.current.render(
        handleTemplate(
          templateClone,
          selectedProject,
          selectedCustomer,
          selectedProducts
        ).data[0]
      );
    }
  }, [selectedProject, selectedCustomer, selectedProducts]);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div id={"pdfWrapper"} style={{ pageBreakInside: "avoid" }}>
        <div
          id="editorjs"
          style={{
            width: "1314px",
            borderRadius: "5px",
            height: "auto",
            margin: "auto",
            padding: "16px 96px 16px 96px",
            boxSizing: "border-box",
            backgroundColor: "white",
            color: "black",
          }}
        ></div>
      </div>
    </div>
  );
};
TemplateViewEditor.propTypes = {
  ejInstance: PropTypes.object,
  template: PropTypes.object,
  selectedProject: PropTypes.object,
  selectedCustomer: PropTypes.object,
  selectedProducts: PropTypes.array,
};
