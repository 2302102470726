import * as React from "react";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import { NewComponentContext } from "../../components/ComponentNode";
import { Collapse } from "@mui/material";
import { TransitionGroup } from "react-transition-group";

export const Categories = () => {
  const { newComponent } = React.useContext(NewComponentContext);

  if (!newComponent.categories.length) {
    return null;
  }

  return (
    <div>
      <TransitionGroup>
        <Paper
          sx={{
            p: 0.5,
            m: 0,
            mt: 1,
            display: "flex",
            gap: 0.5,
            justifyContent: "center",
          }}
        >
          {newComponent.categories.map((category, i) => {
            return (
              <Collapse key={i} in={true}>
                <Chip size="small" label={category} sx={{ borderRadius: 1 }} />
              </Collapse>
            );
          })}
        </Paper>
      </TransitionGroup>
    </div>
  );
};
