import { Box, Paper } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

import { PieChart } from "@mui/x-charts/PieChart";
import { SelectedProjectContext } from "../ProjectView";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const FinancialOverview = () => {
  const project = React.useContext(SelectedProjectContext);
  const paid = project.financial.reduce((acc, payment) => {
    if (payment.amount > 0) {
      return acc + payment.amount;
    } else {
      return acc + 0;
    }
  }, 0);

  const outstanding = project.balance;

  const balanceData = [
    { value: paid.toFixed(2), color: "rgba(75, 192, 192, 1)", label: "Paid" },
    {
      value: outstanding.toFixed(2),
      color: "rgba(255, 99, 132, 1)",
      label: "Outstanding",
    },
  ];

  return (
    <Box>
      <Paper id="project-overview-financial">
        {" "}
        <PieChart
          series={[
            {
              data: balanceData,
              arcLabel: (data) =>
                data.value ? `${formatter.format(data.value)}` : null,
              innerRadius: 30,
              outerRadius: 100,
              paddingAngle: 5,
              cornerRadius: 5,
              startAngle: -90,
              endAngle: 180,
              cx: 150,
              cy: 150,
            },
          ]}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          width={300}
          height={300}
          slotProps={{
            legend: {
              position: { vertical: "top", horizontal: "middle" },
              direction: "row",
              // padding: 1,
            },
          }}
        />
      </Paper>
    </Box>
  );
};

FinancialOverview.propTypes = {
  project: PropTypes.object,
};

// const options = {
//   plugins: {
//     // title: {
//     //   display: true,
//     //   text: "Chart.js Bar Chart - Stacked",
//     // },
//   },
//   responsive: true,
//   scales: {
//     x: {
//       stacked: true,
//       display: false,
//     },
//     y: {
//       stacked: true,
//       display: false,
//     },
//   },
// };
