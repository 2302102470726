import { Paper } from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";
import styles from "./FileManager.module.css";
import { FileView } from "./FileView";
import { FolderBar } from "./FolderBar";
import { useFilesData } from "../hooks/useFilesData";
import { useFoldersData } from "../hooks/useFoldersData";

export const FileManager = ({ user }) => {
  const { data: files } = useFilesData();
  const { data: folders } = useFoldersData();

  // let folders = [
  //   ["templates"],
  //   ["projects"],
  //   ["orders"],
  //   ["misc"],
  //   ["extra"],
  //   ["projects", "project 1"],
  //   ["projects", "project 2"],
  //   ["projects", "project 2", "stuff"],
  // ];

  const [selectedFolder, setSelectedFolder] = React.useState();

  // React.useEffect(() => {
  //   if (files) {
  //     let newFolders = [...folders];

  //     setFolders(newFolders);
  //   }
  // }, []);

  // React.useEffect(() => {
  //   if (files) {
  //     setFolders((prev) => {
  //       let newFolders = [...prev]
  //       files.map((file) => {

  //         for (let i = 1; i <= file.folder.length; i++) {
  //           newFolders.includes(file.folder.slice(0, i).join(','))
  //             ? null
  //             : newFolders.push(file.folder.slice(0, i).join(','));
  //         }
  //       });
  //       return newFolders;
  //     });
  //   }
  // }, [files]);
  // if (!files) {
  //   return <>Loading...</>;
  // }

  // files.map((file) => {
  // });

  return (
    <div className={styles.mainWrapper}>
      {files && folders ? (
        <>
          <div className={styles.folderStructure}>
            <Paper
              sx={{
                height: "100%",
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  width: "0.4em",
                  backgroundColor: "divider",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "primary.main",
                  borderRadius: 1,
                },
              }}
              elevation={2}
            >
              <FolderBar
                folders={folders}
                topLevel={folders.filter((f) => !f.parent)}
                setFolder={setSelectedFolder}
                selectedFolder={selectedFolder}
              />
            </Paper>
          </div>

          <div className={styles.fileView}>
            <Paper sx={{ height: "100%" }} elevation={2}>
              {selectedFolder ? (
                <FileView
                  folder={selectedFolder}
                  folders={folders}
                  user={user.id}
                  files={files}
                  setFolder={setSelectedFolder}
                />
              ) : null}
            </Paper>
          </div>
        </>
      ) : null}
    </div>
  );
};
FileManager.propTypes = {
  user: PropTypes.string,
};
