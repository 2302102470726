import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import axios from "axios";
import { useQueryClient } from "react-query";
import { UserContext } from "../../../App";
import { DatePicker } from "@mui/x-date-pickers";
import { columns, endpoints, titles } from "../utils";
import { useSettingsData } from "../../../hooks/useSettingsData";
import { MuiTelInput } from "mui-tel-input";
import { SnackbarContext } from "../../../Content";

export const NewItemDialog = ({
  ENDPOINT,
  isOpen = false,
  setOpen,
  fields = [],
  updateTable,
  title,
  onSuccess = () => {},
  autocompleteEndpoint = "",
}) => {
  const { user } = React.useContext(UserContext);
  const { data: settings } = useSettingsData();
  const handleCancel = () => setOpen(false);
  const handleClose = () => setOpen(false);
  const [internalFields, setInternalFields] = useState([...fields]);
  const queryClient = useQueryClient();
  const [autocompleteOptions, setAutocompleteOptions] = React.useState([]);
  const [addOpen, setAddOpen] = React.useState(false);
  const { setSnack } = React.useContext(SnackbarContext);
  React.useEffect(() => {
    if (!autocompleteEndpoint) return;

    let abortController = new AbortController();
    let signal = abortController.signal;
    axios
      .get(`${autocompleteEndpoint}`, {
        signal: signal,
        params: { user: user.id },
      })
      .then((r) => {
        if (r.status !== 200) {
          setSnack({
            open: true,
            message: "error",
            type: "error",
          });

          return;
        }
        r.data.map((x) => (x.label = `${x.firstName} ${x.lastName}`));
        r.data.map((x) => (x.value = x._id));
        setAutocompleteOptions(r.data);
      });
    return () => abortController.abort();
  }, [autocompleteEndpoint]);

  const handleValidation = () => {
    const newFields = [...internalFields];
    newFields.map((f) => {
      if ((f.value == "" || !f.value) && f.required) {
        f.error = true;
      } else {
        f.error = false;
      }
    });
    setInternalFields(newFields);
    return internalFields.some((f) => f.error == true);
  };
  const handleSubmit = () => {
    let payload = { user: user.id };
    internalFields.map((f) => (payload[f.field] = f.value));
    if (handleValidation()) {
      return;
    }
    axios
      .post(`${ENDPOINT}`, payload)
      .then((r) => {
        if (r.status === 201) {
          queryClient.invalidateQueries(updateTable);
          onSuccess({ data: r.data });
          setOpen(false);
          setInternalFields([...fields]);
        }
      })
      .catch((error) => {
        setSnack({
          open: true,
          message: error.response.data.message,
          severity: "error",
        });
      });
  };

  const onFieldChange = (fieldId, fieldValue) => {
    let tempFields = [...internalFields];
    tempFields.find((x) => x.field === fieldId).value = fieldValue;
    setInternalFields(tempFields);
  };
  const handleAddNew = () => {
    setAddOpen(true);
  };
  const onAddSuccess = ({ data }) => {
    onFieldChange("customer", data._id);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add New {title}</DialogTitle>
      <DialogContent>
        {internalFields.map((field, i) =>
          field.type == "autocomplete" ? (
            // <Autocomplete
            //   disablePortal
            //   key={`${field.type}-${field.headerName}`}
            //   options={autocompleteOptions}
            //   sx={{ marginTop: 2 }}
            //   onChange={(e, o) => onFieldChange(field.field, o ? o._id : "")}
            //   renderInput={(params) => (
            //     <TextField {...params} label={field.headerName} />
            //   )}
            // />
            <FormControl
              fullWidth
              sx={{ marginTop: 2 }}
              key={`${field.type}-${field.headerName}`}
            >
              <InputLabel>{field.headerName}</InputLabel>
              <Select
                fullWidth
                value={field.value}
                onChange={(e) => onFieldChange(field.field, e.target.value)}
                label={field.headerName}
              >
                {autocompleteOptions.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.firstName} {option.lastName}
                  </MenuItem>
                ))}
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button onClick={handleAddNew}>Add New</Button>
                </Box>
              </Select>
            </FormControl>
          ) : field.field == "role" ? (
            <FormControl
              fullWidth
              key={field.id}
              sx={{ marginTop: 2, width: "100%" }}
            >
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                autoFocus={i === 0}
                margin="dense"
                key={field.id}
                id={field.id}
                disabled={field.disabled}
                label={field.headerName}
                type={field.type}
                value={field.value || ""}
                onChange={(e) => onFieldChange(field.field, e.target.value)}
                fullWidth
              >
                {settings.roles.map((role) => (
                  <MenuItem key={role._id} value={role._id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : field.type == "date" ? (
            <DatePicker
              error={field.error}
              autoFocus={i === 0}
              sx={{ marginTop: 2, width: "100%" }}
              key={field.field}
              id={field.field}
              label={field.headerName}
              type={field.type}
              value={field.value}
              required={field.required}
              onChange={(value) => onFieldChange(field.field, value)}
              InputLabelProps={{ shrink: true }}
            />
          ) : field.type == "tel" ? (
            <MuiTelInput
              fullWidth
              error={field.error}
              autoFocus={i === 0}
              sx={{ marginTop: 2 }}
              key={field.field}
              id={field.field}
              label={field.headerName}
              type={field.type}
              defaultCountry="us"
              value={field.value}
              onChange={(e) => onFieldChange(field.field, e)}
              InputLabelProps={{ shrink: true }}
            />
          ) : !field.hideOnCreate ? (
            <TextField
              fullWidth
              error={field.error}
              autoFocus={i === 0}
              sx={{ marginTop: 2 }}
              key={field.field}
              id={field.field}
              label={field.headerName}
              type={field.type}
              value={field.value}
              required={field.required}
              onChange={(e) => onFieldChange(field.field, e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          ) : null
        )}
        <NewItemDialog
          title={titles["customers"]}
          isOpen={addOpen}
          setOpen={setAddOpen}
          fields={columns["customers"]}
          updateTable={"customers"}
          ENDPOINT={endpoints["customers"]}
          onSuccess={onAddSuccess}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewItemDialog.propTypes = {
  ENDPOINT: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  autocompleteOptions: PropTypes.array,
  fields: PropTypes.array,
  onSubmit: PropTypes.func,
  updateTable: PropTypes.string,
  autocompleteEndpoint: PropTypes.string,
  user: PropTypes.string,
  onSuccess: PropTypes.func,
};
