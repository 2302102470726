import axios from "axios";

const userApi = axios.create();

export const login = async (pack) => {
  const response = await userApi.post(`/api/user/login`, pack);
  return response.data;
};

export const register = async (pack) => {
  const response = await userApi.post(`/api/user/register`, pack);
  return response;
};

export const resendEmail = async (pack) => {
  const response = await userApi.post(`/api/user/resend`, pack);
  return response;
};

export const tokenLogin = async (pack) => {
  const response = await userApi.post(`/api/user/login/${pack.token}`);
  return response.data;
};
