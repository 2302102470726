import React from "react";
import { createRoot } from "react-dom";
import { Box, Divider, Grid } from "@mui/material";
import "./InvoiceHeader.css";

export class InvoiceHeader {
  static get isReadOnlySupported() {
    return true;
  }
  static get toolbox() {
    return {
      title: "Invoice Header",
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }
  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {object} params - tool constructor options
   * @param {ListData} params.data - previously saved data
   * @param {object} params.config - user config for Tool
   * @param {object} params.api - Editor.js API
   * @param {boolean} params.readOnly - read-only mode flag
   * @param {object} params.project
   */
  constructor({ config, readOnly, data }) {
    console.log(config);
    this.readOnly = readOnly;
    this.config = config;
    this.data = {
      ...data,
      html: data.html,
      bankName: data.bankName || "Bank Name",
      country: data.country || "Country",
      routing: data.routing || "Routing",
      account: data.account || "Account",
    };

    // Bind methods
    this.onContentEdit = this.onContentEdit.bind(this);
  }
  onContentEdit(field, event) {
    // Update state with new content
    this.data = { ...this.data, [field]: event.target.textContent };
    console.log(this.data);
  }
  render() {
    const date = new Date().toLocaleDateString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
    const invoice = {
      companyHeader: this.config.organization
        ? [
            this.config.organization.name,
            this.config.organization.address,
            this.config.organization.phone,
            this.config.organization.email,
          ]
        : [
            "{/organizationName}",
            "{/organizationAddress}",
            "{/organizationPhone}",
            "{/organizationEmail}",
          ],
      invoiceNumber: this.data.template
        ? this.data.template.number
        : "{/tNumber}",
      invoiceDate: date,
      billTo: [
        { field: "Bank: ", value: this.data.bankName, key: "bankName" },
        { field: "Country: ", value: this.data.country, key: "country" },
        { field: "Routing #: ", value: this.data.routing, key: "routing" },
        { field: "Account #: ", value: this.data.account, key: "account" },
      ],
      invoiceTo: this.data.customer
        ? [
            <>
              <div className="hover-item">{this.data.customer.firstName}</div>{" "}
              <div className="hover-item">{this.data.customer.lastName}</div>
            </>,
            <div key="company">{this.data.customer.company}</div>,
            <div key="address">{this.data.customer.address}</div>,
            <div key="phone">{this.data.customer.phone}</div>,
            <div key="email">{this.data.customer.email}</div>,
          ]
        : [
            "{/firstName} {/lastName}",
            "{/company}",
            "{/address}",
            "{/phone}",
            "{/email}",
          ],
    };

    const jsx = (
      <div id="blockContainer">
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            {invoice.companyHeader.map((line, i) => (
              <div key={i}>{line}</div>
            ))}
          </Grid>
          <Grid item sx={{ textAlign: "end" }}>
            <div>Invoice Number: {invoice.invoiceNumber}</div>
            <div>Invoice Date: {invoice.invoiceDate}</div>
          </Grid>
        </Grid>
        <Divider sx={{ m: 1 }} />
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Box>
              <Box sx={{ fontWeight: "bold" }}>Invoice To:</Box>
              {invoice.invoiceTo.map((line, i) => (
                <div key={i}>{line}</div>
              ))}
            </Box>
          </Grid>
          <Grid item>
            <Box>
              <Box sx={{ fontWeight: "bold" }}>Bill To:</Box>

              <Grid container justifyContent={"space-between"}>
                <Grid item>
                  {invoice.billTo.map((line, i) => (
                    <div key={i}>{line.field}</div>
                  ))}
                </Grid>
                <Grid item>
                  {invoice.billTo.map((line, i) => (
                    <div
                      contentEditable
                      onInput={(e) => this.onContentEdit(line.key, e)}
                      key={i}
                    >
                      {line.value}
                    </div>
                  ))}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
    // let renderData = (
    //   <>
    //     {this.data}
    //   </>
    // )
    const headerDiv = document.createElement("div");
    // headerDiv.innerHTML = this.data ? this.data : jsx

    // console.log({jsx, data: this.data})

    const root = createRoot(headerDiv);
    root.render(jsx);
    return headerDiv;
  }

  save(blockContent) {
    let html = blockContent.getInnerHTML();
    return {
      html: html,
      bankName: this.data.bankName,
      country: this.data.country,
      routing: this.data.routing,
      account: this.data.account,
    };
  }
}
