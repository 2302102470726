import React from "react";
import { useQuery } from "react-query";
import { getTemplates } from "../api/templateApi";
import { UserContext } from "../App";

export const useTemplatesData = (onSuccess, onError) => {
  const { user } = React.useContext(UserContext);
  return useQuery("templates", () => getTemplates(user.id), {
    onSuccess,
    onError,
  });
};
