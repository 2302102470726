import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

export function MuiAdapter({ theme }) {
  if (!theme.palette) {
    return <></>;
  }
  return (
    <Helmet>
      <style type="text/css">{`
          .fc-theme-standard .fc-popover {
            background: ${theme.palette.background.paper};
            border: 0;
            box-shadow: ${theme.shadows[3]};

          }
          /* more styles go here */
      `}</style>
    </Helmet>
  );
}

MuiAdapter.propTypes = {
  theme: PropTypes.object,
};
