// make a list of all variables from customer, project, supplier, or organization

// make a function that looks over the documents and sees if there are any variables
// that need to be replaced
const templateVariables = [
  {
    name: "Template Name",
    id: "{/tName}",
    key: "name",
    value: "Template Num 1",
  },
  {
    name: "Template Creation Date",
    id: "{/tDate}",
    key: "date",
    value: "Template Num 1",
  },
  {
    name: "Template Number",
    id: "{/tNumber}",
    key: "number",
    value: "Template Num 1",
  },
];
const customerVariables = [
  {
    name: "Customer First Name",
    id: "{/firstName}",
    key: "firstName",
    value: "John Doe",
  },
  {
    name: "Customer Last Name",
    id: "{/lastName}",
    key: "lastName",
    value: "John Doe",
  },
  { name: "Customer Email", id: "{/email}", key: "email", value: "John Doe" },
  { name: "Customer Phone", id: "{/phone}", key: "phone", value: "John Doe" },
  {
    name: "Customer Address",
    id: "{/address}",
    key: "address",
    value: "John Doe",
  },
  {
    name: "Customer Company",
    id: "{/company}",
    key: "company",
    value: "John Doe",
  },
];
const projectVariables = [
  {
    name: "Project Name",
    id: "{/pName}",
    key: "projectName",
    value: "Project Num 1",
  },
  {
    name: "Project Creation Date",
    id: "{/pDate}",
    key: "creationDate",
    value: "Project Num 1",
  },
  {
    name: "Project Due Date",
    id: "{/pDueDate}",
    key: "dueDate",
    value: "Project Num 1",
  },

  {
    name: "Project Customer",
    id: "{/pCustomer}",
    key: "customer",
    value: "Project Num 1",
  },
  {
    name: "Project Zones",
    id: "{/pZones}",
    key: "zones",
    value: "Project Num 1",
    array: true,
  },
  {
    name: "Project Value",
    id: "{/projectValue}",
    key: "projectValue",
    value: "Project Num 1",
  },
  {
    name: "Project Total",
    id: "{/pTotal}",
    key: "total",
    value: "Project Num 1",
  },
  {
    name: "Project Balance",
    id: "{/pBalance}",
    key: "balance",
    value: "Project Num 1",
  },
  { name: "Project Tax", id: "{/pTax}", key: "tax", value: "Project Num 1" },
  {
    name: "Project Tax Rate",
    id: "{/pTaxRate}",
    key: "taxRate",
    value: "Project Num 1",
  },
  {
    name: "Project Discount Rate",
    id: "{/pDiscountRate}",
    key: "discountRate",
    value: "Project Num 1",
  },
  {
    name: "Project Discount",
    id: "{/pDiscount}",
    key: "discount",
    value: "Project Num 1",
  },
];
const supplierVariables = [
  {
    name: "Supplier Name",
    id: "{/sName}",
    key: "name",
    value: "John Doe",
  },
  {
    name: "Supplier Address",
    id: "{/sAddress}",
    key: "address",
    value: "John Doe",
  },
  {
    name: "Supplier Email",
    id: "{/sEmail}",
    key: "email",
    value: "John Doe",
  },
  {
    name: "Supplier Phone",
    id: "{/sPhone}",
    key: "phone",
    value: "John Doe",
  },
  {
    name: "Supplier Contact",
    id: "{/sContact}",
    key: "contact",
    value: "John Doe",
  },
];
const organizationVariables = [
  {
    name: "Organization Name",
    id: "{/organizationName}",
    key: "organizationName",
    value: "John Doe",
  },
  {
    name: "Organization Address",
    id: "{/organizationAddress}",
    key: "organizationAddress",
    value: "John Doe",
  },
  {
    name: "Organization Email",
    id: "{/organizationEmail}",
    key: "organizationEmail",
    value: "John Doe",
  },
  {
    name: "Organization Phone",
    id: "{/organizationPhone}",
    key: "organizationPhone",
    value: "John Doe",
  },
];
const productVariables = [
  { name: "Name", id: "{/name}", key: "name", value: "John Doe" },
  { name: "Price", id: "{/price}", key: "price", value: "John Doe" },
  { name: "Quantity", id: "{/qty}", key: "qty", value: "John Doe" },
  {
    name: "Parameter",
    id: "{/PARAMETER_NAME}",
    key: "PARAMETER_NAME",
    value: "John Doe",
  },
  { name: "Total", id: "{/total}", key: "total", value: "John Doe" },
  {
    name: "Options",
    id: "{/OPTION_NAME}",
    key: "OPTION_NAME",
    value: "John Doe",
    array: true,
  },
];
const optionVariables = [
  {
    name: "Option Name",
    id: "{/optionName}",
    key: "optionName",
    value: "John Doe",
  },
  {
    name: "Option Choice",
    id: "{/optionChoice}",
    key: "optionChoice",
    value: "John Doe",
  },
];
const componentVariables = [
  {
    name: "Name",
    id: "{/cName}",
    key: "componentName",
    value: "Name",
  },
  {
    name: "Quantity",
    id: "{/cQty}",
    key: "componentQty",
    value: "Quantity",
  },
  {
    name: "Stock",
    id: "{/cStock}",
    key: "componentStock",
    value: "Stock",
  },
  {
    name: "Options",
    id: "{/cOPTION_NAME}",
    key: "cOPTION_NAME",
    value: "John Doe",
    array: true,
  },
  {
    name: "Parameter",
    id: "{/cPARAMETER_NAME}",
    key: "cPARAMETER_NAME",
    value: "John Doe",
  },
];

export const variables = {
  customer: customerVariables,
  project: projectVariables,
  supplier: supplierVariables,
  organization: organizationVariables,
  product: productVariables,
  option: optionVariables,
  template: templateVariables,
  component: componentVariables,
};

const allVariables = [
  ...customerVariables,
  ...projectVariables,
  ...supplierVariables,
  ...organizationVariables,
  ...productVariables,
  ...optionVariables,
  ...templateVariables,
  ...componentVariables,
];

export function handleTemplate(template, project, customer, products) {
  console.log({ template, project, customer, products });
  project.creationDate = new Date(project.creationDate).toLocaleDateString();
  project.dueDate = new Date(project.dueDate).toLocaleDateString();
  const blocks = template.data[0].blocks;
  const newBlocks = [];

  blocks.forEach((block) => {
    // Handling Table Block
    if (block.type === "table") {
      const newBlock = { ...block };
      const rows = [...block.data.content];

      if (rows.length == 1) {
        const headerRow = rows[0];
        let newRows = [
          headerRow.map(
            (cell) =>
              allVariables.find((v) => v.id === cell)?.name ||
              cell.replace("{/", "").replace("}", "")
          ),
        ];
        const keys = rows[rows.length - 1]
          .join("_")
          .replaceAll("{/", "")
          .replaceAll("}", "")
          .split("_");
        // check only first variable in key row of table
        // if table is for components
        if (componentVariables.find((v) => v.id === rows[rows.length - 1][0])) {
          console.log("in component variables");
          let formattedComponents = formatComponents(project.components);
          formattedComponents.forEach((component) => {
            const newRow = [];
            console.log(component);
            keys.forEach((key) => {
              let newKey = key.slice(1).toLowerCase();
              // const parameter = component.parameters.find(
              //   (p) => p.name === newKey
              // );
              // const option = component.options.find(
              //   (o) => o.name.toLowerCase() === newKey
              // );

              if (component[newKey]) {
                newRow.push(`${component[newKey]}`);
              } else if (component[key.slice(1)]) {
                newRow.push(`${component[key.slice(1)]}`);
                // } else if (parameter) {
                //   newRow.push(`${parameter.value}`);
                // } else if (option) {
                //   newRow.push(`${option.value}`);
              } else {
                newRow.push("");
              }
            });
            if (
              newRow.every(
                (cell) => cell === "" || cell === "Could not find variable."
              )
            )
              return;
            newRows.push(newRow);
          });
          let newHeaderRow = newRows[0].map((cell) => {
            let found = componentVariables.find(
              (v) => v.name.replaceAll("{/", "").replaceAll("}", "") === cell
            );
            if (!found) {
              return cell.slice(1);
            } else {
              return cell;
            }
          });
          newRows[0] = newHeaderRow;
        } else {
          // if table is for products
          products.forEach((product) => {
            const newRow = [];
            keys.forEach((key) => {
              const parameter = product.parameters.find((p) => p.name === key);
              const option = product.options.find((o) => o.name === key);
              if (product[key]) {
                newRow.push(`${product[key]}`);
              } else if (parameter) {
                newRow.push(`${parameter.value}`);
              } else if (option) {
                newRow.push(`${option.value}`);
              } else {
                newRow.push("");
              }
            });
            if (
              newRow.every(
                (cell) => cell === "" || cell === "Could not find variable."
              )
            )
              return;
            newRows.push(newRow);
          });
        }

        newBlock.data.content = [...newRows];
        newBlocks.push(newBlock);
      } else {
        newBlocks.push(block);
      }
    }
    // Handling InvoiceHeader Block
    else if (block.type === "invoiceHeader") {
      const newBlock = {
        ...block,
        data: { project, customer, template, ...block.data },
      };
      newBlocks.push(newBlock);
    } else if (block.type === "financialSummary") {
      const newBlock = {
        ...block,
        data: { project, ...block.data },
      };

      newBlocks.push(newBlock);
    }
    // Handling Text Block
    else if (block.type === "paragraph") {
      const newBlock = { ...block };
      let text = newBlock.data.text;

      // Replace variables referencing customer or project properties
      for (let variable of customerVariables) {
        text = text.replace(variable.id, customer[variable.key]);
      }
      for (let variable of projectVariables) {
        variable.key.split(".").length > 1
          ? (text = text.replace(
              variable.id,
              project[variable.key.split(".")[0]][variable.key.split(".")[1]]
            ))
          : (text = text.replace(variable.id, project[variable.key]));
      }

      // Replace variables referencing product parameters
      products.forEach((product) => {
        product.parameters.forEach((parameter) => {
          const value = parameter.value;
          text = text.replace(`{/${parameter.name}}`, value);
        });
      });

      newBlock.data.text = text;
      newBlocks.push(newBlock);
    } else if (block.type === "header") {
      const newBlock = { ...block };

      newBlocks.push(newBlock);
    } else if (block.type === "editableField") {
      const newBlock = { ...block };
      newBlocks.push(newBlock);
    } else if (block.type === "productTable") {
      let parsedProducts = products.map((product) => {
        let parsedProduct = { ...product };
        product.parameters.forEach((parameter) => {
          parsedProduct[parameter.name] = parameter.value;
        });
        product.options.forEach((option) => {
          parsedProduct[option.name] = option.value;
        });
        return parsedProduct;
      });
      newBlocks.push({
        ...block,
        data: { ...block.data, rows: parsedProducts },
      });
    }
  });

  console.log({ newBlocks });

  const replacedTemplate = {
    ...template,
    data: [{ ...template.data[0], blocks: newBlocks }],
  };
  return replacedTemplate;
}

const formatComponents = (components) => {
  const formattedComponents = components.map((component) => {
    const formattedComponent = {
      name: component.name,
    };
    if (component.parameters) {
      component.parameters.forEach((parameter) => {
        formattedComponent[parameter.name] = parameter.value;
      });
    }
    if (component.options) {
      component.options.forEach((option) => {
        formattedComponent[option.name] = option.value;
      });
    }
    return formattedComponent;
  });
  return mergeIdenticalRowQty(formattedComponents);
};

const mergeIdenticalRowQty = (rows) => {
  let newRows = [];
  rows.forEach((row) => {
    // let existingRow = newRows.find((r) => r.name === row.name);
    let existingRow = newRows.find((r) => {
      for (let key in row) {
        if (key !== "qty" && r[key] !== row[key]) {
          return false;
        }
      }
      return true;
    });
    if (existingRow) {
      existingRow.qty += row.qty;
    } else {
      newRows.push(row);
    }
  });
  newRows = newRows.map((row) => ({
    ...row,
    qty: Math.ceil(row.qty * 1000) / 1000,
  }));

  return newRows;
};
