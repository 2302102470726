import { Box, Chip, Grid, Paper, Typography } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import * as React from "react";
import { useComponentsData } from "../hooks/useComponentsData";
import { useParametersData } from "../hooks/useParametersData";
import { NoInventoryOverlay } from "../features/inventory/utils/NoInventoryOverlay";
import { AdjustInventory } from "../features/inventory/components/AdjustInventory";
import DeleteButton from "../utils/DeleteButton";
import { useComponentDelete } from "../features/componentAndOptionList/hooks/useComponentDelete";
import PropTypes from "prop-types";

export const Inventory = () => {
  const { data: components } = useComponentsData();
  const { data: parameters } = useParametersData();
  const [selectedProduct, setSelectedProduct] = React.useState([]);
  // eslint-disable-next-line no-unused-vars
  const [productData, setProductData] = React.useState([]);
  const [filter, setFilter] = React.useState("");
  const [filteredRows, setFilteredRows] = React.useState(null);
  const handleSelectionModelChange = (model) => {
    if (model.length == 0) {
      setSelectedProduct([]);
      setProductData([]);
    } else {
      setSelectedProduct(model);
      setProductData(model.map((m) => components.find((c) => c._id === m)));
    }
  };

  const { mutate: deleteComponent, isLoading: deleteLoading } =
    useComponentDelete();

  if (!components || !parameters) return <>Loading...</>;
  let columns = [
    { field: "code", headerName: "Code", flex: 1 },
    { field: "stock", headerName: "Stock", flex: 0.4, type: "number" },

    { field: "name", headerName: "Name", flex: 2 },
    ...parameters
      .filter((p) => !p.global)
      .map((p) => ({
        field: p.name,
        headerName: p.name,
        flex: 1,
        renderCell: (params) => {
          let value = params.row.parameters.find(
            (f) => f.name == p.name && f.hierarchy.length === 1
          );
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                paddingLeft: 10,
                paddingRight: 10,
              }}
            >
              <div>
                {value ? value.localValue || value.originalValue : null}
              </div>
              <div>
                <em style={{ color: "grey" }}> {value ? value.unit : null}</em>
              </div>
            </div>
          );
        },
      })),
  ];
  const chipData = [
    {
      key: 0,
      label: "Supplied",
      filter: () => {
        return components.filter((f) => f.suppliers.length > 0);
      },
    },
    {
      key: 1,
      label: "In Stock",
      filter: () => {
        return components.filter((f) => f.stock > 0);
      },
    },
    {
      key: 2,
      label: "Out of Stock",
      filter: () => {
        return components.filter((f) => f.stock <= 0);
      },
    },
    {
      key: 3,
      label: "Products",
      filter: () => {
        return components.filter((f) => f.isProduct);
      },
    },
  ];

  const handleFilterChange = (f) => {
    if (f.label !== "" && f.label !== filter) {
      setFilter(f.label);
      setFilteredRows(f.filter());
    } else {
      setFilter("");
      setFilteredRows(null);
    }
  };

  const onDelete = (callback) => {
    for (let i = 0; i < productData.length; i++) {
      deleteComponent(productData[i]._id);
    }

    callback();
  };

  return (
    <>
      <Grid
        container
        justifyContent="flex-start"
        alignItems={"center"}
        direction="row"
        columnSpacing={2}
        rowSpacing={2}
        sx={{ mb: 2 }}
      >
        <Grid item xs={"flex"}>
          <Typography variant="h3">Inventory </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        rowSpacing={2}
        columnSpacing={2}
        sx={{ width: "100%", height: "80%" }}
      >
        <Grid item xs={12}>
          <Paper
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              pr: 1,
              mb: 2,
            }}
          >
            <div>
              {chipData.map((data) => (
                <Chip
                  key={data.key}
                  color={filter == data.label ? "primary" : "default"}
                  label={data.label}
                  onClick={() => handleFilterChange(data)}
                  sx={{ m: 1 }}
                />
              ))}
            </div>
            <AdjustInventory selectedItems={productData} />
          </Paper>
          <Paper>
            <DataGridPremium
              density="compact"
              rowSelectionModel={selectedProduct}
              onRowSelectionModelChange={handleSelectionModelChange}
              getRowId={(row) => row._id}
              sx={{
                height: 815,
                ".MuiDataGrid-virtualScroller": {
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                    backgroundColor: "divider",
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "primary.main",
                  },
                },
              }}
              rows={filteredRows ? filteredRows : components}
              checkboxSelection
              components={{
                NoRowsOverlay: NoInventoryOverlay,
              }}
              slots={{
                footer: CustomFooter,
              }}
              slotProps={{
                footer: {
                  onDelete,
                  isLoading: deleteLoading,
                  selected: productData,
                },
              }}
              columns={columns}
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export function CustomFooter(props) {
  return (
    <Box sx={{ p: 1, display: "flex" }}>
      <DeleteButton
        onDelete={props.onDelete}
        loading={props.isLoading}
        disabled={props.selected.length < 1}
      />
    </Box>
  );
}

CustomFooter.propTypes = {
  onDelete: PropTypes.func,
  isLoading: PropTypes.bool,
  selected: PropTypes.array,
};
