import { Paper, Dialog } from "@mui/material";
import PropTypes from "prop-types";

import { DataGridPremium } from "@mui/x-data-grid-premium";
import * as React from "react";
import { useQuery } from "react-query";
// import { getTemplates } from "../../api/templateApi";
// import { TemplateView } from "./TemplateView";
// import { getCustomers } from "../../api/customerApi";
import { UserContext } from "../../../App";
import { getFiles } from "../../../api/fileApi";
import { NoDocumentsOverlay } from "../../templates/utils/NoDocumentsOverlay";
import { useProjectsData } from "../../../hooks/useProjectsData";

const CLOUDFRONT_URL = "https://cdn.manufacture.app/";

export const DocumentList = () => {
  const user = React.useContext(UserContext);
  // const { data: templates } = useQuery('templates', () => getTemplates(user))
  // const [selectedTemplate, setSelectedTemplate] = React.useState({ name: 'Select Template' })
  // const handleTemplateSelect = (template) => {
  //   setSelectedTemplate(template)
  //   setDialogOpen(true)
  // }
  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "description", headerName: "Description", flex: 1 },
    { field: "status", headerName: "Date", flex: 1 },
  ];

  const { data: files } = useQuery("files", () => getFiles(user));

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const handleClose = () => {
    setDialogOpen(false);
  };
  const [selectedDocument, setSelectedDocument] = React.useState(null);
  const handleDocumentSelect = (document) => {
    setSelectedDocument(document);
    setDialogOpen(true);
  };
  const { data: projects } = useProjectsData();
  // const { data: customers } = useQuery("customers", () => getCustomers(user));

  if (!files || !projects) return null;

  const rows = [];
  files.forEach((file) => {
    if (file.template) {
      const ownerProject = projects.find(
        (project) => project._id === file.project
      );
      if (!ownerProject) return;
      let description = ownerProject.projectName;
      let status = file.log.at(-1);
      rows.push({
        id: file.name + status,
        name: file.name,
        description: description,
        status: status,
        key: file.key,
      });
    }
  });

  return (
    <>
      <Paper>
        <DataGridPremium
          rows={rows}
          columns={columns}
          sx={{ height: 400 }}
          slots={{
            noRowsOverlay: NoDocumentsOverlay,
          }}
          density={"compact"}
          onRowClick={(e) => handleDocumentSelect(e.row)}
        />
      </Paper>
      {/* <Dialog open={dialogOpen} onClose={handleClose} maxWidth={"xl"} scroll="paper">
        <TemplateView template={selectedTemplate} projects={projects} customers={customers} readOnly={true} handleClose={handleClose} />
      </Dialog> */}
      <Dialog
        maxWidth="xl"
        open={dialogOpen}
        onClose={handleClose}
        sx={{ maxHeight: "100%" }}
      >
        {selectedDocument ? (
          <iframe
            src={`${CLOUDFRONT_URL}${selectedDocument.key}`}
            seamless="seamless"
            style={{ display: "block", width: "100vh", height: "100vh" }}
          ></iframe>
        ) : null}
      </Dialog>
    </>
  );
};

DocumentList.propTypes = {
  setCreating: PropTypes.func,
};
