/* eslint-disable no-unused-vars */
import React from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import { ArrowLeftIcon } from "@mui/x-date-pickers";
import { useOrganizationsData } from "../features/catalog/hooks/useOrganizationsData";
import { Storefront } from "../features/market/components/Storefront";
import { Store } from "../features/market/components/Store";
import { UserContext } from "../App";
import { MyStorePreview } from "../features/market/components/MyStorePreview";
import { Search } from "../features/market/components/Search";
import { MyStore } from "../features/market/components/MyStore";

export const Market = () => {
  const { data: organizations } = useOrganizationsData();

  const { user } = React.useContext(UserContext);

  const [selectedOrg, setSelectedOrg] = React.useState(null);
  const [myStore, setMyStore] = React.useState(false);

  const handleOrgSelect = (org) => {
    setSelectedOrg(org);
  };

  const handleBack = () => {
    setSelectedOrg(null);
  };

  const handleMyStore = () => {
    setMyStore(true);
  };

  if (!organizations) {
    return <div>Loading...</div>;
  }

  if (myStore) {
    return (
      <Box sx={{ width: "100%" }}>
        <Button onClick={() => setMyStore(false)} startIcon={<ArrowLeftIcon />}>
          Back
        </Button>
        <MyStore />{" "}
      </Box>
    );
  }

  if (selectedOrg) {
    return (
      <Box sx={{ width: "100%" }}>
        <Button onClick={handleBack} startIcon={<ArrowLeftIcon />}>
          Back
        </Button>
        <Store org={selectedOrg} />{" "}
      </Box>
    );
  }
  console.log(user);
  return (
    <div>
      <Typography variant="h3" sx={{ mb: 2 }}>
        Market{" "}
      </Typography>

      {/* <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", width: "100%" }}>
        <Paper
          sx={{ cursor: "pointer", width: "45%" }}
          onClick={() => handleOrgSelect(organizations[0])}
        >
          My Store
        </Paper>

        {organizations
          .filter((o) => o.user !== user.id)
          .map((organization) => (
            <Box
              key={organization._id}
              sx={{ cursor: "pointer", width: "45%" }}
              onClick={() => handleOrgSelect(organization)}
            >
              <Storefront key={organization._id} org={organization} />
            </Box>
          ))}
      </Box> */}
      <MyStorePreview onClick={() => setMyStore(true)} />
      <Search />
    </div>
  );
};
