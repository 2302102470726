import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../../../Content";
import { updateSupplier } from "../../../api/suppplierApi";

export function useSupplierUpdate(snack) {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  return useMutation({
    mutationFn: ({ pack }) => {
      updateSupplier({
        id: pack.id,
        pack,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("suppliers");
      if (snack) {
        setSnack({
          open: true,
          message: snack,
          severity: "success",
        });
      }
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Supplier could not be updated.",
        severity: "error",
      });
    },
  });
}
