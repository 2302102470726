import { Box, Fade, Typography } from "@mui/material";
import React from "react";
import { SignupForm } from "./SignupForm";
import PropTypes from "prop-types";
import { ModuleSelection } from "./ModuleSelection";

export const CustomerInfoContext = React.createContext({
  customerInfo: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    website: "",
    address: "",
    city: "",
    state: "",
    zip: "",
  },
  setCustomerInfo: () => {},
});

export const Signup = ({ setPage }) => {
  const [customerInfo, setCustomerInfo] = React.useState({
    firstName: "Robert",
    lastName: "Ch",
    email: "ravgared@gmail.com",
    phone: "1234567890",
    company: "CorpCo",
    website: "google.com",
    address: "123 Rd St",
    city: "Delray Beach",
    state: "FL",
    zip: "12345",
  });

  const [progress, setProgress] = React.useState(0);

  return (
    <CustomerInfoContext.Provider value={{ customerInfo, setCustomerInfo }}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <div>
          <Fade in={progress == 0} unmountOnExit>
            <Box sx={{ display: "flex", width: "fit-content" }}>
              <Box sx={{ width: 200, mr: 2 }}>
                <Typography variant="h4" fontWeight={"bold"}>
                  Sign Up
                </Typography>
                <Typography variant="h6">
                  Enter your information to get started.
                </Typography>
              </Box>
              <SignupForm setPage={setPage} setProgress={setProgress} />
            </Box>
          </Fade>
        </div>
        <Fade in={progress == 1} unmountOnExit>
          <div>
            <ModuleSelection setProgress={setProgress} />
          </div>
        </Fade>
      </Box>
    </CustomerInfoContext.Provider>
  );
};

Signup.propTypes = {
  setPage: PropTypes.func.isRequired,
};
