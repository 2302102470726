import { create } from "zustand";
import { addEdge, applyNodeChanges, applyEdgeChanges } from "reactflow";
import { handleEdit as handleComponentEdit } from "../hooks/useNewComponent";
import { handleEdit as handleOptionEdit } from "../hooks/useNewOption";
import { isValidChild } from "./utils";
import _ from "lodash";
import { temporal } from "zundo";

const createComponent = (id) => {
  return {
    _id: "newComponent",
    nodeKey: id,
    name: "Component",
    description: "",
    image: null,
    cost: null,
    code: null,
    price: null,
    priceModifier: 0,
    suppliers: [],
    subcomponents: [],
    options: [],
    isProduct: false,
    categories: [],
    parameters: [],
    qty: {
      value: 1,
      formula: "",
      variable: "",
      hierarchy: ["parent"],
    },
  };
};

const createOption = (id) => {
  return {
    _id: "newOption",
    nodeKey: id,
    name: "Option",
    subcomponents: [],
    parameters: [],
    required: true,
    multiple: false,
    qty: {
      value: 1,
      formula: "",
      variable: "",
      hierarchy: ["parent"],
    },
  };
};

// this is our useStore hook that we can use in our components to get parts of the store and call actions
const useStore = create()(
  temporal(
    (set, get) => ({
      nodes: [
        {
          id: "parent",
          position: {
            x: 0,
            y: 0,
          },
          type: "component",
          dragHandle: ".drag-handle",
          data: {
            data: {
              _id: "newComponent",
              nodeKey: "parent",
              name: "Component",
              description: "",
              image: null,
              code: null,
              cost: null,
              price: null,
              priceModifier: 0,
              suppliers: [],
              subcomponents: [],
              options: [],
              isProduct: false,
              categories: [],
              parameters: [],
              qty: {
                value: 1,
                formula: "",
                variable: "",
                hierarchy: ["parent"],
              },
            },
          },
        },
      ],
      edges: [],
      nodeData: [],
      connectingNodeId: { current: null },
      connectingHandleId: { current: null },
      onNodesChange: (changes) => {
        set({
          nodes: applyNodeChanges(changes, get().nodes),
        });
      },
      onEdgesChange: (changes) => {
        set({
          edges: applyEdgeChanges(changes, get().edges),
        });
      },
      onConnectStart: (_, { nodeId, handleId }) => {
        set({
          connectingNodeId: { current: nodeId },
          connectingHandleId: { current: handleId },
        });
      },
      onConnect: (params) => {
        // reset the start node on connections
        set({
          connectingNodeId: { current: null },
          connectingHandleId: { current: null },
        });

        const edges = get().edges;
        const nodes = get().nodes;

        // if you're dragging between option and component, and edge exists, delete it
        if (
          ["right", "left"].includes(params.sourceHandle) ||
          ["right", "left"].includes(params.targetHandle)
        ) {
          let edge = edges.find(
            (e) => e.source === params.source && e.target === params.target
          );
          if (edge) {
            let otherEdges = edges.filter((e) => e.target == params.target);
            if (otherEdges.length > 1) {
              set({
                edges: edges.filter((e) => e.id !== edge.id),
                nodes: nodes.map((n) => {
                  if (n.id === params.source) {
                    n.data.data.options = n.data.data.options.filter(
                      (o) => o !== params.target
                    );
                  }
                  return { ...n };
                }),
              });
              return;
            }
          }
        }

        let edge = { ...params };
        if (params.sourceHandle === "right" || params.sourceHandle === "left") {
          // edge.style = {
          //   stroke: theme.palette.primary.main,
          // };
          edge.type = "optionedge";
          if (
            params.targetHandle === "right" ||
            params.targetHandle === "left"
          ) {
            set({
              nodes: nodes.map((n) => {
                if (n.id === params.source) {
                  n.data.data.options.push(params.target);
                }
                return { ...n };
              }),
            });
          }
        }

        edge.id = `${params.source}-${params.target}`;
        set({
          edges: addEdge(edge, get().edges),
        });
      },
      onConnectEnd: (event, screenToFlowPosition) => {
        const connectingNodeId = get().connectingNodeId;
        const connectingHandleId = get().connectingHandleId;
        const nodes = get().nodes;
        if (!connectingNodeId.current) return;

        const targetIsPane =
          event.target.classList.contains("react-flow__pane");

        // if you're dragging from an option node's sides and target is the pane, return
        if (
          (connectingHandleId.current === "right" ||
            connectingHandleId.current === "left") &&
          nodes.find((n) => n.id == connectingNodeId.current).type == "option"
        )
          return;

        if (targetIsPane) {
          // find out how many nodes are immediate children of the parent node
          // and use that as the new node's id
          let otherChildren = nodes.filter((n) =>
            isSubid(connectingNodeId.current, n.id)
          );

          let numberIds = otherChildren.map((n) => {
            let numberId = n.id.split("-").at(-1).split("_").at(-1);
            if (isNaN(numberId)) {
              return 0;
            } else {
              return parseInt(numberId);
            }
          });
          let largestId = numberIds.length ? Math.max(...numberIds) : 0;
          if (isNaN(largestId)) {
            largestId = 0;
          }

          let otherOptions = nodes.filter((n) => n.type === "option");

          let id =
            connectingHandleId.current == "right" ||
            connectingHandleId.current == "left"
              ? `${nodes[0].id}-${nodes[0].id}_option${otherOptions.length + 1}`
              : `${connectingNodeId.current.split("-").at(-1)}_${
                  largestId + 1
                }`;

          const position = screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
          });

          if (connectingHandleId.current === "right") {
            position.x = position.x + 150;
            position.y = position.y - 28;
          } else if (connectingHandleId.current === "left") {
            position.x = position.x - 150;
            position.y = position.y - 28;
          } else {
            id = `${connectingNodeId.current}-${id}`;
          }

          const type =
            connectingHandleId.current == "right" ||
            connectingHandleId.current == "left"
              ? "option"
              : "component";

          const { nodes: newNodes, edges: newEdges } = addNode({
            id,
            position,
            type,
            parentId: connectingNodeId.current,
            nodes,
            edges: get().edges,
            handleSource: connectingHandleId.current,
          });

          set({
            nodes: newNodes,
            edges: newEdges,
          });
        }
      },
      setNodes: (nodes) => {
        set({ nodes });
      },
      setEdges: (edges) => {
        set({ edges });
      },
      setNodeData: (nodeData) => {
        set({ nodeData });
      },
      editComponentNode: ({ id, field, value, type }) => {
        // add timer to see how long it takes to edit a node
        console.time("editNode");
        let node = _.cloneDeep(get().nodes.find((n) => n.id === id));
        if (field === "qty") {
          let isOverride = !isValidChild(id);
          value.override = node.data.data.edit ? false : isOverride;
          value.name = "qty";
        }

        node.data = {
          ...node.data,
          data: handleComponentEdit({
            component: { ...node.data.data },
            field,
            value,
            type,
          }),
        };
        let index = get().nodes.findIndex((n) => n.id === id);
        let newNodes = _.cloneDeep(get().nodes);
        newNodes[index] = node;
        // if it's a qty change, update parent parameters as well
        if (field === "qty") {
          let parent = newNodes.find(
            (n) => n.id === id.slice(0, id.lastIndexOf("-"))
          );

          let parentParams = parent.data.data.parameters;
          let existingQty = parentParams.find(
            (p) =>
              p.name === "qty" &&
              (p.hierarchy[1] == id || p.hierarchy[1] == id.split("-").at(-1))
          );
          if (existingQty) {
            existingQty = { ...value };
            parentParams = parentParams.map((p) => {
              if (
                p.name === "qty" &&
                (p.hierarchy[1] == id || p.hierarchy[1] == id.split("-").at(-1))
              ) {
                return { ...value, hierarchy: p.hierarchy };
              }
              return p;
            });
          } else {
            parentParams.push({
              ...value,
              hierarchy: ["parent", id],
            });
          }
          parent.data.data.parameters = parentParams;
          let parentIndex = newNodes.findIndex((n) => n.id === parent.id);
          newNodes[parentIndex] = { ...parent };
        }
        // check if this is an existing component, and so if you're editing it, edit other
        // instances of the same component
        let otherInstances = newNodes.filter(
          (n) =>
            n.data.data._id === node.data.data._id &&
            n.id !== id &&
            n.data.data._id !== "newComponent"
        );

        if (otherInstances.length > 0 && field !== "qty") {
          otherInstances.forEach((otherN) => {
            let otherNode = _.cloneDeep(otherN);
            otherNode.data = {
              ...otherNode.data,
              data: handleComponentEdit({
                component: { ...otherNode.data.data },
                field,
                value,
                type,
              }),
            };

            let index = newNodes.findIndex((no) => no.id === otherNode.id);
            newNodes[index] = otherNode;
          });
        }
        console.timeEnd("editNode");
        set({ nodes: newNodes });
      },
      editOptionNode: ({ id, field, value, type }) => {
        let node = get().nodes.find((n) => n.id === id);
        node.data = {
          ...node.data,
          data: {
            ...handleOptionEdit({
              option: { ...node.data.data },
              field,
              value,
              type,
            }),
          },
        };
        let index = get().nodes.findIndex((n) => n.id === id);
        let newNodes = [...get().nodes];
        newNodes[index] = node;
        set({ nodes: newNodes });
      },
      nodeToSwitch: null,
      setNodeToSwitch: (nodeId) => {
        set({ nodeToSwitch: null });
        set({ nodeToSwitch: nodeId });
      },
      switchNode: ({ oldNodeId, newNodeData, components, options }) => {
        if (!oldNodeId) {
          oldNodeId = get().nodeToSwitch.nodeKey;
        }
        let prevNodes = get().nodes;
        let prevEdges = get().edges;
        const { nodes, edges } = switchNode({
          oldNodeId,
          newNodeData,
          components,
          options,
          prevNodes,
          prevEdges,
        });

        nodes.forEach((n) => {
          n.hidden = false;
          n.data.data.collapsed = false;
        });
        edges.forEach((e) => {
          e.hidden = false;
        });

        set({
          nodes: nodes,
          edges: edges,
          nodeToSwitch: null,
        });
      },
      switchNodesMultiple: ({
        oldNodeId,
        newNodesData,
        components,
        options,
      }) => {
        oldNodeId = get().nodeToSwitch.nodeKey;
        const oldNode = get().nodes.find((n) => n.id === oldNodeId);
        let prevNodes = get().nodes;
        let prevEdges = get().edges;
        let newNodes = [];
        let newEdges = [];
        newNodesData.forEach((newNodeData, i) => {
          if (i === 0) {
            const { nodes, edges } = switchNode({
              oldNodeId,
              newNodeData,
              components,
              options,
              prevNodes,
              prevEdges,
            });
            newNodes = nodes;
            newEdges = edges;
          } else {
            let { nodes: tempNodes, edges: tempEdges } = addNode({
              id: oldNodeId,
              position: {
                ...oldNode.position,
                x: oldNode.position.x + i * 320,
              },
              type: "component",
              parentId: oldNodeId.slice(0, oldNodeId.lastIndexOf("-")),
              nodes: newNodes,
              edges: newEdges,
              handleSource: "bottom",
            });
            const { nodes, edges } = switchNode({
              oldNodeId,
              newNodeData,
              components,
              options,
              prevNodes: tempNodes,
              prevEdges: tempEdges,
            });
            newNodes = nodes;
            newEdges = edges;
          }
        });

        // newNodes = newNodes.map((n) => {
        //   if (!prevNodes.find((pn) => pn.id === n.id)) {
        //     return { ...n, selected: true, hidden: false };
        //   }
        //   return { ...n, selected: false, hidden: false };
        // });

        set({
          nodes: newNodes,
          edges: newEdges,
          nodeToSwitch: null,
        });
      },
      reset: () => {
        set({
          nodes: [
            {
              id: "parent",
              position: {
                x: 0,
                y: 0,
              },
              type: "component",
              dragHandle: ".drag-handle",
              data: {
                data: {
                  _id: "newComponent",
                  nodeKey: "parent",
                  name: "Component",
                  description: "",
                  image: null,
                  cost: null,
                  code: null,
                  price: null,
                  priceModifier: 0,
                  suppliers: [],
                  subcomponents: [],
                  options: [],
                  isProduct: false,
                  categories: [],
                  parameters: [],
                  qty: {
                    value: 1,
                    formula: "",
                    variable: "",
                    hierarchy: ["parent"],
                  },
                },
              },
            },
          ],
          edges: [],
          nodeData: [],
          connectingNodeId: { current: null },
          connectingHandleId: { current: null },
          nodeToSwitch: null,
        });
      },
      //
      saveNode: () => {},
      deleteNode: (nodeId) => {
        let prevNodes = get().nodes;
        // remove node from nodes
        let newNodes = _.cloneDeep(prevNodes).filter((n) => n.id !== nodeId);
        // remove children of node from nodes
        // newNodes = newNodes.filter((n) => {
        //   // Split the current node's ID and the old node's ID by dashes
        //   const nIdParts = n.id.split("-");
        //   const oldIdParts = nodeId.split("-");

        //   // Check if the current node's ID starts with the old node's ID
        //   if (nIdParts.length >= oldIdParts.length) {
        //     for (let i = 0; i < oldIdParts.length; i++) {
        //       if (nIdParts[i] !== oldIdParts[i]) {
        //         return true; // If any part is different, keep the node
        //       }
        //     }
        //     return false; // All parts match, exclude the node
        //   } else {
        //     return true; // If the current node's ID is shorter, keep the node
        //   }
        // });

        let parentNode = newNodes.find(
          (n) => n.id === nodeId.slice(0, nodeId.lastIndexOf("-"))
        );

        if (parentNode) {
          parentNode.data.data.subcomponents =
            parentNode.data.data.subcomponents.filter((id) => id !== nodeId);

          if (parentNode.data.data.options) {
            parentNode.data.data.options = parentNode.data.data.options.filter(
              (id) => id !== nodeId
            );
          }

          newNodes = newNodes.map((n) => {
            if (n.id === parentNode.id) {
              return { ...parentNode, data: { ...parentNode.data } };
            }
            return n;
          });
          parentNode.data.data.parameters =
            parentNode.data.data.parameters.filter(
              (p) =>
                p.hierarchy[1] !== nodeId &&
                p.hierarchy[1] !== nodeId.split("-").at(-1)
            );
        }
        newNodes = newNodes.filter((n) => {
          // Split the current node's ID and the old node's ID by dashes
          const nIdParts = n.id.split("-");
          const oldIdParts = nodeId.split("-");

          // Check if the current node's ID starts with the old node's ID
          if (nIdParts.length >= oldIdParts.length) {
            for (let i = 0; i < oldIdParts.length; i++) {
              if (nIdParts[i] !== oldIdParts[i]) {
                return true; // If any part is different, keep the node
              }
            }
            return false; // All parts match, exclude the node
          } else {
            return true; // If the current node's ID is shorter, keep the node
          }
        });

        let newEdges = get().edges.filter(
          (e) => e.source !== nodeId && e.target !== nodeId
        );
        // if there are no edges connecting an option node due to the deletion of a
        // component node, create a connection between the option and the main node
        let optionNodes = newNodes.filter((n) => n.type === "option");
        optionNodes.forEach((o) => {
          let connectingEdges = newEdges.filter((e) => e.target === o.id);
          if (connectingEdges.length === 0) {
            let id = newNodes[0].id;
            newEdges.push({
              id: `${id}-${o.id}`,
              source: id,
              sourceHandle: "right",
              target: o.id,
              targetHandle: "left",
              type: "optionedge",
            });
          }
        });
        set({
          nodes: newNodes,
          edges: newEdges,
        });
      },
      duplicateNode: ({ data, options, components }) => {
        let prevNodes = get().nodes;
        const node = prevNodes.find((n) => n.id === data.nodeKey);
        let dataCopy = _.cloneDeep({ ...data });
        let id = `${node.id}Copy${prevNodes.length + 1}`;

        dataCopy.parameters = data.parameters.map((p) => {
          return {
            ...p,
            hierarchy: ["parent", ...p.hierarchy.slice(1)],
            override: false,
          };
        });
        dataCopy.subcomponents = data.subcomponents.map((s) => {
          return `${id}-${s.slice(s.lastIndexOf("-") + 1, s.length)}`;
        });
        delete dataCopy.parameterOverrides;
        dataCopy._id = "newComponent";
        const newNode = {
          ...node,
          id,
          position: {
            x: node.position.x + 100,
            y: node.position.y + 100,
          },
          dragging: false,
          selected: true,
          dragHandle: ".drag-handle",
          data: {
            data: {
              ...dataCopy,
              edit: true,
              saved: false,
              name: `${data.name}Copy`,
              nodeKey: `${node.id}Copy${prevNodes.length + 1}`,
            },
          },
        };

        prevNodes.push(newNode);
        // edit the parent to reflect the change
        let parent = prevNodes.find(
          (n) => n.id === data.nodeKey.slice(0, data.nodeKey.lastIndexOf("-"))
        );
        let qtyParamToCopy = parent.data.data.parameters.find(
          (p) =>
            p.name === "qty" &&
            (p.hierarchy[1] === data.nodeKey || p.hierarchy[1] === data._id) &&
            p.hierarchy.length === 2
        );
        if (qtyParamToCopy) {
          parent.data.data.parameters.push({
            ...qtyParamToCopy,
            hierarchy: ["parent", id],
          });
        }

        if (!Object.hasOwn(data, "options")) {
          parent.data.data.options = parent.data.data.options.concat(id);
        } else {
          parent.data.data.subcomponents =
            parent.data.data.subcomponents.concat(id);
        }

        prevNodes = prevNodes.map((n) => {
          if (n.id === parent.id) {
            return { ...parent };
          }
          return n;
        });

        let prevEdges = get().edges;

        prevEdges = prevEdges.concat({
          id: `${parent.id}-${id}`,
          source: parent.id,
          sourceHandle: "bottom",
          target: id,
          targetHandle: "top",
          type: "smoothstep",
        });

        let flowData = _.cloneDeep(
          getFlowData(prevNodes, prevEdges, data.nodeKey)
        );
        let replacedFlowData = JSON.parse(
          JSON.stringify(flowData).replaceAll(data.nodeKey, newNode.id)
        );
        replacedFlowData.nodes.unshift(newNode);

        newNode.data.data.flowData = JSON.stringify(replacedFlowData);
        newNode.data.data.nodeKey = newNode.id;

        let newNodes = addSubnodes({
          prevNodes,
          data: newNode.data.data,
          components,
          options,
        });
        let newEdges = prevEdges.concat(
          flowData.edges.map((e) => {
            e.source = e.source.replaceAll(data.nodeKey, newNode.id);
            e.target = e.target.replaceAll(data.nodeKey, newNode.id);
            return {
              ...e,
              id: `${e.source}-${e.target}`,
            };
          })
        );

        set({
          nodes: newNodes,
          edges: newEdges,
        });
        return newNode;
      },
      // determine if there are subnodes, if so, return them with hidden = true
      collapseNode: (nodeId) => {
        let prevNodes = get().nodes;
        let prevEdges = get().edges;

        let newNodes = prevNodes.map((n) => {
          if (n.id === nodeId) {
            return {
              ...n,
              data: {
                ...n.data,
                data: {
                  ...n.data.data,
                  collapsed: true,
                },
              },
            };
          } else if (isSubid(nodeId, n.id)) {
            return {
              ...n,
              hidden: true,
            };
          }
          return n;
        });
        let newEdges = prevEdges.map((e) => {
          if (isSubid(nodeId, e.source) || isSubid(nodeId, e.target)) {
            return {
              ...e,
              hidden: true,
            };
          }
          return e;
        });
        newNodes = newNodes.map((n) => {
          if (n.type === "option") {
            let areAllSourcesHidden = newEdges
              .filter((e) => e.target === n.id)
              .every((e) => newNodes.find((n) => n.id == e.source).hidden);
            if (areAllSourcesHidden) {
              return {
                ...n,
                hidden: true,
              };
            } else {
              return {
                ...n,
                hidden: false,
              };
            }
          }
          return n;
        });
        set({
          nodes: newNodes,
          edges: newEdges,
        });
      },
      expandNode: (nodeId) => {
        let prevNodes = get().nodes;
        let prevEdges = get().edges;

        let newEdges = prevEdges.map((e) => {
          if (isSubid(nodeId, e.source) || isSubid(nodeId, e.target)) {
            return {
              ...e,
              hidden: false,
            };
          }
          return e;
        });
        let newNodes = prevNodes.map((n) => {
          if (n.id === nodeId) {
            return {
              ...n,
              data: {
                ...n.data,
                data: {
                  ...n.data.data,
                  collapsed: false,
                },
              },
            };
          } else if (isSubid(nodeId, n.id)) {
            return {
              ...n,
              hidden: false,
              data: {
                ...n.data,
                data: {
                  ...n.data.data,
                  collapsed: false,
                },
              },
            };
          }
          return n;
        });
        newNodes = newNodes.map((n) => {
          if (n.type === "option") {
            let areAllSourcesHidden = newEdges
              .filter((e) => e.target === n.id)
              .every((e) => newNodes.find((n) => n.id == e.source).hidden);
            if (areAllSourcesHidden) {
              return {
                ...n,
                hidden: true,
              };
            } else {
              return {
                ...n,
                hidden: false,
              };
            }
          }
          return n;
        });
        set({
          nodes: newNodes,
          edges: newEdges,
        });
      },
      getVariables: () => {
        let prevNodes = get().nodes;
        let variables = [];
        prevNodes.forEach((n) => {
          if (n.data.data?.parameters) {
            n.data.data.parameters.forEach((p) => {
              if (p.variable) {
                if (p.name === "qty" && n.type !== "option") {
                  let subcompId = n.data.data.subcomponents.find(
                    (s) =>
                      s.endsWith(p.hierarchy.join("-")) ||
                      s.endsWith(p.hierarchy[1])
                  );
                  let subcomp = prevNodes.find((no) => no.id === subcompId);
                  if (subcomp) {
                    variables.push({
                      ...p,
                      nodeKey: subcompId,
                      label: subcomp.data.data.name,
                    });
                  }
                } else {
                  variables.push({
                    ...p,
                    nodeKey: n.id,
                    label: n.data.data.name,
                  });
                }
              }
            });
          }
        });
        return variables;
      },
      editVariable: ({ parameter }) => {
        set({
          nodes: get().nodes.map((n) => {
            if (Object.hasOwn(parameter, "source")) {
              if (n.id === parameter.nodeKey) {
                return {
                  ...n,
                  data: {
                    ...n.data,
                    data: {
                      ...n.data.data,
                      parameters: n.data.data.parameters.map((p) => {
                        if (
                          p.name === parameter.name &&
                          p.source === parameter.source
                        ) {
                          return parameter;
                        }
                        return p;
                      }),
                    },
                  },
                };
              }
            } else if (parameter.name === "qty") {
              if (
                n.id ===
                parameter.nodeKey.slice(0, parameter.nodeKey.lastIndexOf("-"))
              ) {
                return {
                  ...n,
                  data: {
                    ...n.data,
                    data: {
                      ...n.data.data,
                      parameters: n.data.data.parameters.map((p) => {
                        if (p.name === parameter.name) {
                          return parameter;
                        }
                        return p;
                      }),
                    },
                  },
                };
              }
            } else if (n.id === parameter.nodeKey) {
              return {
                ...n,
                data: {
                  ...n.data,
                  data: {
                    ...n.data.data,
                    parameters: n.data.data.parameters.map((p) => {
                      if (p.name === parameter.name) {
                        return parameter;
                      }
                      return p;
                    }),
                  },
                },
              };
            }
            return { ...n, data: { ...n.data } };
          }),
        });
      },
    }),
    {
      equality: (prev, curr) => {
        return areEqual(prev, curr);
      },
      partialize: (state) => {
        return {
          nodes: state.nodes,
          edges: state.edges,
        };
      },
      // handleSet: (handleSet) =>
      //   _.throttle((state) => {
      //     handleSet(state);
      //   }, 1000),
    }
  )
);

const switchEdges = (oldNodeId, newNodeKey, newFlowData, prevEdges) => {
  let newEdges = prevEdges.map((e) => {
    if (e.target === oldNodeId) {
      return {
        ...e,
        target: newNodeKey,
        id: `${e.source}-${newNodeKey}`,
      };
    }
    return e;
  });

  newEdges = newEdges.filter(
    (e) =>
      e.source !== oldNodeId &&
      e.target !== oldNodeId &&
      nodeStartsWith(e.source, oldNodeId)
  );
  // remove duplicate id edges from newEdges
  let seen = new Set();
  newEdges = newEdges.filter((e) => {
    let duplicate = seen.has(e.id);
    seen.add(e.id);
    return !duplicate;
  });

  // replace target of previous edges that pointed to the old node with the new node

  newEdges = newEdges.concat(newFlowData.edges);
  return newEdges;
};

const switchNodeData = (
  prevNodeData,
  parameterOverrides,
  oldNodeId,
  newNodeKey
) => {
  console.log("prevNodeData", prevNodeData);
  const oldNodeDataIndex = prevNodeData.findIndex(
    (nodeData) => nodeData.nodeKey === oldNodeId
  );
  if (oldNodeDataIndex === -1) {
    console.error(`Node data for node with id ${oldNodeId} not found.`);
    return prevNodeData;
  }
  // handle subcomponents
  const parentNode = prevNodeData.find(
    (nodeData) =>
      nodeData.subcomponents.includes(oldNodeId) ||
      nodeData.options?.includes(oldNodeId)
  );
  if (parentNode) {
    parentNode.subcomponents = parentNode.subcomponents.filter(
      (id) => id !== oldNodeId
    );
    parentNode.subcomponents.push(newNodeKey);
    if (parentNode.options) {
      parentNode.options = parentNode.options.map((id) =>
        id === oldNodeId ? newNodeKey : id
      );
    }
  }

  let newNodeDataArray = [...prevNodeData];

  if (parameterOverrides.length > 0) {
    if (!newNodeDataArray[0].parameterOverrides) {
      newNodeDataArray[0].parameterOverrides = [];
    }
    newNodeDataArray[0].parameterOverrides =
      newNodeDataArray[0].parameterOverrides.concat(parameterOverrides);
  }

  newNodeDataArray.splice(oldNodeDataIndex, 1);

  return newNodeDataArray;
};

export default useStore;

function switchNode({
  oldNodeId,
  newNodeData,
  components,
  options,
  prevNodes,
  prevEdges,
}) {
  let prevNodeData = prevNodes.map((n) => n.data.data);

  const oldNodeIndex = prevNodes.findIndex((node) => node.id === oldNodeId);
  if (oldNodeIndex === -1) {
    console.error(`Node with id ${oldNodeId} not found.`);
    return prevNodes;
  }

  // replace new node id with hierarchy of old one leading up to it
  let splitOldId = oldNodeId.split("-");
  let partToAddToNewId = splitOldId.slice(0, splitOldId.length - 1);
  let newNodeKey;
  if (partToAddToNewId.length === 0) {
    newNodeKey = newNodeData._id;
  } else {
    newNodeKey = partToAddToNewId.join("-") + "-" + newNodeData._id;
  }
  newNodeData.flowData = newNodeData.flowData.replaceAll(
    newNodeData.nodeKey,
    newNodeKey
  );
  newNodeData.nodeKey = newNodeKey;

  let newFlowData = JSON.parse(newNodeData.flowData);

  let oldNode = prevNodes.find((n) => n.id === oldNodeId);
  // set position of first new node to position of old node
  // adjust position of all other nodes to be relative to the first new node
  let difference = {
    x: oldNode.position.x - newFlowData.nodes[0].position.x,
    y: oldNode.position.y - newFlowData.nodes[0].position.y,
  };

  newFlowData.nodes = newFlowData.nodes.map((n, i) => {
    delete n.positionAbsolute;

    if (i === 0) {
      n.position = oldNode.position;
      // n.positionAbsolute = oldNode.positionAbsolute;

      return n;
    }
    let x = n.position.x + difference.x;
    let y = n.position.y + difference.y;
    return {
      ...n,
      position: {
        x: x,
        y: y,
      },
      // positionAbsolute: {
      //   x: n.positionAbsolute.x + xDifference,
      //   y: n.positionAbsolute.y + yDifference,
      // },
    };
  });
  let newNodes = prevNodes.filter((n) => {
    // Split the current node's ID and the old node's ID by dashes
    const nIdParts = n.id.split("-");
    const oldIdParts = oldNodeId.split("-");

    // Check if the current node's ID starts with the old node's ID
    if (nIdParts.length >= oldIdParts.length) {
      for (let i = 0; i < oldIdParts.length; i++) {
        if (nIdParts[i] !== oldIdParts[i]) {
          return true; // If any part is different, keep the node
        }
      }
      return false; // All parts match, exclude the node
    } else {
      return true; // If the current node's ID is shorter, keep the node
    }
  });

  let newEdges = switchEdges(oldNodeId, newNodeKey, newFlowData, prevEdges);

  let parameterOverrides = [];
  if (newNodeData.parameterOverrides && oldNodeIndex > 0) {
    parameterOverrides = newNodeData.parameterOverrides.map((p) => {
      return {
        ...p,
        hierarchy: [...newNodeKey.split("-").slice(0, -1), ...p.hierarchy],
      };
    });
  } else if (newNodeData.parameterOverrides) {
    parameterOverrides = newNodeData.parameterOverrides.map((p) => {
      return {
        ...p,
      };
    });
  }

  let newNodeDataArray = switchNodeData(
    prevNodeData,
    parameterOverrides,
    oldNodeId,
    newNodeKey
  );

  // elevate parameterOverrides to node[0] if it exists
  if (
    newNodeData.parameterOverrides &&
    oldNodeIndex > 0 &&
    newNodes[0].data.data
  ) {
    if (!newNodes[0].data.data?.parameterOverrides) {
      newNodes[0].data.data.parameterOverrides = [];
    }
    newNodes[0].data.data.parameterOverrides =
      newNodes[0].data.data.parameterOverrides.concat(
        newNodeData.parameterOverrides.map((p) => {
          return {
            ...p,
            hierarchy: [newNodeKey, ...p.hierarchy],
          };
        })
      );
  }
  let newNodesToAdd = [];

  for (let i = 0; i < newFlowData.nodes.length; i++) {
    let n = newFlowData.nodes[i];
    let nData =
      components.find((c) => c._id === n.id.split("-").at(-1)) ||
      options.find((o) => o._id === n.id.split("-").at(-1));
    nData = nData ? _.cloneDeep(nData) : null;
    let duplicate = newNodeData?.duplicate && newNodeData?._id === n?.id;
    if (duplicate) {
      nData.nodeKey = "parent";
      nData.flowData = nData.flowData.replaceAll(nData._id, "parent");
      nData.name = newNodeData.name;
    } else {
      duplicate = false;
    }
    if (
      (i == 0 && !duplicate && !newNodeData.edit) ||
      Object.hasOwn(nData, "required")
    ) {
      nData.collapsed = true;
    } else {
      nData.collapsed = false;
    }
    if (!newNodeData.duplicate && !newNodeData.edit && i > 0) {
      n.hidden = true;
    } else {
      n.hidden = n.hidden ? n.hidden : false;
    }

    let edit = newNodeData.edit && newNodeData._id === n.id;

    n.data = {
      data: {
        ...nData,
        duplicate: duplicate,
        edit: !!edit,
      },
    };

    let qtyParam = newNodeData.parameters.find(
      (p) =>
        p.name === "qty" &&
        p.hierarchy[1] == n.id.split("-").at(-1) &&
        p.hierarchy.length === 2
    );
    let qtyOverride = parameterOverrides.find(
      (p) => p.name === "qty" && p.hierarchy.join("-") === n.id
    );
    let parent = [...newNodes, ...newNodesToAdd].find(
      (no) => n.id.split("-").slice(0, -1).join("-") === no.id
    );
    if (parent) {
      if (i == 0 && !Object.hasOwn(newNodeData, "required")) {
        // let qtyOfReplaced = parent.data.data.parameters.find(
        //   (p) =>
        //     p.name === "qty" &&
        //     p.hierarchy[1] !== oldNodeId &&
        //     p.hierarchy[1] !== oldNodeId.split("-").at(-1)
        // );
        parent.data.data.parameters = parent.data.data.parameters.filter(
          (p) =>
            p.hierarchy[1] !== oldNodeId &&
            p.hierarchy[1] !== oldNodeId.split("-").at(-1)
        );
        parent.data.data.parameters.push({
          ...newNodeData.qty,
          name: "qty",
          override: false,
          hierarchy: ["parent", newNodeKey],
        });
      }
      // parent.data.data.parameters = parent.data.data.parameters.filter(
      //   (p) => !p.hierarchy.includes(oldNodeId)
      // );
      qtyParam = parent.data.data.parameters.find(
        (p) =>
          p.name === "qty" &&
          p.hierarchy[1] == n.id.split("-").at(-1) &&
          p.hierarchy.length === 2
      );
    }

    if (qtyOverride) {
      n.data.data.qty = qtyOverride;
    } else if (qtyParam) {
      n.data.data.qty = qtyParam;
    }
    // else {
    //   let parent = newNodesToAdd.find(
    //     (no) => n.id.split("-").slice(0, -1).join("-") === no.id
    //   );
    //   // if (parent.data?.data) {
    //   //   qtyParam = parent.data.data.parameters.find(
    //   //     (p) =>
    //   //       p.name === "qty" &&
    //   //       p.hierarchy[1] === n.id.split("-").at(-1) &&
    //   //       p.hierarchy.length === 2
    //   //   );
    //   //   if (qtyParam) {
    //   //     n.data.data.qty = qtyParam;
    //   //   }
    //   // }
    // }
    // look for parameter overrides
    let parameterOverridesForThisComponent = parameterOverrides.filter(
      (p) =>
        (p.name !== "qty" && p.hierarchy.join("-") === n.id) ||
        (p.name === "qty" && p.hierarchy.slice(0, -1).join("-") === n.id)
    );

    if (parameterOverridesForThisComponent.length > 0) {
      parameterOverridesForThisComponent.forEach((p) => {
        if (p.name === "qty") {
          return;
        }
        let index = n.data.data.parameters.findIndex(
          (param) => param.name === p.name && p.name !== "qty"
        );
        if (index > -1) {
          n.data.data.parameters[index] = {
            ...p,
            hierarchy: n.data.data.parameters[index].hierarchy,
          };
        }
      });
      parameterOverridesForThisComponent.forEach((p) => {
        if (p.name !== "qty") {
          return;
        }
        let index = n.data.data.parameters.findIndex(
          (param) =>
            param.name === p.name && param.hierarchy[1] === p.hierarchy.at(-1)
        );
        if (index > -1) {
          n.data.data.parameters[index] = {
            ...p,
            hierarchy: n.data.data.parameters[index].hierarchy,
          };
        } else {
          n.data.data.parameters.push({
            ...p,
            hierarchy: ["parent", p.hierarchy.at(-1)],
          });
        }
      });
    }

    newNodeDataArray.push({ ...n.data.data, nodeKey: n.id });

    newNodesToAdd.push(n);
  }

  // add new nodes
  newNodes = newNodes.concat(newNodesToAdd.filter((n) => n !== undefined));
  newNodes = newNodes.map((n) => {
    if (n.data.data?.subcomponents) {
      n.data.data.subcomponents = n.data.data.subcomponents.map((s) =>
        s._id ? `${n.id}-${s._id}` : `${s}`
      );
    }
    if (n.data.data?.options) {
      n.data.data.options = n.data.data.options.map((o) =>
        o._id ? `${n.id}-${o._id}` : `${o}`
      );
    }
    n.data.data.nodeKey = n.id;
    delete n.data.data.flowData;
    return n;
  });

  newNodes.forEach((n, i) => {
    if (i === 0) {
      n.hidden = false;
      n.data.data.collapsed = false;
    }

    if (n.data.data.collapsed) {
      let children = newNodes.filter((child) => isSubid(n.id, child.id));
      children.forEach((child) => {
        child.hidden = true;
      });
    }
    if (n.type == "option" && i !== 0) {
      let areAllSourcesHidden = newEdges
        .filter((e) => e.target == n.id)
        .every((e) => newNodes.find((n) => n.id == e.source).hidden);
      if (areAllSourcesHidden) {
        n.hidden = true;
      } else {
        n.hidden = false;
      }
    }
  });

  newNodes = newNodes.map((n, i) => {
    return {
      ...n,
      hidden: i == 0 ? false : n.hidden,
      data: {
        ...n.data,
        data: {
          ...n.data.data,
          collapsed: i == 0 ? false : handleCollapsed(n, newNodes),
        },
      },
    };
  });
  if (newNodeData.freshCreate) {
    newNodes = newNodes.map((n) => {
      let prevNode = prevNodes.find(
        (pn) => pn.id === n.id || pn.data.data.name === n.data.data.name
      );
      let edit = prevNode.data.data._id === n.data.data._id;
      let hidden = prevNode ? prevNode.hidden : n.hidden;
      let collapsed = prevNode
        ? prevNode.data.data.collapsed
        : n.data.data.collapsed;

      return {
        ...n,
        hidden: hidden,
        data: {
          ...n.data,
          data: {
            ...n.data.data,
            collapsed: collapsed,
            edit: prevNode.data.data.edit ? prevNode.data.data.edit : !edit,
          },
        },
      };
    });
  }

  return {
    nodes: newNodes,
    edges: newEdges,
    nodeToSwitch: null,
  };
}

function nodeStartsWith(newNode, oldNode) {
  const nIdParts = newNode.split("-");
  const oldIdParts = oldNode.split("-");

  // Check if the current node's ID starts with the old node's ID
  if (nIdParts.length >= oldIdParts.length) {
    for (let i = 0; i < oldIdParts.length; i++) {
      if (nIdParts[i] !== oldIdParts[i]) {
        return true; // If any part is different, keep the node
      }
    }
    return false; // All parts match, exclude the node
  } else {
    return true; // If the current node's ID is shorter, keep the node
  }
}

function isSubid(nodeId, subcomponentId) {
  const nIdParts = nodeId.split("-");
  const subIdParts = subcomponentId.split("-");
  // Check if the current node's ID starts with the old node's ID
  if (subIdParts.length > nIdParts.length) {
    for (let i = 0; i < nIdParts.length; i++) {
      if (nIdParts[i] !== subIdParts[i]) {
        return false; // If any part is different, keep the node
      }
    }
    return true; // All parts match, exclude the node
  } else {
    return false; // If the current node's ID is shorter, keep the node
  }
}

function areEqual(prev, next) {
  // if (prev.nodes.length !== next.nodes.length) {
  //   return false;
  // }
  let isEqual = true;
  for (let i = 0; i < Math.max(prev.nodes.length, next.nodes.length); i++) {
    if (!_.isEqual(prev.nodes[i]?.data.data, next.nodes[i]?.data.data)) {
      isEqual = false;
    }
  }

  // for (let i = 0; i < Math.max(prev.edges.length, next.edges.length); i++) {
  //   if (!_.isEqual(prev.edges[i], next.edges[i])) {
  //     isEqual = false;
  //   }
  // }

  return isEqual;
}

// eslint-disable-next-line no-unused-vars
function handleCollapsed(node, newFlowData) {
  let children = node.data.data.subcomponents.map((s) =>
    newFlowData.find((n) => n.id === s)
  );
  if (children.length === 0 || children[0] === undefined) {
    return false;
  }
  if (children.every((c) => c.hidden)) {
    return true;
  } else {
    return false;
  }
}

function addSubnodes({ prevNodes, data, components, options }) {
  let newNodeKey = data.nodeKey;
  // let oldNode = prevNodes.find((n) => n.id === data.nodeKey);
  let oldNodeIndex = prevNodes.findIndex((n) => n.id === data.nodeKey);
  let newNodes = [...prevNodes];
  let newFlowData = JSON.parse(data.flowData);

  // set position of nodes
  newFlowData.nodes = newFlowData.nodes.map((n) => {
    delete n.positionAbsolute;

    return {
      ...n,
      position: {
        x: n.position.x + 100,
        y: n.position.y + 100,
      },
    };
  });
  let parameterOverrides = [];
  if (data.parameterOverrides && oldNodeIndex > 0) {
    parameterOverrides = data.parameterOverrides.map((p) => {
      return {
        ...p,
        hierarchy: [...newNodeKey.split("-").slice(0, -1), ...p.hierarchy],
      };
    });
  } else if (data.parameterOverrides) {
    parameterOverrides = data.parameterOverrides.map((p) => {
      return {
        ...p,
      };
    });
  }
  if (data.parameterOverrides && oldNodeIndex > 0 && newNodes[0].data.data) {
    if (!newNodes[0].data.data?.parameterOverrides) {
      newNodes[0].data.data.parameterOverrides = [];
    }
    newNodes[0].data.data.parameterOverrides =
      newNodes[0].data.data.parameterOverrides.concat(
        data.parameterOverrides.map((p) => {
          return {
            ...p,
            hierarchy: [newNodeKey, ...p.hierarchy],
          };
        })
      );
  }
  let newNodesToAdd = [];

  for (let i = 1; i < newFlowData.nodes.length; i++) {
    let n = newFlowData.nodes[i];
    let nData =
      components.find((c) => c._id === n.id.split("-").at(-1)) ||
      options.find((o) => o._id === n.id.split("-").at(-1));
    nData = nData
      ? _.cloneDeep(nData)
      : _.cloneDeep(prevNodes.find((no) => no.id === n.id).data.data);
    let duplicate = data.duplicate && data._id === n.id;
    if (duplicate) {
      nData.nodeKey = "parent";
      nData.flowData = nData.flowData.replaceAll(nData._id, "parent");
      nData.name = data.name;
    }
    if (
      (i == 0 && !duplicate && !data.edit) ||
      Object.hasOwn(nData, "required")
    ) {
      nData.collapsed = true;
    } else {
      nData.collapsed = false;
    }
    if (!data.duplicate && !data.edit && i > 0) {
      n.hidden = true;
    } else {
      n.hidden = n.hidden ? n.hidden : false;
    }

    n.data = {
      data: {
        ...nData,
        duplicate: duplicate,
        edit: data.edit && data._id === n.id,
      },
    };

    n.selected = true;

    let qtyParam = data.parameters.find(
      (p) =>
        p.name === "qty" &&
        p.hierarchy[1] == n.id.split("-").at(-1) &&
        p.hierarchy.length === 2
    );
    let qtyOverride = parameterOverrides.find(
      (p) => p.name === "qty" && p.hierarchy.join("-") === n.id
    );
    let parent = prevNodes.find(
      (no) => n.id.split("-").slice(0, -1).join("-") === no.id
    );
    if (parent) {
      qtyParam = parent.data.data.parameters.find(
        (p) =>
          p.name === "qty" &&
          p.hierarchy[1] == n.id.split("-").at(-1) &&
          p.hierarchy.length === 2
      );
    }

    if (qtyOverride) {
      n.data.data.qty = qtyOverride;
    } else if (qtyParam) {
      n.data.data.qty = qtyParam;
    }
    // else {
    //   let parent = prevNodes.find(
    //     (no) => n.id.split("-").slice(0, -1).join("-") === no.id
    //   );
    // }
    // look for parameter overrides
    let parameterOverridesForThisComponent = parameterOverrides.filter(
      (p) =>
        (p.name !== "qty" && p.hierarchy.join("-") === n.id) ||
        (p.name === "qty" && p.hierarchy.slice(0, -1).join("-") === n.id)
    );

    if (parameterOverridesForThisComponent.length > 0) {
      parameterOverridesForThisComponent.forEach((p) => {
        if (p.name === "qty") {
          return;
        }
        let index = n.data.data.parameters.findIndex(
          (param) => param.name === p.name && p.name !== "qty"
        );
        if (index > -1) {
          n.data.data.parameters[index] = {
            ...p,
            hierarchy: n.data.data.parameters[index].hierarchy,
          };
        }
      });
      parameterOverridesForThisComponent.forEach((p) => {
        if (p.name !== "qty") {
          return;
        }
        let index = n.data.data.parameters.findIndex(
          (param) =>
            param.name === p.name && param.hierarchy[1] === p.hierarchy.at(-1)
        );
        if (index > -1) {
          n.data.data.parameters[index] = {
            ...p,
            hierarchy: n.data.data.parameters[index].hierarchy,
          };
        } else {
          n.data.data.parameters.push({
            ...p,
            hierarchy: ["parent", p.hierarchy.at(-1)],
          });
        }
      });
    }
    newNodesToAdd.push(n);
  }
  newNodes = newNodes.concat(newNodesToAdd.filter((n) => n !== undefined));
  newNodes = newNodes.map((n) => {
    if (n.data.data?.subcomponents) {
      n.data.data.subcomponents = n.data.data.subcomponents.map((s) =>
        s._id ? `${n.id}-${s._id}` : `${s}`
      );
    }
    if (n.data.data?.options) {
      n.data.data.options = n.data.data.options.map((o) =>
        o._id ? `${n.id}-${o._id}` : `${o}`
      );
    }
    n.data.data.nodeKey = n.id;
    delete n.data.data.flowData;
    return n;
  });
  newNodes.forEach((n) => {
    if (n.data.data.collapsed) {
      let children = newNodes.filter((child) => isSubid(n.id, child.id));
      children.forEach((child) => {
        child.hidden = true;
      });
    }
  });
  newNodes = newNodes.map((n, i) => {
    return {
      ...n,
      data: {
        ...n.data,
        data: {
          ...n.data.data,
          collapsed: i == 0 ? false : handleCollapsed(n, newNodes),
        },
      },
    };
  });
  return newNodes;
}

function getFlowData(nodes, edges, id) {
  let flowData = { nodes: [], edges: [] };
  let nodesToAdd = nodes.filter(
    (n) => isSubid(id, n.id) && n.type !== "option"
  );
  flowData.nodes = nodesToAdd;
  flowData.edges = edges.filter((e) => {
    return nodesToAdd.some((n) => n.id === e.source || n.id === e.target);
  });
  return flowData;
}

function addNode({ id, position, type, parentId, nodes, edges, handleSource }) {
  const newNode = {
    id,
    position: position,
    type: type,
    dragHandle: ".drag-handle",
    data: {
      data: type === "option" ? createOption(id) : createComponent(id),
    },
    origin: [1, 1],
  };
  let nodesToSet = _.cloneDeep(nodes);
  nodesToSet.push(newNode);

  let parent = nodesToSet.find((n) => n.id === parentId);

  if (type === "option") {
    nodesToSet[0].data.data.options =
      nodesToSet[0].data.data.options.concat(id);
    // parent.data.data.options = parent.data.data.options.concat(id);
  } else {
    parent.data.data.subcomponents = parent.data.data.subcomponents.concat(id);
  }

  nodesToSet = nodesToSet.map((n) => {
    if (n.nodeKey === parentId) {
      return parent;
    }
    return n;
  });
  let targetHandle = "top";
  if (handleSource === "right") {
    targetHandle = "left";
  } else if (handleSource === "left") {
    targetHandle = "right";
  }
  return {
    nodes: nodesToSet,
    edges: edges.concat({
      id: `${parentId}-${id}`,
      source: parentId,
      sourceHandle: handleSource,
      target: id,
      type: type === "option" ? "optionedge" : "smoothstep",
      targetHandle: targetHandle,
    }),
  };
}

// const handleCollapsedHidden = (nodes, isFreshCreate) => {
//   if (isFreshCreate) {
//     return nodes;
//   }

//   // set which node should be collapsed
//   nodes.forEach((n, i) => {
//     if (i === 0) {
//       n.hidden = false;
//       n.data.data.collapsed = false;
//     } else {
//       if (n.type === "option") {
//         n.data.data.collapsed = true;
//       } else {
//         n.data.data.collapsed = false;
//       }
//     }
//   });

//   // go through nodes again and hide children of collapsed nodes
//   nodes.forEach((n) => {
//     if (n.data.data.collapsed) {
//       let children = nodes.filter((child) => isSubid(n.id, child.id));
//       children.forEach((child) => {
//         child.hidden = true;
//       });
//     }
//   });

//   return nodes;
// };
