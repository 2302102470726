import React from "react";
import PropTypes from "prop-types";
import { Button, CircularProgress } from "@mui/material";

function AddButton(props) {
  const { onClick, ...other } = props;
  const [loading, setLoading] = React.useState(false);

  const handleClick = () => {
    setLoading(true);
    onClick();
  };
  React.useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  return (
    <Button
      {...other}
      aria-label="edit"
      onClick={handleClick}
      sx={{ mr: 0 }}
      disabled={loading || props.disabled}
      startIcon={loading ? <CircularProgress size={20} /> : props.startIcon}
    >
      {loading ? "Loading" : props.buttonText}
    </Button>
  );
}
AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  startIcon: PropTypes.element,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default AddButton;
