import axios from "axios";
// import {
//   parseComponents,
//   parseOptions,
// } from "../components/create/componentCreate/ComponentCreate";
// import { parseOptions } from "./componentApi";
const optionApi = axios.create();

export const getOptions = async (user) => {
  const response = await optionApi.get("/api/option", {
    params: { user: user },
  });
  // let parsedOptions = parseOptions(response.data);
  return response.data;
};

export const getOption = async (id) => {
  const response = await optionApi.get(`/api/option/${id}`);
  return response.data;
};

export const updateOption = async ({ id, pack }) => {
  // if (pack.choices) {
  //   pack.choices.map((choice) => {
  //     if (!choice.id) {
  //       choice.id = choice._id;
  //     }
  //   });
  // }
  return await optionApi.patch(`/api/option/${id}`, pack);
};

export const deleteOption = async (pack) => {
  return await optionApi.delete(`/api/option`, { data: pack });
};
export const createOption = async (pack) => {
  return await optionApi.post("/api/option", pack);
};

// const parseOptions = (options) => {
//   let newOptions = [];

//   options.map((opt) => {
//     let choices = [];

//       opt.choices.map((choice) =>
//         choices.push(Object.assign({}, choice.id, { qty: choice.qty }))
//       );

//       newOptions.push(
//         Object.assign(
//           {},
//           opt,
//           { inheritedFrom: opt.inheritedFrom },
//           { choices: choices }
//         )
//       );

//   });
//   return newOptions;
// };
