import * as React from "react";
import PropTypes from "prop-types";

import { Button, Grid } from "@mui/material";

export const DocusignLogin = ({ settings }) => {
  const docusignLogin = () => {
    window.open(
      `https://account.docusign.com/oauth/auth?response_type=code&scope=signature impersonation&client_id=af7386ed-3582-4972-a306-8d229b8add35&redirect_uri=https://www.manufacture.app/api/auth/docusign`
    );
  };

  return (
    <Grid sx={{ padding: 4, width: "600", height: "775" }}>
      <Button variant="contained" onClick={() => docusignLogin()}>
        Log in to Docusign
      </Button>
      {settings.dsAccountName ? (
        <Grid>
          <Grid item>Logged in as: {settings.dsAccountName}.</Grid>
          <Grid item>{settings.dsAccountEmail}.</Grid>
        </Grid>
      ) : null}
    </Grid>
  );
};
DocusignLogin.propTypes = {
  user: PropTypes.string,
  settings: PropTypes.object,
};
