import React from "react";
import { UserContext } from "../App";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../Content";
import { updateSettings } from "../api/settingsApi";

export function useSettingsUpdate(snack) {
  const { user } = React.useContext(UserContext);
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  return useMutation({
    mutationFn: ({ pack }) => {
      return updateSettings({
        user: user.id,
        pack,
      });
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries("settings");
      console.log(data);
      queryClient.setQueryData("settings", data);

      if (snack) {
        setSnack({
          open: true,
          message: snack,
          severity: "success",
        });
      }
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Settings could not be updated.",
        severity: "error",
      });
    },
  });
}
