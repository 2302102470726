import axios from "axios";

const catalogApi = axios.create();

export const getCatalogs = async (params) => {
  const response = await catalogApi.get("/api/catalog", {
    params: params,
  });

  return response.data;
};

export const createCatalog = async (pack) => {
  const response = await catalogApi.post("/api/catalog", pack);
  return response.data;
};

export const getCatalog = async (id) => {
  const response = await catalogApi.get(`/api/catalog/${id}`);
  return response.data;
};

export const updateCatalog = async (pack) => {
  const response = await catalogApi.patch(`/api/catalog`, pack);
  return response.data;
};

export const deleteCatalog = async (pack) => {
  const response = await catalogApi.delete("/api/catalog", { data: pack });
  return response;
};
