import * as React from "react";
import PropTypes from "prop-types";
import { TextField, Box } from "@mui/material";
// import { limitedEvaluate } from "../../../utils/paramCalculation";
const isAlpha = (ch) => {
  return typeof ch === "string" && ch.length === 1 && /[A-Za-z]/.test(ch);
};

export const ConditionField = (props) => {
  const { userInput, setUserInput, fieldType, checkFormula, ...other } = props;

  const inputRef = React.useRef();

  const onChange = (e) => {
    // const prev = userInput;
    const curr = e.currentTarget.value;
    const position = inputRef.current.selectionStart;
    // const letters = userInput.split("");

    if (curr[position - 1] === "/") {
      let newInput = curr.slice(0, position - 1) + "÷";
      setUserInput(newInput);

      return;
    }

    // fire if empty
    if (curr.length == 0) {
      return;
    }

    // fire if you are not typing a word
    if (curr[position - 1] === "/") {
      curr[position - 1] = "÷";
      setUserInput(curr);

      return;
    }
    if (curr.length == 1 && !isAlpha(curr[0])) {
      setUserInput(curr);
      return;
    }
    if (!isAlpha(curr[position - 1])) {
      setUserInput(curr);

      return;
    }

    // fire if you are typing a word

    // let word = curr.substring(position - start - 1, position);

    // const word = curr.substring(position - 1)

    const input = e.currentTarget.value;

    setUserInput(input);
  };

  const [error, setError] = React.useState("");

  const checkError = (value) => {
    value = value.replaceAll(/([^<>])=([^=])/g, "$1==$2");
    let error = checkFormula(value);
    if (error.error) {
      setError(`${error.error}`);
    } else {
      if (fieldType === "if") {
        try {
          let result = checkFormula(value);
          if (typeof result !== "boolean") {
            setError(
              `Must evaluate to either true or false. Result is ${result}.`
            );
          } else {
            setError("");
          }
        } catch (e) {
          setError(e.message);
        }
      } else {
        try {
          let result = checkFormula(value);
          if (typeof result === "boolean" || isNaN(result)) {
            setError(`Must evaluate to a number. Result is ${result}.`);
          } else {
            setError("");
          }
        } catch (e) {
          setError(e.message);
        }
      }
    }
  };
  React.useEffect(() => {
    if (userInput) {
      checkError(userInput);
    }
  }, [userInput]);
  React.useEffect(() => {
    props.setError((prev) => {
      let existingError = prev.find((e) => e.id === props.fieldId);
      if (existingError) {
        return prev.map((e) => {
          if (e.id === props.fieldId) {
            return { ...e, message: error };
          } else {
            return e;
          }
        });
      } else {
        return [...prev, { id: props.fieldId, message: error }];
      }
    });
  }, [error]);

  return (
    <Box sx={{ width: "100%" }}>
      <TextField
        fullWidth
        size="small"
        {...other}
        inputRef={inputRef}
        autoFocus={true}
        value={userInput}
        error={!!error}
        helperText={error}
        keepMounted
        inputProps={{ spellCheck: "false" }}
        onChange={(e) => onChange(e)}
      />
    </Box>
  );
};

ConditionField.propTypes = {
  component: PropTypes.object,
  suggestions: PropTypes.array,
  field: PropTypes.string,
  param: PropTypes.object,
  handleEdit: PropTypes.func,
  currentComp: PropTypes.object,
  userInput: PropTypes.string,
  setUserInput: PropTypes.func,
  fieldType: PropTypes.string,
  checkFormula: PropTypes.func,
  setError: PropTypes.func,
  fieldId: PropTypes.string,
};
