import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useSettingsUpdate } from "../../../hooks/useSettingsUpdate";
import { useSettingsData } from "../../../hooks/useSettingsData";
import PropTypes from "prop-types";

export const ViewRole = ({ selectedRole, setSelectedRole }) => {
  const { data: settings } = useSettingsData();
  const { mutate: updateSettings } = useSettingsUpdate("Roles updated.");
  const handleClose = () => setSelectedRole(null);
  const [newRole, setNewRole] = React.useState(selectedRole);
  const [error, setError] = React.useState();
  const [edit, setEdit] = React.useState(false);

  React.useEffect(() => {
    if (!newRole) return;
    if (
      settings.roles.find((role) => role.name === newRole.name) &&
      newRole.name !== selectedRole.name
    ) {
      setError("Role name already exists.");
    } else {
      setError(null);
    }
  }, [newRole?.name]);

  const handleNewRole = ({ field, value }) => {
    setNewRole((prev) => ({ ...prev, [field]: value }));
  };

  const handlePermissions = ({ field, value }) => {
    setNewRole((prev) => ({
      ...prev,
      permissions: {
        ...prev.permissions,
        project: {
          ...prev.permissions.project,
          [field]: { ...prev.permissions.project[field], ...value },
        },
      },
    }));
  };

  const handleEdit = () => {
    if (!edit) {
      setEdit(true);
      return;
    }
    updateSettings({
      pack: {
        roles: settings.roles.map((role) => {
          if (role.name === selectedRole.name) {
            return newRole;
          }
          return role;
        }),
      },
    });
    setEdit(false);
  };

  const handleDelete = () => {
    updateSettings({
      pack: {
        roles: settings.roles.filter((role) => role.name !== selectedRole.name),
      },
    });
    setSelectedRole(null);
  };

  if (!selectedRole) return null;

  return (
    <>
      <Dialog
        open={!!selectedRole}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Add Role</DialogTitle>
        <DialogContent>
          <TextField
            label="Role Name"
            disabled={!edit}
            value={newRole.name}
            sx={{ mt: 1, mb: 2 }}
            onChange={(e) =>
              handleNewRole({ field: "name", value: e.target.value })
            }
            helperText={error}
          />
          <Typography variant="h5">Permissions</Typography>

          <Paper sx={{ p: 1 }}>
            <Typography variant="h6">Project</Typography>
            <FormGroup row sx={{ alignItems: "center" }}>
              <Typography variant="h6" sx={{ mr: 4, width: 200 }}>
                View Tasks
              </Typography>
              <RadioGroup
                defaultValue={false}
                row
                onChange={(e) =>
                  handlePermissions({
                    field: "tasks",
                    value: { viewAll: e.target.value },
                  })
                }
              >
                <FormControlLabel
                  value={false}
                  disabled={!edit}
                  control={<Radio />}
                  label="Assigned Only"
                />
                <FormControlLabel
                  value={true}
                  disabled={!edit}
                  control={<Radio />}
                  label="All"
                />
              </RadioGroup>
            </FormGroup>
            <FormGroup row sx={{ alignItems: "center" }}>
              <Typography variant="h6" sx={{ mr: 4, width: 200 }}>
                Files
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newRole.permissions.project.files.view}
                    disabled={!edit}
                    onChange={(e) =>
                      handlePermissions({
                        field: "files",
                        value: {
                          view: e.target.checked,
                        },
                      })
                    }
                  />
                }
                label="View"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={newRole.permissions.project.files.add}
                    disabled={!edit}
                    onChange={(e) =>
                      handlePermissions({
                        field: "files",
                        value: {
                          add: e.target.checked,
                        },
                      })
                    }
                  />
                }
                label="Add"
              />
            </FormGroup>
            <FormGroup row sx={{ alignItems: "center" }}>
              <Typography variant="h6" sx={{ mr: 4, width: 200 }}>
                Chat
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newRole.permissions.project.chat.view}
                    disabled={!edit}
                    onChange={(e) =>
                      handlePermissions({
                        field: "chat",
                        value: {
                          view: e.target.checked,
                        },
                      })
                    }
                  />
                }
                label="View"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newRole.permissions.project.chat.add}
                    disabled={!edit}
                    onChange={(e) =>
                      handlePermissions({
                        field: "chat",
                        value: {
                          add: e.target.checked,
                        },
                      })
                    }
                  />
                }
                label="Add"
              />
            </FormGroup>
          </Paper>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            variant="outlined"
            onClick={handleDelete}
            color="error"
            disabled={edit}
          >
            Delete
          </Button>
          <Box>
            <Button variant="outlined" onClick={handleClose} sx={{ mr: 2 }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleEdit} disabled={error}>
              {edit ? "Save" : "Edit"}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

ViewRole.propTypes = {
  selectedRole: PropTypes.object,
  setSelectedRole: PropTypes.func,
};
