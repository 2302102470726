import React from "react";
import { UserContext } from "../../../App";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../../../Content";
import { updateSettings } from "../../../api/settingsApi";
import { useSettingsData } from "../../../hooks/useSettingsData";

export function useStepDelete() {
  const user = React.useContext(UserContext);
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { data: settings } = useSettingsData();

  return useMutation({
    mutationFn: ({ pack }) => {
      pack.user = user;
      let newSteps = settings.statusSteps.filter((step) => step.id !== pack.id);

      updateSettings({
        user,
        pack: { statusSteps: newSteps },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("settings");
      setSnack({
        open: true,
        message: "Step deleted.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Step could not be deleted.",
        severity: "error",
      });
    },
  });
}
