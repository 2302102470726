import axios from "axios";

const financialApi = axios.create();

export const getFinancials = async (user, type, project) => {
  const response = await financialApi.get("/api/financial", {
    params: { user: user },
  });
  let data = response.data;
  type !== "all" ? (data = data.filter((f) => f.type === type)) : null;
  project !== "all" ? (data = data.filter((f) => f.project === project)) : null;
  return data;
};

// export const getProjectFinancials = async (user, projectId) => {
//   const response = await financialApi.get("/financial", {
//     params: { user: user, project: projectId },
//   });
//   return response.data;
// };

export const updateFinancial = async ({ id, pack }) => {
  return await financialApi.patch(`/api/financial/${id}`, pack);
};

export const deleteFinancial = async (id) => {
  return await financialApi.delete(`/api/financial/${id}`);
};
export const createFinancial = async (pack) => {
  //   for (const value of pack.values()) {
  //   }
  try {
    return await financialApi.post("/api/financial", pack, { timeout: 5000 });
  } catch (err) {
    console.log("Post to financials failed!");
  }
};
