import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { TransitionGroup } from "react-transition-group";
import { useProjectUpdate } from "../hooks/useProjectUpdate";
import { SelectedProjectContext } from "./ProjectView";

export const ProjectHistory = () => {
  const { mutate: updateProject } = useProjectUpdate();

  const project = React.useContext(SelectedProjectContext);

  const [open, setOpen] = React.useState(false);
  const [logEntry, setLogEntry] = React.useState({
    name: "",
    description: "",
  });

  const handleClose = () => {
    setOpen(false);
  };
  const handleCancel = () => {
    setLogEntry({ name: "", description: "" });
    handleClose();
  };
  const handleAdd = () => {
    const newLog = {
      name: logEntry.name,
      description: logEntry.description,
      date: `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString(
        {},
        { timeStyle: "short" }
      )}`,
      longDate: new Date(),
    };
    updateProject({
      id: project._id,
      pack: {
        log: [newLog, ...project.log],
      },
    });
    setLogEntry({ name: "", description: "" });
    handleClose();
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setOpen(true)}
        >
          Add Entry
        </Button>
      </Box>
      <Box
        id="project-history"
        sx={{
          width: "100%",
          height: "400px",
          maxHeight: "400px",
          overflow: "auto",
          pr: 1,
          "&::-webkit-scrollbar": {
            width: "0.4em",
            backgroundColor: "divider",
            borderRadius: 1,
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
            webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "primary.main",
            borderRadius: 1,
          },
        }}
      >
        <TransitionGroup>
          {project.log.map((log) => {
            return (
              <Collapse key={log.longDate}>
                <Paper key={log.date} sx={{ mb: 1, p: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography>{log.name}</Typography>
                    <Typography variant="caption" sx={{ opacity: "0.7" }}>
                      {log.date}
                    </Typography>
                  </Box>
                  <Typography variant="caption">{log.description}</Typography>
                </Paper>
              </Collapse>
            );
          })}
        </TransitionGroup>
      </Box>
      <Dialog open={open} onClose={handleClose} size="xs" fullWidth>
        <Paper>
          <DialogTitle>Add Log Entry</DialogTitle>
          <DialogContent>
            <TextField
              value={logEntry.name}
              onChange={(e) =>
                setLogEntry({ ...logEntry, name: e.target.value })
              }
              label="Name"
              fullWidth
              sx={{ mb: 1, mt: 1 }}
            />
            <TextField
              value={logEntry.description}
              onChange={(e) =>
                setLogEntry({ ...logEntry, description: e.target.value })
              }
              label="Description"
              fullWidth
              multiline
              rows={4}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button variant="contained" onClick={handleAdd}>
              Add
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </>
  );
};

ProjectHistory.propTypes = {
  project: PropTypes.object.isRequired,
};
