import * as React from "react";
import "./DocumentEditor.css";
import {
  Button,
  // FormControl,
  // InputLabel,
  // MenuItem,
  // Select,
  TextField,
} from "@mui/material";

import PropTypes from "prop-types";
import { VariableView } from "./VariableView";
import { UserContext } from "../../../App";
// import { useTemplatesData } from "../hooks/useTemplatesData";
import { useTemplateCreate } from "../hooks/useTemplateCreate";
import { Save } from "@mui/icons-material";
import html2canvas from "html2canvas";
import { useTemplateUpdate } from "../../templates/hooks/useTemplateUpdate";

export const DocumentEditor = ({
  editor,
  selectedTemplate,
  handleCreating,
}) => {
  // const [selectedTemplate, setSelectedTemplate] = React.useState({
  //   name: "Select a template",
  //   id: "",
  // });
  // const { data: templates } = useTemplatesData();
  const { mutate: createTemplate } = useTemplateCreate();
  const { mutate: updateTemplate } = useTemplateUpdate();
  const [docName, setDocName] = React.useState(
    selectedTemplate ? selectedTemplate.name : ""
  );

  const { user } = React.useContext(UserContext);

  const saveAsTemplate = () => {
    editor.current
      .save()
      .then((outputData) => {
        console.log("Article data: ", outputData);
        let pack = new FormData();
        pack.append("name", docName);
        pack.append("data", JSON.stringify(outputData));
        pack.append("user", user.id);

        editor.current.readOnly.toggle();

        const editorDOM = document.getElementById("editorjs");
        editorDOM.style.transition = "none";
        html2canvas(editorDOM).then(function (canvas) {
          canvas.toBlob(function (blob) {
            pack.append("image", blob, "thumbnail.png");

            if (selectedTemplate) {
              updateTemplate({ id: selectedTemplate._id, pack });
              handleCreating();
            } else {
              createTemplate({ pack });
              handleCreating();
            }
          });
        });
      })
      .catch((error) => {
        console.log("Saving failed: ", error);
      });
  };

  const handleDocName = (name) => {
    setDocName(name);
  };

  return (
    <div>
      <TextField
        label="Document Name"
        value={docName}
        onChange={(e) => handleDocName(e.target.value)}
        sx={{ mb: 2, mr: 2, width: 300 }}
        size="small"
      />
      {/* {templates ? (
        <FormControl sx={{ mb: 2, ml: 2, width: 300 }}>
          <InputLabel id="project-select-label">Select Template</InputLabel>
          <Select
            label="Select Template"
            value={selectedTemplate.name}
            onChange={(e) => handleTemplateChange(e.target.value)}
          >
            {templates.map((template) => (
              <MenuItem key={template.name} value={template}>
                {template.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : null} */}
      <Button
        variant="contained"
        id="save-template"
        onClick={() => saveAsTemplate()}
        startIcon={<Save />}
        color={selectedTemplate ? "warning" : "primary"}
        sx={{ ml: 2, mr: 4 }}
      >
        {selectedTemplate ? "Update" : "Create"}
      </Button>
      <VariableView variant="viewVariablesButton" />
    </div>
  );
};

DocumentEditor.propTypes = {
  editor: PropTypes.any,
  handleVariablesOpen: PropTypes.func,
  selectedTemplate: PropTypes.object,
  handleCreating: PropTypes.func,
};
