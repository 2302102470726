import { Box, Paper, TextField, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import CurrencyTextField from "../../../../utils/CurrencyTextField";
import { NewComponentContext } from "../../components/ComponentNode";

export const Supplier = ({ supplier }) => {
  const { handleEdit } = React.useContext(NewComponentContext);

  const handleSupplierEdit = ({ field, value }) => {
    let newSupplier = { ...supplier, [field]: value };
    console.log(newSupplier);
    handleEdit({ field: "suppliers", value: newSupplier, type: "edit" });
  };

  return (
    <Paper sx={{ p: 1, mt: 1 }}>
      <Typography sx={{ mb: 1 }}>{supplier.supplier.name}</Typography>
      <CurrencyTextField
        size="small"
        label="Cost"
        value={supplier.cost}
        fullWidth
        onChange={(e) =>
          handleSupplierEdit({ field: "cost", value: e.target.value })
        }
        // disabled={!open}
        // onChange={(e) =>
        //   handleSupplierEdit(supplier, {
        //     ...supplier,
        //     cost: e.target.value,
        //   })
        // }
      />{" "}
      <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
        <TextField
          size="small"
          label="Code"
          value={supplier.code}
          fullWidth
          onChange={(e) =>
            handleSupplierEdit({ field: "code", value: e.target.value })
          }
        />
        <TextField
          size="small"
          label="Link"
          value={supplier.link}
          fullWidth
          onChange={(e) =>
            handleSupplierEdit({ field: "link", value: e.target.value })
          }
        />
      </Box>
    </Paper>
  );
};

Supplier.propTypes = {
  supplier: PropTypes.object,
};
