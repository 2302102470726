import _ from "lodash";
import React from "react";

export function useNewOption({ saveFn, id, data }) {
  const [newOption, setNewOption] = React.useState(
    data || {
      _id: "newOption",
      nodeKey: id,
      name:
        id.split("-").length > 1
          ? `Option${id.split("-").slice(1).join("-")}`
          : "MyOption",
      subcomponents: [],
      parameters: [],
      required: true,
      multiple: false,
      qty: {
        value: 1,
        formula: "",
        variable: "",
        hierarchy: ["parent"],
      },
    }
  );

  const clearOption = () => {
    setNewOption({
      _id: "newOption",
      nodeKey: id,
      name:
        id.split("-").length > 1
          ? `Option${id.split("-").slice(1).join("-")}`
          : "MyOption",
      subcomponents: "",
      parameters: [],
      required: true,
      multiple: false,
    });
  };

  const handleEdit = ({ field, value, type }) => {
    switch (field) {
      case "subcomponents":
        switch (type) {
          // case "update": {
          // setNewOption((prev) => ({
          //   ...prev,
          //   parameters: handleParameterUpdate({
          //     component: prev,
          //     parameter: value,
          //   }),
          // }));
          //   break;
          // }
          default: {
            setNewOption((prev) => ({
              ...prev,
              parameters: handleSubcomponentAddRemove({
                component: prev,
                parameter: value,
              }),
            }));
            break;
          }
        }
        break;

      case "parameters":
        switch (type) {
          case "update":
            setNewOption((prev) => ({
              ...prev,
              parameters: handleParameterUpdate({
                option: prev,
                parameter: value,
              }),
            }));
            break;
          default:
            setNewOption((prev) => ({
              ...prev,
              parameters: handleParameterAddRemove({
                option: prev,
                parameter: value,
              }),
            }));
        }
        break;
      default:
        setNewOption((prev) => ({
          ...prev,
          [field]: value,
        }));
    }
  };

  React.useEffect(() => {
    if (!saveFn) return;

    saveFn(newOption);
  }, [newOption, saveFn]);

  React.useEffect(() => {
    if (data) {
      setNewOption(data);
    }
  }, [data]);

  return [newOption, handleEdit, clearOption, setNewOption];
}

export const handleParameterAddRemove = ({ option, parameter }) => {
  let index = option.parameters.findIndex(
    (p) =>
      p.name === parameter.name &&
      p.hierarchy.length === 1 &&
      p.source == parameter.source
  );
  if (index > -1) {
    return option.parameters.filter(
      (p) =>
        p.name !== parameter.name ||
        p.source !== parameter.source ||
        p.hierarchy.length !== 1
    );
  } else {
    return [...option.parameters, parameter];
  }
};

const handleParameterUpdate = ({ option, parameter }) => {
  let index = option.parameters.findIndex(
    (p) =>
      p.name === parameter.name && _.isEqual(p.hierarchy, parameter.hierarchy)
  );

  const isOverride =
    option.nodeKey.split("-").length > 1 && option._id !== "newComponent";
  if (isOverride) {
    parameter.override = true;
  }

  option.parameters[index] = parameter;
  return option.parameters;
};

export const handleOptionsInheritedFrom = (component) => {
  let newOptions = [...component.options];
  newOptions.forEach((option) => {
    component.subcomponents.map((subcomponent) => {
      if (
        subcomponent.options.find(
          (o) => o._id === option._id && o.inherited !== false
        )
      ) {
        option.inheritedFrom = [
          ...(option.inheritedFrom ? option.inheritedFrom : []),
          subcomponent._id,
        ];
      }
    });
  });
  return newOptions;
};

// handle subcomponent add or remove
const handleSubcomponentAddRemove = ({ option, component }) => {
  let index = option.subcomponents.findIndex(
    (p) => p.name === component.name && p.hierarchy.length === 1
  );
  if (index > -1) {
    return option.subcomponents.filter(
      (p) => p.name !== component.name || p.hierarchy.length !== 1
    );
  } else {
    return [...option.subcomponents, component];
  }
};

// handle subcomponent remove
// handle option add
// handle option remove

export const handleEdit = ({ option, field, value, type }) => {
  switch (field) {
    case "subcomponents":
      switch (type) {
        // case "update": {
        // setNewOption((prev) => ({
        //   ...prev,
        //   parameters: handleParameterUpdate({
        //     component: prev,
        //     parameter: value,
        //   }),
        // }));
        //   break;
        // }
        default: {
          return {
            ...option,
            parameters: handleSubcomponentAddRemove({
              component: option,
              parameter: value,
            }),
          };
        }
      }

    case "parameters":
      switch (type) {
        case "update":
          return {
            ...option,
            parameters: handleParameterUpdate({
              option: option,
              parameter: value,
            }),
          };
        default:
          return {
            ...option,
            parameters: handleParameterAddRemove({
              option: option,
              parameter: value,
            }),
          };
      }
    default:
      return {
        ...option,
        [field]: value,
      };
  }
};
