import React from "react";
import { useProjectsData } from "../../../hooks/useProjectsData";
import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSettingsData } from "../../../hooks/useSettingsData";
import { Beenhere, Schedule, Visibility } from "@mui/icons-material";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useProjectUpdate } from "../hooks/useProjectUpdate";

export const BoardView = ({ handleSelect }) => {
  const { data: projects } = useProjectsData();
  const { data: settings } = useSettingsData();
  const { mutate: updateProject } = useProjectUpdate();
  const milestones = settings.milestones;
  const handleCheck = ({ task, project }) => {
    task.completed
      ? updateProject({
          id: project._id,
          pack: {
            task: { ...task, completed: false },
            updateType: ["tasks", "update"],
          },
        })
      : updateProject({
          id: project._id,
          pack: {
            task: { ...task, completed: true },
            updateType: ["tasks", "update"],
          },
        });
  };
  const handleCompleteMilestone = ({ project, milestone }) => {
    let newProject = { ...project };
    let newMilestone = newProject.milestones.find(
      (m) => m.name == milestone.name
    );
    newMilestone.active = false;
    newMilestone.completed = true;

    updateProject({
      id: newProject._id,
      pack: { milestone: newMilestone, updateType: ["milestones", "update"] },
    });
  };
  // const milestones = [
  //   { name: "Schedule", tasks: ["Schedule", "Buy Materials"] },
  //   { name: "Build", tasks: ["Cut parts", "Edge Band", "Accessories"] },
  //   { name: "Assemble", tasks: ["Assemble Boxes", "Mount Doors", "Clean"] },
  //   { name: "Deliver", tasks: ["Pick Up", "Drop Off"] },
  //   { name: "Install", tasks: ["Install", "Clean"] },
  //   { name: "Complete", tasks: ["Complete"] },
  // ];
  if (!projects || !settings) return null;

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      {milestones.map((m) => (
        <Box
          key={m.name}
          sx={{ display: "flex", flexDirection: "column", gap: 1, flex: 1 }}
        >
          <Typography variant="h6" align="center">
            {m.name}
          </Typography>

          {projects
            ?.filter((p) =>
              p.milestones.find((ms) => ms.name == m.name && ms.active)
            )
            .sort((a, b) => {
              return new Date(a.dueDate) - new Date(b.dueDate);
            })
            .map((p) => (
              <Card
                key={p._id}
                sx={{
                  p: 1,
                  ":hover": { boxShadow: 4 },
                  cursor: "pointer",

                  transition: "all 0.3s ease-in-out",
                }}
                onClick={() => handleSelect(p)}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h6">{p.projectName}</Typography>

                  <Box sx={{ display: "flex", alignItems: "start", gap: 1 }}>
                    <IconButton size="small" onClick={() => handleSelect(p)}>
                      <Visibility sx={{ color: p.color }} />
                    </IconButton>
                    {p.tasks
                      .filter((t) => t.milestone == m.name)
                      .every((t) => t.completed) ? (
                      <Tooltip title="Complete Milestone">
                        <IconButton
                          size="small"
                          color="success"
                          onClick={() =>
                            handleCompleteMilestone({
                              project: p,
                              milestone: m,
                            })
                          }
                        >
                          <Beenhere />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </Box>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                  <Schedule fontSize="inherit" />

                  <Typography variant="caption" lineHeight={0}>
                    {p.dueDate
                      ? dayjs(p.dueDate).format("MM/DD/YYYY")
                      : "No Due Date"}
                  </Typography>
                </Box>

                {p.tasks
                  .filter((t) => t.milestone == m.name)
                  .map((t) => (
                    <Box key={t.name} sx={{ display: "flex", gap: 1 }}>
                      <FormControlLabel
                        key={t.name}
                        onClick={(e) => e.stopPropagation()}
                        control={
                          <Checkbox
                            size="small"
                            checked={t.completed}
                            onChange={() =>
                              handleCheck({ project: p, task: t })
                            }
                          />
                        }
                        label={t.name}
                      />
                    </Box>
                  ))}
              </Card>
            ))}
        </Box>
      ))}
    </Box>
  );
};

BoardView.propTypes = {
  handleSelect: PropTypes.func,
};
