import { Box, Button, Grid, Paper } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import stripeBlurple from "../../utils/stripeBlurple.svg";

import { signInToStripe } from "../../../../api/paymentApi";
import { checkSubscription } from "../../../../utils/checkSubscription";

export const StripeIntegration = ({ settings }) => {
  const user = settings.user;
  const handleStripe = async () => {
    const data = await signInToStripe({ pack: { user: user } });
    window.location.href = data.data;
  };
  const sub = checkSubscription(settings);
  return (
    <Paper
      sx={{
        display: "flex",
        p: 1,
        overflow: "hidden",
        justifyContent: "space-between",
        // alignItems: "center",
        height: 100,
        //   pl: 8,
        boxShadow: 1,
      }}
      elevation={0}
    >
      <img src={stripeBlurple} alt="docusign" style={{}} />

      <Box sx={{ display: "flex" }}>
        {settings.stripeAccount ? (
          <Box sx={{ display: "flex", alignItems: "center", mr: 8 }}>
            <Grid item>{settings.stripeAccount.object.email}</Grid>
          </Box>
        ) : null}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            onClick={() => handleStripe()}
            disabled={!sub.includes("financial")}
            sx={{
              mr: 3,
              backgroundColor: "#635bff",
              ":hover": {
                backgroundColor: "#0a2540",
              },
            }}
          >
            Connect
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

StripeIntegration.propTypes = {
  settings: PropTypes.object,
};
