import { AddCircle } from "@mui/icons-material";
import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import { columns, endpoints, titles } from "../components/common/utils";
import { UserContext } from "../App";
import { NewItemDialog } from "../components/common/itemList";
import { usePeopleData } from "../features/people/hooks/usePeopleData";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { Roles } from "../features/people/components/Roles";
import { EditPersonDialog } from "../features/people/components/EditPerson";
// import { useSettingsData } from "../hooks/useSettingsData";

export const People = () => {
  const user = React.useContext(UserContext);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const { data: people } = usePeopleData();
  // const { data: settings } = useSettingsData();

  const [selectedPerson, setSelectedPerson] = React.useState();
  const [editOpen, setEditOpen] = React.useState(false);

  // const [roles, setRoles] = React.useState(
  //   settings.roles.map((r) => ({ [r._id]: r.name }))
  // );

  // useEffect(() => {
  //   if (!settings) return;
  //   let tempRoles = {};
  //   settings.roles.map((r) => (tempRoles[r._id] = r.name));
  //   setRoles(tempRoles);
  // }, [settings]);

  const gridColumns = columns["person"];
  // gridColumns[
  //   columns["person"].indexOf(columns["person"].find((x) => x.field === "role"))
  // ].valueGetter = (params) => roles[params.value];

  if (!people) return null;

  console.log(columns, people);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="h3">People</Typography>
          <Button
            id="people-new"
            color="primary"
            variant="contained"
            startIcon={<AddCircle />}
            onClick={() => setDialogOpen(true)}
          >
            New Person
          </Button>
          <NewItemDialog
            title={titles["person"]}
            isOpen={dialogOpen}
            setOpen={setDialogOpen}
            fields={columns["person"]}
            updateTable={"people"}
            ENDPOINT={endpoints["person"]}
            user={user}
          />
        </Box>
        <Roles />
      </Box>
      <Paper id="people-table" sx={{ mt: 2, height: "fit-container" }}>
        <DataGridPremium
          rows={people}
          getRowId={(row) => row._id}
          onRowClick={(params) => {
            setSelectedPerson(params.row);
            setEditOpen(true);
          }}
          columns={gridColumns}
          autoHeight
          disableSelectionOnClick
        />
      </Paper>
      {selectedPerson && (
        <EditPersonDialog
          title={titles["person"]}
          isOpen={editOpen}
          setOpen={setEditOpen}
          fields={columns["person"]}
          updateTable={"person"}
          ENDPOINT={endpoints["person"]}
          user={user}
          data={selectedPerson}
        />
      )}
    </>
  );
};
