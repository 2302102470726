import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

export function NoDocumentsOverlay() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        zIndex: 5,
      }}
    >
      <Typography variant="h5" align="center">
        Documents generated from templates will appear here. You can create a
        template in the Templates tab, then generate one there or from the
        Projects tab once you are inside a project.
      </Typography>
    </div>
  );
}

NoDocumentsOverlay.propTypes = {
  message: PropTypes.string,
};
