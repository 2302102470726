import React from "react";
import { ZoneTable } from "./ZoneTable";
import { Box } from "@mui/system";
import { SelectedProjectContext } from "../../ProjectView";
import { Button, Menu, MenuItem } from "@mui/material";
import { useProjectUpdate } from "../../../hooks/useProjectUpdate";
import { useSettingsData } from "../../../../../hooks/useSettingsData";
import { Components } from "./Components";

export const Products = () => {
  const project = React.useContext(SelectedProjectContext);
  const { mutate: updateProject } = useProjectUpdate(project?._id);
  const { data: settings } = useSettingsData();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAddZone = (zone) => {
    const pack = {
      updateType: ["zones", "add"],
      zone: zone,
    };
    updateProject({
      id: project._id,
      pack: pack,
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <div></div>
        <Button variant="outlined" onClick={handleClick}>
          Add Zone
        </Button>
        <Components />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {settings.zones.map((zone) => (
            <MenuItem
              key={zone}
              onClick={() => handleAddZone(zone)}
              disabled={project.zones.includes(zone)}
            >
              {zone}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      {project.zones.map((zone) => (
        <ZoneTable key={zone} zone={zone} />
      ))}

      <ZoneTable zone="" />
    </Box>
  );
};
