import { useMutation, useQueryClient } from "react-query";
import React from "react";
import {
  createParameter,
  deleteParameter,
  updateParameter,
} from "../../../../api/parameterApi";
import { UserContext } from "../../../../App";
import { SnackbarContext } from "../../../../Content";

export const isArrayValueValid = (value) => {
  if (value === "") return true;
  if (value.includes(",,") || value.startsWith(",")) return false;

  const values = value.split(",");
  const valid = values.every((value) => {
    const num = Number(value);
    if (isNaN(num)) return false;
    return true;
  });
  return valid;
};

export function useParametersUpdate() {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  return useMutation({
    mutationFn: ({ id, pack }) => {
      updateParameter({ id, pack });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("parameters");
      setSnack({
        open: true,
        message: "Parameter updated.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Parameter could not be updated.",
        severity: "error",
      });
    },
  });
}
export function useParameterCreate() {
  const { user } = React.useContext(UserContext);
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  return useMutation({
    mutationFn: ({ pack }) => {
      pack.user = user.id;
      createParameter(pack);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("parameters");
      setSnack({
        open: true,
        message: "Parameter created.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Parameter could not be created.",
        severity: "error",
      });
    },
  });
}
export function useParameterDelete() {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  return useMutation({
    mutationFn: (id) => {
      deleteParameter(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("parameters");
      setSnack({
        open: true,
        message: "Parameter deleted.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Parameter could not be deleted.",
        severity: "error",
      });
    },
  });
}

export const UNITS = [
  "in",
  "ft",
  "cm",
  "m",
  "sqin",
  "sqft",
  "m²",
  "g",
  "kg",
  "lb",
];
