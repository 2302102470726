import {
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  List,
  ListItem,
  Grid,
  ListItemButton,
} from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";
import { ZoneView } from "./ZoneView";
import { useProjectUpdate } from "../hooks/useProjectUpdate";
import { SelectedProjectContext } from "./ProjectView";

export const OptionsZonesTab = () => {
  const project = React.useContext(SelectedProjectContext);
  const { mutate: updateProject } = useProjectUpdate();

  const handleOptionSelect = (option, choice) => {
    let products = [...project.products];
    let newProds = [];
    products.map((product) => {
      if (
        product.options.find((o) => o.name == option.name) &&
        (filter.includes(product.zone) || filter.length === 0)
      ) {
        let index = product.options.findIndex((o) => o.name == option.name);
        product.options.find((o) => o.name == option.name).choice = choice;
        // let newProd = calculatePrice(product, settings.parameters)[0];
        // newProds.push(newProd);
        newProds.push({ id: product._id, field: index, value: choice });
      }
    });
    const pack = { products: newProds, type: "massOptionUpdate" };

    updateProject({ id: project._id, pack: pack });
  };

  let projectOptions = [];

  project.products.map((product) => {
    product.options.map((o) => {
      if (
        !projectOptions.find((opt) => opt.option.choice.name == o.choice.name)
      ) {
        if (!o._id) return;
        projectOptions.push({ option: o, zones: [product.zone] });
      } else {
        let index = projectOptions.findIndex(
          (opt) => opt.option.choice.name == o.choice.name
        );
        projectOptions[index].zones = [
          ...projectOptions[index].zones,
          product.zone,
        ];
      }
    });
  });

  const [filter, setFilter] = React.useState([]);
  const handleChipClick = (chip) => {
    setFilter((prev) => {
      if (prev.includes(chip) && prev.length > 1) {
        return prev.filter((c) => c !== chip);
      } else {
        return [chip];
      }
    });
  };

  console.log(projectOptions);

  return (
    <Paper
      sx={{
        padding: 1,
        height: 636,
        width: "100%",
        maxHeight: "100%",
        boxShadow: 4,
      }}
      elevation={1}
    >
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <Divider sx={{ m: 1 }}>
            Zones <ZoneView project={project} />
          </Divider>
          <List sx={{ maxHeight: "100%", overflow: "auto" }}>
            {project.zones.length > 0 ? (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => setFilter([...project.zones, "No Zone"])}
                >
                  All
                </ListItemButton>
              </ListItem>
            ) : null}
            {project.zones.map((zone, i) => (
              <ListItem
                key={i}
                sx={{
                  bgcolor: filter.includes(zone) ? "primary.main" : "default",
                }}
                disablePadding
              >
                <ListItemButton
                  onClick={() => handleChipClick(zone)}
                  sx={{ mr: 0.5, mb: 0.5 }}
                >
                  {zone}
                </ListItemButton>
              </ListItem>
            ))}
            <ListItem disablePadding>
              <ListItemButton
                sx={{
                  bgcolor: filter.includes("No Zone")
                    ? "primary.main"
                    : "default",
                }}
                onClick={() => setFilter(["No Zone"])}
              >
                No Zone
              </ListItemButton>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={9}>
          <Divider sx={{ m: 1 }}>Options</Divider>
          <div style={{ overflow: "auto", height: "100%" }}>
            {projectOptions.map((option, i) =>
              filter.length === 0 ||
              option.zones.some((z) => filter.includes(z)) ? (
                <FormControl
                  key={i}
                  variant="standard"
                  fullWidth
                  sx={{ mb: i < projectOptions.length - 1 ? 1 : 0 }}
                >
                  <InputLabel>{option.option.name}</InputLabel>
                  <Select
                    value={
                      option.option.choice ? option.option.choice.name : ""
                    }
                    disabled={true}
                    label={option.option.name}
                  >
                    {option.option.choices.map((choice) => (
                      <MenuItem
                        key={choice._id}
                        value={choice.name}
                        onClick={() =>
                          handleOptionSelect(
                            option.option,
                            choice,
                            option.zones
                          )
                        }
                      >
                        {choice.name}
                      </MenuItem>
                    ))}
                    {!option.option.required ? (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    ) : null}
                  </Select>
                </FormControl>
              ) : null
            )}
          </div>
        </Grid>
      </Grid>
    </Paper>
  );
};
OptionsZonesTab.propTypes = {
  project: PropTypes.object,
};
