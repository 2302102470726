import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../../../Content";
import { deleteTemplate } from "../../../api/templateApi";

export function useTemplateDelete() {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  return useMutation({
    mutationFn: ({ id }) => {
      return deleteTemplate(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("templates");

      setSnack({
        open: true,
        message: "Template deleted.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Template could not be deleted.",
        severity: "error",
      });
    },
  });
}
