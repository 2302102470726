import { Button, Container, Paper, Stack, Typography } from "@mui/material";
import React from "react";
import { UserContext } from "../../../App";
import { register } from "../../../api/userApi";

export const Pending = () => {
  const { user, setUser } = React.useContext(UserContext);
  const resendEmail = async () => {
    try {
      const returnedUser = await register({
        email: user.email,
        password: user.password,
        status: "pending",
      });
      setUser(returnedUser);
    } catch (err) {
      console.error("Failed to create new user", err.message);
    }
  };

  return (
    <Container maxWidth="xs">
      <Paper sx={{ marginTop: 12, width: 320, padding: 4 }}>
        <form>
          <Stack spacing={2} alignItems="center">
            <Typography variant="h6">Account created!</Typography>
            <Typography variant="subtitle">
              Please check your email for a confirmation.
            </Typography>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              onClick={resendEmail}
            >
              Resend confirmation email
            </Button>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
};
