import React from "react";
import { useQuery } from "react-query";
import { getParameters } from "../api/parameterApi";
import { UserContext } from "../App";

export const useParametersData = (onSuccess, onError) => {
  const { user } = React.useContext(UserContext);
  return useQuery("parameters", () => getParameters(user.id), {
    onSuccess,
    onError,
    // refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10, // 10 minutes
  });
};
