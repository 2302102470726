import React, { createContext, useState, useMemo } from "react";
import PropTypes from "prop-types";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import styles from "./App.module.css";
import { VbxAppBar } from "./features/appBar/components/AppBar";
import { Alert, CssBaseline, Snackbar } from "@mui/material";
import { amber, blueGrey, grey, teal } from "@mui/material/colors";
// import { CreatePage } from "./components/create/CreatePage";
import { ReactQueryDevtools } from "react-query/devtools";
// import { Create } from "./pages/Create";
import { Financial } from "./pages/Financial";
import { Inventory } from "./pages/Inventory";
import { Project } from "./pages/Project";
import { Files } from "./pages/Files";
import { Customer } from "./pages/Customer";
import { Suppliers } from "./pages/Suppliers";
import { Templates } from "./pages/Templates";
import { Dashboard } from "./pages/Dashboard";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { People } from "./pages/People";
import { UserContext } from "./App";
import { Calendar } from "./pages/Calendar";
import { Organization } from "./pages/Organization";
import { steps } from "./utils/introSteps";
import { useSettingsData } from "./hooks/useSettingsData";
import { checkSubscription } from "./utils/checkSubscription";
// import { WrappedFlow } from "./features/flowCreate/components/Flow";
import { Components } from "./pages/Components";
import { Order } from "./pages/Order";
import { Market } from "./pages/Market";
// import { useProjectData } from "./hooks/useProjectsData";
// import { useSubscriptionData } from "./hooks/useSubscriptionData";

export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const SnackbarContext = createContext({
  snack: { open: false, message: "", severity: "success", action: null },
  setSnack: () => {},
});

export const TabContext = createContext({
  activeTab: "Home",
  options: {},
  handleTabChange: () => {},
});

export const HelpContext = createContext({
  help: [],
  setHelp: () => {},
});

export const SettingsDialogContext = createContext({
  settingsDialog: { open: false, tab: "integrations" },
  setSettingsDialog: () => {},
});

export const SubContext = createContext({
  sub: [],
});

export const ThemeContext = createContext({
  colors: {
    dark: {
      primary: teal,
      secondary: teal,
    },
    light: {
      primary: amber,
      secondary: amber,
    },
  },
  setColors: () => {},
});
const getDesignTokens = (mode, colors) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          primary: colors?.light.primary
            ? { main: colors.light.primary }
            : amber,
          divider: amber[200],
          background: {
            default: grey[200],
            paper: grey[200],
          },
          text: {
            primary: grey[900],
            secondary: grey[800],
          },
        }
      : {
          // palette values for dark mode
          primary: colors?.dark.primary ? { main: colors.dark.primary } : amber,
          // divider: blueGrey[700],
          background: {
            default: blueGrey[900],
            paper: blueGrey[900],
          },
          text: {
            primary: "#fff",
            secondary: amber[50],
          },
        }),
  },
  // shape: {
  //   borderRadius: "8px",
  // },
});

export const Content = ({ setView }) => {
  const { user, setUser } = React.useContext(UserContext);
  const [activeTab, setActiveTab] = useState("Home");
  const [options, setOptions] = useState({});
  const [snack, setSnack] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const handleTabChange = ({ tab, options }) => {
    setActiveTab(tab);
    options ? setOptions(options) : setOptions({});
  };
  const [settingsDialog, setSettingsDialog] = useState({
    open: false,
    tab: "integrations",
  });

  const [mode, setMode] = useState(localStorage.getItem("mode") || "dark");
  const [colors, setColors] = useState(null);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          localStorage.setItem("mode", prevMode === "light" ? "dark" : "light");
          return prevMode === "light" ? "dark" : "light";
        });
      },
    }),
    []
  );
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnack((prev) => ({ ...prev, open: false }));
  };
  const handleExited = () => {
    setSnack((prev) => ({ ...prev, message: undefined }));
  };
  const theme = useMemo(
    () => createTheme(getDesignTokens(mode, colors)),
    [mode, colors]
  );
  const [appBarOpen, setAppBarOpen] = useState(
    localStorage.getItem("appBar") == "true" ? true : false || false
  );
  const { data: settings } = useSettingsData();
  const [sub, setSub] = React.useState(checkSubscription(settings) || []);
  React.useEffect(() => {
    if (settings) {
      setSub(checkSubscription(settings));
    }
  }, [settings]);
  const [help, setHelp] = useState(
    sub.includes("financial")
      ? steps.Home
      : steps.Home.filter((step) => step.sub !== "financial")
  );

  return (
    <div className={styles.container}>
      <ColorModeContext.Provider value={colorMode}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeContext.Provider value={{ colors, setColors }}>
            <HelpContext.Provider value={{ help, setHelp }}>
              <SettingsDialogContext.Provider
                value={{ settingsDialog, setSettingsDialog }}
              >
                <SubContext.Provider value={{ sub }}>
                  <ThemeProvider theme={theme}>
                    <CssBaseline>
                      <SnackbarContext.Provider value={{ snack, setSnack }}>
                        <TabContext.Provider
                          value={{ activeTab, options, handleTabChange }}
                        >
                          <Snackbar
                            open={snack.open}
                            key={snack.message ? snack.message : undefined}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            autoHideDuration={6000}
                            onClose={handleSnackbarClose}
                            TransitionProps={{ onExited: handleExited }}
                          >
                            <Alert
                              severity={snack.severity}
                              onClose={handleSnackbarClose}
                              sx={{ width: "100%" }}
                              action={snack.action ? snack.action : undefined}
                            >
                              {snack.message ? snack.message : undefined}
                            </Alert>
                          </Snackbar>
                          <div
                            style={{
                              flex: "0 0 auto",
                              transition: "width 1s ease-out 2s",
                              width: "65px",
                            }}
                          >
                            <VbxAppBar
                              user={user}
                              activeTab={activeTab}
                              setUser={setUser}
                              setTab={setActiveTab}
                              open={appBarOpen}
                              setOpen={setAppBarOpen}
                              setView={setView}
                            />
                          </div>

                          <div className={styles.layout}>
                            <div className={styles.header}></div>
                            <div className={styles.content}>
                              {user ? (
                                activeTab === "Home" ? (
                                  <Dashboard
                                    options={options}
                                    appBarOpen={appBarOpen}
                                  />
                                ) : activeTab === "Projects" ? (
                                  <Project options={options} />
                                ) : activeTab === "Customers" ? (
                                  <Customer options={options} />
                                ) : activeTab === "Suppliers" ? (
                                  <Suppliers options={options} />
                                ) : activeTab === "Components" ? (
                                  <Components tabOptions={options} />
                                ) : activeTab === "Inventory" ? (
                                  <Inventory />
                                ) : activeTab === "Files" ? (
                                  <Files options={options} />
                                ) : activeTab === "Organization" ? (
                                  <Organization options={options} />
                                ) : activeTab === "Financial" ? (
                                  <Financial options={options} />
                                ) : activeTab === "Templates" ? (
                                  <Templates />
                                ) : activeTab === "People" ? (
                                  <People />
                                ) : activeTab === "Calendar" ? (
                                  <Calendar />
                                ) : activeTab === "Orders" ? (
                                  <Order />
                                ) : activeTab === "Market" ? (
                                  <Market />
                                ) : null
                              ) : null}
                            </div>
                          </div>
                          <ReactQueryDevtools />
                        </TabContext.Provider>
                      </SnackbarContext.Provider>
                    </CssBaseline>
                  </ThemeProvider>
                </SubContext.Provider>
              </SettingsDialogContext.Provider>
            </HelpContext.Provider>
          </ThemeContext.Provider>
        </LocalizationProvider>
      </ColorModeContext.Provider>
    </div>
  );
};
Content.propTypes = {
  app: PropTypes.object,
  user: PropTypes.object,
  setUser: PropTypes.func,
  setView: PropTypes.func,
};
