import { Mail, Password } from "@mui/icons-material";
import {
  Button,
  Container,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { UserContext } from "../../../App";
import { register, resendEmail } from "../../../api/userApi";
// import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../hooks/useQuery";

export const EmailConfirm = () => {
  const { setUser } = React.useContext(UserContext);
  const navigate = useNavigate();
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    stayLoggedIn: false,
  });
  const [error, setError] = React.useState(null);

  const [resend, setResend] = React.useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  let query = useQuery();

  const loginUser = async () => {
    const returnedUser = await register({
      email: values.email,
      password: values.password,
      stayLoggedIn: values.stayLoggedIn,
      token: query.get("token"),
    });
    setUser(returnedUser.data);

    if (returnedUser.data.status === "active") {
      navigate("/app");
    }
  };

  const handleResendClick = async () => {
    await resendEmail({ pack: values.email }).then((res) => {
      if (res.status !== 200) {
        setError(res.data.msg);
      } else {
        setResend(true);
        setError(null);
      }
    });
  };

  return (
    <Container maxWidth="xs">
      <Paper sx={{ marginTop: 12, width: 320, padding: 4 }}>
        <form>
          <Stack spacing={2} alignItems="center">
            <Typography variant="h6">
              {error ? "Token Expired" : "Log in to confirm your email"}
            </Typography>
            <Typography variant="subtitle">
              {error ? null : "Please set your password and login."}
            </Typography>
            <TextField
              fullWidth
              label="Email"
              autoComplete="username"
              value={values.email}
              onChange={handleChange("email")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Mail />
                  </InputAdornment>
                ),
              }}
            />
            {error ? (
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={handleResendClick}
              >
                Resend Email
              </Button>
            ) : !resend ? (
              <>
                <TextField
                  fullWidth
                  label="Password"
                  type="password"
                  autoComplete={"current-password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Password />
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={loginUser}
                >
                  Login
                </Button>
              </>
            ) : (
              <Typography>
                Email sent to {values.email}. Please check your inbox.
              </Typography>
            )}
          </Stack>
        </form>
      </Paper>
    </Container>
  );
};
