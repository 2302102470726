import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { getProject, getProjects } from "../api/projectApi";
import { UserContext } from "../App";

export const useProjectsData = (onSuccess, onError) => {
  const { user } = React.useContext(UserContext);
  return useQuery("projects", () => getProjects(user.id), {
    refetchInterval: 10000,
    onSuccess: (data) => {
      if (onSuccess) onSuccess(data);
    },
    onError,
    staleTime: Infinity,
    keepPreviousData: true,
  });
};

export const useProjectData = (projectId) => {
  const queryClient = useQueryClient();
  const fetchProject = async () => {
    const cachedProject = queryClient.getQueryData(["project", projectId]);
    if (cachedProject) {
      return cachedProject;
    }
    return await getProject(projectId);
  };
  return useQuery(["project", projectId], fetchProject, {
    staleTime: Infinity,
    enabled: !!projectId,
    keepPreviousData: true,
  });
};
