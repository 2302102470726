import * as React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Paper,
  Typography,
  Divider,
  TextField,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import { useSettingsData } from "../../../hooks/useSettingsData";
import { updateSettings } from "../../../api/settingsApi";

export const ComponentSettings = () => {
  const [newZone, setNewZone] = React.useState("");

  const queryClient = useQueryClient();
  const { data: settings } = useSettingsData();

  const updateSettingsMutation = useMutation(updateSettings, {
    onSuccess: () => {
      queryClient.invalidateQueries("settings");
    },
  });

  const [newCat, setNewCat] = React.useState("");
  const handleEnter = (e, item, field) => {
    if (e.keyCode == 13) {
      const newSettings = Object.assign({}, settings, {
        [field]: [item, ...settings[field]],
      });
      updateSettingsMutation.mutate({ user: settings.user, pack: newSettings });

      setNewCat("");
    }
  };
  const handleZoneDelete = (item, field) => {
    const filtered = settings[field].filter((filter) => filter != item);
    const newSettings = Object.assign({}, settings, {
      [field]: filtered,
      modifier: "delete",
      deleted: item,
    });
    updateSettingsMutation.mutate({ user: settings.user, pack: newSettings });
  };
  if (!settings) return null;
  return (
    <>
      Double Click Items To Delete
      <Grid container spacing={1} sx={{ maxHeight: "800px" }}>
        <Grid item xs={4}>
          <Paper elevation={2} sx={{ pl: 1 }}>
            <Typography variant="h6">Zones</Typography>
            <Divider />
            <TextField
              label="New Zone"
              variant="standard"
              value={newZone}
              onKeyDown={(e) => handleEnter(e, newZone, "zones")}
              onChange={(e) => setNewZone(e.target.value)}
            />
            <List sx={{ overflow: "auto" }} dense="true">
              {settings.zones.map((zone) => (
                <ListItem key={zone} disablePadding>
                  <ListItemButton
                    sx={{
                      pl: 0,
                    }}
                    onDoubleClick={() => handleZoneDelete(zone, "zones")}
                  >
                    <Typography>{zone}</Typography>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        <Grid item xs={4}>
          <Paper elevation={2} sx={{ pl: 1 }}>
            <Typography>Categories</Typography>
            <TextField
              label="Add"
              variant="standard"
              value={newCat}
              onKeyDown={(e) => handleEnter(e, newCat, "categories")}
              onChange={(e) => setNewCat(e.target.value)}
            />
            <Divider />
            <List sx={{ maxHeight: "680px", overflow: "auto" }}>
              {settings.categories.map((cat) => (
                <ListItem key={cat} sx={{ pl: 0 }} disablePadding>
                  <ListItemButton
                    sx={{ pl: 0 }}
                    onDoubleClick={() => handleZoneDelete(cat, "categories")}
                  >
                    <Typography>{cat}</Typography>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

ComponentSettings.propTypes = {
  user: PropTypes.string,
  settings: PropTypes.object,
  setSettings: PropTypes.func,
};
