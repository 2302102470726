import React from "react";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { Clear, Edit } from "@mui/icons-material";
import { ControlledEditableInput } from "../../../../utils/ControlledEditableInput";
import { CATEGORIES, STEP_TYPES } from "./utils";
import { useSettingsData } from "../../../../hooks/useSettingsData";
import AddButton from "../../../../utils/AddButton";
import { useStepUpdate } from "../../hooks/useStepUpdate";
import { StepTypeOptions } from "./StepTypeOptions";
import { useStepDelete } from "../../hooks/useStepDelete";

export const Step = ({ step, index }) => {
  const [edit, setEdit] = React.useState({ ...step, editing: false });
  const [loading, setLoading] = React.useState(false);
  const handleChange = ({ field, value }) => {
    setEdit((prev) => ({ ...prev, [field]: value }));
  };
  const { data: settings } = useSettingsData();
  const { mutate: deleteStep } = useStepDelete();
  const isDisabled = step.name == "created" || step.name == "completed";
  const {
    mutate: updateStep,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  } = useStepUpdate();
  React.useEffect(() => {
    if (isUpdateSuccess) {
      setLoading(false);
      setEdit((prev) => ({ ...prev, editing: false }));
    }
    if (isUpdateError) {
      setLoading(false);
    }
  }, [isUpdateSuccess, isUpdateError]);
  const [error, setError] = React.useState({
    name: false,
    description: false,
    type: false,
    category: false,
    active: false,
  });
  const [edited, setEdited] = React.useState(false);
  React.useEffect(() => {
    setEdited(isEdited(step, edit));
  }, [edit]);
  React.useEffect(() => {
    setError((prev) => ({
      name: !edit.name
        ? "Name cannot be empty."
        : settings.statusSteps.some((sStep) => sStep.name === edit.name) &&
          edit.name !== step.name
        ? "Name already exists."
        : false,
      description: !edit.description ? "Description cannot be empty." : false,
      active: prev.active,
    }));
  }, [edit]);
  const handleUpdate = () => {
    if (!edit.active) {
      handleChange({ field: "active", value: true });
      return;
    }
    if (error.name || error.description || error.type) {
      setError((prev) => ({ ...prev, active: true }));
      return;
    }
    setLoading(true);
    updateStep({ pack: edit });
  };
  const handleCancel = () => {
    setEdit({ ...step, editing: false });
  };
  const handleDelete = () => {
    deleteStep({ pack: step });
  };
  return (
    <Draggable
      draggableId={step.name}
      index={index}
      isDragDisabled={isDisabled}
    >
      {(provided) => (
        <Paper
          sx={{ m: 1, p: 1 }}
          elevation={4}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex" }}>
              <Typography variant="h4" sx={{ opacity: "0.5", mr: 1 }}>
                {index + 2}.{" "}
              </Typography>
              <Typography variant="body1">
                <ControlledEditableInput
                  {...edit}
                  size="small"
                  label="Name"
                  field="name"
                  hideLabel
                  value={edit.name}
                  sx={{ mb: 2 }}
                  onChange={handleChange}
                />
              </Typography>
            </Box>

            <Box sx={{ display: "flex", gap: 1, mb: "auto" }}>
              {edit.editing ? (
                <Box sx={{ display: "flex", gap: 1 }}>
                  <AddButton
                    variant="outlined"
                    onClick={handleUpdate}
                    loading={loading}
                    disabled={
                      error.name || error.description || error.type || !edited
                    }
                    buttonText="Save"
                  />
                  <Button
                    onClick={handleCancel}
                    variant="outlined"
                    color="error"
                  >
                    Cancel
                  </Button>
                </Box>
              ) : (
                <IconButton
                  onClick={() =>
                    setEdit((prev) => ({ ...prev, editing: !prev.editing }))
                  }
                  disabled={isDisabled}
                >
                  <Edit />
                </IconButton>
              )}
              <IconButton onClick={() => handleDelete()} disabled={isDisabled}>
                <Clear />
              </IconButton>
            </Box>
          </Box>
          <Typography variant="body2">
            <ControlledEditableInput
              {...edit}
              size="small"
              label="Description"
              hideLabel
              field="description"
              value={edit.description}
              onChange={handleChange}
            />
          </Typography>
          <Grid
            container
            spacing={1}
            sx={{
              overflow: "hidden",
              transition: "height 0.5s",
              height: edit.editing ? "120px" : 0,
              mt: edit.editing ? 2 : 0,
              // mt: 2,
            }}
          >
            <Grid item xs={6}>
              <FormControl size="small" fullWidth>
                <InputLabel>Prompt user to:</InputLabel>
                <Select
                  label="Prompt user to:"
                  value={edit.type}
                  disabled
                  onChange={(e) =>
                    handleChange({ field: "type", value: e.target.value })
                  }
                >
                  {STEP_TYPES.map((type) => (
                    <MenuItem key={type.name} value={type.name} dense>
                      {type.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
              }}
            >
              <FormControl sx={{ width: "30%" }} size="small">
                <InputLabel>Category</InputLabel>
                <Select
                  label="Category"
                  value={edit.category}
                  onChange={(e) =>
                    handleChange({ field: "category", value: e.target.value })
                  }
                >
                  <MenuItem value="" dense>
                    <em>None</em>
                  </MenuItem>
                  {CATEGORIES.map((category) => (
                    <MenuItem key={category} value={category} dense>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <StepTypeOptions step={edit} handleChange={handleChange} />
            </Grid>
          </Grid>
        </Paper>
      )}
    </Draggable>
  );
};

Step.propTypes = {
  step: PropTypes.object,
  index: PropTypes.number,
  isDragging: PropTypes.bool,
  deleteStep: PropTypes.func,
};

const isEdited = (step, edit) => {
  return (
    step.name !== edit.name ||
    step.description !== edit.description ||
    step.type !== edit.type ||
    step.category !== edit.category ||
    step.template !== edit.template ||
    step.templateId !== edit.templateId ||
    step.amount !== edit.amount ||
    step.percent !== edit.percent ||
    step.send !== edit.send
  );
};
