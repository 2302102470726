import {
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { ParameterTable } from "./ParameterTable";
import { UNITS, useParameterCreate } from "./utils";
import { useParametersData } from "../../../../hooks/useParametersData";

export const CreateNewParameter = () => {
  const [newParam, setNewParam] = React.useState({
    active: false,
    name: "",
    description: "",
    value: 1,
    unit: "",
    global: false,
    values: "",
  });
  const { data: parameters } = useParametersData();
  const { mutate: createParameter, isSuccess } = useParameterCreate();
  const [error, setError] = React.useState({
    name: false,
    description: false,
    value: false,
    active: false,
  });
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    setError((prev) => ({
      name: !newParam.name
        ? "Name cannot be empty."
        : parameters.some((param) => param.name === newParam.name)
        ? "Name already exists."
        : false,
      description: !newParam.description
        ? "Description cannot be empty."
        : false,
      value: !newParam.value ? "Value cannot be empty." : false,
      active: prev.active,
    }));
  }, [newParam]);
  React.useEffect(() => {
    if (isSuccess) {
      setNewParam({
        active: false,
        name: "",
        description: "",
        value: 1,
        unit: "",
        global: false,
        values: "",
      });
      setLoading(false);
    }
  }, [isSuccess]);
  const handleChange = ({ field, value }) => {
    setNewParam((prev) => ({ ...prev, [field]: value }));
  };
  const handleAdd = () => {
    if (!newParam.active) {
      setNewParam((prev) => ({ ...prev, active: true }));
      return;
    }
    if (error.name || error.description || error.value) {
      setError((prev) => ({ ...prev, active: true }));
      return;
    }
    setLoading(true);
    createParameter({ pack: newParam });
  };
  const handleCancel = () => {
    setNewParam({
      active: false,
      name: "",
      description: "",
      value: "",
      global: false,
      values: "",
    });
  };

  if (!parameters) return null;

  return (
    <Box>
      <Grid
        container
        spacing={1}
        sx={{
          height: newParam.active ? "195px" : 0,
          width: "100%",
          transition: "height 0.5s",
          overflow: "hidden",
        }}
      >
        <Grid item xs={3}>
          <TextField
            error={error.active && error.name}
            helperText={error.active ? error.name : ""}
            value={newParam.name}
            label="Name"
            size="small"
            fullWidth
            onChange={(e) =>
              handleChange({ field: "name", value: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={9}>
          <TextField
            error={error.active && error.description}
            helperText={error.active ? error.description : ""}
            value={newParam.description}
            label="Description"
            size="small"
            fullWidth
            onChange={(e) =>
              handleChange({ field: "description", value: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={error.active && error.value}
            helperText={error.active ? error.value : ""}
            value={newParam.value}
            label="Value"
            size="small"
            fullWidth
            onChange={(e) =>
              handleChange({ field: "value", value: e.target.value })
            }
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
          }}
        >
          <FormControl sx={{ width: "30%" }} size="small">
            <InputLabel>Unit</InputLabel>
            <Select
              label="Unit"
              value={newParam.unit}
              onChange={(e) =>
                handleChange({ field: "unit", value: e.target.value })
              }
            >
              <MenuItem value="" dense>
                <em>None</em>
              </MenuItem>
              {UNITS.map((unit) => (
                <MenuItem key={unit} value={unit} dense>
                  {unit}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <RadioGroup
            row
            sx={{ ml: 2, width: "70%", display: "flex", alignItems: "start" }}
            value={newParam.global}
            onChange={(e) =>
              handleChange({ field: "global", value: e.target.value })
            }
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="Global"
              sx={{ mr: 2 }}
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Component Specific"
            />
          </RadioGroup>
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "end", p: 1 }}>
        <Fade in={newParam.active}>
          <Button
            variant="outlined"
            color="error"
            sx={{ mt: 1, mr: 1 }}
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
        </Fade>

        <Button
          variant="outlined"
          onClick={() => handleAdd()}
          sx={{ mt: 1 }}
          style={{
            minWidth: "0px",
            maxWidth: newParam.active ? "7%" : "25%",
            transition: "width 0.5s",
            overflow: "hidden",
          }}
          disabled={
            (newParam.active &&
              error.name &&
              error.description &&
              error.value) ||
            loading
          }
        >
          {loading ? (
            <>
              <CircularProgress size={20} />
              Loading
            </>
          ) : newParam.active ? (
            "Add"
          ) : (
            "Add New"
          )}
        </Button>
      </Box>
      <Paper elevation={0} sx={{ display: "flex" }}>
        <ParameterTable active={newParam.active} />
      </Paper>
    </Box>
  );
};
