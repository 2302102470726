import { Box, Button, Card, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
// import axios from "axios";

export const PlansAndPricing = ({ user, setPage }) => {
  // const handleClick = (lookupKey) => {
  //   axios.post("/payment/checkout", {
  //     lookup_key: lookupKey,
  //     user: user.id,
  //   });
  // };
  return (
    <Box sx={{ mb: 4, mt: 4 }}>
      <Box sx={{ textAlign: "center", mb: 4 }}>
        <Typography variant="h3" fontWeight={"bold"}>
          Plans & Pricing
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
        <Card
          sx={{
            width: "100%",
            textAlign: "center",
            height: "200px",
            display: "flex",
            flexDirection: "column",
            p: 2,
          }}
        >
          <Typography variant="h4">Modular Pricing</Typography>
          <Typography variant="body">
            Manufacture.app is now module based! Select only the modules you
            need.
          </Typography>
          <Box sx={{ mt: "auto" }}>
            <Typography variant="h5">Starting at $15/month</Typography>

            {user.id ? (
              <Button variant="contained" onClick={() => setPage("account")}>
                Get Started
              </Button>
            ) : (
              <Button variant="contained" onClick={() => setPage("signup")}>
                Get Started
              </Button>
            )}
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

PlansAndPricing.propTypes = {
  user: PropTypes.string,
  handleNoUserClick: PropTypes.func.isRequired,
  setPage: PropTypes.func,
};
