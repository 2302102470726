import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import routes from "./pages/pagesData";
import PropTypes from "prop-types";
import { UserContext } from "./App";
import { Content } from "./Content";
import { Employee } from "./pages/Employee";

export const Router = () => {
  const { user } = React.useContext(UserContext);

  const [stateRoutes, setStateRoutes] = React.useState(
    routes(user, checkAppAccess(user))
  );

  React.useEffect(() => {
    setStateRoutes(routes(user, checkAppAccess(user)));
  }, [user]);
  const routing = useRoutes(stateRoutes);

  return <>{routing}</>;
};

Router.propTypes = {
  user: PropTypes.object,
};

const checkAppAccess = (user) => {
  if (user.person && user.person.role !== "Admin") {
    return <Employee />;
  } else if (user?.status == "active") {
    return <Content />;
  } else {
    return <Navigate to="/login" />;
  }
};
