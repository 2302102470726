import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

export function NoCustomersOverlay() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        zIndex: 5,
      }}
    >
      <Typography variant="h5" align="center">
        Your Customers will appear here. You can add one at the top of the page.
      </Typography>
    </div>
  );
}

NoCustomersOverlay.propTypes = {
  message: PropTypes.string,
};
