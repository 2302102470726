import { Box, Button, Paper, Typography, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { SelectedProjectContext } from "../../ProjectView";
import { useProjectUpdate } from "../../../hooks/useProjectUpdate";
import { ProjectCalendar } from "../../../../calendar/components/ProjectCalendar";

export const Calendar = () => {
  const project = React.useContext(SelectedProjectContext);

  const { mutate: updateProject } = useProjectUpdate(project._id);
  const [edited, setEdited] = React.useState([]);

  const [events, setEvents] = React.useState([]);

  const theme = useTheme();

  React.useEffect(() => {
    console.log(project);
    console.log(`Setting events with color ${project.color}`);
    setEvents(() => [
      {
        start: dayjs(project.creationDate).toDate(),
        end: dayjs(project.dueDate).toDate(),
        allDay: true,
        display: "background",
      },
      ...createEvents(project, theme),
    ]);
  }, [
    project,
    project.projectName,
    project.creationDate,
    project.dueDate,
    project.milestones,
    project.tasks,
    project.color,
  ]);

  const handleEvents = (es) => {
    console.log("Setting events");
    // let different = false;
    if (!es.length) return;
    console.log(es);
    // es.forEach((event) => {
    //   console.log(event.title);
    //   let task = project.tasks.find((t) => t.name == event.title);
    //   if (!task) return;
    //   if (
    //     dayjs(task.startDate).format("YYYY-MM-DD") !==
    //       dayjs(event.start).format("YYYY-MM-DD") ||
    //     dayjs(task.endDate).format("YYYY-MM-DD") !==
    //       dayjs(event.end).format("YYYY-MM-DD")
    //   ) {
    //     different = true;
    //   }
    // });
    // if (different) {

    setEdited(es);
    // }
  };

  const handleSave = () => {
    let newTasks = project.tasks.map((task) => ({
      ...task,
      startDate: edited.find((e) => e.title == task.name).start,
      endDate: edited.find((e) => e.title == task.name).end,
    }));
    updateProject({
      id: project._id,
      pack: { tasks: newTasks, updateType: ["tasks", "raw"] },
    });
  };

  const checkForChanges = () => {
    let different = false;
    events.forEach((event) => {
      let task = edited.find((t) => t.title == event.title);
      if (!task) return false;

      if (
        dayjs(task.start).format("YYYY-MM-DD") !==
          dayjs(event.start).format("YYYY-MM-DD") ||
        dayjs(task.end).format("YYYY-MM-DD") !==
          dayjs(event.end).format("YYYY-MM-DD")
      ) {
        different = true;
      }
    });
    if (different) {
      return true;
    }
  };

  return (
    <>
      <Paper sx={{ p: 1 }}>
        {checkForChanges() && (
          <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
            <Typography variant="h6">
              Changes have been made to the calendar.
            </Typography>
            <Button onClick={handleSave} variant="contained" size="small">
              Save
            </Button>
          </Box>
        )}

        <Box sx={{ flex: 1 }}>
          <ProjectCalendar events={events} eventsSet={handleEvents} />
        </Box>
      </Paper>
    </>
  );
};

Calendar.propTypes = {
  project: PropTypes.object,
  setProject: PropTypes.func,
};
const createEvents = (project, theme) => {
  let newEvents = [];
  let startDate = dayjs(project.creationDate).toDate();
  let endDate = dayjs(project.creationDate).add(1, "day").toDate();
  project.tasks.forEach((task) => {
    task.startDate ? (startDate = task.startDate) : null;
    task.endDate ? (endDate = task.endDate) : null;
    newEvents.push({
      title: task.name,
      start: startDate,
      end: endDate,
      allDay: true,
      backgroundColor: project.color,
      fontColor: project.color
        ? getContrastYIQ(project.color)
        : theme.palette.text.primary,
      milestone: task.milestone,
      project: project.projectName,
      assignedTo: task.assignedTo.map((p) => p._id),
      extendedProps: {
        assignedTo: task.assignedTo.map((p) => p._id),
        milestone: task.milestone,
        color: project.color,
      },
    });
    startDate = endDate;
    3;
    endDate = dayjs(endDate).add(1, "day").toDate();
  });
  return newEvents;
};
function getContrastYIQ(hexcolor) {
  // Convert hex color to RGB
  var r = parseInt(hexcolor.substr(1, 2), 16);
  var g = parseInt(hexcolor.substr(3, 2), 16);
  var b = parseInt(hexcolor.substr(5, 2), 16);

  // Calculate YIQ (brightness) value
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Return 'black' or 'white' based on the YIQ value
  return yiq >= 128 ? "black" : "white";
}
