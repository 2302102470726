// is child of root node or is child of new node
export const isEditable = (nodeId, edit) => {
  let splitId = nodeId.split("-");
  if (edit) return true;
  // if the node is the root node
  if (splitId.length === 1) {
    return true;
    // if the node is one you just added
  } else if (splitId.at(-1).includes("_")) {
    return true;
  } else {
    return false;
  }
};
// is this node a child of a an editable node (for the purpose of editing qty)
export const isValidChild = (nodeId) => {
  let splitId = nodeId.split("-");
  // if the node is a child of the root node
  if (splitId.length === 2) {
    return true;
    // if the node is a child of a node you just added
  } else if (splitId.length < 2) {
    return false;
  } else if (splitId.at(-2).includes("_")) {
    return true;
  } else {
    return false;
  }
};

export const isRootNode = (nodeId) => {
  let splitId = nodeId.split("-");
  // if the node is the root node
  if (splitId.length === 1) {
    return true;
  } else {
    return false;
  }
};

export const canOpenNodeMenu = (nodeId) => {
  let splitId = nodeId.split("-");
  // if the node is the root node
  if (splitId.length === 1) {
    return true;
    // if the node is one you just added
  } else if (splitId.at(-1).includes("_")) {
    return true;
    // if the node is a child of the root node
  } else if (splitId.length === 2) {
    return true;
    // if the node is a child of a node you just added
  } else if (splitId.at(-2).includes("_")) {
    return true;
  } else {
    return false;
  }
};

export const getSubnodes = (nodeId, nodes) => {
  let id = nodeId.split("-");
  let subnodes = nodes.filter((node) => {
    let nodeId = node.id.split("-");
    if (nodeId.length === id.length + 1) {
      if (nodeId.slice(0, id.length).join("-") === id.join("-")) {
        return true;
      }
    }
  });
  return subnodes;
};
