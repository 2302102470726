import * as React from "react";
import Paper from "@mui/material/Paper";
import { Divider, Menu } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import DownloadIcon from "@mui/icons-material/Download";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import SendIcon from "@mui/icons-material/Send";
// import axios from "axios";
import { useFileDelete } from "../hooks/useFileDelete";
import { getFile } from "../../../api/fileApi";

export default function FileContextMenu({
  contextMenu,
  handleClose,
  file,
  setEdit,
  openSendDialog,
}) {
  const { mutate: deleteFile } = useFileDelete();

  const handleDelete = () => {
    deleteFile({ pack: file._id });
    handleClose();
  };

  const handleRename = () => {
    setEdit(true);
    handleClose();
  };
  const handleDownload = async () => {
    try {
      const response = await getFile(file._id);
      const blob = new Blob(
        [
          new Uint8Array(
            atob(response.buffer)
              .split("")
              .map((c) => c.charCodeAt(0))
          ),
        ],
        { type: response.contentType }
      );

      const href = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", response.file.name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      handleClose();
    } catch (error) {
      console.error("Error in downloading the file:", error);
    }
  };

  const handleSendToSign = () => {
    openSendDialog();
    handleClose();
  };

  return (
    <Paper sx={{ width: 320, maxWidth: "100%" }}>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem onClick={handleDownload}>
          <ListItemIcon>
            <DownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Download</ListItemText>
        </MenuItem>

        <MenuItem onClick={handleRename}>
          <ListItemIcon>
            <DriveFileRenameOutlineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Rename</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleSendToSign}>
          <ListItemIcon>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Send To Sign</ListItemText>
        </MenuItem>
      </Menu>
    </Paper>
  );
}

FileContextMenu.propTypes = {
  contextMenu: PropTypes.object,
  handleClose: PropTypes.func,
  file: PropTypes.object,
  setEdit: PropTypes.func,
  openSendDialog: PropTypes.func,
};
