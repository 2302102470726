import { Paper, Typography } from "@mui/material";
import {
  DataGridPremium,
  GridOverlay,
  GridToolbarContainer,
} from "@mui/x-data-grid-premium";
import React from "react";
import { SelectedProjectContext } from "../../ProjectView";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { EditZone } from "./EditZone";

export const ZoneTable = ({ zone }) => {
  const project = React.useContext(SelectedProjectContext);

  const [data, setData] = React.useState(
    makeData(project.products?.filter((p) => p.zone === zone) || [])
  );
  React.useEffect(() => {
    setData(makeData(project.products?.filter((p) => p.zone === zone) || []));
  }, [project.products, zone]);

  return (
    <Paper sx={{ mt: 1 }}>
      <DataGridPremium
        rows={data.rows}
        columns={data.columns}
        hideFooter
        autoHeight
        density="compact"
        slots={{ toolbar: CustomToolbar, noRowsOverlay: NoRowsOverlay }}
        slotProps={{ toolbar: { zone: zone, project } }}
      />
    </Paper>
  );
};

const CustomToolbar = (props) => {
  let zone = props.zone;
  if (props.project.zones.length > 0 && !zone) {
    zone = "No Zone";
  }
  return (
    <GridToolbarContainer>
      {" "}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          gap: 1,
          p: 1,
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">{zone}</Typography>

        <EditZone zone={props.zone} />
      </Box>
    </GridToolbarContainer>
  );
};

CustomToolbar.propTypes = {
  zone: PropTypes.string,
  project: PropTypes.object,
};

const NoRowsOverlay = () => {
  return <GridOverlay>No products. Edit the zone to add some.</GridOverlay>;
};

const makeData = (products) => {
  let rows = [];
  let columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.3,
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
  ];
  products.forEach((product) => {
    let row = {};
    product.parameters.forEach((param) => {
      row[param.name] = param.value;

      if (columns.find((c) => c.field === param.name)) return;
      columns.push({
        field: param.name,
        headerName: param.name,
        flex: 0.5,
      });
    });
    product.options.forEach((option) => {
      row[option.name] = option.value;
      if (columns.find((c) => c.field === option.name)) return;
      columns.push({
        field: option.name,
        headerName: option.name,
        flex: 1,
      });
    });
    row.id = product.id;
    row.name = product.name;
    row.price = product.price;

    rows.push(row);
  });
  columns.push({
    field: "price",
    headerName: "Price",
    type: "number",
    renderCell: (params) => formatter.format(params.value),
    flex: 1,
  });
  return { rows, columns };
};

ZoneTable.propTypes = {
  zone: PropTypes.string,
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
