import { AddCircle, Receipt } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React from "react";
import { useCustomersData } from "../../../hooks/useCustomerData";
import { useProjectsData } from "../../../hooks/useProjectsData";
import { createInvoice } from "../../../api/paymentApi";
import { UserContext } from "../../../App";
import { NewItemDialog } from "../../../components/common/itemList";
import { columns, endpoints, titles } from "../../../components/common/utils";
import { TransitionGroup } from "react-transition-group";
import { useNewFinancial } from "../hooks/useNewFinancial";
import { useFinancialCreate } from "../hooks/useFinancialCreate";
import PropTypes from "prop-types";
import { useSettingsData } from "../../../hooks/useSettingsData";
import CurrencyTextField from "../../../utils/CurrencyTextField";

export const Invoice = ({ info }) => {
  const [open, setOpen] = React.useState(info ? info.open : false);
  const [newCustomerOpen, setNewCustomerOpen] = React.useState(false);
  const handleNewCustomerOpen = () => {
    setNewCustomerOpen(true);
  };
  const [loading, setLoading] = React.useState(false);
  const { newFinancial, handleFinancialChange, clearFinancial } =
    useNewFinancial();
  const { mutate: createFinancial } = useFinancialCreate(info.project);

  const [newProduct, setNewProduct] = React.useState({
    name: "",
    qty: 1,
    price: "",
  });

  const { user } = React.useContext(UserContext);

  const [invoice, setInvoice] = React.useState({
    customer: "",
    project: "",
    products: [],
  });

  const handleInvoice = ({ field, value }) => {
    if (field === "project") {
      const project = projects.find((p) => p._id === value);
      let invoiceProducts = [];
      project.products.forEach((p) => {
        if (
          invoiceProducts.find((x) => x.name == p.name && x.price == p.price)
        ) {
          invoiceProducts.find(
            (x) => x.name == p.name && x.price == p.price
          ).qty += 1;
        } else {
          invoiceProducts.push({ ...p, qty: 1 });
        }
      });
      setInvoice((prev) => ({
        ...prev,
        products: project ? invoiceProducts : [],
        project: value,
      }));
    } else {
      setInvoice((prev) => ({ ...prev, [field]: value }));
    }
  };

  const { data: customers } = useCustomersData();
  const { data: projects } = useProjectsData();
  const { data: settings } = useSettingsData();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = async () => {
    setLoading(true);

    let invoicePack = {
      ...invoice,
      products: invoice.products.map((p) => ({
        ...p,
        price: parseFloat(`${p.price}`.replaceAll(",", "")).toFixed(2),
      })),
    };
    const data = await createInvoice({
      pack: { ...invoicePack, user: user.id },
    });
    const newInvoice = data.data;
    const pack = {
      ...newFinancial,
      user: user.id,
      amount: (newInvoice.amount / 100).toFixed(2),
      customer: newInvoice.customer,
      project: invoice.project ? invoice.project : "",
      type: "invoice",
      stripeInvoiceId: newInvoice.stripeInvoiceId,
      stripeCustomerId: newInvoice.stripeCustomerId,
      stripeInvoiceStatus: newInvoice.stripeInvoiceStatus,
    };
    createFinancial({ pack });
    clearFinancial();
    setLoading(false);
    handleClose();
  };

  const handleProductAdd = () => {
    setInvoice((prev) => ({
      ...prev,
      products: [
        ...prev.products,
        {
          name: newProduct.name,
          price: parseFloat(newProduct.price),
        },
      ],
    }));
    setNewProduct({ name: "", price: "" });
  };

  const checkValidProduct = () => {
    if (parseFloat(newProduct.price) < 0 || isNaN(newProduct.price))
      return false;
    if (newProduct.name === "") return false;
    return true;
  };

  React.useEffect(() => {
    if (info) {
      if (info.customer) {
        handleInvoice({ field: "customer", value: info.customer._id });
      }
      if (info.project) {
        handleInvoice({ field: "project", value: info.project._id });
        handleFinancialChange({
          field: "name",
          value: `Invoice for ${info.project.projectName}`,
        });
      }
    }
  }, [info]);

  if (!customers || !projects || !settings) return null;
  const active =
    settings.stripeAccount?.object.capabilities.card_payments == "active";

  return (
    <>
      <Tooltip
        title={
          !active
            ? "Connect your Stripe account to use automatic invoicing."
            : null
        }
      >
        <Box id="stripe-invoice">
          <Button
            variant="contained"
            onClick={handleClickOpen}
            disabled={!active}
            startIcon={<Receipt />}
            sx={{
              backgroundColor: "#6772e5",
              ":hover": {
                backgroundColor: "#9072e5",
              },
            }}
          >
            Invoice
          </Button>
        </Box>
      </Tooltip>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Invoice</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Name"
            value={newFinancial.name}
            sx={{ mt: 2 }}
            onChange={(e) =>
              handleFinancialChange({
                field: "name",
                value: e.target.value,
              })
            }
          />
          <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel>Customer</InputLabel>
            <Select
              label="Customer"
              renderValue={(value) => {
                const customer = customers.find((c) => c._id === value);
                return `${customer.firstName} ${customer.lastName}`;
              }}
              onChange={(e) =>
                handleInvoice({ field: "customer", value: e.target.value })
              }
              value={invoice.customer}
            >
              {customers.map((customer) => (
                <MenuItem key={customer._id} value={customer._id}>
                  {customer.firstName} {customer.lastName}
                </MenuItem>
              ))}
              <MenuItem onClick={handleNewCustomerOpen}>
                Add new customer
              </MenuItem>
            </Select>
          </FormControl>
          {invoice.customer && (
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel>Project</InputLabel>
              <Select
                label="Project"
                renderValue={(value) => {
                  const project = projects.find((c) => c._id === value);
                  return project.projectName;
                }}
                onChange={(e) =>
                  handleInvoice({ field: "project", value: e.target.value })
                }
                value={invoice.project}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {projects
                  .filter((p) => p.customer._id === invoice.customer)
                  .map((project) => (
                    <MenuItem key={project._id} value={project._id}>
                      {project.projectName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          {invoice.customer && <Divider sx={{ mt: 1 }}>Items</Divider>}
          {invoice.products.length > 0 && (
            <Box sx={{ overflow: "auto" }}>
              <TransitionGroup>
                {invoice.products.map((product, i) => (
                  <Collapse key={i}>
                    <Box key={i} sx={{ display: "flex", gap: 1, mt: 1 }}>
                      <TextField
                        sx={{ width: "75%" }}
                        size="small"
                        label="Name"
                        value={product.name}
                      />
                      <TextField size="small" label="Qty" value={product.qty} />

                      <CurrencyTextField
                        label="Price"
                        value={product.price}
                        size="small"
                      />
                    </Box>
                  </Collapse>
                ))}
              </TransitionGroup>
            </Box>
          )}
          {invoice.customer && (
            <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
              <TextField
                sx={{ width: "75%" }}
                size="small"
                label="Name"
                value={newProduct.name}
                onChange={(e) =>
                  setNewProduct((prev) => ({ ...prev, name: e.target.value }))
                }
              />
              <TextField
                size="small"
                value={newProduct.qty}
                label="Qty"
                onChange={(e) =>
                  setNewProduct((prev) => ({ ...prev, qty: e.target.value }))
                }
              />
              <CurrencyTextField
                size="small"
                value={newProduct.price}
                label="Price"
                onChange={(e) =>
                  setNewProduct((prev) => ({ ...prev, price: e.target.value }))
                }
              />
              <Button
                onClick={handleProductAdd}
                variant="outlined"
                disabled={checkValidProduct() ? false : true}
              >
                <AddCircle />
              </Button>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleCreate} disabled={loading}>
            {loading ? <CircularProgress size={25} /> : "Create"}
          </Button>
        </DialogActions>
      </Dialog>
      <NewItemDialog
        title={titles["customers"]}
        isOpen={newCustomerOpen}
        setOpen={setNewCustomerOpen}
        fields={columns["customers"]}
        updateTable={"customers"}
        ENDPOINT={endpoints["customers"]}
      />
    </>
  );
};

Invoice.propTypes = {
  info: PropTypes.object,
};
