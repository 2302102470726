import axios from "axios";

const templateApi = axios.create();

export const getTemplates = async (user) => {
  const response = await templateApi.get(`/api/template?user=${user}`);
  return response.data.reverse();
};

export const updateTemplate = async ({ id, pack }) => {
  return await templateApi.patch(`/api/template/${id}`, pack);
};

export const deleteTemplate = async (id) => {
  return await templateApi.delete(`/api/template/${id}`);
};
export const createTemplate = async (pack) => {
  return await templateApi.post("/api/template", pack);
};
