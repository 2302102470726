import React from "react";
import { Box } from "@mui/system";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ProductContext } from "./ProductCard";
import PropTypes from "prop-types";

export const ParameterSelect = ({ view }) => {
  // eslint-disable-next-line no-unused-vars
  const { product, handleEdit } = React.useContext(ProductContext);
  console.log({ product });

  const [selectableParameters, setSelectableParameters] = React.useState(
    getSelectableParameters(product)
  );

  React.useEffect(() => {
    setSelectableParameters(getSelectableParameters(product));
  }, [product]);

  const handleSelect = ({ parameter, value }) => {
    console.log({ ...parameter, selection: value });
    handleEdit({
      field: "parameters",
      type: "update",
      value: { ...parameter, selection: value },
    });
  };
  const [custom, setCustom] = React.useState("");
  const handleCustom = (e) => {
    setCustom(e.target.value);
  };

  const handleCustomConfirm = (e, parameter, value) => {
    console.log({ parameter, value });
    e.stopPropagation();
    handleSelect({ parameter, value });
  };

  console.log(selectableParameters);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: view === "compact" ? "row" : "column",
        gap: 1,
      }}
    >
      {selectableParameters.map((p) => {
        return (
          <FormControl key={p} fullWidth size="small">
            <InputLabel id={p.label}>{p.label}</InputLabel>
            <Select
              label={p.label}
              size="small"
              onChange={(e) =>
                handleSelect({ parameter: p, value: e.target.value })
              }
              value={p.selection}
              renderValue={(v) => v}
            >
              {p.formula.split(",").map((f) => (
                <MenuItem key={f} value={f}>
                  {f}
                </MenuItem>
              ))}
              <MenuItem value={custom} sx={{ display: "flex", gap: 1 }}>
                <TextField
                  size="small"
                  value={custom}
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                  fullWidth
                  onChange={handleCustom}
                />
                <Button
                  size="small"
                  onClick={(e) => handleCustomConfirm(e, p, custom)}
                >
                  Confirm
                </Button>
              </MenuItem>
            </Select>
          </FormControl>
        );
      })}
    </Box>
  );
};

ParameterSelect.propTypes = {
  view: PropTypes.string,
};

const isSelectable = (parameter) => parameter.formula?.includes(",");

const getSelectableParameters = (product) => {
  let params = [];
  console.log({ product });

  product.allParameters.forEach((p) => {
    if (isSelectable(p)) {
      params.push({ ...p, label: `${p.name}` });
    }
  });

  return params;
};
