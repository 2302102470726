import { IconButton } from "@mui/material";
import React from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PropTypes from "prop-types";
import { Steps } from "intro.js-react";
import { HelpContext } from "../Content.js";
import { steps as introSteps } from "./introSteps.js";
import { useSettingsData } from "../hooks/useSettingsData.js";

export const HelpButton = () => {
  const { help } = React.useContext(HelpContext);
  const { data: settings } = useSettingsData();

  const [open, setOpen] = React.useState(false);
  const [firstOpen, setFirstOpen] = React.useState(false);

  const onExitFirst = () => {
    setFirstOpen(false);
  };

  const [steps, setSteps] = React.useState(help);

  React.useEffect(() => {
    setSteps(help);
    if (open) {
      setOpen(false);
      setTimeout(() => setOpen(true), 500);
    }
  }, [help]);

  const onExit = () => {
    setOpen(false);
  };

  const firstTimeLogin = localStorage.getItem("firstTimeLogin");
  React.useEffect(() => {
    if (firstTimeLogin !== "false" && settings?.organization) {
      setFirstOpen(true);
      localStorage.setItem("firstTimeLogin", false);
    } else if (firstTimeLogin === null) {
      localStorage.setItem("firstTimeLogin", true);
    }
  }, [firstTimeLogin, settings]);

  return (
    <>
      <IconButton onClick={() => setOpen(true)} id="help">
        <HelpOutlineIcon />
      </IconButton>
      <Steps enabled={open} steps={steps} initialStep={0} onExit={onExit} />
      <Steps
        enabled={firstOpen}
        steps={introSteps.firstTime}
        initialStep={0}
        onExit={onExitFirst}
      />
    </>
  );
};

HelpButton.propTypes = {
  page: PropTypes.string,
};
