import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../../../Content";
import { createFolder } from "../../../api/folderApi";
import { UserContext } from "../../../App";

export function useFolderCreate() {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { user } = React.useContext(UserContext);

  return useMutation({
    mutationFn: ({ pack }) => {
      pack.user = user.id;
      return createFolder(pack);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("folders");
      setSnack({
        open: true,
        message: "Folder created.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Folder could not be created.",
        severity: "error",
      });
    },
  });
}
