import * as React from "react";
import { Button, Dialog, Menu, MenuItem } from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PropTypes from "prop-types";
import { useProjectsData } from "../../../hooks/useProjectsData";
import { useCustomersData } from "../../../hooks/useCustomerData";
import { useTemplatesData } from "../../../hooks/useTemplatesData";
import { TemplateView } from "../../templateEditor/components/TemplateView";
import { checkSubscription } from "../../../utils/checkSubscription";
import { useSettingsData } from "../../../hooks/useSettingsData";

export const CreateFromTemplate = ({ project, folder }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
    setDialogOpen(true);
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  // const queryClient = useQueryClient();
  const { data: templates } = useTemplatesData();
  const { data: projects } = useProjectsData();
  const { data: customers } = useCustomersData();
  const { data: settings } = useSettingsData();

  // const generateDocumentMutation = useMutation(generateDocument, {
  //   onSuccess: () => {
  //     setLoading(false);
  //     queryClient.invalidateQueries("files");
  //   },
  // });

  if (!templates) {
    return <>Loading...</>;
  }

  // const handleSubmit = (template) => {
  //   setLoading(true);
  //   setAnchorEl(null);
  //   generateDocumentMutation.mutate({
  //     template: template._id,
  //     project: project,
  //     folder: folder._id,
  //     user: user,
  //   });
  // };
  const sub = checkSubscription(settings).includes("project");

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleClick}
        startIcon={<NoteAddIcon />}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        disabled={!sub}
        aria-expanded={open ? "true" : undefined}
      >
        Template
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {templates.length ? (
          templates.map((template) => (
            <MenuItem
              key={template.name}
              onClick={() => handleTemplateSelect(template)}
            >
              {template.name}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>No Templates</MenuItem>
        )}
      </Menu>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth={"xl"}
        scroll="paper"
      >
        {/* <DocumentEditor /> */}

        {/* <Invoice /> */}

        <TemplateView
          template={selectedTemplate}
          select={{
            project: project,
            folder: folder,
            customer: project.customer,
            products: [...project.products],
          }}
          projects={projects}
          customers={customers}
          readOnly={true}
          handleClose={handleDialogClose}
        />
      </Dialog>
    </>
  );
};

CreateFromTemplate.propTypes = {
  user: PropTypes.string,
  project: PropTypes.object,
  folder: PropTypes.object,
};
