import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../../../Content";
import { sendToSignFile } from "../../../api/fileApi";

export function useFileSendToSign(project) {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  return useMutation({
    mutationFn: ({ id, pack }) => {
      return sendToSignFile({ id, pack });
    },
    onSuccess: () => {
      if (project) {
        queryClient.invalidateQueries(["project", project._id]);
        queryClient.invalidateQueries("projects");
      }
      queryClient.invalidateQueries("files");

      setSnack({
        open: true,
        message: "File sent.",
        severity: "success",
      });
      return true;
    },
    onError: () => {
      setSnack({
        open: true,
        message: "File could not be sent.",
        severity: "error",
      });
    },
  });
}
