import { Paper } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export const Store = ({ org }) => {
  return (
    <Paper sx={{ width: "100%", p: 1 }}>
      <img
        src={`https://cdn.manufacture.app/${org.logo}`}
        alt="logo"
        style={{ width: "100%" }}
      />
      <h2>{org.name}</h2>
      <h3>{org.description}</h3>
      <div>
        {org.catalogs.map((catalog) => (
          <div key={catalog._id}>
            <h3>{catalog.name}</h3>
            <p>{catalog.description}</p>
          </div>
        ))}
      </div>
    </Paper>
  );
};

Store.propTypes = {
  org: PropTypes.object.isRequired,
};
