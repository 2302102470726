import React from "react";
import { useQuery } from "react-query";
import { UserContext } from "../../../App";
import { getOrganization } from "../../../api/organizationApi";

export const useOrganizationData = (onSuccess, onError) => {
  const { user } = React.useContext(UserContext);
  return useQuery(
    "organization",
    () => getOrganization(user.organization._id),
    {
      onSuccess,
      onError,
    }
  );
};
