import axios from "axios";
// import _ from "lodash";
// import { useMutation, useQueryClient } from "react-query";

// const parseProducts = (project) => {
//   let newProducts = [];
//   project.products.map((product, i) => {
//     let newOptions = [...]

//     let newProduct = Object.assign({}, product.product, {
//       number: i,
//       zone: product.zone,
//       price: product.price,
//       total: product.total,
//       options: options
//     });
//   });
// };

const projectApi = axios.create();

export const getProjects = async (user) => {
  const response = await projectApi.get(`/api/project`, {
    params: { user: user },
  });

  return response.data;
};

export const getProject = async (id) => {
  const response = await projectApi.get(`/api/project/${id}`);
  // let projects = [];
  // response.data.map((proj) => projects.push(parseProducts(proj)));

  return response.data;
};

export const updateProject = async ({ id, pack }) => {
  console.log({ id, pack });

  const response = await projectApi.patch(`/api/project/${id}`, pack);

  return response.data;
};

// export function UpdateProjectMutation  ({id, pack})  {
//   const queryClient = useQueryClient()
//   const updateProjectMutation = useMutation(updateProject, {
//     onSuccess: () => {
//       queryClient.invalidateQueries('projects')
//     }
//   })
//   updateProjectMutation.mutate({id: id, pack: pack})

// }
export const deleteProject = async (id) => {
  return await projectApi.delete(`/api/project/${id}`);
};

// const handleProjectProductTrim = (products) => {
//   products.forEach((product) => {
//     product.subcomponents = product.subcomponents.map((sub) => sub._id);
//     product.options = product.options.map((opt) => ({
//       _id: opt._id,
//       choice: opt.choice._id,
//     }));
//   });
//   return products;
// };
