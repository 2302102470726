import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { deleteComponent } from "../../../api/componentApi";
import { SnackbarContext } from "../../../Content";
import { UserContext } from "../../../App";

export function useComponentDelete(withSnack = true) {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { user } = React.useContext(UserContext);

  return useMutation({
    mutationFn: (id) => {
      let pack = { id: id, user: user.id };
      return deleteComponent(pack);
    },
    onSuccess: () => {
      // const idOfDeletedComp = data.data;
      // queryClient.removeQueries(["component", idOfDeletedComp]);
      // queryClient.setQueryData("components", (old) => {
      //   return old.filter((c) => c._id !== idOfDeletedComp);
      // });
      queryClient.invalidateQueries("components");
      queryClient.invalidateQueries("options");
      // queryClient.invalidateQueries("settings");
      if (withSnack) {
        setSnack({
          open: true,
          message: "Component deleted.",
          severity: "success",
        });
      }
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Component could not be deleted.",
        severity: "error",
      });
    },
  });
}
