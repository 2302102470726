import React from "react";
import { UserContext } from "../../../App";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../../../Content";
import { updateSettings } from "../../../api/settingsApi";
import { useSettingsData } from "../../../hooks/useSettingsData";

export function useStepCreate() {
  const user = React.useContext(UserContext);
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { data: settings } = useSettingsData();

  return useMutation({
    mutationFn: ({ pack }) => {
      pack.user = user;
      delete pack._id;
      updateSettings({
        user,
        pack: { statusSteps: [...settings.statusSteps, pack] },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("settings");
      setSnack({
        open: true,
        message: "Step created.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Step could not be created.",
        severity: "error",
      });
    },
  });
}
