import React from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { useTemplatesData } from "../../../../hooks/useTemplatesData";
import PercentIcon from "@mui/icons-material/Percent";
import PropTypes from "prop-types";
import CurrencyTextField from "../../../../utils/CurrencyTextField";

export const StepTypeOptions = ({ step, handleChange }) => {
  const { data: templates } = useTemplatesData();
  const handleTemplateChange = (e) => {
    handleChange({ field: "template", value: e.target.value });
    handleChange({
      field: "templateId",
      value: templates.find((t) => t.name == e.target.value)._id,
    });
  };
  const handlePercentTextChange = (e) => {
    let regex = /^(100(\.0{0,2})?|(\d|[1-9]\d)(\.\d{0,2})?)$/;
    if (regex.test(e.target.value)) {
      handleChange({ field: "amount", value: e.target.value });
    }
  };
  if (!templates) return null;
  return (
    <div>
      {step.type == "fileTemplate" ? (
        <Box sx={{ display: "flex" }}>
          <FormControl sx={{ width: "60%", mr: 2 }} size="small">
            <InputLabel>Template</InputLabel>
            <Select
              value={step.template}
              label="Template"
              onChange={handleTemplateChange}
            >
              {templates.map((template, i) => (
                <MenuItem key={i} value={template.name}>
                  {template.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={step.send}
                onChange={(e) =>
                  handleChange({ field: "send", value: e.target.checked })
                }
              />
            }
            label="Send to sign?"
          />
        </Box>
      ) : step.type == "payment" ? (
        <Box sx={{ display: "flex" }}>
          {!step.percent ? (
            <CurrencyTextField
              label="Amount"
              size="small"
              value={step.amount}
              onChange={(e) =>
                handleChange({ field: "amount", value: e.target.value })
              }
            />
          ) : (
            <TextField
              label="Amount"
              size="small"
              value={step.amount}
              onChange={(e) => handlePercentTextChange(e)}
            />
          )}
          <Tooltip title="Make the amount required be a percentage of the total project value.">
            <IconButton
              color={step.percent ? "primary" : "default"}
              sx={{ ml: 1 }}
              value={step.percent}
              onClick={() =>
                handleChange({ field: "percent", value: !step.percent })
              }
            >
              <PercentIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ) : step.type === "fileUpload" ? (
        <FormControlLabel
          control={
            <Checkbox
              checked={step.send}
              onChange={(e) =>
                handleChange({ field: "send", value: e.target.checked })
              }
            />
          }
          label="Send to sign?"
        />
      ) : null}
    </div>
  );
};

StepTypeOptions.propTypes = {
  step: PropTypes.object,
  handleChange: PropTypes.func,
};
