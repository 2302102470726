import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ImageIcon from "@mui/icons-material/Image";
import CategoryIcon from "@mui/icons-material/Category";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import QrCodeIcon from "@mui/icons-material/QrCode";

import React from "react";
import PropTypes from "prop-types";
import { NewComponentContext } from "../../components/ComponentNode";
import {
  CheckBox,
  DragHandle,
  Visibility,
  VisibilityOff,
  Warehouse,
} from "@mui/icons-material";
import { CategoryMenu } from "./CategoryMenu";
import { useParametersData } from "../../../../hooks/useParametersData";
import useStore from "../../components/store";
import { canOpenNodeMenu, getSubnodes } from "../../components/utils";
import { useComponentsData } from "../../../../hooks/useComponentsData";
import { useOptionsData } from "../../../../hooks/useOptionsData";
import { SupplierMenu } from "./SupplierMenu";

const selector = (state) => ({
  setNodeToSwitch: state.setNodeToSwitch,
  deleteNode: state.deleteNode,
  duplicateNode: state.duplicateNode,
  collapseNode: state.collapseNode,
  expandNode: state.expandNode,
  switchNode: state.switchNode,
  nodes: state.nodes,
});

export const NodeMenu = () => {
  // should let you:
  // - turn node into another
  // - delete node
  // - duplicate node (with a new key and name)
  const { newComponent, handleEdit } = React.useContext(NewComponentContext);

  // const nodes = useStore((state) => state.nodes);
  // const switchFn = useStore((state) => state.switchNode);
  // const { components } = React.useContext(ComponentsContext);

  // const parameters = useCachedParameters();
  const { data: parameters } = useParametersData();
  const { data: components } = useComponentsData();
  const { data: options } = useOptionsData();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const {
    setNodeToSwitch,
    deleteNode,
    duplicateNode,
    collapseNode,
    expandNode,
    nodes,
  } = useStore(selector);

  // const { data: components } = useComponentsData();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.preventDefault();

    // if (!canOpenNodeMenu(newComponent.nodeKey)) {
    //   return;
    // }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTurnIntoClick = () => {
    setNodeToSwitch(newComponent);
    handleClose();
  };
  const handleDuplicateClick = () => {
    let duplicate = duplicateNode({
      data: { ...newComponent },
      options,
      components,
    });
    console.log(duplicate);
    handleClose();
  };

  // const handleComponentClick = (component) => {
  //   switchFn({
  //     oldNodeId: newComponent.nodeKey,
  //     newNodeData: component,
  //     components,
  //   });
  //   setComponentsDialogOpen(false);
  // };

  const handleDeleteClick = () => {
    deleteNode(newComponent.nodeKey);
    handleClose();
  };

  const handleParameterSelect = (p) => {
    let parameter = {
      name: p.name,
      description: p.description,
      value: p.value,
      formula: p.formula,
      unit: p.unit,
      axis: p.axis,
      variable: "",
      hierarchy: ["parent"],
    };
    handleEdit({
      field: "parameters",
      value: parameter,
    });
  };

  const handleImageClick = () => {
    let value = newComponent.image == null ? "" : null;
    handleEdit({
      field: "image",
      value: value,
    });
  };

  const [catAnchor, setCatAnchor] = React.useState(null);
  const [supplierAnchor, setSupplierAnchor] = React.useState(null);

  const handleCategoryClick = (e) => {
    setCatAnchor(e.currentTarget);
  };

  const handleSupplierClick = (e) => {
    setSupplierAnchor(e.currentTarget);
  };

  const handleCostClick = () => {
    const hasSupplier = newComponent.suppliers.length;
    if (hasSupplier) {
      handleEdit({
        field: "suppliers",
        type: "deleteAll",
      });

      // remove suppliers
    } else {
      handleEdit({
        field: "cost",
        value: newComponent.cost == null ? "" : null,
      });
    }
  };

  const handlePriceClick = () => {
    let value = newComponent.price == null ? "" : null;
    handleEdit({
      field: "price",
      value: value,
    });
  };

  const handleCodeClick = () => {
    let value = newComponent.code == null ? "" : null;
    handleEdit({
      field: "code",
      value: value,
    });
  };

  const handleCollapseClick = () => {
    if (!newComponent.collapsed) {
      collapseNode(newComponent.nodeKey);
    } else {
      expandNode(newComponent.nodeKey);
    }
  };

  const disabled = React.useMemo(() => {
    if (newComponent._id === "newComponent" || newComponent.edit) {
      return false;
    } else {
      return newComponent.nodeKey.split("-").length > 1;
    }
  }, [newComponent]);

  const MENU_ITEMS = [
    {
      name: "Image",
      icon: (
        <ImageIcon color={newComponent.image == null ? "default" : "primary"} />
      ),
      action: handleImageClick,
      field: "image",
    },
    {
      name: "Category",
      icon: (
        <CategoryIcon
          color={!newComponent.categories.length ? "default" : "primary"}
        />
      ),
      action: handleCategoryClick,
    },
    {
      name: "Price",
      icon: (
        <AttachMoneyIcon
          color={newComponent.price == null ? "default" : "primary"}
        />
      ),
      action: handlePriceClick,
    },
    {
      name: "Cost",
      icon: (
        <MoneyOffIcon
          color={
            newComponent.cost == null || newComponent.suppliers.length
              ? "default"
              : "primary"
          }
        />
      ),
      action: handleCostClick,
    },
    {
      name: "Supplier",
      icon: (
        <Warehouse
          color={!newComponent.suppliers.length ? "default" : "primary"}
        />
      ),
      action: handleSupplierClick,
    },
    {
      name: "Code",
      icon: (
        <QrCodeIcon color={newComponent.code == null ? "default" : "primary"} />
      ),
      action: handleCodeClick,
    },
  ];

  const isParentEditable = React.useMemo(() => {
    const parent = nodes.find(
      (n) => n.id === newComponent.nodeKey.split("-").slice(0, -1).join("-")
    );
    if (!parent) return false;
    return parent.data.data.edit;
  }, [newComponent, nodes]);

  const hasUnsavedSubnodes = React.useMemo(() => {
    return getSubnodes(newComponent.nodeKey, nodes).some(
      (n) => !n.data.data.saved
    );
  }, [newComponent, nodes]);

  return (
    <div className="drag-handle">
      <IconButton
        id="add-button"
        size="small"
        color="primary"
        variant="outlined"
        aria-controls={open ? "add-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onContextMenu={handleClick}
      >
        <DragHandle />
      </IconButton>
      <Menu
        id="add-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {newComponent.nodeKey.split("-").length > 1 ? (
          <div>
            <MenuItem
              onClick={handleTurnIntoClick}
              disabled={
                !canOpenNodeMenu(newComponent.nodeKey) && !isParentEditable
              }
            >
              <ListItemIcon>
                <SwapHorizIcon />
              </ListItemIcon>
              <ListItemText>Turn Into</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={handleDuplicateClick}
              disabled={
                (!canOpenNodeMenu(newComponent.nodeKey) && !isParentEditable) ||
                hasUnsavedSubnodes
              }
            >
              <ListItemIcon>
                <ControlPointDuplicateIcon />
              </ListItemIcon>
              <ListItemText>Duplicate</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={handleDeleteClick}
              disabled={
                !canOpenNodeMenu(newComponent.nodeKey) && !isParentEditable
              }
            >
              <ListItemIcon>
                <DeleteForeverIcon />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleCollapseClick}>
              {newComponent.collapsed ? (
                <>
                  <ListItemIcon>
                    <Visibility />
                  </ListItemIcon>
                  <ListItemText>Expand</ListItemText>
                </>
              ) : (
                <>
                  <ListItemIcon>
                    <VisibilityOff />
                  </ListItemIcon>
                  <ListItemText>Collapse</ListItemText>
                </>
              )}
            </MenuItem>
            <Divider />
          </div>
        ) : null}
        {MENU_ITEMS.map((item) => (
          <MenuItem key={item.name} onClick={item.action} disabled={disabled}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText>{item.name}</ListItemText>
          </MenuItem>
        ))}
        <Divider />
        {parameters &&
          parameters
            .filter((p) => !p.global)
            .map((parameter) => (
              <MenuItem
                key={parameter.name}
                onClick={() => handleParameterSelect(parameter)}
                disabled={disabled}
              >
                <ListItemIcon>
                  {newComponent.parameters.find(
                    (p) => p.name === parameter.name && p.hierarchy.length === 1
                  ) ? (
                    <CheckBox color="primary" />
                  ) : (
                    <CheckBoxOutlineBlankIcon />
                  )}
                </ListItemIcon>
                <ListItemText>{parameter.name}</ListItemText>
              </MenuItem>
            ))}
      </Menu>
      <CategoryMenu anchor={catAnchor} setAnchor={setCatAnchor} />
      <SupplierMenu anchor={supplierAnchor} setAnchor={setSupplierAnchor} />
    </div>
  );
};

NodeMenu.propTypes = {
  currentId: PropTypes.string,
  switchFn: PropTypes.func,
  duplicateFn: PropTypes.func,
  deleteFn: PropTypes.func,
};
