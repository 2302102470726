/* eslint-disable react/prop-types */
import { Paper, TextField, useTheme } from "@mui/material";
import React from "react";
import { Handle, Position } from "reactflow";
// import { Image } from "../nodes/component/Image";
import { Box } from "@mui/system";
// import { useNewOption } from "../hooks/useNewOption";
import { NodeMenu } from "../nodes/option/NodeMenu";
import { OptionVars } from "../nodes/option/OptionVars";
import useStore from "./store";
import { isEditable } from "./utils";
import { CustomIconButton } from "../../../utils/CustomIconButtom";
import { Check, Lock, PriorityHigh } from "@mui/icons-material";

export const NewOptionContext = React.createContext({
  newOption: {
    _id: "newOption",
    nodeKey: "newOption",
    name: "MyOption",
    subcomponents: "",
    parameters: [],
    required: true,
    multiple: false,
  },
  handleEdit: () => {},
  clearOption: () => {},
  setOption: () => {},
});
// eslint-disable-next-line react/display-name
export default function OptionNode({ id, data, selected }) {
  const theme = useTheme();
  const colorMode = theme.palette.mode;
  const newOption = data.data;
  const handleEdit = useStore((state) => state.editOptionNode);
  if (!newOption) return null;
  const nodeExists = !id.includes("_") && id !== "parent";

  return (
    <div>
      <NewOptionContext.Provider
        value={{
          newOption,
          handleEdit: ({ field, value, type }) =>
            handleEdit({
              id: id,
              field: field,
              value: value,
              type: type,
            }),
        }}
      >
        <Paper
          sx={{
            boxShadow: selected ? 8 : 4,
            p: 1,
            minWidth: 300,
            boxSizing: "border-box",
            transition: "all 0.2s",
            border: selected
              ? `1px solid ${colorMode === "light" ? "black" : "white"}`
              : `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <CustomIconButton
              sx={{
                position: "absolute",
                mt: -3,
                p: "2px",
                minWidth: 0,
                zIndex: -1,
              }}
              tooltipPosition={"top"}
              onClick={() => {
                !newOption.edit && !newOption.duplicate && nodeExists
                  ? handleEdit({
                      id: id,
                      field: "edit",
                      value: !newOption.edit,
                    })
                  : newOption.saved &&
                    !newOption.duplicate &&
                    nodeExists &&
                    id.split("-").length > 1
                  ? handleEdit({
                      id: id,
                      field: "edit",
                      value: !newOption.edit,
                    })
                  : null;
              }}
              title={
                !newOption.edit && !newOption.duplicate && nodeExists
                  ? "Locked"
                  : newOption.saved
                  ? "Saved"
                  : "Unsaved"
              }
              variant="contained"
              color={
                !newOption.edit && !newOption.duplicate && nodeExists
                  ? "primary"
                  : newOption.saved
                  ? "success"
                  : "error"
              }
              icon={
                !newOption.edit && !newOption.duplicate && nodeExists ? (
                  <Lock sx={{ fontSize: "16px" }} />
                ) : newOption.saved ? (
                  <Check sx={{ fontSize: "16px" }} />
                ) : (
                  <PriorityHigh sx={{ fontSize: "16px" }} />
                )
              }
            ></CustomIconButton>
            {!newOption.edit &&
            Object.hasOwn(newOption, "edit") &&
            newOption.nodeKey.split("-").length > 1 &&
            !newOption.saved ? (
              <CustomIconButton
                color="error"
                icon={<PriorityHigh sx={{ fontSize: "16px" }} />}
                variant="contained"
                title={
                  "Unsaved parameter overrides. Saving will apply them only to this component."
                }
                sx={{
                  position: "absolute",
                  right: 35,
                  mt: -3,
                  p: "2px",
                  minWidth: 0,
                  zIndex: -1,
                }}
                tooltipPosition={"top"}
              />
            ) : null}
          </Box>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <TextField
              label="Name"
              size="small"
              fullWidth
              value={newOption.name}
              onChange={(e) =>
                isEditable(id, newOption.edit) &&
                handleEdit({ id, field: "name", value: e.target.value })
              }
            />
            <div className="drag-handle">
              <NodeMenu />
            </div>
          </Box>
          <OptionVars />
        </Paper>

        <Handle
          type="source"
          id="bottom"
          position={Position.Bottom}
          style={{
            bottom: "-16px",
            width: "18px",
            height: "12px",
            borderRadius: "5px",
            border: 0,
            backgroundColor: newOption.collapsed
              ? theme.palette.info.main
              : theme.palette.grey[300],
          }}
          isConnectable={newOption._id === "newOption" || newOption.edit}
        />
        <Handle
          type="target"
          id="right"
          position={Position.Right}
          style={{
            right: "-16px",
            width: "12px",
            height: "12px",
            borderRadius: "5px",
            border: 0,
            backgroundColor: theme.palette.primary.main,
            top: "28px",
          }}
        />
        <Handle
          type="target"
          id="left"
          position={Position.Left}
          style={{
            left: "-16px",
            width: "12px",
            height: "12px",
            borderRadius: "5px",
            border: 0,
            backgroundColor: theme.palette.primary.main,
            top: "28px",
          }}
        />
      </NewOptionContext.Provider>
    </div>
  );
}
