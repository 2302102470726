import { MenuItem, darken, lighten, styled } from "@mui/material";
import { green, red } from "@mui/material/colors";

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

export const FinancialMenuItem = styled(MenuItem)(({ theme }) => ({
  "&.income": {
    backgroundColor: getBackgroundColor(green[400], theme.palette.mode),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(green[400], theme.palette.mode),
    },
  },
  "&.expense": {
    backgroundColor: getBackgroundColor(red[400], theme.palette.mode),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(red[400], theme.palette.mode),
    },
  },
}));
