import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleIcon from "@mui/icons-material/People";
import WorkIcon from "@mui/icons-material/Work";
import ConstructionIcon from "@mui/icons-material/Construction";
import EngineeringIcon from "@mui/icons-material/Engineering";
import HomeIcon from "@mui/icons-material/Home";
import PropTypes from "prop-types";
import LogoutIcon from "@mui/icons-material/Logout";
import { HelpContext, TabContext } from "../../../Content";
import DescriptionIcon from "@mui/icons-material/Description";
import InventoryIcon from "@mui/icons-material/Inventory";
import ReceiptIcon from "@mui/icons-material/Receipt";
import {
  AttachMoney,
  Business,
  CalendarMonth,
  Folder,
  // Storefront,
  Warehouse,
} from "@mui/icons-material";
import { UserSettings } from "../../userSettings/components/UserSettings";
import { Link } from "react-router-dom";
import { checkSubscription } from "../../../utils/checkSubscription";
import { useSettingsData } from "../../../hooks/useSettingsData";
import { HelpButton } from "../../../utils/HelpButton";
import { steps } from "../../../utils/introSteps";
import { useOrganizationData } from "../../organization/hooks/useOrganizationData";
import { UserAvatar } from "./UserAvatar";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const VbxAppBar = ({ user, setUser, activeTab, open, setOpen }) => {
  const theme = useTheme();
  const { handleTabChange } = React.useContext(TabContext);
  const { setHelp } = React.useContext(HelpContext);

  const { data: settings } = useSettingsData();
  const logout = async () => {
    localStorage.removeItem("user");
    setUser(null);
  };

  const handleDrawerOpen = () => {
    localStorage.setItem("appBar", true);
    setOpen(true);
  };

  const handleDrawerClose = () => {
    localStorage.setItem("appBar", false);
    setOpen(false);
  };

  const onTabClick = (tab) => {
    handleTabChange({ tab });
    if (tab === "Home") {
      sub?.includes("financial")
        ? setHelp(steps.Home)
        : setHelp(steps.Home.filter((s) => s.sub !== "financial"));
    } else {
      setHelp(steps[tab]);
    }
  };

  const tabs = [
    {
      name: "Home",
      icon: <HomeIcon />,
      subscription: ["base"],
    },
    { name: "Calendar", icon: <CalendarMonth />, subscription: ["project"] },
    { name: "Financial", icon: <AttachMoney />, subscription: ["financial"] },
    { name: "Customers", icon: <PeopleIcon />, subscription: ["base"] },
    { name: "Projects", icon: <WorkIcon />, subscription: ["base"] },
    { name: "Orders", icon: <ReceiptIcon />, subscription: ["base"] },
    // { name: "Market", icon: <Storefront />, subscription: ["base"] },
    { name: "Suppliers", icon: <Warehouse />, subscription: ["base"] },
    { name: "People", icon: <EngineeringIcon />, subscription: ["project"] },
    { name: "Inventory", icon: <InventoryIcon />, subscription: ["base"] },
    { name: "Components", icon: <ConstructionIcon />, subscription: ["base"] },
    { name: "Files", icon: <Folder />, subscription: ["base"] },
    {
      name: "Templates",
      icon: <DescriptionIcon />,
      subscription: ["financial"],
    },
  ];
  const { data: organization } = useOrganizationData();

  if (!settings) return null;
  const sub = checkSubscription(settings);

  const sortedTabs = tabs
    .map((t) => ({
      ...t,
      disabled: sub?.some((s) => t.subscription.includes(s)) ? false : true,
    }))
    .sort((a, b) => (a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1));

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Link
            to="/"
            style={{
              textDecoration: "none",
              color: theme.palette.text.primary,
            }}
          >
            <Typography
              variant="h6"
              sx={{ display: "flex", alignItems: "end", cursor: "pointer" }}
            >
              [MANUFACTURE]
              <Typography
                sx={{
                  ml: -0.54,
                  mb: 0.3,
                  color:
                    theme.palette.mode === "dark" ? "primary.main" : "white",
                  fontSize: 13,
                }}
              >
                .app
              </Typography>
            </Typography>
          </Link>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ marginLeft: 2, marginRight: "auto" }}
          >
            {" "}
            {organization?.name}{" "}
          </Typography>
          <HelpButton page={activeTab} />

          <div style={{ display: "inline-block", marginLeft: 1 }}>
            {user ? <UserSettings settings={settings} user={user.id} /> : null}
          </div>
          <UserAvatar />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{ sx: { boxShadow: "1px 20px 20px black" } }}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{ p: 0.5 }} id="sidebar">
          {sortedTabs.map((tab) => (
            <ListItem
              key={tab.name}
              disablePadding
              sx={{
                display: "block",
                borderRadius: 1,
                bgcolor:
                  activeTab == tab.name
                    ? theme.palette.primary.main
                    : "default",
                color:
                  activeTab == tab.name
                    ? theme.palette.primary.contrastText
                    : "default",
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  borderRadius: 1,
                  px: 2.5,
                }}
                disabled={
                  sub?.some((s) => tab.subscription.includes(s)) ? false : true
                }
                onClick={() => onTabClick(tab.name)}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color:
                      activeTab == tab.name
                        ? theme.palette.primary.contrastText
                        : "default",
                  }}
                >
                  {tab.icon}
                </ListItemIcon>
                <ListItemText
                  primary={tab.name}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
          <Divider sx={{ mb: 10, mt: 0.5 }} />
          <ListItem
            disablePadding
            sx={{
              display: "block",
              borderRadius: 1,
              bgcolor:
                activeTab == "Organization"
                  ? theme.palette.primary.dark
                  : "default",
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                borderRadius: 1,
              }}
              onClick={() => handleTabChange({ tab: "Organization" })}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <Business />
              </ListItemIcon>
              <ListItemText
                primary="Organization"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding sx={{ display: "block", borderRadius: 1 }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                borderRadius: 1,
              }}
              onClick={logout}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
      </Drawer>
    </Box>
  );
};
VbxAppBar.propTypes = {
  setUser: PropTypes.func,
  user: PropTypes.object,
  activeTab: PropTypes.string,
  setTab: PropTypes.func,
  settings: PropTypes.object,
  setSettings: PropTypes.func,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setView: PropTypes.func,
};
