// import { Content } from "../Content";
import React from "react";
import { LoginRegister } from "./LoginRegister";
import { Main } from "./Main";
import { Invited } from "../features/loginRegister/components/Invited";
import { EmailConfirm } from "../features/loginRegister/components/EmailConfirm";
import { GoVerify } from "../features/loginRegister/components/GoVerify";
// import { Navigate } from "react-router-dom";
// import { Employee } from "./Employee";

const routes = (user, appElement) => [
  {
    title: "welcome",
    path: "/",
    element: <Main user={user} />,
  },
  {
    title: "login",
    path: "/login",
    element: <LoginRegister />,
  },
  {
    title: "auth",
    path: "/user/invited",
    element: <Invited />,
  },
  // the route for the email confirmation
  {
    title: "emailConfirm",
    path: "/user/confirm",
    element: <EmailConfirm />,
  },
  {
    title: "app",
    path: "/app",
    element: appElement,
  },
  // the route for the redirect from the payment provider, it asks you to go verify
  {
    title: "verify",
    path: "/payment/redirect",
    element: <GoVerify />,
  },
];

export default routes;
