import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { ParameterSelect } from "./ParameterSelect";
import { Options } from "./Options";
import { SelectedProjectContext } from "../ProjectView";
import _ from "lodash";
import { useProduct } from "./productUtils";

const CLOUDFRONT_URL = "https://cdn.manufacture.app/";

export const ProductContext = React.createContext({
  product: {},
  handleEdit: () => {},
});

export const ProductCard = ({
  setSelection,
  product,
  options,
  view = "compact",
}) => {
  const project = React.useContext(SelectedProjectContext);
  const handleRemove = () => {
    setSelection((prev) => prev.filter((p) => p.id !== product.id));
  };

  const [newProduct, handleEdit] = useProduct(
    product.componentId
      ? {
          data: _.cloneDeep({
            ...product,
            subcomponents: project.components.filter(
              (c) => c.product === product.id && c.zone === product.zone
            ),
          }),
          dataType: "existing",
        }
      : {
          data: _.cloneDeep(product),
          dataType: "new",
        }
  );

  React.useEffect(() => {
    setSelection((prev) => {
      let newSelection = [...prev];
      console.log("changing selection");
      newSelection = newSelection.map((p) => {
        console.log(p.id, newProduct);
        if (p.id === newProduct.id) {
          return newProduct;
        }
        return p;
      });
      return newSelection;
    });
  }, [newProduct]);

  React.useEffect(() => {
    if (!options) return;

    for (let i = 0; i < newProduct.options.length; i++) {
      for (let j = 0; j < options.length; j++) {
        if (
          newProduct.options[i].name === options[j].name &&
          options[j].choice
        ) {
          console.log("updating options", options[j]);
          handleEdit({
            type: "update",
            value: {
              ...newProduct.options[i],
              choice: newProduct.options[i].subcomponents.find(
                (s) => s.name === options[j].choice.name
              ),
            },
            field: "options",
          });
        }
      }
    }
  }, [options]);

  console.log(options);

  if (newProduct._id === "product") return null;

  if (
    Object.hasOwn(newProduct, "component") &&
    newProduct.component === undefined
  ) {
    return (
      <Paper
        sx={{
          p: 1,
          mt: 1,
          display: "flex",

          gap: 1,
        }}
      >
        <ProductContext.Provider value={{ product: newProduct, handleEdit }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ m: 1, mb: 0, width: 200 }}
          >
            {product.id}. {product.name}
          </Typography>
          <Box sx={{ flex: 1 }}>
            {product.parameters.map((p) => (
              <Box key={p.name} sx={{ display: "flex", gap: 1 }}>
                <Typography variant="caption">{p.name}</Typography>
                <Typography variant="caption">{p.value}</Typography>
              </Box>
            ))}
          </Box>
          <Box sx={{ flex: 1 }}>
            {product.options.map((o) => (
              <Box key={o.name} sx={{ display: "flex", gap: 1 }}>
                <Typography variant="caption">{o.name}</Typography>
                <Typography variant="caption">
                  {o.choice ? o.choice.name : "None"}
                </Typography>
              </Box>
            ))}
          </Box>
          <Typography variant="caption" sx={{ flex: 1 }}>
            This product was deleted from the database. It remains in the
            project for reference.
          </Typography>
          <Button
            variant="contained"
            color="error"
            onClick={handleRemove}
            size="small"
          >
            Remove
          </Button>
        </ProductContext.Provider>
      </Paper>
    );
  }

  console.log(newProduct);

  if (view === "compact") {
    return (
      <Paper
        sx={{
          p: 1,
          mt: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
          width: "100%",
        }}
      >
        <ProductContext.Provider value={{ product: newProduct, handleEdit }}>
          <Typography
            variant="h6"
            component="div"
            sx={{ m: 1, mb: 0, width: 200 }}
          >
            {product.id}. {product.name}
          </Typography>
          <Box sx={{ flex: 1 }}>
            <ParameterSelect />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Options />
          </Box>
          <Button
            variant="contained"
            color="error"
            onClick={handleRemove}
            size="small"
          >
            Remove
          </Button>
        </ProductContext.Provider>
      </Paper>
    );
  } else {
    return (
      <Card elevation={3} sx={{ width: 220 }}>
        <ProductContext.Provider value={{ product: newProduct, handleEdit }}>
          <Typography variant="h6" component="div" sx={{ m: 1, mb: 0 }}>
            {product.id}. {product.name}
          </Typography>

          <Box sx={{ display: "flex", gap: 1, p: 1 }}></Box>
          <CardMedia
            sx={{ height: 110, transition: "height 0.5s ease" }}
            image={`${CLOUDFRONT_URL}${newProduct.image}`}
            title="image"
          />
          <CardContent sx={{ pt: 1 }}>
            <Divider sx={{ mb: 1 }}>Parameters</Divider>
            <ParameterSelect view={view} />
            <Divider sx={{ mb: 1 }}>Options</Divider>

            <Options view={view} />
            <Typography variant="caption" sx={{ mt: 1 }}>
              {newProduct.price.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            </Typography>
          </CardContent>

          <CardActions>
            <Button
              variant="contained"
              color="error"
              onClick={handleRemove}
              size="small"
            >
              Remove
            </Button>
          </CardActions>
        </ProductContext.Provider>
      </Card>
    );
  }
};

ProductCard.propTypes = {
  selection: PropTypes.array.isRequired,
  product: PropTypes.object.isRequired,
  setSelection: PropTypes.func.isRequired,
  options: PropTypes.array,
  view: PropTypes.string,
};
