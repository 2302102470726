import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  Collapse,
  Divider,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Paper,
} from "@mui/material";
import FunctionsIcon from "@mui/icons-material/Functions";
import { NewComponentContext } from "../../components/ComponentNode";
import PropTypes from "prop-types";
import { limitedEvaluate } from "../../components/utils/limitedEvaluate";
import { Conditions } from "./Conditions";
import AltRouteIcon from "@mui/icons-material/AltRoute";

export const Quantity = ({ variables }) => {
  const { newComponent, handleEdit } = useContext(NewComponentContext);

  const [inputValue, setInputValue] = useState(
    newComponent.qty.formula
      ? newComponent.qty.formula.includes(":")
        ? newComponent.qty.formula.split("::")[1]
        : newComponent.qty.formula
      : newComponent.qty.value
  );
  const [error, setError] = useState(null);
  useEffect(() => {
    if (newComponent.qty.formula) {
      setInputValue(
        newComponent.qty.formula.includes(":")
          ? newComponent.qty.formula.split("::")[1]
          : newComponent.qty.formula
      );
    } else {
      setInputValue(newComponent.qty.value);
    }
  }, [newComponent.qty]);

  const [focused, setFocused] = React.useState(false);
  const [conditional, setConditional] = React.useState(
    newComponent.qty.formula.includes(":")
  );
  const [conditionValue, setConditionValue] = React.useState(
    newComponent.qty.formula.includes(":") ? newComponent.qty.formula : null
  );

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (conditional) {
      setConditionValue((prev) => `${prev.split("::")[0]}::${e.target.value}`);
    }
    if (newComponent.qty.formula) {
      if (handleFormula(e.target.value)) {
        setError(null);
      }
    }
  };
  const handleInputBlur = (e) => {
    if (
      newComponent.qty.formula
        ? e.target.value == newComponent.qty.formula
        : e.target.value == newComponent.qty.value
    )
      return;
    if (e.target.value.trim() !== "") {
      if (isNumeric(e.target.value) && !conditionValue) {
        handleParameterValueEdit(e);
      } else {
        if (handleFormula(e.target.value)) {
          handleEdit({
            field: "qty",
            value: {
              ...newComponent.qty,
              formula: conditionValue ? conditionValue : e.target.value,
            },
            type: "update",
          });
        } else {
          handleEdit({
            field: "qty",
            value: {
              ...newComponent.qty,
              formula: conditionValue ? conditionValue : inputValue,
              error: error,
            },
            type: "update",
          });
        }
      }
    } else {
      handleEdit({
        field: "qty",
        value: {
          ...newComponent.qty,
          value: 1,
          formula: "",
        },
        type: "update",
      });
    }
  };

  // const handleFormulaClick = () => {
  //   setChecked((prev) => {
  //     if (prev) {
  //       handleEdit({
  //         field: "qty",
  //         value: {
  //           ...newComponent.qty,
  //           formula: "",
  //         },
  //         type: "update",
  //       });
  //       return false;
  //     } else {
  //       handleEdit({
  //         field: "qty",
  //         value: {
  //           ...newComponent.qty,
  //           formula: newComponent.qty.value,
  //         },
  //         type: "update",
  //       });
  //       return true;
  //     }
  //   });
  // };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleParameterValueEdit = (e) => {
    handleEdit({
      field: "qty",
      value: {
        ...newComponent.qty,
        value: Number(e.target.value),
        formula: "",
      },
      type: "update",
    });
  };

  const handleConditionalClick = () => {
    setConditional((prev) => {
      setConditionValue(`::${inputValue}`);

      return !prev;
    });
  };

  const handleFormula = (value) => {
    try {
      let scope = new Map();
      variables?.forEach((v) => {
        scope.set(v.variable, v.value);
      });

      if (value.split(",").length > 1) {
        value.split(",").map((v) => limitedEvaluate(v, scope));
      } else {
        limitedEvaluate(value, scope);
      }
      setError(null);
      return true;
    } catch (error) {
      setError(error.message);
      return false;
    }
  };

  const handleVariable = () => {
    if (!newComponent.qty.variable) {
      handleEdit({
        field: "qty",
        value: {
          ...newComponent.qty,
          variable: "qty",
        },
        type: "update",
      });
    } else {
      handleEdit({
        field: "qty",
        value: {
          ...newComponent.qty,
          variable: "",
        },
        type: "update",
      });
    }
  };
  const checkFormula = (value) => {
    try {
      let scope = new Map();
      variables?.forEach((v) => {
        scope.set(v.variable, v.value);
      });
      let result = limitedEvaluate(value, scope);
      return result;
    } catch (error) {
      return { error: error.message };
    }
  };
  const checked = !isNumeric(inputValue);

  return (
    <>
      <Paper
        sx={{
          gap: 1,
          // position: "fixed",
          mt: 1,
          ml: focused && checked ? -15 : 0,
          mr: focused && checked ? -15 : 0,
          p: 0,
          // width: focused && checked ? 400 : 200,
          transition: "all 500ms",
        }}
        elevation={focused && checked ? 4 : 1}
      >
        <ClickAwayListener onClickAway={() => setFocused(false)}>
          <div>
            <Collapse in={conditional && focused} unmountOnExit>
              <Conditions
                localValue={conditionValue}
                setLocalValue={(value) =>
                  setConditionValue(`${value}::${inputValue}`)
                }
                checkFormula={checkFormula}
                error={error}
              />
            </Collapse>
            <FormControl
              variant="outlined"
              fullWidth
              size="small"
              onFocus={handleFocus}
            >
              <OutlinedInput
                size="small"
                value={inputValue}
                fullWidth
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                error={error}
                startAdornment={
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mr: 1,
                      pr: -1,
                    }}
                  >
                    <Button
                      sx={{
                        ml: -1,
                        pl: 1,
                        pr: 1,
                        pt: 0.2,
                        pb: 0.2,
                        mr: 0.5,
                        color: newComponent.qty.variable
                          ? "default"
                          : "text.primary",
                        fontSize: 15,
                        boxSizing: "border-box",
                        border: "1px solid transparent",
                      }}
                      size="small"
                      variant={
                        newComponent.qty.variable ? "contained" : "outlined"
                      }
                      onClick={handleVariable}
                      value={newComponent.qty}
                    >
                      qty
                    </Button>

                    <Collapse
                      in={checked}
                      unmountOnExit
                      orientation="horizontal"
                    >
                      <Button
                        sx={{
                          ml: -1,
                          pl: 1,
                          pr: 1,
                          minWidth: 0,
                          color: checked ? "primary" : "text.primary",
                        }}
                      >
                        <FunctionsIcon
                          color={checked ? "primary" : "default"}
                          sx={{ height: "26.232px" }}
                        />
                      </Button>
                    </Collapse>
                    <Collapse
                      in={(focused && checked) || conditional}
                      unmountOnExit
                      orientation="horizontal"
                    >
                      <Button
                        onClick={handleConditionalClick}
                        sx={{
                          p: 0.2,
                          minWidth: 0,
                          color: conditional ? "default" : "text.primary",
                        }}
                      >
                        <AltRouteIcon
                          color={conditional ? "primary" : "default"}
                          sx={{ height: "26.232px" }}
                        />
                      </Button>
                    </Collapse>
                    <Divider orientation="vertical" flexItem />
                  </Box>
                }
              />
              {error && <FormHelperText error>{error}</FormHelperText>}
            </FormControl>
          </div>
        </ClickAwayListener>
      </Paper>
    </>
  );
};

Quantity.propTypes = {
  variables: PropTypes.array,
};
function isNumeric(str) {
  if (!str) return true;
  if (typeof str == "number") return true; // we only process strings!
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}
