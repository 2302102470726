import React from "react";
import { ProductContext } from "./ProductCard";
import { Option } from "./Option";

export const Options = () => {
  const { product } = React.useContext(ProductContext);
  return (
    <div>
      {product.options.map((option) => (
        <Option key={option.name} option={option} />
      ))}
    </div>
  );
};
