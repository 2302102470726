import React, { useEffect, useState, useContext } from "react";
import { useChatData } from "../hooks/useChatData";
import { UserContext } from "../../../../../App";
import { Avatar, Badge, Box, Paper, TextField } from "@mui/material";
import { Send } from "@mui/icons-material";
import { Message } from "./Message";
import PropTypes from "prop-types";
import { CustomIconButton } from "../../../../../utils/CustomIconButtom";

const cloudfrontUrl = "https://cdn.manufacture.app/";

export const ChatWindow = ({ project }) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [connectionStatus, setConnectionStatus] = useState("Connecting...");
  const [connectedUsers, setConnectedUsers] = useState([]);
  const { user } = useContext(UserContext);

  let socket = React.useRef(null);

  const { data: messagesData } = useChatData(project._id);
  useEffect(() => {
    if (messagesData) {
      setMessages(messagesData);
    }
  }, [messagesData]);

  useEffect(() => {
    socket.current = new WebSocket("wss://www.manufacture.app/");

    const keepAliveInterval = setInterval(() => {
      if (socket.current.readyState === WebSocket.OPEN) {
        socket.current.send(JSON.stringify({ pack: { type: "PING" } }));
      }
    }, 30000); // Ping every 30 seconds

    socket.current.addEventListener("open", () => {
      setConnectionStatus("Connected");
      console.log("Socket opened");
      socket.current.send(
        JSON.stringify({
          pack: {
            type: "CONNECT",
            user: user.id,
          },
        })
      );
    });

    socket.current.addEventListener("message", (event) => {
      const messageData = JSON.parse(event.data);
      if (messageData.type === "USER_STATUS") {
        setConnectedUsers(messageData.status);
      } else if (messageData.type !== "PONG") {
        setMessages((prevMessages) => [messageData, ...prevMessages]);
      }
    });

    socket.current.addEventListener("error", () => {
      setConnectionStatus("Error");
    });

    socket.current.addEventListener("close", () => {
      setConnectionStatus("Disconnected");
      socket.current.send(
        JSON.stringify({
          pack: {
            type: "DISCONNECT",
            user: user.id,
          },
        })
      );
    });

    return () => {
      clearInterval(keepAliveInterval);
      socket.current.send(
        JSON.stringify({
          pack: {
            type: "DISCONNECT",
            user: user.id,
          },
        })
      );
      socket.current.close();
      socket.current.removeEventListener("open", () => {});
      socket.current.removeEventListener("message", () => {});
      socket.current.removeEventListener("error", () => {});
      socket.current.removeEventListener("close", () => {});
    };
  }, [user.id]);

  const sendMessage = () => {
    if (!inputMessage) return;
    socket.current.send(
      JSON.stringify({
        pack: {
          content: inputMessage,
          project: project._id,
          type: "message",
          user: user.id,
          sender: user.id,
        },
      })
    );
    setInputMessage("");
  };

  const getColor = () => {
    switch (connectionStatus) {
      case "Connected":
        return "success";
      case "Connecting...":
        return "warning";
      default:
        return "error";
    }
  };

  return (
    <Paper
      sx={{
        width: "100%",
        height: 800,
        p: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      }}
    >
      <Box
        sx={{
          mb: 2,
          overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        {messages.map((message, index) => (
          <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
            <Message message={message} connectedUsers={connectedUsers} />
          </Box>
        ))}
      </Box>

      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
          color={getColor()}
        >
          <Avatar
            sx={{ width: 32, height: 32 }}
            src={
              user.person.avatar
                ? `${cloudfrontUrl}${user.person.avatar}`
                : null
            }
            alt={user.person.firstName}
          />
        </Badge>
        <TextField
          size="small"
          fullWidth
          value={inputMessage}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendMessage();
            }
          }}
          onChange={(e) => setInputMessage(e.target.value)}
        />
        <CustomIconButton
          icon={<Send />}
          disabled={!inputMessage}
          variant="outlined"
          onClick={sendMessage}
        />
      </Box>
    </Paper>
  );
};

export default ChatWindow;

ChatWindow.propTypes = {
  project: PropTypes.object.isRequired,
};
