import { Box, Button, Collapse, Paper, Typography } from "@mui/material";
import React from "react";
import { useProjectsData } from "../../../hooks/useProjectsData";
import { useProjectUpdate } from "../../project/hooks/useProjectUpdate";
import { TransitionGroup } from "react-transition-group";

export const Notifications = () => {
  const [notifications, setNotifications] = React.useState([]);
  const { data: projects } = useProjectsData();
  const { mutate: updateProject } = useProjectUpdate("", "noSnack");
  React.useEffect(() => {
    if (projects) {
      let notifications = [];
      projects.forEach((project) => {
        project.log.forEach((log) => {
          if (log.notification && !log.seen) {
            notifications.push({
              ...log,
              project: project.projectName,
              projectId: project._id,
            });
          }
        });
      });

      setNotifications(notifications);
    }
  }, [projects]);

  const handleMarkSeen = (notification) => {
    updateProject({
      id: notification.projectId,
      pack: { log: [notification], updateType: ["log", "markSeen"] },
    });
  };

  return (
    <Paper sx={{ p: 1, width: "100%", mb: 2 }} id="dashboard-notifications">
      <Typography variant="h5">
        {notifications.length ? "Notifications" : "No Notifications"}
      </Typography>
      <TransitionGroup>
        {notifications.map((notification) => {
          return (
            <Collapse
              in={true}
              timeout={500}
              key={new Date(notification.longDate).getTime()}
            >
              <Paper key={notification.date} sx={{ mt: 1, p: 1 }} elevation={0}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <Typography>
                    {notification.task} {notification.project}
                  </Typography>
                  <Typography variant="caption" sx={{ opacity: "0.7" }}>
                    {notification.date}
                  </Typography>
                </Box>
                <Typography variant="caption">
                  {notification.description}
                </Typography>
                <Button onClick={() => handleMarkSeen(notification)}>
                  Mark as seen
                </Button>
              </Paper>
            </Collapse>
          );
        })}
      </TransitionGroup>
    </Paper>
  );
};
