import { Delete, DeleteForever, Edit, Visibility } from "@mui/icons-material";
import { Box, Dialog, IconButton } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useTemplateDelete } from "../hooks/useTemplateDelete";
import { TemplateView } from "../../templateEditor/components/TemplateView";

export const CardActions = ({
  template,
  projects,
  customers,
  setSelectedTemplate,
}) => {
  const [deleting, setDeleting] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { mutate: deleteTemplate } = useTemplateDelete();
  const handleDelete = () => {
    if (deleting) {
      deleteTemplate({ id: template._id });
    } else {
      setDeleting(true);
      setTimeout(() => {
        setDeleting(false);
      }, 3000);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-around" }}>
        <IconButton onClick={() => setOpen(true)}>
          <Visibility />
        </IconButton>
        <IconButton onClick={() => setSelectedTemplate(template)}>
          <Edit />
        </IconButton>
        <IconButton onClick={handleDelete}>
          {deleting ? <DeleteForever color="error" /> : <Delete />}
        </IconButton>
      </Box>
      <Dialog open={open} onClose={handleClose} maxWidth={"xl"} scroll="paper">
        <TemplateView
          template={template}
          projects={projects}
          customers={customers}
          select={{
            project: projects[0],
            customer: projects[0].customer,
            products: [...projects[0].products],
          }}
          readOnly={true}
          handleClose={handleClose}
        />
      </Dialog>
    </>
  );
};
CardActions.propTypes = {
  template: PropTypes.object,
  projects: PropTypes.array,
  customers: PropTypes.array,
  setSelectedTemplate: PropTypes.func,
};
