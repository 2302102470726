import { Mail, Password } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { register } from "../../../api/userApi";
import { UserContext } from "../../../App";

export const Register = () => {
  const { setUser } = React.useContext(UserContext);

  const [values, setValues] = React.useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    stayLoggedIn: false,
    error: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const registerUser = async () => {
    try {
      const returnedUser = await register({
        email: values.email,
        password: values.password,
        status: "pending",
      });
      console.log(returnedUser);
      setUser(returnedUser);
    } catch (error) {
      // the error has no message property
    }
  };

  console.log(values);

  return (
    <Container maxWidth="xs">
      <Paper sx={{ marginTop: 12, width: 320, padding: 4 }}>
        <form>
          <Stack spacing={2} alignItems="center">
            <Typography variant="h6">Welcome to Manufacture.app!</Typography>
            <Typography variant="subtitle">{"Let's get started!"}</Typography>
            <Box sx={{ display: "flex", gap: 1 }}>
              <TextField
                fullWidth
                label="First Name"
                value={values.firstName}
                onChange={handleChange("firstName")}
              />
              <TextField
                fullWidth
                label="Last Name"
                value={values.lastName}
                onChange={handleChange("lastName")}
              />
            </Box>
            <TextField
              fullWidth
              label="Email"
              autoComplete="username"
              value={values.email}
              onChange={handleChange("email")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Mail />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              label="Password"
              type="password"
              autoComplete={"new-password"}
              value={values.password}
              onChange={handleChange("password")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Password />
                  </InputAdornment>
                ),
              }}
            />
            {values.error && (
              <Typography variant="caption" color="error">
                {values.error}
              </Typography>
            )}
            <Button
              variant="contained"
              fullWidth
              color="primary"
              onClick={registerUser}
            >
              Create account
            </Button>
            <Typography>
              Already have an account?{" "}
              <Box
                component="span"
                onClick={() => setUser(null)}
                sx={{ color: "#9155fd", cursor: "pointer" }}
              >
                Login
              </Box>
            </Typography>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
};
