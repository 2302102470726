import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  Stack,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";
import { ProductContext } from "./ProductCard";
export const Option = ({ option }) => {
  const { handleEdit } = React.useContext(ProductContext);
  console.log(option);
  const [choice, setChoice] = React.useState(
    option.choice ? option.choice.name : ""
  );

  const [filter, setFilter] = React.useState([]);

  const handleChoice = (e) => {
    setChoice(e.target.value);
    handleEdit({
      type: "update",
      value: {
        ...option,
        choice: option.subcomponents.find((s) => s.name === e.target.value),
      },
      field: "options",
    });
  };

  const handleChipClick = (category, e) => {
    e.stopPropagation();
    setFilter((prev) => {
      let newFilter = [...prev];
      if (newFilter.includes(category)) {
        newFilter = newFilter.filter((c) => c !== category);
      } else {
        newFilter = [...newFilter, category];
      }
      return newFilter;
    });
  };

  React.useEffect(() => {
    setChoice(option.choice ? option.choice.name : "");
  }, [option]);

  console.log(filter);

  return (
    <div>
      <FormControl key={option.name} value={choice.name} fullWidth size="small">
        <InputLabel>{option.name}</InputLabel>
        <Select
          key={option.name}
          label={option.name}
          size="small"
          value={choice}
          onChange={handleChoice}
        >
          <Box sx={{ pb: 1, pl: 1, pr: 1 }}>
            <OptionFilterChips
              option={option}
              filter={filter}
              handleChipClick={handleChipClick}
            />
          </Box>
          {option.subcomponents
            .filter((s) => {
              if (filter.length === 0) return true;
              return s.categories.some((c) => filter.includes(c));
            })
            .map((subcomp) => (
              <MenuItem key={subcomp} value={subcomp.name}>
                {subcomp.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

Option.propTypes = {
  option: PropTypes.object,
  subcomponents: PropTypes.array,
};

const OptionFilterChips = ({ option, filter, handleChipClick }) => {
  const getFilterChips = () => {
    let chips = [];
    option.subcomponents.map((choice) => {
      if (choice.categories.length > 0) {
        choice.categories.map((category) => {
          if (
            !chips.includes(category) &&
            !option.subcomponents.every((c) => c.categories.includes(category))
          ) {
            chips.push(category);
          }
        });
      }
    });
    return chips;
  };

  return (
    <Stack
      direction="row"
      gap={0.5}
      sx={{ maxWidth: 285, display: "flex", flexWrap: "wrap" }}
    >
      {getFilterChips().map((chip) => (
        <Chip
          key={chip}
          label={chip}
          onClick={(e) => handleChipClick(chip, e)}
          color={filter && filter.includes(chip) ? "primary" : "default"}
        />
      ))}
    </Stack>
  );
};

OptionFilterChips.propTypes = {
  option: PropTypes.object,
  filter: PropTypes.array,
  i: PropTypes.number,
  handleChipClick: PropTypes.func,
};
