import * as React from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { DocusignLogin } from "./DocumentSettings";
import PropTypes from "prop-types";
import { ComponentSettings } from "./ComponentSettings";
import { FinancialSettings } from "./FinancialSettings";
import { ParameterSettings } from "./parameterSettings/ParameterSettings";
import { useSettingsData } from "../../../hooks/useSettingsData";
import { StatusBarSettings } from "./statusStepperSettings/StatusBarSettings.js";
import { ThemeSettings } from "./ThemeSettings";
import { Integrations } from "./Integrations";
import { MilestonesTasks } from "./MilestonesTasks";
import { SettingsDialogContext, SubContext } from "../../../Content.js";
// import { ThemeSettings } from "./ThemeSettings";

export const UserSettings = ({ setSettings, user }) => {
  const theme = useTheme();
  const {
    settingsDialog: { open, tab },
    setSettingsDialog,
  } = React.useContext(SettingsDialogContext);

  const { data: settings } = useSettingsData();

  const setDialogOpen = () => {
    setSettingsDialog((prev) => ({ ...prev, open: !prev.open }));
  };
  const onListItemPress = (tab) => {
    setSettingsDialog((prev) => ({ ...prev, tab: tab }));
  };
  const { sub } = React.useContext(SubContext);

  if (!settings) return null;

  const settingItems = [
    { name: "integrations", label: "Integrations" },
    { name: "components", label: "Components" },
    { name: "financial", label: "Financial" },
    { name: "parameters", label: "Parameters" },
    { name: "tasks", label: "Tasks", disabled: !sub.includes("project") },
    // { name: "StatusBar", label: "Status Bar" },
    { name: "theme", label: "Theme" },
  ];
  return (
    <>
      <IconButton onClick={() => setDialogOpen()} id="settings">
        <SettingsIcon></SettingsIcon>
      </IconButton>
      <Dialog open={open} onClose={setDialogOpen} fullWidth maxWidth={"lg"}>
        <Paper>
          <Grid container sx={{ padding: 2, height: 800, maxHeight: 800 }}>
            <Grid item xs={2}>
              <List sx={{ p: 0.5 }}>
                {settingItems.map((item) => (
                  <ListItem
                    key={item.name}
                    sx={{
                      borderRadius: 1,
                      display: "block",
                    }}
                  >
                    <ListItemButton
                      sx={{
                        borderRadius: 1,
                        px: 2.5,
                        transition: "0.5s",
                        bgcolor:
                          tab === item.name
                            ? theme.palette.primary.main
                            : "default",
                        ":hover": {
                          bgcolor:
                            tab === item.name
                              ? theme.palette.primary.light
                              : "default",
                        },
                      }}
                      onClick={() => onListItemPress(item.name)}
                      disabled={item.disabled}
                    >
                      <ListItemText
                        sx={{
                          color:
                            tab === item.name
                              ? theme.palette.primary.contrastText
                              : "default",
                        }}
                        primary={item.label}
                      ></ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid
              item
              xs={10}
              sx={{
                padding: 1,
                overflow: "auto",
                maxHeight: 764,
              }}
            >
              {tab === "documents" ? (
                <DocusignLogin user={user} settings={settings} />
              ) : tab === "components" ? (
                <ComponentSettings
                  user={user}
                  settings={settings}
                  setSettings={setSettings}
                />
              ) : tab === "financial" ? (
                <FinancialSettings
                  settings={settings}
                  setSettings={setSettings}
                />
              ) : tab === "parameters" ? (
                <ParameterSettings
                  settings={settings}
                  setSettings={setSettings}
                />
              ) : tab === "statusBar" ? (
                <StatusBarSettings user={user} />
              ) : tab === "theme" ? (
                <ThemeSettings />
              ) : tab === "integrations" ? (
                <Integrations />
              ) : tab === "tasks" ? (
                <MilestonesTasks user={user} />
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
};
UserSettings.propTypes = {
  settings: PropTypes.object,
  setSettings: PropTypes.func,
  user: PropTypes.string,
};
