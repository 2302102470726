import axios from "axios";

const fileApi = axios.create();

export const getFiles = async (user) => {
  const response = await fileApi.get("/api/file", {
    params: { user: user },
  });
  return response.data;
};

export const getFile = async (id) => {
  const response = await fileApi.get(`/api/file/${id}`);
  return response.data;
};

export const getProjectFiles = async (user, projectId) => {
  const response = await fileApi.get("/api/file", {
    params: { user: user, project: projectId },
  });
  return response.data;
};

export const getTemplateFiles = async (user) => {
  const response = await fileApi.get("/api/file", {
    params: { user: user, folder: "templates" },
  });
  return response.data;
};

export const updateFile = async ({ id, pack }) => {
  return await fileApi.patch(`/api/file/${id}`, pack);
};

export const deleteFile = async (id) => {
  return await fileApi.delete(`/api/file/${id}`);
};
export const createFile = async (pack) => {
  try {
    return await fileApi.post("/api/file", pack, { timeout: 5000 });
  } catch (err) {
    return console.log("Post to files failed!");
  }
};

export const generateDocument = async ({ template, project, folder, user }) => {
  return await fileApi.patch(`/api/file/${template}/${project._id}`, {
    folder: folder,
    user: user,
  });
};

export const sendToSignFile = async ({ id, pack }) => {
  let { user, customer, subject } = pack;
  return await fileApi.patch(`/api/sign/${id}`, {
    user: user,
    customer: customer,
    subject: subject,
  });
};
