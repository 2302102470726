import * as React from "react";
import PropTypes from "prop-types";
import { List } from "@mui/material";
import { FolderBarFolder } from "./FolderBarFolder";
// import FolderOpenIcon from '@mui/icons-material/FolderOpen';

export const FolderBar = ({ folders, setFolder, topLevel }) => {
  // const topLevel = folders.filter(folder => !folder.parent)

  return (
    <List sx={{ px: 1 }}>
      {topLevel
        ? topLevel.map((folder) => (
            <FolderBarFolder
              folder={folder}
              folders={folders}
              setFolder={setFolder}
              key={folder.name}
            />
          ))
        : folders.map((folder, i) => (
            <FolderBarFolder
              folder={folder}
              folders={folders}
              setFolder={setFolder}
              key={i}
            />
          ))}
    </List>
  );
};
FolderBar.propTypes = {
  folders: PropTypes.array,
  setFolder: PropTypes.func,
  topLevel: PropTypes.array,
};

// export const getSubFolders = (folder, subFolders) => {
//   const checkFolder = (subFolder) => {
//     return folder.every((f, i) => f == subFolder[i]);
//   };

//   return subFolders.filter((f) => checkFolder(f));
// };
