import {
  Avatar,
  AvatarGroup,
  Box,
  Collapse,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useProjectsData } from "../../../hooks/useProjectsData";
import dayjs from "dayjs";
import { TransitionGroup } from "react-transition-group";

let cloudfrontUrl = "https://cdn.manufacture.app/";

export const Tasks = () => {
  let events = [];
  const theme = useTheme();
  const { data: projects } = useProjectsData();

  if (!projects) return null;
  projects.forEach((project) => {
    project.tasks.forEach((task) => {
      if (
        (task.startDate &&
          dayjs(task.startDate).format("MM-DD-YYYY") ==
            dayjs().format("MM-DD-YYYY")) ||
        (task.endDate && dayjs(task.endDate)) < dayjs()
      )
        events.push({
          title: task.name,
          start: dayjs(task.startDate).toDate(),
          // remove a day from end date (fullCalendar "allDay" event bug (feature?))
          end: dayjs(task.endDate).subtract(1, "day").toDate(),
          overdue: new Date(task.endDate) < new Date(),
          allDay: true,
          milestone: task.milestone,
          project: project.projectName,
          assignedTo: task.assignedTo,
          completed: task.completed,
        });
    });
  });
  return (
    <Paper sx={{ p: 1 }} id="dashboard-tasks">
      <Typography variant="h5" sx={{ display: "block" }}>
        Tasks
      </Typography>
      <TransitionGroup>
        {events
          .sort((a, b) => new Date(a.end) - new Date(b.end))
          .filter((e) => !e.completed)
          .map((info) => {
            return (
              <Collapse
                in={true}
                timeout={500}
                key={`${info.project}_${info.title}`}
              >
                <Paper
                  key={`${info.project}_${info.title}`}
                  elevation={0}
                  sx={{
                    p: 0.5,
                    mt: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>{info.title}</Typography>
                    <Typography variant="caption">{info.project} </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="caption"
                      color={
                        info.overdue ? theme.palette.warning.main : "default"
                      }
                    >
                      {info.overdue ? "Overdue " : null}
                      {dayjs(info.end).format("DD/MM/YYYY") ==
                      dayjs().format("DD/MM/YYYY")
                        ? "Today"
                        : dayjs(info.end).format("DD MMM")}
                    </Typography>
                    <Typography variant="caption">
                      <Tooltip
                        title={
                          "Assigned to " +
                          info.assignedTo
                            .map((p) => `${p.firstName} ${p.lastName}`)
                            .join(", ") +
                          "."
                        }
                      >
                        <AvatarGroup max={4}>
                          {info.assignedTo.map((person) => (
                            <Avatar
                              key={person._id}
                              alt={`${person.firstName} ${person.lastName}`}
                              sx={{ width: 24, height: 24 }}
                              size="small"
                              src={`${cloudfrontUrl}${person.avatar}`}
                            />
                          ))}
                        </AvatarGroup>
                      </Tooltip>
                    </Typography>
                  </Box>
                </Paper>
              </Collapse>
            );
          })}
      </TransitionGroup>
    </Paper>
  );
};
