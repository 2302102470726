import React from "react";
import {
  Grid,
  Typography,
  Button,
  Paper,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import PropTypes from "prop-types";
import { ProjectView } from "../features/project/components/ProjectView";
import { UserContext } from "../App";
import { columns, endpoints, titles } from "../components/common/utils";
import { NoProjectsOverlay } from "../features/project/utils/NoProjectsOverlay";
import { BoardView } from "../features/project/components/BoardView";
import { NewProjectDialog } from "../features/project/components/NewProjectDialog";
import { useSettingsData } from "../hooks/useSettingsData";
import { AddCircle } from "@mui/icons-material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { checkSubscription } from "../utils/checkSubscription";
import { HelpContext } from "../Content";
import { steps } from "../utils/introSteps";
// import { useCachedProjects } from "../hooks/useCachedProjects";
import { useProjectsData } from "../hooks/useProjectsData";

export const Project = ({ options }) => {
  const { user } = React.useContext(UserContext);
  const { setHelp } = React.useContext(HelpContext);

  const [dialogOpen, setDialogOpen] = React.useState(
    options.createNew ? true : false
  );

  const [view, setView] = React.useState(
    localStorage.getItem("projectView") || "list"
  );

  const [selectedProject, setSelectedProject] = React.useState(
    options.project ? [options.project._id] : []
  );

  const { data: projects, isLoading } = useProjectsData();

  const { data: settings } = useSettingsData();

  const handleSelect = (model) => {
    if (model.length == 0) {
      setSelectedProject([]);
    } else {
      setSelectedProject(model[model.length - 1]);
    }
  };
  const handleBack = () => {
    setSelectedProject([]);
    setHelp(steps["Projects"]);
  };

  const handleModeChange = (e) => {
    if (e.target.value !== null) {
      setView(e.target.value);
      localStorage.setItem("projectView", e.target.value);
    }
  };
  const handleProjectClick = (project) => {
    setSelectedProject([project._id]);
  };
  // React.useEffect(() => {
  //   if (projects && projectData) {
  //     setSelectedProject([projectData._id]);
  //     setProjectData(
  //       projects.filter((filter) => filter._id == projectData._id)[0]
  //     );
  //   }
  // }, [projects]);

  return (
    <>
      {!selectedProject.length ? (
        <Grid
          container
          justifyContent="flex-start"
          direction="row"
          alignItems={"center"}
          columnSpacing={2}
          rowSpacing={2}
          sx={{ mb: 2 }}
        >
          <Grid item>
            <Typography variant="h3">Projects</Typography>
          </Grid>
          <Grid item xs={4}>
            <Button
              id="projects-new"
              color="primary"
              variant="contained"
              startIcon={<AddCircle />}
              onClick={() => setDialogOpen(true)}
            >
              New Project
            </Button>
            <NewProjectDialog
              title={titles["pros"]}
              isOpen={dialogOpen}
              setOpen={setDialogOpen}
              fields={columns["pros"]}
              updateTable={"projects"}
              ENDPOINT={endpoints["pros"]}
              autocompleteEndpoint={"customer"}
              user={user}
            />
            <ToggleButtonGroup
              id="projects-view-switch"
              sx={{ p: 1 }}
              color="primary"
              value={view}
              exclusive
              onChange={(e) => handleModeChange(e)}
            >
              <ToggleButton size="small" value="list">
                List View
              </ToggleButton>
              <ToggleButton
                size="small"
                value="board"
                disabled={
                  settings.milestones.length == 0 ||
                  !checkSubscription(settings).includes("project")
                }
              >
                Board View
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      ) : null}

      <Grid
        container
        justifyContent="center"
        rowSpacing={2}
        columnSpacing={2}
        sx={{ width: "100%", height: "80%" }}
      >
        {!selectedProject.length ? (
          view == "list" ? (
            <Grid item xs={12}>
              <Paper
                id="projects-table"
                sx={{
                  height: "100%",
                }}
              >
                <DataGridPremium
                  selectionModel={selectedProject}
                  onRowClick={(row) => {
                    setSelectedProject([row.row._id]);
                    setHelp(steps["ViewProject"]);
                  }}
                  onSelectionModelChange={handleSelect}
                  getRowId={(row) => row._id}
                  disableSelection
                  components={{
                    NoRowsOverlay: NoProjectsOverlay,
                  }}
                  loading={isLoading}
                  rows={projects ? projects : []}
                  columns={columns.pros
                    .filter((c) => !c.hideOnTable)
                    .map((column) => ({
                      headerName: column.headerName,
                      field: column.field,
                      width: column.width,
                      renderCell: column.renderCell,
                      flex: column.flex,
                      hide: true,
                      valueGetter: column.valueGetter,
                    }))}
                />
              </Paper>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <BoardView handleSelect={handleProjectClick} />
            </Grid>
          )
        ) : (
          <Grid item xs={12}>
            <ProjectView
              selectedProject={selectedProject[0]}
              handleBack={handleBack}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

Project.propTypes = {
  user: PropTypes.string,
  projData: PropTypes.object,
  selectedProj: PropTypes.string,
  settings: PropTypes.object,
  projects: PropTypes.array,
  options: PropTypes.object,
};
