import React from "react";
import { useQuery } from "react-query";
import { getOrganizations } from "../../../api/organizationApi";
import { UserContext } from "../../../App";

export const useOrganizationsData = (onSuccess, onError) => {
  const { user } = React.useContext(UserContext);

  return useQuery("organizations", () => getOrganizations({ user: user.id }), {
    onSuccess: (data) => {
      // Call the provided onSuccess handler
      if (onSuccess) onSuccess(data);
    },
    onError,
    staleTime: 1000 * 60 * 10, // 10 minutes
  });
};
