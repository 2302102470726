import React from "react";
import PropTypes from "prop-types";
import { useProjectUpdate } from "../../hooks/useProjectUpdate";
import { Box, Button, TextField } from "@mui/material";
import CurrencyTextField from "../../../../utils/CurrencyTextField";

export const Discount = ({ project }) => {
  const [discount, setDiscount] = React.useState(project.discount);
  const [discountRate, setDiscountRate] = React.useState(project.discountRate);

  const { mutate: updateProject } = useProjectUpdate(project._id);

  const handleSave = () => {
    const pack = {
      discount: discount,
      discountRate: discountRate,
    };
    updateProject({ id: project._id, pack });
  };

  const handleDiscount = (e) => {
    setDiscount(e.target.value);
    setDiscountRate(((e.target.value / project.projectValue) * 100).toFixed(2));
  };

  const handleDiscountRate = (e) => {
    setDiscountRate(e.target.value);
    setDiscount(((e.target.value / 100) * project.projectValue).toFixed(2));
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <CurrencyTextField
          label="Discount"
          value={discount}
          onChange={handleDiscount}
        />
        <TextField
          label="Discount Rate"
          value={discountRate}
          onChange={handleDiscountRate}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={handleSave}>Save</Button>
      </Box>
    </Box>
  );
};

Discount.propTypes = {
  project: PropTypes.object,
};
