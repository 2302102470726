import React, { useState } from "react";

import PropTypes from "prop-types";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";
import { UserContext } from "../../../App";
import axios from "axios";
import { useProjectUpdate } from "../hooks/useProjectUpdate";
import { CirclePicker } from "react-color";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const columns = [
  { id: "projectName", label: "Project Name", type: "text", minWidth: 80 },
  {
    id: "customer",
    label: "Customer",
    type: "autocomplete",
    minWidth: 80,
    renderCell: (x) =>
      `${x.formattedValue.firstName} ${x.formattedValue.lastName}`,
  },
  { id: "creationDate", label: "Creation Date", type: "date", minWidth: 80 },
  { id: "dueDate", label: "Due Date", type: "date", minWidth: 80 },
  { id: "address", label: "Address", type: "address", minWidth: 100 },
];

const autocompleteEndpoint = "api/customer";

export const EditProjectDialog = ({ data, isOpen = false, setOpen }) => {
  const { mutate: editProject } = useProjectUpdate(data._id);
  const handleCancel = () => setOpen(false);
  const handleClose = () => setOpen(false);
  const [internalFields, setInternalFields] = useState([]);
  const [autocompleteOptions, setAutocompleteOptions] = React.useState([]);
  const { user } = React.useContext(UserContext);
  const [newCustomer, setNewCustomer] = React.useState("");
  const [color, setColor] = React.useState(data.color);
  React.useEffect(() => {
    if (!autocompleteEndpoint) return;
    let abortController = new AbortController();
    let signal = abortController.signal;
    axios
      .get(autocompleteEndpoint, { signal: signal, params: { user: user.id } })
      .then((r) => {
        if (r.status !== 200) return;
        r.data.map((x) => (x.label = `${x.firstName} ${x.lastName}`));
        r.data.map((x) => (x.value = x.field));
        setAutocompleteOptions(r.data);
      });
    return () => abortController.abort();
  }, [autocompleteEndpoint]);

  React.useEffect(() => {
    if (!data) return;
    const dialogFields = [{ id: "_id" }, ...columns];
    dialogFields.map((x) => (x.value = data[x.id]));
    setInternalFields(dialogFields);
  }, [data]);

  //

  const onFieldChange = (fieldId, fieldValue) => {
    if (fieldId === "customer") {
      setNewCustomer(fieldValue.label);
    }
    let tempFields = [...internalFields];
    tempFields.find((x) => x.id === fieldId).value = fieldValue;
    setInternalFields(tempFields);
  };
  const onColorChange = (color) => {
    setColor(color.hex);
  };
  const handleSubmit = () => {
    let payload = {};
    internalFields.map((f) => (payload[f.id] = f.value));

    const { _id } = data;

    payload._id = _id;
    payload.color = color;

    editProject({
      pack: { ...payload, updateType: ["details", "edit"] },
      id: _id,
    });
    setOpen(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle
        id="form-dialog-title"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Edit Project{" "}
        <Tooltip
          title={
            <Paper sx={{ p: 1, m: -1 }}>
              <CirclePicker onChange={onColorChange} />
            </Paper>
          }
        >
          <span
            style={{
              width: "28px",
              height: "28px",
              borderRadius: "100px",
              backgroundColor: color || null,
              border: color ? null : "1px solid black",
            }}
          ></span>
        </Tooltip>
      </DialogTitle>
      <DialogContent>
        {internalFields &&
          internalFields.map((field, i) =>
            field.type == "autocomplete" ? (
              <Autocomplete
                disablePortal
                key={`${field.type}-${field.label}`}
                disabled
                options={autocompleteOptions}
                sx={{ marginTop: 2 }}
                value={
                  typeof field.value === "object"
                    ? `${field.value?.firstName} ${field.value?.lastName}` || ""
                    : newCustomer
                }
                onChange={(e, o) => onFieldChange(field.field, o || "")}
                renderInput={(params) => (
                  <TextField {...params} label={field.label} />
                )}
                // getOptionLabel={(o) => `${o.firstName} ${o.lastName}`}
              />
            ) : field.type == "date" ? (
              <DatePicker
                error={field.error}
                autoFocus={i === 0}
                sx={{ marginTop: 2, width: "100%" }}
                key={field.field}
                id={field.field}
                label={field.headerName}
                type={field.type}
                value={dayjs(field.value)}
                required={field.required}
                onChange={(value) => onFieldChange(field.id, value)}
                InputLabelProps={{ shrink: true }}
              />
            ) : (
              !["uuid", "_id"].includes(field.id) && (
                <TextField
                  autoFocus={i === 0}
                  margin="dense"
                  key={field.id}
                  sx={{ marginTop: 2, width: "100%" }}
                  id={field.field}
                  label={field.label}
                  type={field.type}
                  value={field.value || ""}
                  onChange={(e) => onFieldChange(field.id, e.target.value)}
                  fullWidth
                />
              )
            )
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditProjectDialog.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.object,
  customerID: PropTypes.string,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  updateRow: PropTypes.func,
};
