import * as React from "react";
import PropTypes from "prop-types";
// import { SfFileManager } from '../sfFileManager/SfFileManager'
// import { registerLicense } from "@syncfusion/ej2-base";

import { Typography } from "@mui/material";
import { UserContext } from "../App";
import { EditorContainer } from "../features/templateEditor/components/EditorContainer";
import { DocumentList } from "../features/templateEditor/components/DocumentList";
import { FileManager } from "../features/fileManager/components/FileManager";

// import { DocumentEditor } from "./DocumentEditor";

// registerLicense(
//     "ORg4AjUWIQA/Gnt2VVhkQlFacldJXnxNYVF2R2BJfl96d11MYF5BJAtUQF1hSn5RdkZiW3pYcH1WQmdY"
//   );
export const Files = () => {
  const { user } = React.useContext(UserContext);
  const [creating, setCreating] = React.useState(false);

  return (
    <>
      <Typography variant="h3">Files </Typography>

      {creating ? (
        <EditorContainer />
      ) : (
        <>
          <div id="files-explorer" style={{ height: 375, marginBottom: 12 }}>
            <FileManager user={user} />
          </div>
          <div id="files-documents" style={{ height: 400 }}>
            <DocumentList setCreating={setCreating} />
          </div>
        </>
      )}
    </>
  );
};
Files.propTypes = {
  user: PropTypes.string,
};
