import {
  Box,
  Dialog,
  Typography,
  useTheme,
  ClickAwayListener,
  TextField,
  Badge,
} from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FileContextMenu from "./FileContextMenu";
import { SendToSignDialog } from "./SendToSignDialog";
import ReactPlayer from "react-player";
import { useFileUpdate } from "../hooks/useFileUpdate";

export const FileIcon = ({ file, project }) => {
  const theme = useTheme();
  const { mutate: updateFile } = useFileUpdate();
  let cloudfrontUrl = "https://cdn.manufacture.app/";

  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const handleFocus = (e) => {
    e.preventDefault();
    const { target } = e;
    const extensionStarts = target.value.lastIndexOf(".");
    target.focus();
    target.setSelectionRange(0, extensionStarts);
  };
  const [editName, setEditName] = React.useState(file.name);
  const handleFileEdit = () => {
    let pack = { name: editName };
    if (file.name === editName) {
      setEdit(false);
      return;
    }
    updateFile({ id: file._id, pack: pack });
  };

  const [sendDialogOpen, setSendDialogOpen] = React.useState(false);
  const showSendDialog = () => {
    setSendDialogOpen((prev) => !prev);
  };
  const [contextMenu, setContextMenu] = React.useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };
  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <Box
      id="IconBox"
      sx={{
        boxSizing: "border-box",
        borderRadius: 1,
        width: 100,
        height: 100,
        boxShadow: edit ? 5 : 0,
        backgroundColor: edit ? theme.palette.background.paper : "default",
        ":hover": {
          boxShadow: 5,
          backgroundColor: theme.palette.background.paper,
        },
      }}
    >
      <div onContextMenu={handleContextMenu} style={{ cursor: "context-menu" }}>
        <div
          onDoubleClick={() => setOpen(true)}
          style={{
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {file.status ? (
            <Badge badgeContent={file.status} color="primary">
              <InsertDriveFileIcon />
            </Badge>
          ) : (
            <InsertDriveFileIcon />
          )}
        </div>
        <div
          style={{
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: edit ? "visible" : "hidden",
          }}
        >
          {edit ? (
            <ClickAwayListener onClickAway={() => handleFileEdit()}>
              <TextField
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
                sx={{ border: 0 }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleFileEdit();
                  }
                }}
                variant="standard"
                autoFocus
                multiline
                onFocus={(e) => handleFocus(e)}
                InputProps={{ sx: { p: 0 } }}
              ></TextField>
            </ClickAwayListener>
          ) : (
            <Typography
              align="center"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
              onDoubleClick={() => setEdit(true)}
            >
              {file.name}
            </Typography>
          )}
        </div>
      </div>
      <Dialog
        maxWidth="xl"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ maxHeight: "100%" }}
      >
        {file.key.endsWith(".pdf") || file.key.endsWith(".docx") ? (
          <iframe
            src={`${cloudfrontUrl}${file.key}`}
            seamless="seamless"
            style={{ display: "block", width: "100vh", height: "100vh" }}
          ></iframe>
        ) : file.key.endsWith(".jpg") || file.key.endsWith(".png") ? (
          <img
            src={`${cloudfrontUrl}${file.key}`}
            style={{ maxWidth: "100%", maxHeight: "calc(100vh - 64px)" }}
          ></img>
        ) : file.key.endsWith(".mp4") ? (
          <ReactPlayer
            controls={true}
            url={`${cloudfrontUrl}${file.key}`}
            width="100%"
            height="100vh"
          />
        ) : null}
      </Dialog>
      <FileContextMenu
        file={file}
        setEdit={setEdit}
        contextMenu={contextMenu}
        handleClose={handleClose}
        openSendDialog={showSendDialog}
      />
      {project ? (
        <SendToSignDialog
          open={sendDialogOpen}
          project={project}
          setOpen={setSendDialogOpen}
          file={file}
        />
      ) : null}
    </Box>
  );
};

FileIcon.propTypes = {
  file: PropTypes.object,
  project: PropTypes.object,
};
String.prototype.trunc = function (n) {
  return this.slice(0, n - 1) + (this.length > n ? "..." : "");
};

// Creates a thumbnail fitted insize the boundBox (w x h)
// const generateThumbnail = (file, boundBox) => {
//   if (!boundBox || boundBox.length != 2){
//     throw "You need to give the boundBox"
//   }
//   // let scaleRatio = Math.min(...boundBox) / Math.max(file.width, file.height)
//   let reader = new FileReader();
//   let canvas = document.createElement("canvas")
//   let ctx = canvas.getContext('2d');

//   return new Promise((resolve, reject) => {
//     reader.onload = function(event){
//         let img = new Image();
//         img.onload = function(){
//             let scaleRatio = Math.min(...boundBox) / Math.max(img.width, img.height)
//             let w = img.width*scaleRatio
//             let h = img.height*scaleRatio
//             canvas.width = w;
//             canvas.height = h;
//             ctx.drawImage(img, 0, 0, w, h);
//             return resolve(canvas.toDataURL(file.type))
//         }
//         img.src = event.target.result;
//     }
//     reader.readAsDataURL(file);
//   })
// }
