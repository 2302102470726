import {
  Avatar,
  AvatarGroup,
  Box,
  Checkbox,
  FormControlLabel,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { SelectedProjectContext } from "../../ProjectView";
import { useProjectUpdate } from "../../../hooks/useProjectUpdate";
import dayjs from "dayjs";

const CLOUDFRONT_URL = "https://cdn.manufacture.app/";

export const Task = ({ task }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const project = React.useContext(SelectedProjectContext);
  const { mutate: updateProject } = useProjectUpdate(project._id);

  const handleCheckTask = () => {
    console.log("Check Task");
    task.completed
      ? updateProject({
          id: project._id,
          pack: {
            task: { ...task, completed: false },
            updateType: ["tasks", "update"],
          },
        })
      : updateProject({
          id: project._id,
          pack: {
            task: { ...task, completed: true },
            updateType: ["tasks", "update"],
          },
        });
  };

  const handleAssignTask = (person) => {
    task.assignedTo.includes(person._id)
      ? updateProject({
          id: project._id,
          pack: {
            task: {
              ...task,
              assignedTo: task.assignedTo.filter((p) => p !== person._id),
            },
            updateType: ["tasks", "update"],
          },
        })
      : updateProject({
          id: project._id,
          pack: {
            task: { ...task, assignedTo: [...task.assignedTo, person._id] },
            updateType: ["tasks", "update"],
          },
        });
  };

  return (
    <Paper sx={{ p: 1 }} elevation={2}>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={task.completed}
              onChange={handleCheckTask}
              name
            />
          }
          label={task.name}
        />

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <AvatarGroup
            max={4}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            sx={{
              cursor: "pointer",
              ":hover": { opacity: 0.7 },
              transition: "all 0.3s",
            }}
          >
            {task.assignedTo.map((personId) => {
              const person = project.people.find((p) => p._id === personId);
              console.log(personId, project, person);

              return (
                <Avatar
                  key={person._id}
                  alt={person.firstName}
                  src={`${CLOUDFRONT_URL}${person.avatar}`}
                  sx={{ width: 32, height: 32 }}
                />
              );
            })}
            {task.assignedTo.length === 0 && (
              <Avatar sx={{ width: 32, height: 32 }} />
            )}
          </AvatarGroup>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {project.people.map((person) => (
              <MenuItem
                key={person._id}
                onClick={() => handleAssignTask(person)}
                sx={{
                  backgroundColor: task.assignedTo.includes(person._id)
                    ? "primary.main"
                    : "default",
                  ":hover": {
                    backgroundColor: task.assignedTo.includes(person._id)
                      ? "primary.light"
                      : "default",
                  },
                  color: task.assignedTo.includes(person._id)
                    ? "primary.contrastText"
                    : "default",
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    alt={person.firstName}
                    src={`${CLOUDFRONT_URL}${person.avatar}`}
                    sx={{ width: 32, height: 32 }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={`${person.firstName} ${person.lastName}`}
                />
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
        <Typography variant="caption">
          {dayjs(task.startDate).format("DD/MM/YYYY")}
        </Typography>

        <Typography variant="caption">
          {dayjs(task.endDate).format("DD/MM/YYYY")}
        </Typography>
      </Box>
    </Paper>
  );
};

Task.propTypes = {
  task: PropTypes.object.isRequired,
};
