import React, { useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { updateSettings } from "../../../api/settingsApi";
import { columns } from "../../../components/common/utils";
import { MuiTelInput } from "mui-tel-input";

export const OrganizationDialog = ({
  isOpen = false,
  setOpen,
  fields = columns.organization,
  updateTable,

  autocompleteEndpoint = "",
  user,
}) => {
  const [internalFields, setInternalFields] = useState([...fields]);
  const queryClient = useQueryClient();
  const [autocompleteOptions, setAutocompleteOptions] = React.useState([]);

  const updateSettingsMutation = useMutation(updateSettings, {
    onSuccess: () => {
      queryClient.invalidateQueries(updateTable);
      setOpen(false);
    },
  });

  React.useEffect(() => {
    if (!autocompleteEndpoint) return;
    let abortController = new AbortController();
    let signal = abortController.signal;
    axios
      .get(autocompleteEndpoint, { signal: signal, params: { user: user } })
      .then((r) => {
        if (r.status !== 200) return;
        r.data.map((x) => (x.label = `${x.firstName} ${x.lastName}`));
        r.data.map((x) => (x.value = x._id));
        setAutocompleteOptions(r.data);
      });
    return () => abortController.abort();
  }, [autocompleteEndpoint]);

  const handleValidation = () => {
    const newFields = [...internalFields];
    newFields.map((f) => {
      if (f.value == "" && f.required) {
        f.error = true;
      } else {
        f.error = false;
      }
    });
    setInternalFields(newFields);
    return internalFields.some((f) => f.error == true);
  };
  const handleSubmit = () => {
    let payload = {};
    internalFields.map((f) => (payload[f.field] = f.value));
    if (handleValidation()) {
      return;
    }
    payload.user = user;
    updateSettingsMutation.mutate({ user, pack: { organization: payload } });
  };

  const onFieldChange = (fieldId, fieldValue) => {
    let tempFields = [...internalFields];
    tempFields.find((x) => x.field === fieldId).value = fieldValue;
    setInternalFields(tempFields);
  };
  return (
    <Dialog open={isOpen} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Your Organization</DialogTitle>

      <DialogContent>
        <Typography variant="subtitle1" sx={{ marginLeft: 2 }}>
          Please fill out the following information about your organization.
          This will help us customize your experience.
        </Typography>
        {internalFields.map((field, i) =>
          field.type == "autocomplete" ? (
            <Autocomplete
              disablePortal
              key={`${field.type}-${field.headerName}`}
              options={autocompleteOptions}
              sx={{ marginTop: 2 }}
              onChange={(e, o) => onFieldChange(field.field, o ? o._id : "")}
              renderInput={(params) => (
                <TextField {...params} label={field.headerName} />
              )}
              // getOptionLabel={(o) => `${o.firstName} ${o.lastName}`}
            />
          ) : field.type == "tel" ? (
            <MuiTelInput
              fullWidth
              error={field.error}
              autoFocus={i === 0}
              sx={{ marginTop: 2 }}
              key={field.field}
              id={field.field}
              label={field.headerName}
              type={field.type}
              defaultCountry="us"
              value={field.value}
              onChange={(e) => onFieldChange(field.field, e)}
              InputLabelProps={{ shrink: true }}
            />
          ) : (
            <TextField
              fullWidth
              error={field.error}
              autoFocus={i === 0}
              sx={{ marginTop: 2 }}
              key={field.field}
              id={field.field}
              label={field.headerName}
              type={field.type}
              value={field.value}
              onChange={(e) => onFieldChange(field.field, e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          )
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

OrganizationDialog.propTypes = {
  ENDPOINT: PropTypes.string,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func,
  autocompleteOptions: PropTypes.array,
  fields: PropTypes.array,
  onSubmit: PropTypes.func,
  updateTable: PropTypes.func,
  autocompleteEndpoint: PropTypes.string,
  user: PropTypes.string,
};
