import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import ChatWindow from "../../project/components/chat/components/ChatWindow";

export const Project = ({ person, project, handleCheck }) => {
  return (
    <Paper sx={{ p: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}></Box>
      <Box>
        {project.tasks
          ?.filter((t) => t.assignedTo.find((p) => p === person._id))
          .map((task) => (
            <Paper key={task._id} sx={{ mb: 1, p: 1 }}>
              <FormControlLabel
                label={task.name}
                disabled={task.completed}
                control={<Checkbox checked={task.completed} />}
                onChange={(e) =>
                  handleCheck({ project: project, task, event: e })
                }
              />
              <Typography variant="caption">
                {dayjs(task.endDate).format("MM/DD/YYYY")}
              </Typography>
            </Paper>
          ))}
      </Box>
      <ChatWindow project={project} />
    </Paper>
  );
};

Project.propTypes = {
  person: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  handleCheck: PropTypes.func.isRequired,
};
