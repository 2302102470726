import { Avatar, Badge, Box, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isYesterday);

const cloudfrontUrl = "https://cdn.manufacture.app/";

export const Message = ({ message, connectedUsers }) => {
  console.log(message);
  if (message.type === "system_log") {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "flex-end" }}>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              {`${message.title}`}
            </Typography>
            <Typography variant="caption">
              {formatDate(message.createdAt)}
            </Typography>
          </Box>
          <Typography variant="body1" sx={{ color: "text.primary" }}>
            {message.content}
          </Typography>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          mb: 2,
          gap: 1,
        }}
      >
        {" "}
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
          color={
            connectedUsers.includes(message.user) ? "success" : "transparent"
          }
        >
          <Avatar
            sx={{ width: 32, height: 32 }}
            src={
              message.sender.avatar
                ? `${cloudfrontUrl}${message.sender.avatar}`
                : null
            }
            alt={message.sender.firstName}
          />
        </Badge>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "flex-end" }}>
            <Typography variant="body1" sx={{ color: "text.secondary" }}>
              {`${message.sender.firstName}  ${message.sender.lastName}`}
            </Typography>
            <Typography variant="caption">
              {formatDate(message.createdAt)}
            </Typography>
          </Box>
          <Typography variant="body1" sx={{ color: "text.primary" }}>
            {message.content}
          </Typography>
        </Box>
      </Box>
    );
  }
};

Message.propTypes = {
  message: PropTypes.object,
  connectedUsers: PropTypes.func,
};

const formatDate = (date) => {
  const d = dayjs(date);

  const isToday = d.isToday();
  if (isToday) {
    return `Today at ${d.format("h:mm A")}`;
  }
  const isYesterday = d.isYesterday();
  if (isYesterday) {
    return `Yesterday at ${d.format("h:mm A")}`;
  }

  return d.format("MMMM D, YYYY h:mm A");
};
