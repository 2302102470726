import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../../../Content";
import { updateOrder } from "../../../api/suppplierApi";

export function useOrderUpdate(snack) {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  return useMutation({
    mutationFn: ({ id, pack }) => {
      updateOrder({
        id: id,
        pack,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("suppliers");
      queryClient.invalidateQueries("components");
      if (snack) {
        setSnack({
          open: true,
          message: snack,
          severity: "success",
        });
      }
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Supplier could not be updated.",
        severity: "error",
      });
    },
  });
}
