import React from "react";
import { UserContext } from "../App";
import { Login } from "../features/loginRegister/components/Login";
import { Pending } from "../features/loginRegister/components/Pending";
import { Register } from "../features/loginRegister/components/Register";

export const LoginRegister = () => {
  const { user } = React.useContext(UserContext);

  if (!user) {
    return <Login />;
  }

  switch (user.status) {
    case "pending":
      return <Pending />;
    case "register":
      return <Register />;
    default:
      return <Login />;
  }
};
