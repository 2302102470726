import _ from "lodash";
// import React from "react";

// export function useNewComponent({ saveFn, id, data, handleQty }) {
//   const [newComponent, setNewComponent] = React.useState(
//     data || {
//       _id: "newComponent",
//       nodeKey: id,
//       name:
//         id.split("-").length > 1
//           ? `Component${id.split("-").slice(1).join("-")}`
//           : "Component",
//       description: "",
//       image: null,
//       cost: null,
//       price: null,
//       code: null,
//       priceModifier: 0,
//       suppliers: [],
//       subcomponents: [],
//       options: [],
//       isProduct: false,
//       categories: [],
//       parameters: [],
//       qty: {
//         value: 1,
//         formula: "",
//         variable: "",
//         hierarchy: ["parent"],
//       },
//     }
//   );

//   const clearComponent = () => {
//     setNewComponent({
//       _id: "newComponent",
//       name: "Component",
//       description: "",
//       image: "",
//       cost: "",
//       price: "",
//       code: null,
//       priceModifier: 0,
//       suppliers: [],
//       subcomponents: [],
//       options: [],
//       isProduct: false,
//       categories: [],
//       parameters: [],
//     });
//   };

//   const handleEdit = ({ field, value, type }) => {
//     console.log("value", value);

//     switch (field) {
//       case "parameters":
//         switch (type) {
//           // handle update
//           case "update":
//             setNewComponent((prev) => ({
//               ...prev,
//               parameters: handleParameterUpdate({
//                 component: prev,
//                 parameter: value,
//               }),
//             }));
//             break;
//           // handle add or remove
//           default:
//             setNewComponent((prev) => ({
//               ...prev,
//               parameters: handleParameterAddRemove({
//                 component: prev,
//                 parameter: value,
//               }),
//             }));
//             break;
//         }
//         break;
//       case "qty":
//         setNewComponent((prev) => {
//           handleQty({
//             component: {
//               ...prev,
//               qty: value,
//             },
//             qty: value,
//           });

//           return {
//             ...prev,
//             qty: value,
//           };
//         });
//         break;
//       case "categories":
//         setNewComponent((prev) => ({
//           ...prev,
//           categories: handleCategoryAddRemove({
//             component: prev,
//             category: value,
//           }),
//         }));
//         break;
//       case "cost":
//         setNewComponent((prev) => ({
//           ...prev,
//           cost: value,
//         }));
//         break;
//       case "price":
//         setNewComponent((prev) => ({
//           ...prev,
//           price: value,
//           isProduct: value ? true : false,
//         }));
//         break;
//       case "suppliers":
//         // type: edit supplier
//         switch (type) {
//           case "edit":
//             setNewComponent((prev) => {
//               const { suppliers, cost } = handleSupplierEdit({
//                 component: prev,
//                 supplier: value,
//               });

//               return {
//                 ...prev,
//                 suppliers: suppliers,
//                 cost: cost,
//               };
//             });
//             break;

//           default:
//             setNewComponent((prev) => {
//               const { suppliers, cost } = handleSupplierAddRemove({
//                 component: prev,
//                 supplier: value,
//               });
//               return {
//                 ...prev,
//                 suppliers: suppliers,
//                 cost: cost,
//               };
//             });
//         }
//         break;
//       // default: add or remove supplier

//       default:
//         setNewComponent((prev) => ({
//           ...prev,
//           [field]: value,
//         }));
//     }
//   };

//   React.useEffect(() => {
//     if (!saveFn) return;
//     saveFn(newComponent);
//   }, [newComponent, saveFn]);

//   React.useEffect(() => {
//     console.log("rerendering because of data change");

//     if (data) {
//       console.log("rerendering because of data change");
//       // if (data.duplicate) {
//       //   data._id = "newComponent";
//       //   data.nodeKey = "parent";
//       //   data.duplicate = false;
//       // }
//       setNewComponent(data);
//     }
//   }, [data]);

//   console.log("newComponent", newComponent);

//   return [newComponent, handleEdit, clearComponent, setNewComponent];
// }

export const handleOptionsInheritedFrom = (component) => {
  let newOptions = [...component.options];
  newOptions.forEach((option) => {
    component.subcomponents.map((subcomponent) => {
      if (
        subcomponent.options.find(
          (o) => o._id === option._id && o.inherited !== false
        )
      ) {
        option.inheritedFrom = [
          ...(option.inheritedFrom ? option.inheritedFrom : []),
          subcomponent._id,
        ];
      }
    });
  });
  return newOptions;
};

// handle parameter add or remove
const handleParameterAddRemove = ({ component, parameter }) => {
  let index = component.parameters.findIndex(
    (p) => p.name === parameter.name && p.hierarchy.length === 1
  );
  if (index > -1) {
    return component.parameters.filter(
      (p) => p.name !== parameter.name || p.hierarchy.length !== 1
    );
  } else {
    return [...component.parameters, parameter];
  }
};
// handle parameter update
const handleParameterUpdate = ({ component, parameter }) => {
  let index = component.parameters.findIndex(
    (p) =>
      p.name === parameter.name && _.isEqual(p.hierarchy, parameter.hierarchy)
  );

  let original = component.parameters[index];
  // mark that this parameter change is an override to be added to its parent
  const isOverride =
    component.nodeKey.split("-").length > 1 &&
    component._id !== "newComponent" &&
    !component.edit;
  const isChanged =
    original.value !== parameter.value ||
    original.formula !== parameter.formula ||
    original.variable !== parameter.variable;

  if (isOverride && isChanged) {
    parameter.override = true;
  } else {
    parameter.override = false;
  }

  component.parameters[index] = parameter;
  return component.parameters;
};

// handle subcomponent add
// const handleSubcomponentAdd = ({ component, subcomponents }) => {

// handle subcomponent remove
// handle option add
// handle option remove

// handle category add or remove
const handleCategoryAddRemove = ({ component, category }) => {
  let index = component.categories.findIndex((c) => c === category);
  if (index > -1) {
    return component.categories.filter((c) => c !== category);
  } else {
    return [...component.categories, category];
  }
};

// handle supplier add or remove
const handleSupplierAddRemove = ({ component, supplier }) => {
  let index = component.suppliers.findIndex((s) => s._id === supplier._id);
  if (index > -1) {
    let newSuppliers = component.suppliers.filter(
      (s) => s.cost !== supplier.cost && s.code !== supplier.code
    );
    if (newSuppliers.length) {
      newSuppliers[0].default = true;
      return { suppliers: newSuppliers, cost: newSuppliers[0].cost };
    } else {
      return {
        suppliers: newSuppliers,
        cost: null,
      };
    }
  } else {
    return {
      suppliers: [...component.suppliers, supplier],
      cost: component.suppliers.length ? component.cost : supplier.cost,
    };
  }
};

// handle supplier edit
const handleSupplierEdit = ({ component, supplier }) => {
  console.log("supplier", supplier);
  console.log("component", component);
  let index = component.suppliers.findIndex((s) => s._id === supplier._id);
  let newSuppliers = [...component.suppliers];
  newSuppliers[index] = supplier;

  return { suppliers: newSuppliers, cost: newSuppliers[0].cost };
};

export const handleEdit = ({ component, field, value, type }) => {
  switch (field) {
    case "parameters":
      switch (type) {
        // handle update
        case "update":
          return {
            ...component,
            parameters: handleParameterUpdate({
              component: component,
              parameter: value,
            }),
          };
        // handle add or remove
        default:
          return {
            ...component,
            parameters: handleParameterAddRemove({
              component: component,
              parameter: value,
            }),
          };
      }
    // case "qty":
    // setNewComponent((prev) => {
    //   handleQty({
    //     component: {
    //       ...prev,
    //       qty: value,
    //     },
    //     qty: value,
    //   });

    //   return {
    //     ...prev,
    //     qty: value,
    //   };
    // });
    // break;
    case "categories":
      return {
        ...component,
        categories: handleCategoryAddRemove({
          component: component,
          category: value,
        }),
      };
    case "cost":
      return {
        ...component,
        cost: value,
      };
    case "price":
      return {
        ...component,
        price: value,
        isProduct: value ? true : false,
      };
    case "priceModifier":
      return {
        ...component,
        priceModifier: value,
        isProduct: value ? true : false,
      };
    case "suppliers":
      // type: edit supplier
      switch (type) {
        case "edit": {
          const { suppliers, cost } = handleSupplierEdit({
            component,
            supplier: value,
          });

          return {
            ...component,
            suppliers: suppliers,
            cost: cost,
          };
        }
        case "deleteAll": {
          return {
            ...component,
            suppliers: [],
            cost: "",
          };
        }
        default: {
          const { suppliers, cost } = handleSupplierAddRemove({
            component: component,
            supplier: value,
          });
          return {
            ...component,
            suppliers: suppliers,
            cost: cost,
          };
        }
      }
    // default: add or remove supplier

    default:
      return {
        ...component,
        [field]: value,
      };
  }
};

// const handleQty = ({ component, qty }) => {
//   let newQty = {
//     ...qty,
//     hierarchy: [...qty.hierarchy, component._id],
//   };

//   component.subcomponents.forEach((subcomponent) => {
//     subcomponent.qty = newQty;
//   });

//   return component;
// }
