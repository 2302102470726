import { Button, Dialog, Paper } from "@mui/material";
import React from "react";
import { useSettingsData } from "../../../hooks/useSettingsData";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { AddRole } from "./AddRole";
import { ViewRole } from "./ViewRole";

export const Roles = () => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [selectedRole, setSelectedRole] = React.useState();
  const { data: settings } = useSettingsData();

  const columns = [
    { field: "name", headerName: "Name", width: 200 },
    {
      field: "permissions",
      headerName: "Permissions",
      flex: 1,
      valueGetter: (params) => parsePermissions(params.value.project),
    },
  ];

  const onRowClick = (params) => {
    setSelectedRole(params.row);
  };

  return (
    <>
      <Button
        id="people-roles"
        variant="outlined"
        onClick={() => setDialogOpen(true)}
      >
        Roles
      </Button>

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth={"md"}
        fullWidth
      >
        <Paper sx={{ height: 800, p: 1 }}>
          <AddRole />

          <DataGridPremium
            rows={settings.roles}
            getRowId={(row) => row._id}
            sx={{ mt: 2 }}
            columns={columns}
            onRowClick={onRowClick}
            autoHeight
            disableSelectionOnClick
          />
        </Paper>
      </Dialog>
      {selectedRole && (
        <ViewRole
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
        />
      )}
    </>
  );
};

function parsePermissions(permissions) {
  const parsedPermissions = {};

  // Recursive function to flatten the object
  function flattenObject(obj, parentKey = "") {
    for (const [key, value] of Object.entries(obj)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key;
      if (value && typeof value === "object") {
        flattenObject(value, newKey);
      } else {
        const [group, permission] = newKey.split(".");
        if (!parsedPermissions[group]) {
          parsedPermissions[group] = [];
        }
        if (permission === "viewAll") {
          // Handle the special case for viewAll
          parsedPermissions[group].push(value ? "All" : "Assigned Only");
        } else if (value) {
          // Only add to result if the value is true
          parsedPermissions[group].push(
            permission.charAt(0).toUpperCase() + permission.slice(1)
          ); // Capitalize the permission
        }
      }
    }
  }

  flattenObject(permissions);

  // Convert the parsedPermissions object into a readable string
  return Object.entries(parsedPermissions)
    .map(
      ([group, perms]) =>
        `${group.charAt(0).toUpperCase() + group.slice(1)}: ${perms.join(", ")}`
    )
    .join(", ");
}
