import { Paper } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export const MyStorePreview = ({ onClick }) => {
  return (
    <Paper
      sx={{ width: "100%", mb: 1, p: 1, cursor: "pointer" }}
      onClick={onClick}
    >
      My Store Preview: Click To Edit
    </Paper>
  );
};

MyStorePreview.propTypes = {
  onClick: PropTypes.func,
};
