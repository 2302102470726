import { darken, lighten, styled } from "@mui/material";
import { green, red, yellow } from "@mui/material/colors";
import { DataGridPremium } from "@mui/x-data-grid-premium";

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

export const FinancialGrid = styled(DataGridPremium)(({ theme }) => ({
  "& .super-app-theme--income": {
    backgroundColor: getBackgroundColor(green[800], theme.palette.mode),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(green[800], theme.palette.mode),
    },
  },
  "& .super-app-theme--expense": {
    backgroundColor: getBackgroundColor(red[800], theme.palette.mode),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(red[800], theme.palette.mode),
    },
  },
  "& .super-app-theme--futureIncome": {
    backgroundColor: getBackgroundColor(green[400], theme.palette.mode),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(green[400], theme.palette.mode),
    },
  },
  "& .super-app-theme--futureExpense": {
    backgroundColor: getBackgroundColor(red[400], theme.palette.mode),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(red[400], theme.palette.mode),
    },
  },
  "& .super-app-theme--overdue": {
    backgroundColor: getBackgroundColor(yellow[800], theme.palette.mode),
    "&:hover": {
      backgroundColor: getHoverBackgroundColor(yellow[800], theme.palette.mode),
    },
  },
}));
