import axios from "axios";

const parameterApi = axios.create();

export const getParameters = async (user) => {
  const response = await parameterApi.get("/api/parameter", {
    params: { user: user },
  });
  return response.data;
};

// export const getProjectParameters = async (user, projectId) => {
//   const response = await parameterApi.get("/parameter", {
//     params: { user: user, project: projectId },
//   });
//   return response.data;
// };

export const updateParameter = async ({ id, pack }) => {
  return await parameterApi.patch(`/api/parameter/${id}`, pack);
};

export const deleteParameter = async (id) => {
  return await parameterApi.delete(`/api/parameter/${id}`);
};
export const createParameter = async (pack) => {
  //   for (const value of pack.values()) {
  //   }
  try {
    return await parameterApi.post("/api/parameter", pack, { timeout: 5000 });
  } catch (err) {
    console.log("Post to parameters failed!");
  }
};
