import React from "react";
import { useMutation } from "react-query";
import { SnackbarContext } from "../../../Content";
import { updateOrganization } from "../../../api/organizationApi";

export function useOrganizationUpdate() {
  const { setSnack } = React.useContext(SnackbarContext);

  return useMutation({
    mutationFn: (pack) => {
      return updateOrganization(pack);
    },
    onSuccess: () => {
      setSnack({
        open: true,
        message: "Organization updated.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Organization could not be updated.",
        severity: "error",
      });
    },
  });
}
