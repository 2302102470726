import { Typography } from "@mui/material";
import React from "react";
import { Logo } from "../features/organization/components/Logo";
// import { PublicProfile } from "../features/organization/components/PublicProfile";
import { useOrganizationData } from "../features/organization/hooks/useOrganizationData";

export const Organization = () => {
  const { data: organization } = useOrganizationData();
  if (!organization) return <div>Loading...</div>;

  return (
    <div>
      <Logo org={organization} />
      <Typography variant="h4">{organization.name}</Typography>
      <Typography variant="h5">{organization.address}</Typography>
      <Typography variant="h5">
        {organization.city}, {organization.state} {organization.zip}
      </Typography>
      <Typography variant="h5">{organization.phone}</Typography>
      <Typography variant="h5">{organization.email}</Typography>
      <Typography variant="h5">{organization.website}</Typography>
      {/* <PublicProfile org={organization} /> */}
    </div>
  );
};
