import { HelpOutline } from "@mui/icons-material";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import React from "react";

export const ImportGuide = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <IconButton onClick={handleOpen}>
        <HelpOutline />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          Currently supports only imports from Mozaik. Submit your suggestions
          for what to support next to suggestions@manufacture.app.
          <br />
          To import from Mozaik, go to your Mozaik project and export it as
          database. It should download a .rar file. Extract the .rar file and
          upload the file titled JobData.db to Manufacture.app. A dialog will
          open to guide you on how to continue. This dialog can be reopened with
          the View Data button that will appear once you have uploaded.
        </DialogContent>
      </Dialog>
    </>
  );
};
