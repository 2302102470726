import { Box, Grid } from "@mui/material";
import React from "react";
import { People } from "./People";
import { AddTasks } from "./AddTasks";
import { Calendar } from "./Calendar";
import { SelectedProjectContext } from "../../ProjectView";
import { Milestone } from "./Milestone";

export const PlanTab = () => {
  const project = React.useContext(SelectedProjectContext);
  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <People />
          <AddTasks />
          {project.milestones.map((milestone) => (
            <Milestone key={milestone.id} milestone={milestone} />
          ))}
        </Grid>
        <Grid item xs={9}>
          {/* <Calendar /> */}
          <Calendar />
        </Grid>
      </Grid>
    </Box>
  );
};
