import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { splitProductString } from "../MozaikTable";

export const MozaikRooms = ({ project }) => {
  const rooms = project.importData.RoomTable.map((r) => {
    let material = splitProductString(r.material);
    let room = {
      name: r.roomName,
      id: r.ID,
      extTexture: r.extTexture,
      intTexture: r.intTexture,
      material: [],
    };
    for (const [key, value] of Object.entries(material)) {
      room.material.push({ name: key, value: value });
    }
    return room;
  });
  return (
    <div>
      <Box sx={{ p: 1, display: "flex", gap: 1, flexWrap: "wrap" }}>
        {rooms.map((r) => (
          <Paper key={r.name} sx={{ p: 1 }}>
            <Typography variant="h5">{r.name}</Typography>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
              }}
            >
              <Typography>Exterior Texture: </Typography>
              <Typography>{r.extTexture}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
              }}
            >
              <Typography>Interior Texture: </Typography>
              <Typography>{r.intTexture}</Typography>
            </Box>
            {r.material.map((m) => (
              <Typography
                key={`${r.name}${m.value}`}
                variant="body1"
              >{`${m.name}: ${m.value}`}</Typography>
            ))}
          </Paper>
        ))}
      </Box>
    </div>
  );
};

MozaikRooms.propTypes = {
  project: PropTypes.object,
};
