import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import { useNewFinancial } from "../hooks/useNewFinancial";
import { ContentCopy, Link } from "@mui/icons-material";
import { useFinancialCreate } from "../hooks/useFinancialCreate";
import { FinancialMenuItem } from "../utils/FinancialEntryTypeMenuItem";
import { ENTRY_TYPES } from "../utils/entryTypes";
import { createPaymentLink } from "../../../api/paymentApi";
import { UserContext } from "../../../App";
import { useCustomersData } from "../../../hooks/useCustomerData";
import { NewItemDialog } from "../../../components/common/itemList";
import { columns, endpoints, titles } from "../../../components/common/utils";
import { useSettingsData } from "../../../hooks/useSettingsData";
import CurrencyTextField from "../../../utils/CurrencyTextField";

export const PaymentLink = ({ type, project, data }) => {
  const { user } = React.useContext(UserContext);
  const { data: customers } = useCustomersData();
  const { data: settings } = useSettingsData();
  const [open, setOpen] = React.useState(false);
  const { newFinancial, handleFinancialChange, clearFinancial } =
    useNewFinancial(data);
  const [paymentLink, setPaymentLink] = React.useState(null);
  const [alert, setAlert] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [newCustomerOpen, setNewCustomerOpen] = React.useState(false);
  const handleNewCustomerOpen = () => {
    setNewCustomerOpen(true);
  };
  const { mutate: createFinancial } = useFinancialCreate(project);
  const upload = async () => {
    handleFinancialChange({ field: "type", value: type });
    createFinancial({
      pack: {
        ...newFinancial,
        project: project ? project : "",
        type: type ? type : newFinancial.type,
        plink: paymentLink.url,
        plinkId: paymentLink.id,
        plinkStatus: "created",
      },
    });
    clearFinancial();
    setOpen(false);
  };

  const handleCreate = async () => {
    setLoading(true);
    const data = await createPaymentLink({
      pack: {
        user: user.id,
        amount: newFinancial.amount,
        name: newFinancial.name,
        description: newFinancial.memo,
      },
    });
    setPaymentLink(data.data);
    setLoading(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(paymentLink.url);
    setAlert("Copied to clipboard.");
  };

  // React.useEffect(() => {

  // }, [isCreateSuccess]);

  if (!customers || !settings) return null;

  const active =
    settings.stripeAccount?.object.capabilities?.link_payments === "active";

  return (
    <div>
      <Tooltip
        title={
          !active ? "Connect your Stripe account to use payment links." : null
        }
      >
        <Box id="stripe-link">
          <Button
            startIcon={<Link />}
            disabled={!active}
            variant="contained"
            sx={{
              backgroundColor: "#6772e5",
              ":hover": {
                backgroundColor: "#9072e5",
              },
            }}
            onClick={() => setOpen((prev) => !prev)}
          >
            Payment Link
          </Button>
        </Box>
      </Tooltip>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <Paper>
          <DialogTitle>Add Entry</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Name"
              value={newFinancial.name}
              sx={{ mt: 2 }}
              onChange={(e) =>
                handleFinancialChange({
                  field: "name",
                  value: e.target.value,
                })
              }
            />
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel>Customer</InputLabel>
              <Select
                label="Customer"
                renderValue={(value) => {
                  const customer = customers.find((c) => c._id === value);
                  return `${customer.firstName} ${customer.lastName}`;
                }}
                onChange={(e) =>
                  handleFinancialChange({
                    field: "customer",
                    value: e.target.value,
                  })
                }
                value={newFinancial.customer}
              >
                {customers.map((customer) => (
                  <MenuItem key={customer._id} value={customer._id}>
                    {customer.firstName} {customer.lastName}
                  </MenuItem>
                ))}
                <MenuItem onClick={handleNewCustomerOpen}>
                  Add new customer
                </MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ display: "flex", alignItems: "center", mt: 2, gap: 1 }}>
              <CurrencyTextField
                fullWidth
                label="Amount"
                value={newFinancial.amount}
                onChange={(e) =>
                  handleFinancialChange({
                    field: "amount",
                    value: e.target.value,
                  })
                }
              />
              {type ? null : (
                <FormControl sx={{ width: "35%", mb: "auto" }}>
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={newFinancial.type}
                    label="Type"
                    onChange={(e) =>
                      handleFinancialChange({
                        field: "type",
                        value: e.target.value,
                      })
                    }
                  >
                    <ListSubheader>Income</ListSubheader>
                    {ENTRY_TYPES.filter((e) => e.className === "income").map(
                      (entry) => (
                        <FinancialMenuItem
                          className="income"
                          key={entry.value}
                          value={entry.value}
                        >
                          {entry.label}
                        </FinancialMenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              )}
            </Box>
            <TextField
              fullWidth
              sx={{ mt: 2 }}
              label="Memo"
              value={newFinancial.memo}
              onChange={(e) =>
                handleFinancialChange({
                  field: "memo",
                  value: e.target.value,
                })
              }
            />

            {/* <TextField
              fullWidth
              sx={{ mt: 2 }}
              label="Date"
              value={newFinancial.date}
              type="date"
              onChange={(e) =>
                handleFinancialChange({
                  field: "date",
                  value: e.target.value,
                })
              }
            /> */}
            <Fade in={!!paymentLink}>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <OutlinedInput
                  fullWidth
                  disabled
                  endAdornment={
                    <InputAdornment>
                      <IconButton onClick={handleCopy}>
                        <ContentCopy />
                      </IconButton>
                    </InputAdornment>
                  }
                  helperText={alert}
                  sx={{
                    "&.Mui-disabled": {
                      borderColor: "#6772e5",
                      ".MuiOutlinedInput-notchedOutline": {
                        borderColor: "#6772e5",
                      },
                    },
                  }}
                  value={paymentLink?.url}
                />
                <FormHelperText>{alert}</FormHelperText>
              </FormControl>
            </Fade>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen((prev) => !prev)}>Cancel</Button>

            {paymentLink ? (
              <Button onClick={() => upload()}>Add</Button>
            ) : (
              <Button onClick={() => handleCreate()} disabled={loading}>
                {loading ? <CircularProgress size={24} /> : "Create"}
              </Button>
            )}
          </DialogActions>
        </Paper>
      </Dialog>
      <NewItemDialog
        title={titles["customers"]}
        isOpen={newCustomerOpen}
        setOpen={setNewCustomerOpen}
        fields={columns["customers"]}
        updateTable={"customers"}
        ENDPOINT={endpoints["customers"]}
      />
    </div>
  );
};

PaymentLink.propTypes = {
  type: PropTypes.string,
  project: PropTypes.string,
  data: PropTypes.object,
};
