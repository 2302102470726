import React from "react";
import { Box, Button, Collapse, Typography } from "@mui/material";
import { useTemplatesData } from "../hooks/useTemplatesData";
import { TemplateCard } from "../features/templates/components/TemplateCard";
import { CreateTemplate } from "../features/templates/components/CreateTemplate";
import { Add, ArrowBack } from "@mui/icons-material";
import { useCustomersData } from "../hooks/useCustomerData";
import { useProjectsData } from "../hooks/useProjectsData";
import { TransitionGroup } from "react-transition-group";
import { HelpContext } from "../Content";
import { steps } from "../utils/introSteps";

export const Templates = () => {
  const [creating, setCreating] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const { setHelp } = React.useContext(HelpContext);
  const { data: projects } = useProjectsData();
  const { data: customers } = useCustomersData();
  const { data: templates } = useTemplatesData();

  const handleClick = () => {
    setCreating((prev) => {
      if (prev) {
        setSelectedTemplate(null);
        setHelp(steps["Templates"]);
      } else {
        setHelp(steps["ViewTemplate"]);
      }
      return !prev;
    });
  };
  React.useEffect(() => {
    if (selectedTemplate) {
      setCreating(true);
    } else {
      setCreating(false);
    }
  }, [selectedTemplate]);

  if (!templates || !projects || !customers) {
    return null;
  }

  return (
    <Box>
      {creating ? (
        <Button
          variant="outlined"
          startIcon={<ArrowBack />}
          onClick={handleClick}
          sx={{ mb: 2 }}
        >
          Back
        </Button>
      ) : (
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ mb: 2 }} variant="h3">
            Templates{" "}
          </Typography>
        </Box>
      )}
      {creating ? (
        <CreateTemplate
          selectedTemplate={selectedTemplate}
          handleCreating={handleClick}
        />
      ) : (
        // <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        <TransitionGroup
          style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}
        >
          <Button
            id="templates-new"
            sx={{ width: 220, height: 250 }}
            variant="outlined"
            startIcon={<Add sx={{ transform: "scale(2)" }} />}
            onClick={handleClick}
          ></Button>

          {templates.map((template) => (
            <Collapse
              key={template.name}
              orientation="horizontal"
              id="templates-cards"
            >
              <TemplateCard
                key={template.name}
                template={template}
                projects={projects}
                customers={customers}
                setSelectedTemplate={setSelectedTemplate}
              />
            </Collapse>
          ))}
        </TransitionGroup>
        ///* </Box> */}
      )}
    </Box>
  );
};
