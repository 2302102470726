import * as React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { AddFinancial } from "../../../financial/components/AddFinancial";
import { ViewFinancial } from "../../../financial/components/ViewFinancial";

export const CostDetails = ({ project }) => {
  const [entry, setEntry] = React.useState({ open: false });

  const columns = [
    { field: "id", hide: true },
    { field: "name", headerName: "Name", flex: 1 },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      valueGetter: (params) => {
        return new Date(params.value).toLocaleString();
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      valueGetter: (params) => {
        return formatter.format(params.value);
      },
    },
  ];

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      <Box
        sx={{
          overflow: "auto",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <div style={{ display: "flex", justifyContent: "end" }}>
          <AddFinancial project={project._id} />
        </div>

        <DataGridPremium
          columns={columns}
          density="compact"
          hideFooter
          rows={project.financial
            .filter((f) => f.amount < 0)
            .map((deposit, i) => ({ ...deposit, id: i }))}
          onRowClick={(params) => {
            setEntry({ ...params.row, open: true });
          }}
        />
        <ViewFinancial entry={entry} />
      </Box>
    </>
  );
};
CostDetails.propTypes = {
  project: PropTypes.obj,
};
