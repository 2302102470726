import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../../../Content";
import { updateCustomer } from "../../../api/customerApi";

export function useCustomerUpdate(id) {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  return useMutation({
    mutationFn: ({ id, pack }) => {
      return updateCustomer({ id, pack });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("customers");
      queryClient.invalidateQueries(["customer", id]);
      setSnack({
        open: true,
        message: "Customer updated.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Customer could not be updated.",
        severity: "error",
      });
    },
  });
}
