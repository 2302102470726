import _ from "lodash";
import { limitedEvaluate } from "../../../flowCreate/components/utils/limitedEvaluate";
import React from "react";
import { useParametersData } from "../../../../hooks/useParametersData";
// import { limitedEvaluate } from "../../../flowCreate/components/utils/limitedEvaluate";
export function useProduct({ data, dataType }) {
  const { data: parameters } = useParametersData();

  const [product, setProduct] = React.useState(
    dataType === "existing"
      ? initializeExistingProduct(
          data,
          parameters
            .filter((p) => p.global)
            .map((p) => ({ ...p, variable: p.name }))
        )
      : dataType === "new"
      ? initializeComponentData(data)
      : {
          _id: "product",
          id: 0,
          name: "Component",
          description: "",
          image: "",
          cost: null,
          price: null,
          priceModifier: 0,
          suppliers: [],
          subcomponents: [],
          flatSubcomponents: [],
          options: [],
          type: [],
          isProduct: false,
          categories: [],
          parameters: [],
        }
  );

  const clearProduct = () => {
    setProduct({
      _id: "product",
      name: "Component",
      description: "",
      image: "",
      cost: "",
      price: "",
      priceModifier: 0,
      suppliers: [],
      subcomponents: [],
      flatSubcomponents: [],
      options: [],
      type: [],
      isProduct: false,
      categories: [],
      parameters: [],
    });
  };
  console.log(product);

  const handleEdit = ({ field, value, type }) => {
    switch (field) {
      case "parameters":
        switch (type) {
          // handle update
          case "update":
            setProduct((prev) =>
              handleParameterUpdate({
                component: prev,
                parameter: value,
                variables: parameters
                  .filter((p) => p.global)
                  .map((p) => ({ ...p, variable: p.name })),
              })
            );
            break;
          // handle add or remove
          default:
            setProduct((prev) => ({
              ...prev,
              parameters: handleParameterAddRemove({
                component: prev,
                parameter: value,
              }),
            }));
            break;
        }
        break;
      case "options":
        switch (type) {
          case "update":
            setProduct((prev) => {
              let component = handleOptionUpdate({
                component: prev,
                option: value,
                variables: parameters
                  .filter((p) => p.global)
                  .map((p) => ({ ...p, variable: p.name })),
              });
              return component;
            });
            break;
        }
        break;
      case "product":
        setProduct({
          ...initializeComponentData(value),
        });
        break;
      case "existingProduct":
        setProduct(
          initializeExistingProduct(
            value,
            parameters
              .filter((p) => p.global)
              .map((p) => ({ ...p, variable: p.name }))
          )
        );
        break;
      default:
        setProduct((prev) => ({
          ...prev,
          [field]: value,
        }));
    }
  };
  return [product, handleEdit, clearProduct, setProduct];
}

// handle parameter add or remove
const handleParameterAddRemove = ({ component, parameter }) => {
  let index = component.parameters.findIndex(
    (p) => p.name === parameter.name && p.hierarchy.length === 1
  );
  if (index > -1) {
    return component.parameters.filter(
      (p) => p.name !== parameter.name || p.hierarchy.length !== 1
    );
  } else {
    return [...component.parameters, parameter];
  }
};
// handle parameter update
const handleParameterUpdate = ({ component, parameter, variables }) => {
  // if (parameter.hierarchy.length === 1) {
  //   if (parameter.name === "qty") {
  //     component.qty = parameter;
  //   } else {
  //     let index = component.parameters.findIndex(
  //       (p) => p.name === parameter.name && p.hierarchy.length === 1
  //     );
  //     component.parameters[index] = parameter;
  //   }
  // } else {
  //   let subcompIndex = component.flatSubcomponents.findIndex(
  //     (s) => s.hierarchy.join("-") === parameter.hierarchy.join("-")
  //   );
  //   if (parameter.name === "qty") {
  //     component.flatSubcomponents[subcompIndex].qty = parameter;
  //   } else {
  //     let paramIndex = component.flatSubcomponents[
  //       subcompIndex
  //     ].parameters.findIndex((p) => p.name === parameter.name);
  //     component.flatSubcomponents[subcompIndex].parameters[paramIndex] =
  //       parameter;
  //   }
  // }

  component.allParameters = getAllParameters(component);
  let paramIndex = component.allParameters.findIndex(
    (p) =>
      p.hierarchy.join("-") == parameter.hierarchy.join("-") &&
      p.name === parameter.name
  );
  component.allParameters[paramIndex].selection = parameter.selection;
  component.allParameters = calculateParams(component, variables);

  component = multiplyQty(component);
  console.log({ component, parameter });
  component.price = calculatePrice(component);

  return {
    ...component,
  };
};

export const initializeComponentData = (product) => {
  let newProduct = _.cloneDeep(product);
  newProduct.flatSubcomponents = flattenSubcomponents(newProduct, [
    newProduct._id,
  ]);
  newProduct.allParameters = getAllParameters(newProduct);

  //   newProduct = multiplyQty(newProduct);
  return newProduct;
};
const initializeExistingProduct = (product, variables) => {
  let component = _.cloneDeep(product.component);
  if (!component) {
    return product;
  }
  if (!component._id) return product;
  console.log({ component, product });
  let flatSubcomponents = flattenSubcomponents(component, [component._id]);
  component.flatSubcomponents = flatSubcomponents;
  let allParameters = getAllParameters(component);
  component.allParameters = allParameters;
  component.options.forEach((o) => {
    let optionIndex = product.options.findIndex((p) => p.name === o.name);
    if (optionIndex === -1) {
      return;
    }
    console.log({ o, optionIndex, product });
    component = handleOptionUpdate({
      component: component,
      option: {
        ...o,
        choice: o.subcomponents.find(
          (s) => s.name === product.options[optionIndex].value
        ),
      },
      variables: variables,
    });
  });
  component.allParameters.forEach((p) => {
    console.log(p);
    p.formula = `${p.formula}`;
    if (p.formula?.includes(",")) {
      let subcomp = product.subcomponents.find(
        (s) => s.hierarchy.join("-") === p.hierarchy.join("-")
      );
      if (!subcomp) {
        subcomp = product;
      }

      component = handleParameterUpdate({
        component: component,
        parameter: {
          ...p,
          selection: `${
            subcomp.parameters.find((sp) => sp.name === p.name).value
          }`,
        },
        variables: variables,
      });
    }
  });
  component.id = product.id;
  component.price = calculatePrice(component);
  return component;
};

const flattenSubcomponents = (product, hierarchy) => {
  let flatSubcomponents = [];
  product.subcomponents.forEach((c) => {
    let h = [...hierarchy, c._id];
    let newParams = c.parameters.map((p) => {
      if (p.name === "qty") {
        return { ...p, hierarchy: [...h, p.hierarchy[1]] };
      } else {
        return { ...p, hierarchy: h };
      }
    });
    flatSubcomponents.push({ ...c, hierarchy: h, parameters: newParams });
    flatSubcomponents = flatSubcomponents.concat(flattenSubcomponents(c, h));
  });
  return flatSubcomponents;
};

const getAllParameters = (component) => {
  let product = _.cloneDeep(component);
  let parameters = [];
  product.parameters.forEach((p) => {
    if (p.name === "qty") {
      parameters.push({ ...p, hierarchy: [product._id, p.hierarchy[1]] });
    } else {
      parameters.push({ ...p, hierarchy: [product._id] });
    }
  });
  product.flatSubcomponents.forEach((c) => {
    parameters = parameters.concat(c.parameters);
  });
  product.parameterOverrides.forEach((p) => {
    parameters = parameters.map((param) => {
      if (
        param.name === p.name &&
        param.hierarchy.join("-") === p.hierarchy.join("-")
      ) {
        return { ...p };
      }
      return param;
    });
  });
  product.options.forEach((o) => {
    o.parameters.forEach((p) => {
      if (p.source) {
        o.sources.forEach((source) => {
          let sourceParam = parameters.find(
            (par) => par.hierarchy.join("-") === source && par.name === p.name
          );
          if (!sourceParam) {
            p.error = "Source parameter not found.";
            return;
          }
          sourceParam.sourceVariables
            ? sourceParam.sourceVariables.push(p.variable)
            : (sourceParam.sourceVariables = [p.variable]);
        });
      } else if (p.source === false && o.choice) {
        o.sources.forEach((source) => {
          let choiceParam = parameters.find(
            (par) =>
              par.hierarchy.join("-") ===
                `${source}-${o._id}-${o.choice._id}` && par.name === p.name
          );

          if (!choiceParam) {
            p.error = "Choice parameter not found.";
            return;
          }
          choiceParam.choiceVariables
            ? choiceParam.choiceVariables.push(p.variable)
            : (choiceParam.choiceVariables = [p.variable]);
        });
      }
    });
  });
  parameters = parameters.map((p) => {
    let found = component.allParameters?.find(
      (param) =>
        param.hierarchy.join("-") === p.hierarchy.join("-") &&
        param.name === p.name
    );
    if (found && found.selection) {
      return { ...p, selection: found.selection };
    }
    return p;
  });
  return parameters;
};

const removeChoice = ({ component, option }) => {
  let index = component.options.findIndex((o) => o._id === option._id);
  option.sources.forEach((source) => {
    let subcomp = _.cloneDeep(option.choice);
    console.log(source);
    if (source === "parent" || source.split("-").length === 1) {
      component.type = component.type?.filter((t) => t !== subcomp.name);
    } else {
      let sourceIndex = component.flatSubcomponents.findIndex(
        (s) =>
          s.hierarchy.join("-").replace("parent", component._id) ===
          source.replace("parent", component._id)
      );
      if (sourceIndex === -1) {
        return;
      }
      component.flatSubcomponents[sourceIndex].type =
        component.flatSubcomponents[sourceIndex].type?.filter(
          (t) => t !== subcomp.name
        ) || [];
    }
    subcomp.hierarchy = [...source.replace("parent", component._id).split("-")];
    subcomp.hierarchy.push(option._id);
    subcomp.hierarchy.push(subcomp._id);
    let subcompIndex = component.flatSubcomponents.findIndex(
      (s) => s.hierarchy.join("-") === subcomp.hierarchy.join("-")
    );
    component.flatSubcomponents.splice(subcompIndex, 1);

    // remove subcomponents of choice
    let id = subcomp.hierarchy;
    let componentsFromChoice = component.flatSubcomponents.filter((c) => {
      let nodeId = c.hierarchy;
      if (nodeId.length > id.length) {
        if (nodeId.slice(0, id.length).join("-") === id.join("-")) {
          return true;
        }
      } else {
        return false;
      }
    });
    console.log(componentsFromChoice);
    for (let i = 0; i < componentsFromChoice.length; i++) {
      let index = component.flatSubcomponents.findIndex(
        (c) => c._id === componentsFromChoice[i]._id
      );
      component.flatSubcomponents.splice(index, 1);
    }
    component.flatSubcomponents = component.flatSubcomponents.filter(
      (c) => !componentsFromChoice.includes(c)
    );
  });

  let newOption = { ...component.options[index] };
  delete newOption.choice;

  // remove choices of options that don't have sources inside flatSubcomponents anymore
  component.options = component.options.map((o) => ({
    ...o,
    choice: o.sources.some(
      (s) =>
        component.flatSubcomponents.find((c) => c.hierarchy.join("-") == s) ||
        s == component._id
    )
      ? o.choice
      : null,
  }));

  component.options[index] = newOption;

  console.log({ component, option });
  component.allParameters = getAllParameters(component);
  return component;
};

const handleOptionUpdate = ({ component, option, variables }) => {
  let index = component.options.findIndex((o) => o._id === option._id);
  if (component.options[index].choice) {
    component = removeChoice({ component, option: component.options[index] });
  }
  component.options[index] = option;

  option.sources.forEach((source) => {
    let subcomp = _.cloneDeep(option.choice);
    subcomp.hierarchy = [...source.split("-")];
    subcomp.hierarchy;
    subcomp.hierarchy.push(option._id);
    subcomp.hierarchy.push(subcomp._id);
    // assign type to subcomponent that owns the option
    let optionParentIndex = component.flatSubcomponents.findIndex(
      (s) =>
        s.hierarchy.join("-").replace("parent", component._id) ===
        source.replace("parent", component._id)
    );
    let optionParent = component.flatSubcomponents[optionParentIndex];
    if (source === "parent" || source.split("-").length === 1) {
      optionParent = { ...component };
    }
    console.log(optionParent);
    console.log(option);
    if (optionParent) {
      if (!optionParent.type) {
        optionParent.type = [];
      }
      optionParent.type = [...optionParent.type, subcomp.name];
      // !!! Here we should calculate the qty of the choice !!!
      let optionQtyParam = option.parameters.find(
        (p) =>
          p.name === "qty" &&
          p.hierarchy.length === 2 &&
          p.hierarchy[1] === subcomp._id
      );
      if (component.parameterOverrides) {
        console.log("HERE", component, subcomp, source);
        let hierarchyToFind = [component._id, option._id, subcomp._id].join(
          "-"
        );
        let override = component.parameterOverrides.find(
          (p) => p.name === "qty" && p.hierarchy.join("-") === hierarchyToFind
        );
        if (override) {
          optionQtyParam = { ...override };
        }
      }
      if (optionQtyParam) {
        subcomp.qty.value = optionQtyParam.value * optionParent.qty.value;
        subcomp.parameters.push({
          ...optionQtyParam,
          name: "qty",
          value: optionQtyParam.value * optionParent.qty.value,
          hierarchy: [...subcomp.hierarchy],
        });
      } else {
        subcomp.qty.value = optionParent.qty.value;
        subcomp.parameters.push({
          name: "qty",
          value: optionParent.qty.value,
          hierarchy: [...subcomp.hierarchy],
        });
      }
      // change hierarchy of qty params for its subcomponents to be equal to its new hierarchy
      subcomp.parameters = subcomp.parameters.map((param) => {
        if (param.name !== "qty") {
          return {
            ...param,
            hierarchy: [...subcomp.hierarchy],
          };
        } else {
          return { ...param };
        }
      });
      console.log(subcomp);
      //
      component.flatSubcomponents[optionParentIndex] = optionParent;
    }

    component.flatSubcomponents.push(subcomp);
    let flattened = flattenSubcomponents(subcomp, subcomp.hierarchy, component);
    component.flatSubcomponents = component.flatSubcomponents.concat(flattened);
  });

  component.allParameters = [...getAllParameters(component)];
  console.log(component);
  let allParameters = calculateParams(component, variables);
  component.allParameters = [...allParameters];

  component = multiplyQty(component);
  component.price = calculatePrice(component);

  return {
    ...component,
    options: component.options,
  };
};
const calculateParams = (component, variables) => {
  let allParams = _.cloneDeep(component.allParameters);
  let scope = new Map();
  let formulas = [];
  let normalParams = [];
  console.log({ allParams, variables });
  allParams.forEach((p) => {
    if (typeof p.formula === "string" && p.formula.trim() !== "") {
      formulas.push(p);
    } else if (p.variable) {
      console.log("no formula and pushing to scope:", p.variable, p.value);
      scope.set(p.variable, p.value);
      normalParams.push(p);
    } else {
      normalParams.push(p);
    }
  });
  variables.forEach((v) => scope.set(v.variable, v.value));
  let evaluatedFormulas = [];
  let errors = [];
  console.log({ allParams, formulas, scope, component });
  let repetitions = 0;
  while (formulas.length > 0) {
    formulas.forEach((formula, index) => {
      let variable = formula.variable;
      let expression = formula.formula.trim();
      let localScope = new Map(scope);
      allParams.forEach((param) => {
        if (
          param.sourceVariables?.length &&
          param.hierarchy.at(-1) == formula.hierarchy.at(-3)
        ) {
          let sourceVars = [...new Set(param.sourceVariables)];
          sourceVars.forEach((v) => {
            if (param.formula) {
              try {
                localScope.set(v, limitedEvaluate(param.formula, scope));
              } catch {
                console.log("skipping");
              }
            } else {
              localScope.set(v, param.value);
            }
          });
          // here we check if the parameter is a choice parameter and if it's hierarchy is one level deeper than the formula's hierarchy
        } else if (
          param.choiceVariables?.length &&
          param.hierarchy.length == formula.hierarchy.length + 2 &&
          param.hierarchy
            .join("-")
            .replace("parent", component._id)
            .startsWith(
              formula.hierarchy.join("-").replace("parent", component._id)
            )
        ) {
          console.log("getting here");
          param.choiceVariables.forEach((v) => {
            if (param.formula) {
              try {
                scope.set(v, limitedEvaluate(param.formula, localScope));
              } catch {
                console.log("skipping");
              }
            } else {
              console.log("setting", v, param.value);
              scope.set(v, param.value);
            }
          });
        }
      });
      try {
        // Use limitedEvaluate to evaluate expressions within a restricted scope
        let result;

        // first, check that if it's a parameter that requires a selection from user, that the selection is made
        if (expression.includes(",")) {
          if (!formula.selection) {
            result = expression
              .split(",")
              .map((v) => limitedEvaluate(v, localScope))[0];
          } else {
            result = limitedEvaluate(formula.selection, localScope);
          }
        } else if (expression.includes("::")) {
          let conditions = expression.split("::")[0].split("||");
          let isTrue = false;
          conditions.forEach((c) => {
            let [ifCond, thenCond] = c.split(":");
            if (limitedEvaluate(ifCond, localScope)) {
              isTrue = true;
              result = limitedEvaluate(thenCond, localScope);
            }
          });
          if (!isTrue) {
            result = limitedEvaluate(expression.split("::")[1], localScope);
          }
        } else {
          result = limitedEvaluate(expression, localScope);
        }

        formula.value = result;
        if (variable) {
          scope.set(variable, result);
        }
        console.log("result", result, scope);
        evaluatedFormulas.push(formula);
        formulas.splice(index, 1);
      } catch (error) {
        repetitions++;
        console.log(error, scope);
        if (repetitions > 50) {
          errors.push({ formula, error: "Too many repetitions" });
          formulas.splice(index, 1);
          repetitions = 0;
        }
        // formulas.splice(index, 1);
        // Variable in formula not yet defined
      }
    });
  }
  console.log(scope);

  //   for (let i = 0; i < evaluatedFormulas.length; i++) {
  //     let formula = evaluatedFormulas[i];
  //     if (formula.name === "qty" && formula.formula) {
  //       let parent = [...evaluatedFormulas, ...normalParams].find(
  //         (p) =>
  //           p.hierarchy.join("-") ===
  //             formula.hierarchy
  //               .slice(0, formula.hierarchy.length - 1)
  //               .join("-") && p.name === "qty"
  //       );
  //       if (!parent) {
  //         parent = [...evaluatedFormulas, ...normalParams].find(
  //           (p) =>
  //             p.hierarchy.join("-") ===
  //               formula.hierarchy
  //                 .slice(0, formula.hierarchy.length - 2)
  //                 .join("-") && p.name === "qty"
  //         );
  //       }
  //       console.log({ parent, formula, evaluatedFormulas, normalParams });
  //       if (parent) {
  //         formula.value = parent.value * formula.value;
  //       }
  //     }
  //   }

  console.log(errors);
  console.log(evaluatedFormulas);
  console.log({ evaluatedFormulas, normalParams });
  return [...evaluatedFormulas, ...normalParams, ...formulas];
};

const multiplyQty = (component) => {
  let qtyParameters = _.cloneDeep(
    component.allParameters
      .filter((p) => p.name === "qty")
      .sort((a, b) => a.hierarchy.length - b.hierarchy.length)
  );
  console.log(qtyParameters);
  qtyParameters.forEach((param) => {
    let children = qtyParameters.filter(
      (p) =>
        p.hierarchy.join("-").startsWith(param.hierarchy.join("-")) &&
        p.hierarchy.length > param.hierarchy.length
    );
    console.log({ param, children });
    children.forEach((child) => {
      child.value = param.value * child.value;
    });
  });
  component.allParameters = component.allParameters.map((p) => {
    if (p.name === "qty") {
      return qtyParameters.find(
        (param) => param.hierarchy.join("-") === p.hierarchy.join("-")
      );
    }
    return p;
  });
  console.log(component);
  return component;
};
const calculatePrice = (component) => {
  if (!component.priceModifier) {
    return component.price;
  } else {
    let price = component.flatSubcomponents.reduce((acc, subcomp) => {
      let qtyParam = component.allParameters.find(
        (p) =>
          p.name === "qty" &&
          p.hierarchy.join("-") == subcomp.hierarchy.join("-")
      );
      let qty = qtyParam ? qtyParam.value : 1;
      console.log({
        subcomp,
        qtyParam,
        allParameters: component.allParameters,
        component,
      });
      let cost = subcomp.cost || 0;

      let total = cost * qty;

      return acc + total;
    }, 0);
    return Number.parseFloat((price * component.priceModifier).toFixed(2));
  }
};
