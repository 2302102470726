// my store
// this is the screen for the store of the user
// here you can add/edit products, categories, and other organization of the store
// description of the store and what it sells
// how orders are handled
// maybe view orders here instead of in its own tab
// preview the store
//

import React from "react";
import { Orders } from "./Orders";
import { MyProducts } from "./MyProducts";

export const MyStore = () => {
  return (
    <div>
      My Store
      <Orders />
      <MyProducts />
    </div>
  );
};
