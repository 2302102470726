import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";

export const ProjectValueDetails = ({ project }) => {
  const [zones] = React.useState(project.zones);
  //   const [zonePrices, setZonePrices] = React.useState([])
  const products = project.importData?.products || project.products;
  console.log(project);
  let zonePrices = [];
  zones.map((zone) => {
    let zoneTotal = 0;
    products.map((product) => {
      product.zone === zone ? (zoneTotal += parseFloat(product.price)) : null;
    });
    zonePrices.push({ zone: zone, total: zoneTotal });
  });
  let noZoneTotal = 0;
  products.map((product) => {
    if (product.zone === "") {
      noZoneTotal += parseFloat(product.price);
    }
  });
  zonePrices.push({ zone: "No Zone", total: noZoneTotal });
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return (
    <>
      {zonePrices.map((zone) => (
        <>
          <Grid container justifyContent="space-between">
            <Typography> {zone.zone}:</Typography>

            <Typography>{formatter.format(zone.total)}</Typography>
          </Grid>
        </>
      ))}
    </>
  );
};

ProjectValueDetails.propTypes = {
  project: PropTypes.object,
};
