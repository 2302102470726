import * as React from "react";
import PropTypes from "prop-types";
import { Box, Button, Collapse, IconButton } from "@mui/material";
import { TransitionGroup } from "react-transition-group";
import Clear from "@mui/icons-material/Clear";
import { ConditionField } from "./ConditionField";

export const Conditions = ({ localValue, setLocalValue, checkFormula }) => {
  const [conditions, setConditions] = React.useState(
    checkForConditions(localValue || "::1")
  );

  const handleConditionChange = (field, value, index) => {
    setConditions((prev) => {
      let newC = [...prev];
      if (value.includes("/")) {
        value = value.replaceAll("/", "÷");
      }
      newC[index][field] = value;

      return newC;
    });
  };

  const addOr = () => {
    setConditions((prev) => [
      ...prev,
      { name: `C${prev.length}`, if: "", then: "", isTrue: false },
    ]);
  };

  const deleteCondition = (i) => {
    setConditions((prev) => prev.filter((c, index) => index !== i));
  };

  React.useEffect(() => {
    let parsedConditions = [];
    conditions.forEach((c) => {
      if (c.if !== "" && c.then !== "") {
        parsedConditions.push(`${c.if}:${c.then}`);
      }
    });
    setLocalValue(parsedConditions.join("||"));
  }, [conditions]);

  React.useEffect(() => {
    setConditions(checkForConditions(localValue));
  }, [localValue]);
  const [error, setError] = React.useState([{ i: null, field: "", error: "" }]);

  return (
    <Box sx={{ width: "100%", mb: 1, pl: 1, pr: 1 }}>
      <TransitionGroup>
        {conditions.map((condition, i) => {
          return (
            <Collapse key={condition.name} sx={{ width: "100%", mt: 1 }}>
              <Box key={condition}>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Box sx={{ flex: 1 }}>
                    <ConditionField
                      label="If"
                      userInput={condition.if}
                      setUserInput={(value) =>
                        handleConditionChange("if", value, i)
                      }
                      error={error}
                      setError={setError}
                      checkFormula={checkFormula}
                      fieldId={`if${i}`}
                      fieldType="if"
                    />
                  </Box>
                  <Box sx={{ flex: 0.91 }}>
                    <ConditionField
                      label="Then"
                      userInput={condition.then}
                      setUserInput={(value) =>
                        handleConditionChange("then", value, i)
                      }
                      error={error}
                      setError={setError}
                      checkFormula={checkFormula}
                      fieldId={`then${i}`}
                      fieldType="then"
                    />
                  </Box>
                  <Box sx={{ flex: 0.07 }}>
                    <IconButton
                      disabled={i == 0}
                      onClick={() => deleteCondition(i)}
                      color={condition.isTrue ? "success" : "error"}
                    >
                      <Clear />
                    </IconButton>
                  </Box>
                </Box>

                <Box sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
                  {i !== conditions.length - 1 ? (
                    "OR"
                  ) : (
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() => addOr()}
                    >
                      Or
                    </Button>
                  )}
                </Box>
              </Box>
            </Collapse>
          );
        })}
      </TransitionGroup>
    </Box>
  );
};
Conditions.propTypes = {
  component: PropTypes.object.isRequired,
  localValue: PropTypes.string.isRequired,
  setLocalValue: PropTypes.func.isRequired,
  checkFormula: PropTypes.func.isRequired,
  allParams: PropTypes.array.isRequired,
  error: PropTypes.object,
  setError: PropTypes.func,
  param: PropTypes.object,
};
const checkForConditions = (localValue) => {
  let newConditions = [
    {
      name: "C0",
      if: "",
      then: "",
      isTrue: false,
    },
  ];
  let conditions = localValue.split("::")[0];

  if (conditions) {
    newConditions = [];
    let conds = conditions.split("||");
    conds.map((c, i) =>
      newConditions.push({
        name: `C${i}`,
        if: c.split(":")[0],
        then: c.split(":")[1],
        isTrue: false,
      })
    );
  }

  return newConditions;
};
