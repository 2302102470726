import { useQueryClient } from "react-query";

export const useCachedSettings = () => {
  const queryClient = useQueryClient();

  // Fetch all projects and filter out undefined
  const settings = queryClient.getQueryData("settings");

  return settings;
};
