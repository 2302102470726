import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { useSupplierUpdate } from "../../supplier/hooks/useSupplierUpdate";
import { useComponentsUpdateMany } from "../../../hooks/useComponentsUpdateMany";

export const AdjustInventory = ({ selectedItems }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [supplied, setSupplied] = React.useState(
    selectedItems
      .filter((item) => item.suppliers?.length > 0)
      .map((item) => ({
        ...item,
        toAdd: 0,
        newTotal: item.stock,
        selectedSupplier: item.suppliers[0],
      }))
  );
  const [manufactured, setManufactured] = React.useState(
    selectedItems
      .filter((item) => item.suppliers?.length == 0)
      .map((item) => ({
        ...item,
        toAdd: 0,
        newTotal: item.stock,
      }))
  );

  React.useEffect(() => {
    setSupplied(
      selectedItems
        .filter((item) => item.suppliers?.length > 0)
        .map((item) => ({
          ...item,
          toAdd: 0,
          newTotal: item.stock,
          selectedSupplier: item.suppliers[0],
        }))
    );
    setManufactured(
      selectedItems
        .filter((item) => item.suppliers?.length == 0)
        .map((item) => ({
          ...item,
          toAdd: 0,
          newTotal: item.stock,
        }))
    );
  }, [selectedItems]);

  const handleSuppliedChange = (value, item, field) => {
    if (field === "toAdd") {
      setSupplied((prev) =>
        prev.map((i) => {
          if (i._id === item._id) {
            return {
              ...i,
              toAdd: value,
              newTotal: Number(value) + Number(i.stock),
            };
          } else {
            return i;
          }
        })
      );
    } else if (field === "newTotal") {
      setSupplied((prev) =>
        prev.map((i) => {
          if (i._id === item._id) {
            return {
              ...i,
              toAdd: Number(value) - Number(i.stock),
              newTotal: value,
            };
          } else {
            return i;
          }
        })
      );
    } else if (field === "selectedSupplier") {
      setSupplied((prev) =>
        prev.map((i) => {
          if (i._id === item._id) {
            return {
              ...i,
              selectedSupplier: value,
            };
          } else {
            return i;
          }
        })
      );
    }
  };

  const handleManufacturedChange = (value, item, field) => {
    if (field === "toAdd") {
      setManufactured((prev) =>
        prev.map((i) => {
          if (i._id === item._id) {
            return {
              ...i,
              toAdd: value,
              newTotal: Number(value) + Number(i.stock),
            };
          } else {
            return i;
          }
        })
      );
    } else if (field === "newTotal") {
      setManufactured((prev) =>
        prev.map((i) => {
          if (i._id === item._id) {
            return {
              ...i,
              toAdd: Number(value) - Number(i.stock),
              newTotal: value,
            };
          } else {
            return i;
          }
        })
      );
    }
  };

  const { mutate: updateSupplier } = useSupplierUpdate();
  const { mutate: updateManyComponents } =
    useComponentsUpdateMany("Inventory updated.");

  const handleSave = () => {
    let suppliers = [];
    supplied.forEach((item) => {
      if (!suppliers.includes(item.selectedSupplier.supplier._id)) {
        suppliers.push(item.selectedSupplier.supplier._id);
      }
    });
    let packs = [];
    let componentPack = [];

    suppliers.forEach((supplier) => {
      packs.push({
        id: supplier,
        orders: {
          date: new Date(),
          status: "Created",
          items: supplied
            .filter((item) => item.selectedSupplier.supplier._id === supplier)
            .map((item) => ({
              item: item._id,
              code: item.selectedSupplier.code,
              qty: item.toAdd,
            })),
        },
      });
    });
    manufactured.forEach((item) => {
      componentPack.push({
        _id: item._id,
        stock: item.toAdd,
      });
    });
    updateManyComponents({ pack: componentPack });
    packs.forEach((pack) => updateSupplier({ pack }));

    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title={
          selectedItems.length === 0 ? "Select items to adjust first." : null
        }
      >
        <Box sx={{ width: "fit-content" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickOpen}
            disabled={selectedItems.length === 0}
          >
            Adjust Inventory
          </Button>
        </Box>
      </Tooltip>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <Paper>
          <DialogTitle>Adjust Inventory</DialogTitle>

          <DialogContent>
            <Typography variant="body">
              Inventory items that have a supplier defined are on the left, and
              orders will be created in the respective supplier&apos;s page.
              Manufactured items are simply added to inventory. You can edit
              either how much you wish to add, or the new total.
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                gap: 2,
                mt: 3,
              }}
            >
              {supplied && (
                <Paper sx={{ width: "55%", p: 1 }} elevation={0}>
                  <Typography variant="h6">Supplied</Typography>
                  {supplied.map((item) => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 1,
                      }}
                      key={item._id}
                    >
                      <p>{item.name}</p>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <TextField
                          size="small"
                          sx={{ width: 80 }}
                          label="Stock"
                          value={item.stock}
                          disabled
                        />

                        <TextField
                          size="small"
                          sx={{ width: 80 }}
                          label="To Add"
                          value={item.toAdd}
                          onChange={(e) =>
                            handleSuppliedChange(e.target.value, item, "toAdd")
                          }
                        />
                        <TextField
                          size="small"
                          sx={{ width: 80 }}
                          label="New Total"
                          value={item.newTotal}
                          onChange={(e) =>
                            handleSuppliedChange(
                              e.target.value,
                              item,
                              "newTotal"
                            )
                          }
                        />
                        <FormControl size="small">
                          <InputLabel>Supplier</InputLabel>
                          <Select
                            value={item.selectedSupplier}
                            size="small"
                            label="Supplier"
                            renderValue={(value) => value.supplier.name}
                            onChange={(e) =>
                              handleSuppliedChange(
                                e.target.value,
                                item,
                                "selectedSupplier"
                              )
                            }
                          >
                            {item.suppliers.map((supplier) => (
                              <MenuItem key={supplier._id} value={supplier}>
                                {supplier.supplier.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                  ))}
                </Paper>
              )}
              {manufactured && (
                <Paper sx={{ width: "45%", p: 1 }} elevation={0}>
                  <Typography variant="h6">Manufactured</Typography>
                  {manufactured.map((item) => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 1,
                      }}
                      key={item._id}
                    >
                      <p>{item.name}</p>
                      <Box sx={{ display: "flex", gap: 1 }}>
                        <TextField
                          size="small"
                          sx={{ width: 80 }}
                          label="Stock"
                          value={item.stock}
                          disabled
                        />

                        <TextField
                          size="small"
                          sx={{ width: 80 }}
                          label="To Add"
                          value={item.toAdd}
                          onChange={(e) =>
                            handleManufacturedChange(
                              e.target.value,
                              item,
                              "toAdd"
                            )
                          }
                        />
                        <TextField
                          size="small"
                          sx={{ width: 80 }}
                          label="New Total"
                          value={item.newTotal}
                          onChange={(e) =>
                            handleManufacturedChange(
                              e.target.value,
                              item,
                              "newTotal"
                            )
                          }
                        />
                      </Box>
                    </Box>
                  ))}
                </Paper>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)} variant="outlined">
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSave}>
              Save
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </>
  );
};

AdjustInventory.propTypes = {
  selectedItems: PropTypes.array,
};
