import React from "react";
// import { ProductContext } from "./ProductCard";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import PropTypes from "prop-types";
import { SelectedProjectContext } from "../../ProjectView";
import { Button, Dialog, Paper, Typography } from "@mui/material";
import { Construction } from "@mui/icons-material";

// const columns = [
//   {
//     field: "qty",
//     headerName: "Qty",
//     flex: 1,
//   },
//   {
//     field: "type",
//     headerName: "Type",
//     flex: 1,
//   },
// ];

export const Components = () => {
  const project = React.useContext(SelectedProjectContext);

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  let { rows, columns } = formatDataForTable(project.components);

  const [sortModel, setSortModel] = React.useState([
    {
      field: "name",
      sort: "asc",
    },
  ]);

  //   React.useEffect(() => {
  //     setData(makeRows(product));
  //   }, [product]);

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setOpen(true);
        }}
        startIcon={<Construction />}
      >
        Components
      </Button>

      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <Paper sx={{}}>
          <DataGridPremium
            sx={{ height: 500 }}
            defaultGroupingExpansionDepth={10}
            rows={rows}
            density="compact"
            getRowId={(row) => rows.indexOf(row)}
            columns={columns}
            groupingColDef={{ minWidth: 300 }}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            // treeData
            // getTreeDataPath={(row) => {
            //   return row.hierarchy;
            // }}
            slots={{
              noRowsOverlay: () => (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    align={"center"}
                    sx={{ width: "45%" }}
                  >
                    {" "}
                    Here you can see all the components that will be needed for
                    this project.
                  </Typography>
                </div>
              ),
            }}
          />
        </Paper>
      </Dialog>
    </div>
  );
};

Components.propTypes = {
  zone: PropTypes.string,
};

function formatDataForTable(components) {
  const rows = [];
  let columns = [
    { field: "qty", headerName: "Qty", flex: 0.5, type: "number" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
    },
  ];
  // Iterate through the components array
  components.forEach((component) => {
    const formattedComponent = {
      name: component.name,
    };
    // Add parameters to the formatted component
    if (component.parameters) {
      component.parameters.forEach((parameter) => {
        formattedComponent[parameter.name] = parameter.value;
        if (columns.find((c) => c.field === parameter.name)) return;
        columns.push({
          field: parameter.name,
          headerName: parameter.name,
          flex: 0.5,
        });
      });
    }

    // Add options to the formatted component
    if (component.options) {
      component.options.forEach((option) => {
        formattedComponent[option.name] = option.value;
      });
    }

    rows.push(formattedComponent);
  });

  // Iterate through the rows to find all the unique columns
  rows.forEach((row) => {
    Object.keys(row).forEach((key) => {
      if (!columns.find((column) => column.field === key)) {
        if (key == "qty") return;
        columns.push({
          field: key,
          headerName: key,
          flex: 1,
        });
      }
    });
  });

  return { rows: mergeIdenticalRowQty(rows), columns };
}

export const mergeIdenticalRowQty = (rows) => {
  let newRows = [];
  rows.forEach((row) => {
    // let existingRow = newRows.find((r) => r.name === row.name);
    let existingRow = newRows.find((r) => {
      for (let key in row) {
        if (key !== "qty" && r[key] !== row[key]) {
          return false;
        }
      }
      return true;
    });
    if (existingRow) {
      existingRow.qty += row.qty;
    } else {
      newRows.push(row);
    }
  });
  return newRows;
};

// const makeRows = (component) => {
//   let idsAndNames = new Map();
//   let subcomponents = [...component.flatSubcomponents];
//   subcomponents.forEach((subcomponent) => {
//     idsAndNames.set(subcomponent._id, subcomponent.name);
//   });

//   let rows = [];
//   subcomponents.forEach((subcomponent) => {
//     let row = {
//       id: subcomponent._id,
//       name: subcomponent.name,
//       hierarchy: subcomponent.hierarchy,
//       type: subcomponent.type,
//     };

//     let parameters = component.allParameters.filter(
//       (p) => p.hierarchy.join("-") === subcomponent.hierarchy.join("-")
//     );

//     parameters?.forEach((p) => {
//       row[p.name] = p.value;
//     });

//     row.hierarchy = row.hierarchy
//       .map((h) => {
//         let name = idsAndNames.get(h);
//         return name;
//       })
//       .filter((h) => h);

//     rows.push(row);
//   });

//   let columns = [];
//   rows.map((r) => {
//     let rowCols = Object.keys(r);
//     rowCols.map((c) => {
//       if (
//         !columns.find((col) => col.field === c) &&
//         c !== "id" &&
//         c !== "hierarchy" &&
//         c !== "name"
//       ) {
//         c == "name"
//           ? columns.push({ field: c, headerName: c, flex: 2 })
//           : columns.push({ field: c, headerName: c, flex: 1 });
//       }
//     });
//     r.parameters?.map((p) => {
//       if (!columns.find((col) => col.field === p.name)) {
//         columns.push({ field: p.name, headerName: p.name, flex: 1 });
//       }
//     });
//   });
//   console.log({ rows, columns: columns.sort((a, b) => a.field - b.field) });
//   return { rows, columns };
// };
