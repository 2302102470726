import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { UserContext } from "../../../App";
import { SnackbarContext } from "../../../Content";
import { updateFinancial } from "../../../api/financialApi";

export function useFinancialUpdate() {
  const { user } = React.useContext(UserContext);
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  return useMutation({
    mutationFn: ({ pack }) => {
      pack.user = user.id;
      let formData = new FormData();
      delete pack.file;
      delete pack.open;
      for (let [key, value] of Object.entries(pack)) {
        formData.append(key, key == "file" ? value : JSON.stringify(value));
      }

      return updateFinancial({ id: pack._id, pack: formData });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("financial");
      setSnack({
        open: true,
        message: "Entry updated.",
        severity: "success",
      });
    },
    onError: () => {
      queryClient.invalidateQueries("financial");
      setSnack({
        open: true,
        message: "Entry could not be updated.",
        severity: "error",
      });
    },
  });
}
