import { Box, Paper, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useFinancialData } from "../hooks/useFinancialData";
import { ViewFinancial } from "./ViewFinancial";
import { FinancialGrid } from "../utils/FinancialGrid";
import { ENTRY_TYPES } from "../utils/entryTypes";
import { NoFinancialsOverlay } from "../utils/NoFinancialsOverlay";
import { Warning } from "@mui/icons-material";

// const formatter = new Intl.DateTimeFormat("en-US");
const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const FinancialTable = () => {
  const columns = [
    {
      headerName: "Date",
      field: "date",
      flex: 1,
      valueFormatter: (params) => {
        let date = new Date(params.value);
        date = date.toLocaleDateString("en-US", {
          timeZone: "UTC",
        });
        return date;
      },
    },
    { headerName: "Type", field: "type", flex: 1 },
    { headerName: "Memo", field: "memo", flex: 1 },
    { headerName: "Status", field: "status", flex: 1 },
    { headerName: "Name", field: "name", flex: 1 },
    {
      headerName: "Amount",
      field: "amount",
      flex: 1,
      valueFormatter: (params) => {
        if (params.value.toString().startsWith("-")) {
          return `(${currencyFormatter.format(params.value)})`;
        } else {
          return currencyFormatter.format(params.value);
        }
      },
      renderCell: (params) => {
        let value = params.value;
        if (params.value.toString().startsWith("-")) {
          value = `(${currencyFormatter.format(params.value)})`;
        } else {
          value = currencyFormatter.format(params.value);
        }
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography>{value}</Typography>
            {params.row.dueDate &&
            new Date(params.row.dueDate).setHours(0, 0, 0, 0) <
              new Date().setHours(0, 0, 0, 0) &&
            params.row.status !== "paid" ? (
              <Tooltip title="Overdue!">
                <Warning color="warning" sx={{ marginLeft: "5px" }} />
              </Tooltip>
            ) : null}
          </Box>
        );
      },
    },
  ];
  const { data: financial } = useFinancialData();
  const [entry, setEntry] = React.useState({ open: false });

  if (!financial) return null;
  return (
    <Paper id="financial-table" sx={{ height: "100%" }}>
      <FinancialGrid
        getRowClassName={(params) => `super-app-theme--${parseRow(params)}`}
        density="compact"
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: "date", sort: "desc" }],
          },
        }}
        disableRowSelectionOnClick
        rows={financial}
        onRowClick={(params) => {
          setEntry({ ...params.row, open: true });
        }}
        components={{
          NoRowsOverlay: NoFinancialsOverlay,
        }}
        getRowId={(row) => row._id}
      />
      <ViewFinancial entry={entry} />
    </Paper>
  );
};

const parseRow = (params) => {
  if (!params.row) return;
  const className = ENTRY_TYPES.find(
    (type) => type.value === params.row.type
  )?.className;
  if (params.row.plinkStatus) {
    if (params.row.plinkStatus == "paid") {
      return "income";
    } else if (params.row.plinkStatus == "created") {
      return "futureIncome";
    }
  }
  if (params.row.stripeInvoiceStatus) {
    if (params.row.stripeInvoiceStatus == "paid") {
      return "income";
    } else if (params.row.stripeInvoiceStatus == "open") {
      return "futureIncome";
    }
  }
  if (className === "expense") {
    return new Date(params.row.date) > new Date() ? "futureExpense" : "expense";
  } else if (className === "income") {
    return new Date(params.row.date) > new Date() ? "futureIncome" : "income";
  } else if (className === "transfer") {
    return "transfer";
  } else {
    return "other";
  }
};
