import React from "react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { AddCatalog } from "./AddCatalog";
import { useCatalogsData } from "../hooks/useCatalogsData";
import { Catalog } from "./Catalog";
import StyleIcon from "@mui/icons-material/Style";

export const ViewCatalogs = () => {
  const [open, setOpen] = React.useState(false);
  const { data: catalogs } = useCatalogsData();
  console.log(catalogs);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        color="primary"
        startIcon={<StyleIcon />}
      >
        Catalogs
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          Catalogs
          <AddCatalog />
        </DialogTitle>
        <DialogContent>
          {catalogs?.map((catalog) => (
            <Catalog key={catalog._id} catalog={catalog} />
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};
