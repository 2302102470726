import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../../../Content";
import { deleteFinancial } from "../../../api/financialApi";

export function useFinancialDelete() {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  return useMutation({
    mutationFn: ({ pack }) => {
      return deleteFinancial(pack);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("financial");
      setSnack({
        open: true,
        message: "Entry deleted.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Entry could not be deleted.",
        severity: "error",
      });
    },
  });
}
