import { Button, Dialog, DialogActions, Paper } from "@mui/material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import React from "react";
import { variables } from "./editorModules/variables";
import Draggable from "react-draggable";

function PaperComponent(props) {
  return <Paper {...props} style={{ margin: 0, maxHeight: "100%" }} />;
}

export const VariableView = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen((prev) => !prev);
  };
  const onClick = (value) => {
    if (value.row.id == undefined) return;
    navigator.clipboard.writeText(value.row.id);
  };

  const columns = [{ field: "id", headerName: "ID", flex: 1 }];
  let rows = [];

  for (const [key, value] of Object.entries(variables)) {
    value.map((v) => {
      rows.push({ hierarchy: [key, v.name], id: v.id, name: v.name });
    });
  }
  return (
    <>
      <Button
        id="template-variables"
        variant="contained"
        onClick={() => handleOpen()}
      >
        Variables
      </Button>
      <Draggable
        handle={'[class*="MuiDialog-root"]'}
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Dialog
          open={open}
          hideBackdrop
          id="variablesDialog"
          disableEnforceFocus
          disableBackdropClick
          disableScrollLock
          PaperComponent={PaperComponent}
          sx={{
            top: "30%", // Position however you like
            left: "30%",
            height: "fit-content", // Ensures that the dialog is
            width: "fit-content", // exactly the same size as its contents
          }}
        >
          <DialogActions>
            <Button variant="contained" onClick={() => handleOpen()}>
              Close
            </Button>
          </DialogActions>
          <Paper sx={{ height: "100%" }}>
            <DataGridPremium
              rows={rows}
              columns={columns}
              treeData
              getTreeDataPath={(node) => node.hierarchy}
              defaultGroupingExpansionDepth={1}
              groupingColDef={{
                field: "hierarchy",
                flex: 1,
                headerName: "Name",
              }}
              density="compact"
              sx={{ width: 500, height: 900 }}
              onRowClick={(e) => onClick(e)}
            />
          </Paper>
        </Dialog>
      </Draggable>
    </>
  );
};
