import { Button, Tooltip } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

export const CustomIconButton = (props) => {
  const { title, tooltipPosition, ...rest } = props;
  return (
    <Tooltip title={title ? title : null} placement={tooltipPosition} arrow>
      <Button
        {...rest}
        size="small"
        sx={{ pl: 1, pr: 1, minWidth: "40px", maxWidth: "40px", ...props.sx }}
      >
        {props.icon}
      </Button>
    </Tooltip>
  );
};

CustomIconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  sx: PropTypes.object,
  title: PropTypes.string,
  tooltipPosition: PropTypes.string,
};
