import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../../../Content";
import { updateFile } from "../../../api/fileApi";

export function useFileUpdate() {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  return useMutation({
    mutationFn: ({ id, pack }) => {
      return updateFile({ id, pack });
    },
    onSuccess: () => {
      queryClient.invalidateQueries("files");
      setSnack({
        open: true,
        message: "File updated.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "File could not be updated.",
        severity: "error",
      });
    },
  });
}
