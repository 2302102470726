import React from "react";

import { useProjectsData } from "../hooks/useProjectsData";
import { CustomCalendar } from "../features/calendar/components/Calendar";
import dayjs from "dayjs";

export const Calendar = () => {
  const { data: projects } = useProjectsData();

  if (!projects) return null;
  let events = [];

  projects.forEach((project) => {
    // events.push({
    //   title: project.projectName,
    //   start: dayjs(project.creationDate).toDate(),
    //   end: dayjs(project.creationDate).add(1, "day").toDate(),
    //   allDay: true,
    //   type: "project",
    // });
    project.tasks.forEach((task) => {
      if (task.startDate && task.endDate)
        events.push({
          title: task.name,
          start: dayjs(task.startDate).toDate(),
          end: dayjs(task.endDate).toDate(),
          allDay: true,
          milestone: task.milestone,
          project: project.projectName,
          color: project.color,
          fontColor: getContrastYIQ(project.color),
          assignedTo: task.assignedTo,
          completed: task.completed,
          extendedProps: {
            color: project.color,
          },
        });
    });
  });

  return (
    <div>
      <CustomCalendar events={events} />
    </div>
  );
};

function getContrastYIQ(hexcolor) {
  // Convert hex color to RGB
  var r = parseInt(hexcolor.substr(1, 2), 16);
  var g = parseInt(hexcolor.substr(3, 2), 16);
  var b = parseInt(hexcolor.substr(5, 2), 16);

  // Calculate YIQ (brightness) value
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;

  // Return 'black' or 'white' based on the YIQ value
  return yiq >= 128 ? "black" : "white";
}
