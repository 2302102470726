import { Grid, Button, Typography, Paper, Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { UserContext } from "../App";
import { columns, endpoints, titles } from "../components/common/utils";
import { NewItemDialog } from "../components/common/itemList";
import { ViewOrders } from "../features/supplier/components/ViewOrders";
import { NoSuppliersOverlay } from "../features/supplier/utils/NoSuppliersOverlay";
import { useSuppliersData } from "../hooks/useSuppliersData";
import { AddCircle } from "@mui/icons-material";
import { HelpContext } from "../Content";
import { steps } from "../utils/introSteps";

export const Suppliers = ({ options }) => {
  const user = React.useContext(UserContext);

  const [dialogOpen, setDialogOpen] = React.useState(
    options.createNew ? true : false
  );
  const { setHelp } = React.useContext(HelpContext);
  const [selectedSupplier, setSelectedSupplier] = React.useState();
  const { data: suppliers } = useSuppliersData();

  const handleSelect = (row) => {
    setSelectedSupplier(row);
    if (row) {
      setHelp(steps["ViewSupplier"]);
    } else {
      setHelp(steps["Suppliers"]);
    }
  };
  React.useEffect(() => {
    if (selectedSupplier) {
      setSelectedSupplier((prev) => suppliers.find((s) => s._id === prev._id));
    }
  }, [suppliers]);
  if (!suppliers) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography variant="h3">Suppliers </Typography>
          <Button
            id="suppliers-new"
            color="primary"
            variant="contained"
            startIcon={<AddCircle />}
            onClick={() => setDialogOpen(true)}
          >
            New Supplier
          </Button>
          <NewItemDialog
            title={titles["suppliers"]}
            isOpen={dialogOpen}
            setOpen={setDialogOpen}
            fields={columns["suppliers"]}
            updateTable={"suppliers"}
            ENDPOINT={endpoints["suppliers"]}
            user={user}
          />
        </Box>
      </Box>

      <Grid
        container
        justifyContent="center"
        rowSpacing={2}
        columnSpacing={2}
        sx={{ width: "100%", height: "80%" }}
      >
        <Grid item xs={12}>
          <Paper
            id="suppliers-table"
            sx={{
              height: selectedSupplier ? 110 : "100%",
              display: "flex",
              flexFlow: "column",
              position: "relative",
              mb: 1,
            }}
            maxHeight
          >
            <div
              style={{
                height: selectedSupplier ? 110 : "100%",
                display: selectedSupplier ? "none" : "flex",
                flexFlow: "column",
                position: "relative",
              }}
            >
              <DataGridPremium
                onRowClick={(params) => handleSelect(params.row)}
                columns={columns.suppliers}
                rows={suppliers}
                disableSelection
                components={{
                  NoRowsOverlay: NoSuppliersOverlay,
                }}
                getRowId={(row) => row._id}
              />
              {/* <VBXDataGrid
                typeKey={dataTypeKey}
                user={user}
                selectionModel={selectedSupplier}
                disableSelection={true}
                onSelectionModelChange={handleSelect}
              /> */}
            </div>
            {selectedSupplier ? (
              <>
                <DataGridPremium
                  columns={columns["suppliers"]}
                  rows={[selectedSupplier]}
                  getRowId={(row) => row._id}
                  onRowClick={() => handleSelect()}
                  hideFooter
                />
              </>
            ) : null}
          </Paper>
          {selectedSupplier ? (
            <Paper>
              <div>
                <ViewOrders supplier={selectedSupplier} />
              </div>
            </Paper>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
};
Suppliers.propTypes = {
  user: PropTypes.string,
  options: PropTypes.object,
};
