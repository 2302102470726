import React from "react";
import PropTypes from "prop-types";
import { Paper } from "@mui/material";
import { Subcomponents } from "./Subcomponents";
import { Options } from "./Options";
import { useProduct } from "../hooks/useProduct";
import { ParameterSelect } from "./ParameterSelect";

export const ProductContext = React.createContext({
  product: {},
  handleEdit: () => {},
});

export const ProductCard = ({ product }) => {
  // eslint-disable-next-line no-unused-vars
  const [newProduct, handleEdit, clearProduct, setProduct] = useProduct({
    data: product,
  });
  React.useEffect(() => {
    handleEdit({
      field: "product",
      value: product,
    });
  }, [product._id]);
  console.log(newProduct);
  if (newProduct._id === "product") return null;
  return (
    <>
      <ProductContext.Provider value={{ product: newProduct, handleEdit }}>
        <Paper sx={{ p: 1, width: "100%" }}>
          <h1>{product.name}</h1>

          <Options />
          <ParameterSelect />
          <Subcomponents />
        </Paper>
      </ProductContext.Provider>
    </>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object,
};
