import React from "react";
import PropTypes from "prop-types";
// import { VBXDataGrid } from "../common/vbxDataGrid/VBXDataGrid";
import { Grid, Paper, Button, Typography } from "@mui/material";
import { columns, endpoints, titles } from "../components/common/utils";
import { useCustomersData } from "../hooks/useCustomerData";
import { NewItemDialog } from "../components/common/itemList";
import { CustomerView } from "../features/customer/components/CustomerView";
import { NoCustomersOverlay } from "../features/customer/utils/NoCustomersOverlay";
import { AddCircle } from "@mui/icons-material";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { HelpContext } from "../Content";
import { steps } from "../utils/introSteps";

export const Customer = ({ options }) => {
  const [customerData, setCustomerData] = React.useState();
  const [dialogOpen, setDialogOpen] = React.useState(
    options.createNew ? true : false
  );

  const { data: customers } = useCustomersData();
  const { setHelp } = React.useContext(HelpContext);
  // const handleSelect = (e, d) => {
  //   if (e.length == 0) {
  //     setSelectedCustomer([]);
  //     setCustomerData();
  //   } else {
  //     setSelectedCustomer(e[e.length - 1]);
  //     setCustomerData(d.filter((filter) => filter._id == e[e.length - 1])[0]);
  //   }
  // };
  // const handleSelectionModelChange = (model) => {
  //   const details = customers.filter((r) => model.includes(r._id));
  //   if (model.length == 0) {
  //     setSelectedCustomer([]);
  //     setCustomerData();
  //   } else {
  //     setSelectedCustomer(model[model.length - 1]);
  //     setCustomerData(
  //       details.filter((filter) => filter._id == model[model.length - 1])[0]
  //     );
  //   }
  // };
  // const handleCustomerAdded = () => {
  //   resetDataTypeKey();
  //   setDialogOpen(false);
  // };
  const handleBack = () => {
    // setSelectedCustomer([]);
    setHelp(steps["Customers"]);
    setCustomerData();
  };
  // let d =
  //   new Date().getFullYear() +
  //   "-" +
  //   `${new Date().getMonth() + 1 < 10 ? "0" : ""}${new Date().getMonth() + 1}` +
  //   "-" +
  //   `${new Date().getDate() + 1 < 10 ? "0" : ""}${new Date().getDate()}`;

  if (!customers) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {!customerData ? (
        <Grid
          container
          justifyContent="flex-start"
          alignItems={"center"}
          direction="row"
          columnSpacing={2}
          rowSpacing={2}
          sx={{ mb: 2 }}
        >
          <Grid item>
            <Typography variant="h3">Customers </Typography>
          </Grid>
          <Grid item xs={4}>
            <Button
              color="primary"
              id="customers-new"
              variant="contained"
              startIcon={<AddCircle />}
              onClick={() => setDialogOpen(true)}
            >
              New Customer
            </Button>
            <NewItemDialog
              title={titles["customers"]}
              isOpen={dialogOpen}
              setOpen={setDialogOpen}
              fields={columns["customers"]}
              updateTable={"customers"}
              ENDPOINT={endpoints["customers"]}
            />
          </Grid>
        </Grid>
      ) : null}
      <Grid
        container
        justifyContent="center"
        rowSpacing={2}
        columnSpacing={2}
        sx={{ width: "100%", height: "80%" }}
      >
        {!customerData ? (
          <Grid item xs={12}>
            <Paper
              sx={{
                height: "100%",
              }}
              id="customers-table"
            >
              <DataGridPremium
                onRowClick={(e) => {
                  // setSelectedCustomer([e.row._id]);
                  setHelp(steps["ViewCustomer"]);
                  setCustomerData(e.row);
                }}
                getRowId={(row) => row._id}
                disableSelection
                components={{
                  NoRowsOverlay: NoCustomersOverlay,
                }}
                rows={customers}
                columns={columns.customers}
              />
            </Paper>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <CustomerView customer={customerData} handleBack={handleBack} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

Customer.propTypes = {
  selectedCustomer: PropTypes.object,
  user: PropTypes.string,
  options: PropTypes.object,
};
