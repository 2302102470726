import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../../../Content";
import { deleteOption } from "../../../api/optionApi";
import { UserContext } from "../../../App";

export function useOptionDelete() {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { user } = React.useContext(UserContext);

  return useMutation({
    mutationFn: (id) => {
      let pack = { id: id, user: user.id };
      return deleteOption(pack);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("options");
      queryClient.invalidateQueries("components");
      setSnack({
        open: true,
        message: "Option deleted.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Option could not be deleted.",
        severity: "error",
      });
    },
  });
}
