import axios from "axios";

const settingsApi = axios.create();

export const getSettings = async (user) => {
  const response = await settingsApi.get(`/api/settings`, {
    params: { user: user },
  });
  return response.data;
};

export const updateSettings = async ({ user, pack }) => {
  const response = await settingsApi.patch(`/api/settings?user=${user}`, pack);

  return response.data;
};
