/* eslint-disable react/prop-types */
import React from "react";
import ReactDOM from "react-dom/client";

import { ProductsTableComponent } from "./ProductsTableComponent";

export class ProductsTable {
  static get isReadOnlySupported() {
    return true;
  }
  static get toolbox() {
    return {
      title: "Products Table",
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }
  /**
   * Render plugin`s main Element and fill it with saved data
   *
   * @param {object} params - tool constructor options
   * @param {ListData} params.data - previously saved data
   * @param {object} params.config - user config for Tool
   * @param {object} params.api - Editor.js API
   * @param {boolean} params.readOnly - read-only mode flag
   */
  constructor({ config, readOnly, data }) {
    this.readOnly = readOnly;
    this.config = config;
    this.data = data;
    this.wrapper = undefined;
    this.columns = this.data.columns;
    if (this.data.rows) {
      this.data.rows.forEach((row) => {
        let options = row.options;
        if (options?.length) {
          options.forEach((option) => {
            let foundOptionVar = this.config.variables.find(
              (variable) => variable.field === option.name
            );
            if (!foundOptionVar) {
              this.config.variables.push({
                headerName: option.name,
                field: option.name,
                valueFormatter: (props) => props.value,
              });
            }
          });
        }
      });
    }

    // Bind methods
    this.renderSettings = this.renderSettings.bind(this);
    this.render = this.render.bind(this);
    this.save = this.save.bind(this);
    this.setColumns = this.setColumns.bind(this);
    this.handleHeaderSelect = this.handleHeaderSelect.bind(this);
  }

  setColumns(columns) {
    this.columns = columns;
  }

  renderSettings() {
    const wrapper = document.createElement("div");

    return wrapper;
  }

  render() {
    const headerDiv = document.createElement("div");
    ReactDOM.createRoot(headerDiv).render(
      <ProductsTableComponent
        readOnly={this.readOnly}
        columns={this.data.columns}
        rows={this.data.rows}
        variables={this.config.variables}
        setColumns={this.setColumns}
      />
    );
    return headerDiv;
  }

  save() {
    // Save selected headers
    return {
      columns: this.columns,
    };
  }

  handleHeaderSelect(header, value) {
    // Update selected header option
    this.selectedHeaders[header] = value;
  }
}
