import axios from "axios";

const messageApi = axios.create();

export const getMessages = async (project) => {
  const response = await messageApi.get("/api/message", {
    params: { project: project },
  });
  return response.data;
};

export const createMessage = async (pack) => {
  //   for (const value of pack.values()) {
  //   }
  try {
    return await messageApi.post("/api/message", pack, { timeout: 5000 });
  } catch (err) {
    console.log("Post to messages failed!");
  }
};
