export const steps = {
  dashboard: [
    // {
    //   intro: "This is your dashboard.",
    //   position: "bottom",
    //   tooltipClass: "customStep",
    // },
    {
      intro: "You can see your assigned tasks here.",
      position: "bottom",
      tooltipClass: "customStep",
      element: "#tasks",
    },
    {
      intro: "You can flip through the projects you are assigned to here.",
      position: "bottom",
      tooltipClass: "customStep",
      element: ".projects",
    },
    {
      intro: "Press this button to show this tutorial again.",
      position: "bottom",
      tooltipClass: "customStep",
      element: "#help",
    },
    {
      intro:
        "Click on your profile here to edit your profile picture or log out.",
      position: "bottom",
      tooltipClass: "customStep",
      element: "#profile",
    },
  ],
};
