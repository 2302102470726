//usePersonDelete
import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../../../Content";
import { deletePerson } from "../../../api/peopleApi";

export function usePersonDelete() {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  return useMutation({
    mutationFn: ({ id }) => {
      return deletePerson(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("people");
      setSnack({
        open: true,
        message: "Person deleted.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Person could not be deleted.",
        severity: "error",
      });
    },
  });
}
