import { Container, Paper, Stack, Typography } from "@mui/material";
import React from "react";

export const GoVerify = () => {
  const success = window.location.href.includes("success=true");

  return (
    <Container maxWidth="xs">
      <Paper sx={{ marginTop: 12, width: 320, padding: 4 }}>
        <form>
          <Stack spacing={2} alignItems="center">
            {success ? (
              <Typography variant="h6">
                Check your email for a confirmation link.
              </Typography>
            ) : (
              <Typography variant="h6">
                Something went wrong. Please try again.
              </Typography>
            )}
          </Stack>
        </form>
      </Paper>
    </Container>
  );
};
