import { Mail, Password } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { UserContext } from "../../../App";
import { login } from "../../../api/userApi";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../hooks/useQuery";

export const Invited = () => {
  const { setUser } = React.useContext(UserContext);
  const navigate = useNavigate();
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    stayLoggedIn: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  let query = useQuery();

  const loginUser = async () => {
    const returnedUser = await login({
      email: values.email,
      password: values.password,
      stayLoggedIn: values.stayLoggedIn,
      token: query.get("token"),
    });
    setUser(returnedUser);

    if (returnedUser.status === "active") {
      navigate("/app");
    }
    if (returnedUser.token) {
      localStorage.setItem("user", returnedUser.token);
    }
  };

  const handleRegisterClick = () => {
    setUser((prev) => ({ ...prev, userType: "register", status: "register" }));
  };

  return (
    <Container maxWidth="xs">
      <Paper sx={{ marginTop: 12, width: 320, padding: 4 }}>
        <form>
          <Stack spacing={2} alignItems="center">
            <Typography variant="h6">Welcome to Manufacture.app!</Typography>
            <Typography variant="subtitle">
              Please set your password and login.
            </Typography>
            <TextField
              fullWidth
              label="Email"
              autoComplete="username"
              value={values.email}
              onChange={handleChange("email")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Mail />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              fullWidth
              label="Password"
              type="password"
              autoComplete={"current-password"}
              value={values.password}
              onChange={handleChange("password")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Password />
                  </InputAdornment>
                ),
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.stayLoggedIn}
                  onChange={(e) =>
                    setValues({ ...values, stayLoggedIn: e.target.checked })
                  }
                />
              }
              label="Stay logged in?"
            />
            <Button
              variant="contained"
              fullWidth
              color="primary"
              onClick={loginUser}
            >
              Login
            </Button>
            <Typography>
              New to our platform?{" "}
              <Box
                component="span"
                onClick={handleRegisterClick}
                sx={{ color: "#9155fd", cursor: "pointer" }}
              >
                Create an account
              </Box>
            </Typography>
          </Stack>
        </form>
      </Paper>
    </Container>
  );
};
