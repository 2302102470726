import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../Content";
import { updateManyComponents } from "../api/componentApi";

export function useComponentsUpdateMany(snack) {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  return useMutation({
    mutationFn: ({ pack }) => {
      updateManyComponents(pack);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("components");
      if (snack) {
        setSnack({
          open: true,
          message: snack,
          severity: "success",
        });
      }
    },
    onError: () => {
      setSnack({
        open: true,
        message: "Components could not be updated.",
        severity: "error",
      });
    },
  });
}
