import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { Delete, Edit, KeyboardBackspace } from "@mui/icons-material";
import { columns } from "../../../components/common/utils";
import { EditCustomerDialog } from "./EditCustomerDialog";
import { useProjectsData } from "../../../hooks/useProjectsData";
import { ProjectCard } from "../../project/components/ProjectCard";
import { useCustomerDelete } from "../hooks/useCustomerDelete";
import { FinancialCard } from "./FinancialCard";
import { useFinancialData } from "../../financial/hooks/useFinancialData";

export const CustomerView = ({ customer, handleBack }) => {
  const { data: projects } = useProjectsData();
  const { data: financials } = useFinancialData();
  if (!projects || !financials) {
    return <div>Loading...</div>;
  }
  return (
    <div>
      <Button
        onClick={handleBack}
        startIcon={<KeyboardBackspace />}
        sx={{ mb: 1 }}
      >
        Back
      </Button>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <CustomerInfoCard customer={customer} handleBack={handleBack} />
          <FinancialCard
            customer={customer}
            projects={projects.filter((x) => x.customer._id === customer._id)}
            financials={financials.filter(
              (f) => !f.project && f.customer === customer._id
            )}
          />
        </Grid>
        <Grid item xs={8} id="customer-projects">
          {projects &&
            projects
              .filter((x) => x.customer._id == customer._id)
              .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate))
              .map((project) => (
                <ProjectCard key={project._id} project={project} />
              ))}
        </Grid>
      </Grid>
    </div>
  );
};

CustomerView.propTypes = {
  customer: PropTypes.object,
  handleBack: PropTypes.func,
};

const CustomerInfoCard = ({ customer, handleBack }) => {
  const [open, setOpen] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const { mutate: deleteCustomer } = useCustomerDelete();
  const handleDelete = async () => {
    if (deleting) {
      deleteCustomer({ pack: customer._id });
      handleBack();
    } else {
      setDeleting(true);
      setTimeout(() => {
        setDeleting(false);
      }, 3000);
    }
  };

  return (
    <Card id="customer-card">
      <CardContent>
        <Typography sx={{ mb: 1 }} variant="h4">
          {" "}
          {customer.firstName} {customer.lastName}
        </Typography>
        {/* <Typography variant="body1">
          {" "}
          {`${customer.customer.firstName} ${customer.customer.lastName}`}
        </Typography> */}
        <Typography variant="body1"> {customer.phone}</Typography>

        <Typography sx={{ mb: 1 }} variant="body1">
          {" "}
          {customer.email}
        </Typography>
        <Typography variant="body1"> {customer.address}</Typography>

        {/* <Typography variant="body1">
            {" "}
            Created: {new Date(customer.creationDate).toLocaleDateString("en-US")}
          </Typography>
          <Typography sx={{ mb: 1 }} variant="body1">
            Due: {new Date(customer.dueDate).toLocaleDateString("en-US")}
          </Typography> */}
      </CardContent>
      <CardActions>
        <IconButton onClick={() => setOpen(true)}>
          <Edit />
        </IconButton>
        <IconButton onClick={handleDelete}>
          <Delete color={deleting ? "error" : "default"} />
        </IconButton>
        <EditCustomerDialog
          columns={columns}
          data={customer}
          isOpen={open}
          setOpen={setOpen}
        />
      </CardActions>
    </Card>
  );
};
CustomerInfoCard.propTypes = {
  customer: PropTypes.obj,
  handleBack: PropTypes.func,
};
