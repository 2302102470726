import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { UserContext } from "../../../App";
import { SnackbarContext } from "../../../Content";
import { createFinancial } from "../../../api/financialApi";

export function useFinancialCreate(project) {
  const { user } = React.useContext(UserContext);
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);

  return useMutation({
    mutationFn: ({ pack }) => {
      pack.user = user.id;
      delete pack._id;
      delete pack.file;
      return createFinancial(pack);
    },
    onSuccess: () => {
      queryClient.invalidateQueries("financial");
      if (project) {
        queryClient.invalidateQueries(["project", project._id]);
        queryClient.invalidateQueries("projects");
      }
      setSnack({
        open: true,
        message: "Entry created.",
        severity: "success",
      });
    },
    onError: () => {
      queryClient.invalidateQueries("financial");
      setSnack({
        open: true,
        message: "Entry could not be created.",
        severity: "error",
      });
    },
  });
}
