import axios from "axios";

const supplierApi = axios.create();

export const getSuppliers = async (user) => {
  const response = await supplierApi.get(`/api/supplier?user=${user}`);
  return response.data;
};

export const updateSupplier = async ({ id, pack }) => {
  return await supplierApi.patch(`/api/supplier/${id}`, pack);
};

export const updateOrder = async ({ id, pack }) => {
  return await supplierApi.patch(`/api/supplier/${id}/o`, pack);
};

export const deleteSupplier = async (id) => {
  return await supplierApi.delete("/api/supplier", { params: { id: id } });
};
export const createSupplier = async (pack) => {
  return await supplierApi.post("/api/supplier", pack);
};
