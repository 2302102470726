import React from "react";
import { useQuery, useQueryClient } from "react-query";
import { getComponent, getComponents } from "../api/componentApi";
import { UserContext } from "../App";

export const useComponentsData = (onSuccess, onError) => {
  const { user } = React.useContext(UserContext);
  const queryClient = useQueryClient();

  return useQuery("components", () => getComponents({ user: user.id }), {
    onSuccess: (data) => {
      // // Cache each component individually
      data.forEach((component) => {
        queryClient.setQueryData(["component", component._id], component);
      });

      // Call the provided onSuccess handler
      if (onSuccess) onSuccess(data);
    },
    onError,
    staleTime: 1000 * 60 * 10, // 10 minutes
  });
};

export const useComponentData = (componentId) => {
  const queryClient = useQueryClient();

  // Define a query function that tries to get the data from the cache first
  const fetchComponent = async () => {
    // Try to get the component from the cache
    const cachedComponent = queryClient.getQueryData([
      "component",
      componentId,
    ]);

    // If found in the cache, return it
    if (cachedComponent) {
      return cachedComponent;
    }

    // If not found in the cache, fetch it from your API
    return await getComponent(componentId);
  };

  return useQuery(["component", componentId], fetchComponent, {
    staleTime: Infinity,
    // ... other options you might need, like refetchOnWindowFocus, etc.
  });
};
