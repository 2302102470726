import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { ProductCard } from "../../productCard/ProductCard";
import { useComponentsData } from "../../../../../hooks/useComponentsData";
import PropTypes from "prop-types";
import { CustomIconButton } from "../../../../../utils/CustomIconButtom";
import { Add } from "@mui/icons-material";
import { useProjectUpdate } from "../../../hooks/useProjectUpdate";
import { SelectedProjectContext } from "../../ProjectView";
import _ from "lodash";

const SelectionContext = React.createContext();

export const EditZone = ({ zone }) => {
  const project = React.useContext(SelectedProjectContext);
  const [open, setOpen] = useState(false);
  const { data: components } = useComponentsData();

  const [selection, setSelection] = useState([]);
  const handleClickOpen = () => {
    setOpen(true);
    setSelection(
      project.products
        .filter((p) => p.zone === zone)
        .map((p) => ({
          ...p,
          component: components?.find((c) => c._id === p.componentId),
        })) || []
    );
  };
  const onClose = () => {
    setOpen(false);
    setSelection([]);
  };
  const [products, setProducts] = useState([]);
  const [options, setOptions] = useState([]);
  const [view, setView] = useState("full");
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    if (!components) return;
    setProducts(components?.filter((c) => c.isProduct === true));
  }, [components]);

  useEffect(() => {
    const cats = new Set(products.map((p) => p.categories).flat());
    setCategories([...cats]);
    setSelection((prev) =>
      prev.map((p) => ({
        ...p,
        component: components?.find((c) => c._id === p.componentId),
      }))
    );
  }, [products]);

  const handleSelection = (product) => {
    setSelection((prev) => {
      let newSelection = [...prev];
      newSelection.push({ ...product, id: prev.length + 1 });
      return newSelection;
    });
  };
  const { mutate: updateProject } = useProjectUpdate(project?._id);
  const handleAdd = () => {
    const pack = {
      updateType: ["products", "add"],
      products: selection.map((p) => ({ ...p, zone: zone || "" })),
    };
    if (selection.length === 0) {
      updateProject({
        id: project._id,
        pack: {
          updateType: ["products", "remove"],
          zone: zone,
        },
      });
    } else {
      console.log(pack);
      updateProject({
        id: project._id,
        pack: pack,
      });
    }
  };

  React.useEffect(() => {
    let options = [];
    selection.forEach((p) => {
      p.options.forEach((o) => {
        if (options.find((op) => op.name === o.name)) return;
        let option = _.cloneDeep(o);
        delete option.choice;
        options.push(option);
      });
    });
    console.log(options);
    setOptions(options);
  }, [selection]);

  const handleOptionSelect = (option, choice) => {
    setOptions((prev) => {
      let newOptions = [...prev];
      newOptions = newOptions.map((o) => {
        console.log(o, option);
        if (o.name === option.name) {
          return { ...o, choice: choice };
        }
        return o;
      });
      console.log(newOptions);
      return newOptions;
    });
  };

  const handleView = () => {
    setView((prev) => (prev === "compact" ? "full" : "compact"));
  };

  const [zonesOpen, setZonesOpen] = useState(false);

  const handleDelete = () => {
    if (selection.length > 0) {
      setZonesOpen(true);
    } else {
      const pack = {
        updateType: ["zones", "remove"],
        zone: zone,
      };
      updateProject({
        id: project._id,
        pack: pack,
      });
      setOpen(false);
    }
  };

  const handleSwitchProducts = (newZone) => {
    const pack = {
      updateType: ["zones", "switch"],
      oldZone: zone,
      newZone: newZone,
    };
    updateProject({
      id: project._id,
      pack: pack,
    });
    setOpen(false);
    setZonesOpen(false);
  };

  const handleDeleteZone = () => {
    const pack = {
      updateType: ["zones", "remove"],
      zone: zone,
    };
    updateProject({
      id: project._id,
      pack: pack,
    });
    setOpen(false);
    setZonesOpen(false);
  };

  console.log({ project, selection });

  return (
    <>
      <SelectionContext.Provider value={{ selection, setSelection }}>
        <Button variant="contained" color="primary" onClick={handleClickOpen}>
          Edit
        </Button>

        <Dialog open={open} maxWidth="xl" fullWidth>
          <DialogTitle
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="h5">{zone}</Typography>
            {zone === "" ? (
              <Button
                onClick={handleDelete}
                variant="outlined"
                disabled={!selection.length || !project.zones.length}
              >
                Switch
              </Button>
            ) : (
              <Button onClick={handleDelete} variant="contained" color="error">
                Delete
              </Button>
            )}
          </DialogTitle>

          <DialogContent sx={{ display: "flex" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {categories.map((c) => (
                <List key={c} sx={{ p: 1, width: 300 }}>
                  <Typography variant="h6">{c}</Typography>
                  {products
                    .filter((p) => p.categories.includes(c))
                    .map((p) => (
                      <ListItem
                        key={p.name}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          gap: 1,
                          mt: 1,
                        }}
                      >
                        {p.name}
                        <CustomIconButton
                          variant="outlined"
                          icon={<Add />}
                          onClick={() => handleSelection(p)}
                        />
                      </ListItem>
                    ))}
                </List>
              ))}
              <List sx={{ p: 1, width: 300 }}>
                <Typography variant="h5">
                  {categories.length === 0 ? "Products" : "No Category"}
                </Typography>
                {products
                  .filter((p) => p.categories.length === 0)
                  .map((p) => (
                    <ListItem
                      key={p.name}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 1,
                        mt: 1,
                      }}
                    >
                      {p.name}
                      <CustomIconButton
                        variant="outlined"
                        icon={<Add />}
                        onClick={() => handleSelection(p)}
                      />
                    </ListItem>
                  ))}
              </List>
            </Box>

            <Box sx={{ width: "100%" }}>
              <Box
                sx={{ display: "flex", gap: 1, mb: 1, mt: 1, flexWrap: "wrap" }}
              >
                {options?.map((o) => (
                  <FormControl key={o.name} sx={{ width: 200 }}>
                    <InputLabel>{o.name}</InputLabel>
                    <Select
                      option={o}
                      value={o.choice}
                      label={o.name}
                      renderValue={(v) => v.name}
                      onChange={(e) => handleOptionSelect(o, e.target.value)}
                    >
                      {o.subcomponents?.map((c) => (
                        <MenuItem key={c} value={c}>
                          {c.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ))}
              </Box>
              <Divider />
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <FormControlLabel
                  label="Compact View"
                  control={
                    <Switch
                      checked={view === "compact"}
                      onChange={handleView}
                      color="primary"
                    />
                  }
                />
              </Box>
              <Grid container gap={1} sx={{ mt: 1 }}>
                {selection.map((p) => (
                  <Grid item key={p.id} xs={view === "compact" ? 12 : "auto"}>
                    <ProductCard
                      product={p}
                      setSelection={setSelection}
                      options={options}
                      view={view}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="outlined">
              Close
            </Button>
            <Button onClick={handleAdd} variant="contained">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={zonesOpen} onClose={() => setZonesOpen(false)}>
          {zone == "" ? (
            <>
              <DialogTitle>Select a zone to move the products to.</DialogTitle>
              <DialogContent>
                {project.zones.map((z) => (
                  <Button key={z} onClick={() => handleSwitchProducts(z)}>
                    {z}
                  </Button>
                ))}
              </DialogContent>
            </>
          ) : (
            <>
              <DialogTitle>
                Are you sure you want to delete this zone?
              </DialogTitle>
              <DialogContent>
                <Typography>
                  You can choose to move them to another zone.
                </Typography>

                {project.zones
                  .filter((z) => z !== zone)
                  .map((z) => (
                    <Button key={z} onClick={() => handleSwitchProducts(z)}>
                      {z}
                    </Button>
                  ))}
                <Button
                  key={"No Zone"}
                  onClick={() => handleSwitchProducts("")}
                >
                  No Zone
                </Button>
                <Typography>Or delete them.</Typography>
                <Button onClick={handleDeleteZone} color="error">
                  Delete All
                </Button>
              </DialogContent>
            </>
          )}
        </Dialog>
      </SelectionContext.Provider>
    </>
  );
};

EditZone.propTypes = {
  selection: PropTypes.array,
  zone: PropTypes.string,
  setSelection: PropTypes.func,
};
