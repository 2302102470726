import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";
import { ProductContext } from "./ProductCard";
export const Option = ({ option }) => {
  const { handleEdit } = React.useContext(ProductContext);
  const [choice, setChoice] = React.useState({});
  const handleChoice = (e) => {
    setChoice(e.target.value);
    handleEdit({
      type: "update",
      value: { ...option, choice: e.target.value },
      field: "options",
    });
  };

  return (
    <div>
      <FormControl key={option.name}>
        <InputLabel>{option.name}</InputLabel>
        <Select
          key={option.name}
          sx={{ width: 300 }}
          value={choice}
          label={option.name}
          renderValue={(v) => v.name}
          onChange={handleChoice}
        >
          {option.subcomponents.map((subcomp) => (
            <MenuItem key={subcomp} value={subcomp}>
              {subcomp.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

Option.propTypes = {
  option: PropTypes.object,
  subcomponents: PropTypes.array,
};
