import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { SnackbarContext } from "../../../Content";
import { createFile } from "../../../api/fileApi";
import { UserContext } from "../../../App";
// import { UserContext } from "../../../App";

export function useFileCreate(project) {
  const queryClient = useQueryClient();
  const { setSnack } = React.useContext(SnackbarContext);
  const { user } = React.useContext(UserContext);
  // const user = React.useContext(UserContext);
  return useMutation({
    mutationFn: ({ pack }) => {
      // pack.user = user;
      pack.append("user", user.id);
      // let formData = new FormData();
      // for (let [key, value] of Object.entries(pack)) {
      //   formData.append(key, key == "file" ? value : JSON.stringify(value));
      // }
      return createFile(pack);
    },
    onSuccess: () => {
      if (project) {
        queryClient.invalidateQueries(["project", project._id]);
        queryClient.invalidateQueries("projects");
      }

      queryClient.invalidateQueries("files");

      setSnack({
        open: true,
        message: "File created.",
        severity: "success",
      });
    },
    onError: () => {
      setSnack({
        open: true,
        message: "File could not be created.",
        severity: "error",
      });
    },
  });
}
